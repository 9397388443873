import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
    RiSendPlaneFill
} from 'react-icons/ri';
import { 
    FaRegWindowMaximize
} from 'react-icons/fa';
import fetchData from '../../fetchData';
import ReportButton from './ReportButton';
import {chatActions} from '../../actions/actions';
import { IChatLog, IChatLogMessage } from '../../interfaces/IChatLog';

const mapStateToProps = (state:any) => ({
    chat:state.chat,
    session:state.session,
    userinfo:state.userinfo
})

const mapDispatchToProps = {
    setGlobalChat: chatActions.setGlobalChat
}

class GlobalChatModuleState {

    scrolledBottom:boolean;
    showNotif:boolean;
    anonymous:boolean;
    error:string;
    message:string;
    constructor() {
        this.message = "";
        this.scrolledBottom = true;
        this.showNotif = false;
        this.error = "";
        this.anonymous = false;
    }
}

interface GlobalChatModuleProps {
    visible:boolean;
    chat: {
        globalChat: Array<any>
    },
    session: {
        loggedin:boolean,
        admins:Array<string>
    },
    userinfo: {
        username: string
    }, 
    setGlobalChat: (globalChat:any) => {}
}

class GlobalChatModuleBind extends Component<GlobalChatModuleProps> {

    state:GlobalChatModuleState;
    private bumper = React.createRef<HTMLDivElement>();
    private scrollContainer = React.createRef<HTMLDivElement>();
    
    constructor(props:GlobalChatModuleProps) {
        super(props);
        this.state = new GlobalChatModuleState();
    }

    handleMessage(e:any) {
        this.setState({message:e.target.value});
    }

    handleKey(e:any) {
        if(e.which === 13) {
            e.preventDefault();
            this.sendMessage();
        }
    }

    componentDidMount() {
        fetchData("/api/getGlobalChat")
        .then((data:any )=> {
            this.props.setGlobalChat(data.chat);
        });
    }

    sendMessage() {
        let message = this.state.message;
        if(message.length > 500) {
            alert("Message too long. Max length 500 characters. Current Length: " + message.length);
            return;
        }
        message = message.replace("\n", " ");
        if(message.length === 0) {
            return;
        }
        fetch('/api/addChatGlobal/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                anonymous:this.state.anonymous,
                message
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                this.setState({error:data.message});
            } else {
                this.setState({message:'', error: ''});
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }
    

    componentDidUpdate(prevProps:GlobalChatModuleProps) {
        if(this.props.visible && !prevProps.visible) {
            if(this.bumper.current)
                this.bumper.current.scrollIntoView();
        }
        if(prevProps.chat.globalChat.length !== this.props.chat.globalChat.length) {
            if(this.props.visible && this.bumper.current && this.state.scrolledBottom) {
                this.bumper.current.scrollIntoView();
            } else {
                if(!this.state.scrolledBottom) {
                    this.setState({showNotif:true});
                }
            }
        } else {
            if(prevProps.chat.globalChat.length > 1) {
                let l = prevProps.chat.globalChat.length;
                let prevLast = prevProps.chat.globalChat[l - 1];
                let currentLast = this.props.chat.globalChat[l - 1];
                if((prevLast.messageid !== currentLast.messageid) && this.props.visible) {
                    if(this.state.scrolledBottom) {
                        if(this.bumper.current) 
                            this.bumper.current.scrollIntoView();   
                    } else {
                        this.setState({showNotif:true});
                    }
                }
            }
        }
    }

    checkScroll() {
        if(this.bumper.current !== null && this.scrollContainer.current !== null) {
            let contTop = this.scrollContainer.current.scrollTop;
            let scrollHeight = this.scrollContainer.current.scrollHeight;
            let contHeight = this.scrollContainer.current.clientHeight;
            let bottom = scrollHeight - contTop <= contHeight;
            this.setState({scrolledBottom:bottom});
            if(bottom) {
                this.setState({showNotif:false});
            }
        }
    }

    getDisplayName(message:IChatLogMessage) {
        let name = message.username;
        if(!isNaN(Number(message.userid))) {
            name += ` (${message.userid})`;
        }
        return name;
    }

    isAdmin(userid:string) {
        return this.props.session.admins.indexOf(userid) !== -1;
    }

    getNameClass(message:IChatLogMessage) {

        let className = "chat-username";
        if(this.isAdmin(message.userid)) {
            className += " chat-username-admin";
        }
        return className;
    }

    getTruncID(message:IChatLogMessage) {
        if(!isNaN(Number(message.userid))) {
            return "";
        } else {
            return message.userid.slice(0, 5);
        }
    }

    render() {
        return(
            <div 
                className="global-chat">
                <div 
                    className="global-chat-messages"
                    ref={this.scrollContainer}
                    onScroll={() => this.checkScroll()}>
                    <div className="chat-message-container">
                    {
                        this.props.chat.globalChat.map((message:IChatLogMessage) => 
                        <div className="chat-message" key={message.messageid}>
                            <span
                                title={new Date(message.timestamp).toLocaleDateString()} 
                                className="chat-timestamp">
                                    {new Date(message.timestamp).toLocaleTimeString()}
                            </span>
                            <span 
                                className={this.getNameClass(message)}
                                title={this.isAdmin(message.userid) ? "Admin" : ""}>
                                    {this.getDisplayName(message)}
                            </span>
                            <span
                                className="chat-userid">
                                {this.getTruncID(message)}
                            </span>
                            <ReportButton id={message.messageid}/>
                            <div 
                                dangerouslySetInnerHTML={{__html: message.message}}>
                            </div>
                        </div>
                        )
                    }
                    </div>
                    <div className="bumper" ref={this.bumper}></div>
                    {
                        this.state.showNotif ?
                        <div className="global-message-notif">
                            New Message
                        </div> : null
                    }
                </div>
                <div className="global-chat-input flex flex-col">
                    <div className="chat-options flex flex-row">
                        <div className="toggle-anonymous flex flex-row">
                            <div className="toggle-anonymous-label">
                                Anonymous:
                            </div>
                            <div 
                                className={"anonymous-checkbox " + 
                                    (this.state.anonymous ? "checked" : "")}
                                onClick={() => this.setState({anonymous:!this.state.anonymous})}>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <textarea 
                            rows={2} 
                            onChange={(e) => this.handleMessage(e)}
                            onKeyUp={(e) => this.handleKey(e)}
                            value={this.state.message}
                            placeholder="Type message here...">
                        </textarea>
                        <div className="send-chat flex flex-column">
                            <div
                                onClick={() => this.sendMessage()} 
                                className="send-chat-btn">
                                <RiSendPlaneFill style={{verticalAlign: 'middle'}}/>
                            </div>
                            <div 
                                title="Pop Out"
                                className="pop-out-window"
                                onClick={() => {
                                    window.open('/globalchat', 'popup', 'width=600, height=800');
                                    return false;
                                }}>
                                <FaRegWindowMaximize style={{verticalAlign: 'middle'}}/>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    this.state.error !== "" ?
                    <div className="global-chat-error">
                        {this.state.error}
                    </div> : null
                }
            </div>
        )
    }
}

const GlobalChatModule = connect(
    mapStateToProps, 
    mapDispatchToProps
)(GlobalChatModuleBind);
export default GlobalChatModule;