import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import '../../../css/profile/profile.scss';
import '../../../css/profile/items.scss';
import '../../../css/dialogue.scss';

import numberWithCommas from '../../numberWithCommas';

import Coin from '../Coin';

import {lineage} from '../Icons';

import DeleteAccountDialogue from './DeleteAccountDialogue';
import UpdateIconDialogue from './UpdateIconDialogue';
import NotVerified from './NotVerified';
import UserInfoFormItem from './UserInfoFormItem';
import SettingsDialogue from './SettingsDialogue';
import MutedMessage from './MutedMessage';
import AssetsContainer from './AssetsContainer';
import Crafting from './Crafting';

import History from './History';

import { 
    BiUpArrow,
    BiDownArrow
} from 'react-icons/bi';
import {
    HiOutlineTrash
} from 'react-icons/hi';
import {
    BsGearFill
} from 'react-icons/bs';

import {
    FaCoins
} from 'react-icons/fa';
import {IMyCoin, IUserFunds, IWallet} from '../../interfaces/IWallet';
import { UserItems, IItemCatalogue } from '../../interfaces/IItem';
import {IPerformance, IPerformanceHistory} from '../../interfaces/Performance';

import { datasetTemplate } from '../DatasetTemplate';
import { ICoinDataCollection, ICoinHistory } from '../../interfaces/ICoinInfo';
import { Loading } from '../Loading';
import { Link } from 'react-router-dom';
import Items from './Items';
import { Themes } from '../../Themes';
import DirectMessages from './DirectMessages/DirectMessages';
import { SortByGen } from '../../sort';
import ProfileMutualFundContainer from './ProfileMutualFundContainer';
import { IMutualFundStatCollection } from '../../interfaces/MutualFunds';
import UserFunds from './UserFunds';

const mapStateToProps = (state:any, props:any) => ({
    session: state.session,
    userinfo: state.userinfo,
    stats: state.stats,
    itemcatalogue: state.itemcatalogue,
    settings:state.settings,
    mutualfunds:state.mutualfunds
});

interface ProfileProps {
    session: {
        loggedin: boolean
    },
    userinfo: {
        username:string,
        email:string,
        wallet:IWallet,
        icon:string,
        verified:boolean,
        loaded:boolean,
        muted:string,
        items:UserItems,
        performance:IPerformanceHistory,
        mutualfunds:IUserFunds
    },
    itemcatalogue:IItemCatalogue,
    stats: {
        stats: {
            [key:string]:any
        },
        coinHistory: ICoinHistory,
        coinInfo: ICoinDataCollection
    },
    mutualfunds: {
        fundstats: IMutualFundStatCollection
    },
    settings: {
        theme: Themes
    }
}

enum ProfileViews {
    History,
    Assets,
    Items,
    DirectMessages,
    Funds
}

class ProfileState {
    iconDialogueVisible: boolean;
    deleteAccountDialogueVisible: boolean;
    settingsDialogueVisible: boolean;
    username:string;
    email:string;
    redirect:boolean;
    error:string;
    coins:Array<string>;
    activeView: ProfileViews;
    constructor() {
        this.iconDialogueVisible = false;
        this.deleteAccountDialogueVisible = false;
        this.settingsDialogueVisible = false;
        this.redirect = false;
        this.username = "";
        this.email = "";
        this.error = "";
        this.coins = [];
        this.activeView = ProfileViews.History;
    }
}

class ProfileBind extends Component<ProfileProps> {

    state: ProfileState;

    constructor(props:ProfileProps) {
        super(props);
        this.state = new ProfileState();
    }

    getNetWorth() {
        let wallet:IWallet = this.props.userinfo.wallet;
        let coins = Object.keys(wallet.coins);
        let netWorth:number = wallet.balance;
        coins.forEach((coin:string) => {
            if(this.props.stats.coinInfo.data[coin] !== undefined) {
                let value = this.props.stats.coinInfo.data[coin].price;
                netWorth += value * wallet.coins[coin].amt;
            }
        });
        let funds = Object.keys(this.props.userinfo.mutualfunds);
        funds.forEach((fund:string) => {
            if(this.props.mutualfunds.fundstats[fund] !== undefined) {
                netWorth += this.props.mutualfunds.fundstats[fund].price * this.props.userinfo.mutualfunds[fund].amt;
            }
        });
        return Math.round(netWorth * 100) / 100;
    }

    getGraphData() {
        
        if(!this.props.userinfo.loaded) return;
        let datasets: Array<any> = [];
        let dataset = {...datasetTemplate};

        if(this.props.settings.theme === Themes.HALLOWEEN) {
            dataset.borderColor = 'rgb(255, 176, 124)';
            dataset.pointBorderColor = 'rgb(255, 176, 124)';
            dataset.pointHoverBackgroundColor = 'rgb(255, 176, 124)';
            dataset.pointHoverBorderColor = 'rgb(255, 176, 124)';
        }

        let data:any = {};

        dataset.label = "Performance History";

        let labels:any = [];
        let values:any = [];
        let performance:IPerformanceHistory = this.props.userinfo.performance;
        performance.forEach((day:IPerformance) => {
            labels.push(new Date(day.timestamp).toLocaleDateString());
            values.push(day.worth);
        })

        labels.push(new Date().toLocaleString());
        values.push(this.getNetWorth());

        data.labels = labels;
        dataset.data = values;

        datasets.push(dataset);
        data.datasets = datasets;
        return data;
    }

    deleted() {
        this.setState({redirect:true})
    }

    toggleIconDialogue() {
        this.setState({
            iconDialogueVisible: !this.state.iconDialogueVisible
        });
        return;
    }

    toggleDeleteDialogue() {
        this.setState({
            deleteAccountDialogueVisible: !this.state.deleteAccountDialogueVisible
        });
    }

    toggleSettingsDialogue() {
        this.setState({
            settingsDialogueVisible: !this.state.settingsDialogueVisible
        });
    }
    
    formatPrice(price:number):String {
        let priceString = '';
        if(price < 0) priceString += "-";
        priceString += "$" + numberWithCommas(Math.round(Math.abs(price) * 100) / 100);
        return priceString;
    }

    filterCoinsFromWallet(wallet:any) {
        let coins = Object.keys(wallet.coins);
        let activeCoins = coins.filter((c:string) => {
            return wallet.coins[c].amt > 0;
        })
        return activeCoins;
    }

    componentDidMount() {
        if(this.props.userinfo.wallet !== undefined) {
            let activeCoins = this.filterCoinsFromWallet(this.props.userinfo.wallet);
            SortByGen(activeCoins);
            this.setState({
                coins:activeCoins
            })
        }
    }

    componentDidUpdate(prevProps:ProfileProps) {
        let activePrev = this.filterCoinsFromWallet(prevProps.userinfo.wallet);
        let activeNow = this.filterCoinsFromWallet(this.props.userinfo.wallet);
        if(activePrev.length !== activeNow.length) {
            SortByGen(activeNow);
            this.setState({
                coins:activeNow
            })
        }
    }

    renderHistory() {
        let performanceData = this.getGraphData();
        return <History performanceData={performanceData} />
    }

    activeViewTabClass(view:ProfileViews) {
        if(this.state.activeView === view) {
            return "active";
        } else {
            return "";
        }
    }

    render() {
        if(!this.props.session.loggedin) {
            return(
                <Redirect to="/login" />
            )
        }

        let notLoaded = false;
        if(this.props.stats.stats['aki'] === {}) notLoaded = true;
        if(this.props.stats.coinInfo.data === undefined) notLoaded = true;
        if(this.props.stats.coinHistory['aki'] === undefined) notLoaded = true;
        if(!this.props.userinfo.loaded) notLoaded = true;
        if(notLoaded) {
            return (
                <div className="not-loaded-outer">
                    <Loading />;
                </div> 
            ) 
        }

        let uniqueAssets = 0;
        let totalAssets = 0;
        let netWorth = this.getNetWorth();
        
        if(Object.keys(this.props.stats.stats).length === 0) return null;
        
        let coins = Object.keys(this.props.userinfo.wallet.coins);

        let filteredCoins:Array<string> = [];

        coins.forEach((coin:string) => {

            let myCoin:IMyCoin = this.props.userinfo.wallet.coins[coin];
            
            if(myCoin.amt > 0) {
                uniqueAssets++;
                totalAssets += myCoin.amt;
                filteredCoins.push(coin);
            }

        });

        SortByGen(filteredCoins);

        let performance:IPerformanceHistory = this.props.userinfo.performance;
        let pLength = performance.length;
        
        let beforeP:IPerformance = performance[pLength - 1];

        let delta = Math.round((netWorth - beforeP.worth) * 100) / 100;
        let deltaP = Math.round((delta / Math.abs(beforeP.worth)) * 10000) / 100;

        let deltaClass = delta > 0 ? "green" : "red";

        let showMuted = false;
        let muted:any = this.props.userinfo.muted;
        if(this.props.userinfo.muted !== null) {
            muted = JSON.parse(muted);
            if(muted.until < new Date().getTime()) {
                showMuted = false;
            } else {
                showMuted = true;
            }
        }
        
        if(this.state.redirect) {
            return(
                <Redirect to="/logout" />
            )
        }

        return(
            <div className="container scroll-space">
                <div className="container-content">
                    <div className="container-section profile-container">
                        <div className="section-background">
                        </div>
                        <div className="section-content">
                            <div className="header">
                                Profile
                            </div>
                            <div className="profile-row">
                                <div className="profile-card profile-personal">
                                    <div
                                        onClick={() => this.toggleIconDialogue()} 
                                        className="profile-icon">
                                        {
                                            this.props.userinfo.icon === "none" ||
                                            this.props.userinfo.icon === null ?
                                            <Coin name={"blank"} />
                                            :
                                            <Coin name={this.props.userinfo.icon} />
                                        }
                                    </div>
                                    
                                    <UserInfoFormItem />

                                    <div className="reset-password">
                                        <Link to="/resetPassword">
                                            Change Password
                                        </Link>
                                    </div>
                                    
                                    <div className="account-actions">
                                        <div 
                                            className="account-action-item delete-account-icon" 
                                            title="Delete Account"
                                            onClick={() => this.toggleDeleteDialogue()}>
                                            <HiOutlineTrash style={{verticalAlign:'middle'}}/>
                                        </div>
                                        <div 
                                            className="account-action-item update-settings"
                                            title="Account Settings"
                                            onClick={() => this.toggleSettingsDialogue()}>
                                            <BsGearFill style={{verticalAlign:'middle'}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-card profile-balance">
                                    <div className="profile-balance-content">
                                        <div className={"profile-balance-amt"}>
                                            <span className="coin-icon">
                                                <FaCoins style={{verticalAlign: 'middle'}}/>
                                            </span>
                                            {numberWithCommas(Math.round(netWorth * 100)/100)}
                                        </div>
                                        <div className={`delta-networth ${deltaClass}`}>
                                            {
                                                delta > 0 ?
                                                <BiUpArrow style={{verticalAlign: 'middle'}}/>
                                                :
                                                <BiDownArrow style={{verticalAlign: 'middle'}}/>
                                            }
                                            {
                                                "$" + numberWithCommas(Math.abs(delta))
                                            }
                                        </div>
                                        <div className={`delta-p-networth ${deltaClass}`}>
                                            {
                                                deltaP > 0 ?
                                                <BiUpArrow style={{verticalAlign: 'middle'}}/>
                                                :
                                                <BiDownArrow style={{verticalAlign: 'middle'}}/>
                                            }
                                            {
                                                numberWithCommas(Math.abs(deltaP)) + "%"
                                            }
                                        </div>
                                    </div>
                                    <div className="profile-balance-items">
                                        <div className="profile-balance-item">
                                            <div className="data">
                                                {uniqueAssets}
                                            </div>
                                            <div className="label">
                                                Unique Assets
                                            </div>
                                        </div>
                                        <div className="profile-balance-item">
                                            <div className="data">
                                                {totalAssets}
                                            </div>
                                            <div className="label">
                                                Total Assets
                                            </div>
                                        </div>
                                        <div className="profile-balance-item">
                                            <div className=
                                                    {`data ${this.props.userinfo.wallet.balance > 0 
                                                        ? "green" : "red"}`}>
                                                {this.formatPrice(this.props.userinfo.wallet.balance)}
                                            </div>
                                            <div className="label">
                                                Current Balance
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    { this.props.userinfo.verified ? null : <NotVerified />}

                    { showMuted ? <MutedMessage muted={muted}/> : null }

                    <div className="profile-view-select flex flex-row">
                        <div 
                            className={`profile-view-tab ${this.activeViewTabClass(ProfileViews.History)}`}
                            onClick={() => this.setState({activeView: ProfileViews.History})}>
                            History
                        </div>
                        <div 
                            className={`profile-view-tab ${this.activeViewTabClass(ProfileViews.Assets)}`}
                            onClick={() => this.setState({activeView: ProfileViews.Assets})}>
                            Assets
                        </div>
                        <div 
                            className={`profile-view-tab ${this.activeViewTabClass(ProfileViews.Items)}`}
                            onClick={() => this.setState({activeView: ProfileViews.Items})}>
                            Items
                        </div>
                        <div 
                            className={`profile-view-tab ${this.activeViewTabClass(ProfileViews.Funds)}`}
                            onClick={() => this.setState({activeView: ProfileViews.Funds})}>
                            Funds
                        </div>
                        <div 
                            className={`profile-view-tab ${this.activeViewTabClass(ProfileViews.DirectMessages)}`}
                            onClick={() => this.setState({activeView: ProfileViews.DirectMessages})}>
                            Direct Messages
                        </div>
                    </div>
                    
                    { 
                        this.state.activeView === ProfileViews.History ?
                        this.renderHistory() : null
                    }
                    {
                        this.state.activeView === ProfileViews.Assets ?
                        <>
                            <ProfileMutualFundContainer />
                            <AssetsContainer
                                uniqueAssets={uniqueAssets}
                                coins={filteredCoins}
                                showMuted={showMuted}
                                verified={this.props.userinfo.verified}/>
                        </> : null
                    }
                    {
                        this.state.activeView === ProfileViews.Items ?
                        <>
                        <Crafting />
                        <Items
                            useritems={this.props.userinfo.items}
                            catalogue={this.props.itemcatalogue} />
                        </> : null
                    }
                    {
                        this.state.activeView === ProfileViews.Funds ?
                        <UserFunds /> : null
                    }
                    {
                        this.state.activeView === ProfileViews.DirectMessages ?
                        <DirectMessages verified={this.props.userinfo.verified}/> : null
                    }
                    
                </div>
                <UpdateIconDialogue
                    visible={this.state.iconDialogueVisible}
                    toggle={() => this.toggleIconDialogue()} />
                <DeleteAccountDialogue
                    visible={this.state.deleteAccountDialogueVisible}
                    deleted={() => this.deleted()}
                    toggle={() => this.toggleDeleteDialogue()} />
                <SettingsDialogue
                    visible={this.state.settingsDialogueVisible}
                    toggle={() => this.toggleSettingsDialogue()} />
            </div>
        )
    }
}

const Profile = connect(
    mapStateToProps
)(ProfileBind);

export default Profile;
