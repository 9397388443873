import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IMutualFundMember, IMutualFunds } from '../../interfaces/MutualFunds';
import Coin from '../Coin';

import "../../../css/profile/userfunds.scss";

const mapStateToProps = (state:any) => ({
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds,
});

const mapDispatchToProps = {
}

interface IUserFundsProps {
    userinfo: {
        id:string
    },
    mutualfunds: {
        funds: IMutualFunds,
    },
}

class UserFundsBind extends Component<IUserFundsProps> {

    getColor(fund:string) {
        let f = this.props.mutualfunds.funds[fund];
        return "#" + f.color;
    }

    render() {

        let fundsUserIn:Array<string> = [];
        let funds:Array<string> = Object.keys(this.props.mutualfunds.funds);
        funds.forEach((fund:string) => {

            let members:Array<IMutualFundMember> = this.props.mutualfunds.funds[fund].members;
            if(members.findIndex((member:IMutualFundMember) => {
                return member.id === this.props.userinfo.id;
            }) !== -1) {
                fundsUserIn.push(fund);
            }

        });

        return (
            <div className="container-section">
            <div className="section-background"></div>
            <div className="section-content">
                <div className="header">
                    Funds
                </div>
                <div className="user-funds-outer">
                    {
                        fundsUserIn.map((fund:string) => 
                        <Link to={"/mutualfund/" + fund}>
                            <div
                                style={{"borderColor":this.getColor(fund)}} 
                                className="user-fund-item">
                                <Coin name={this.props.mutualfunds.funds[fund].icon} />
                                {this.props.mutualfunds.funds[fund].name}
                            </div>
                        </Link>
                        )
                    }
                    {
                        fundsUserIn.length === 0 ?
                        <div className="not-in-any-funds flex center-child">
                            You do not belong to any funds.
                        </div> : null
                    }
                </div>
            </div>
            </div>
        )

    }
}

const UserFunds = connect(
    mapStateToProps, 
    mapDispatchToProps
)(UserFundsBind);
export default UserFunds;
