import React, {Component} from 'react';
import "../../../css/chat/chat.scss";

import { HiChatAlt2 } from 'react-icons/hi';
import { connect } from 'react-redux';
import GlobalChatModule from './GlobalChatModule';

const mapStateToProps = (state:any) => ({
    session:state.session,
})

interface GlobalChatProps {
    session: {
        loggedin:boolean
    }
}

class GlobalChatState {
    visible:boolean;
    constructor() {
        this.visible = false;
    }
}

class GlobalChatBind extends Component<GlobalChatProps> {

    state:GlobalChatState;
    constructor(props:GlobalChatProps) {
        super(props);
        this.state = new GlobalChatState();
    }

    toggleVisible() {
        this.setState({visible:!this.state.visible});
    }

    render() {
        if(!this.props.session.loggedin) {
            return null;
        }
        return(
            <div className={"global-chat-container "  + (this.state.visible ? "visible" : "")}>
                <div className="global-chat-container-inner">
                    <div 
                        className="global-chat-pull-out flex center-child"
                        onClick={() => this.toggleVisible()}>
                        <HiChatAlt2 style={{verticalAlign: 'middle'}}/>
                    </div>
                    <GlobalChatModule visible={this.state.visible} />
                </div>
            </div>
        )
    }

}

const GlobalChat = connect(mapStateToProps)(GlobalChatBind);

export default GlobalChat;