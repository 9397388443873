export const maleNames:Array<string> = [
    "altare",
    "dezmond",
    "syrios",
    "vesper",
    "miyabi",
    "izuru",
    "aruran",
    "rikka",
    "astel",
    "temma",
    "roberu",
    "shien",
    "kaoru",
    "oga",
    "bettel",
    "flayon",
    "hakka",
    "shinri",
    "rexford",
    "goldbullet",
    "octavio",
    "ruze"
];
