import React, {Component} from 'react';
import {connect} from 'react-redux';

import '../../../css/admin/userwallets.scss';
import fetchData from '../../fetchData';
import { IWallet } from '../../interfaces/IWallet';
import numberWithCommas from '../../numberWithCommas';
import { SortByGen } from '../../sort';
import Button from '../Button';
import Coin from '../Coin';
import { lineage } from '../Icons';

const mapStateToProps = (state:any) => ({

});

const mapDispatchToProps = {

}

interface IUserWalletsProps {

}

class UserWalletsState {
    search:string;
    userid:string;
    error:string;
    username:string;
    wallet:IWallet | null;
    newBalance:string;
    newCoinAmounts:any;
    showResultPrompt:boolean;
    updateSuccess:boolean;
    constructor() {
        this.search = '';
        this.userid = '';
        this.error = '';
        this.username = '';
        this.wallet = null;
        this.newBalance = '';
        this.newCoinAmounts = {};
        this.showResultPrompt = false;
        this.updateSuccess = false;
    }
}

class UserWalletsBind extends Component<IUserWalletsProps> {
    state:UserWalletsState;
    constructor(props:IUserWalletsProps) {
        super(props);
        this.state = new UserWalletsState();
    }

    searchUser() {
        if(this.state.search === '') {
            this.setState({error:"Enter a user ID."});
            return;
        };

        fetchData('/api/adminGetUserWallet?userid=' + this.state.search)
        .then((data:any) => {
            if(data.success) {
                this.setState({
                    username:data.username, 
                    wallet:data.wallet, 
                    userid:this.state.search.trim(),
                    error: ''});
            } else {
                this.setState({error:data.message});
            }
        })
    }

    filterName(name:string) {
        return name === "himemoriluna" ? "luna" : name;
    }

    renderWalletCoins() {
        if(this.state.wallet === null) return null;

        if(Object.keys(this.state.wallet.coins).length === 0) {
            return (
                <tr>
                    <td colSpan={5}>
                    User does not own any coins.
                    </td>
                </tr>
            )
        }

        let coinNames:Array<any> = [];
        lineage.forEach((gen:Array<string>) => {
            coinNames = [...coinNames, ...gen];
        });
        let coinRows:Array<any> = [];
        coinNames.forEach((coin:string) => {
            if(this.state.wallet !== null) {

                let amount:any;
                let mpp:any;
                let timestamp:any;

                if(this.state.wallet.coins[coin] !== undefined) {
                    amount = numberWithCommas(this.state.wallet.coins[coin].amt);
                    mpp = `\$${numberWithCommas(this.state.wallet.coins[coin].meanPurchasePrice)}`;
                    timestamp = new Date(this.state.wallet.coins[coin].timestamp).toLocaleString()
                } else {
                    amount = "0";
                    mpp = "-";
                    timestamp = "-";
                }

                coinRows.push(
                    <tr key={coin}>
                        <td className="coin-name">
                            <Coin name={this.filterName(coin)} />
                            <div>
                            {this.filterName(coin)}
                            </div>
                        </td>
                        <td className="coin-amount">
                            {amount}
                        </td>
                        <td className="update-coin-amount">
                            <div className="update-coin-amount-container flex flex-row">
                                <input 
                                    type="text" 
                                    placeholder="New amount" 
                                    name={coin}
                                    onChange={(e) => this.handleCoinAmountChange(e)}/>
                                <div 
                                    className="simple-button"
                                    onClick={() => this.updateCoinAmount(coin)}>
                                    Update
                                </div>
                            </div>
                        </td>
                        <td className="coin-mpp">
                            {mpp}
                        </td>
                        <td>{timestamp}</td>
                    </tr>
                )
            }
        });

        return coinRows;
    }

    handleBalanceChange(e:any) {
        let text = e.target.value;
        text = text.replace(/\$/g, '');
        text = text.replace(/,/g, '');
        let amount = parseInt(text);
        if(isNaN(amount)) {
            this.setState({newBalance:''})
        } else {
            let balanceString = numberWithCommas(amount);
            balanceString = "$" + balanceString;
            this.setState({newBalance:balanceString});
        }

    }

    updateWallet(wallet:any, userid:string, callback:any) {
        fetch('/api/adminUpdateUserWallet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                requestedUser: userid,
                wallet
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.setState({
                    showResultPrompt: true,
                    updateSuccess: true,
                });
                setTimeout(() => {
                    this.setState({showResultPrompt: false});
                }, 5000);
                callback();
            } else {
                this.setState({
                    showResultPrompt: true,
                    updateSuccess:false
                });
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    updateBalance() {
        let balanceString = this.state.newBalance.replace(/\$/g, '');
        if(balanceString === "") return;
        balanceString = balanceString.replace(/,/g, '');
        let balanceAmount = parseInt(balanceString);
        
        let wallet = {
            balance:balanceAmount,
            coins: {}
        }

        this.updateWallet(wallet, this.state.userid, () => {
            this.setState({
                wallet: {
                    ...this.state.wallet,
                    balance:balanceAmount
                }
            })
        });

    }

    handleCoinAmountChange(e:any) {
        this.setState({
            newCoinAmounts: {
                ...this.state.newCoinAmounts,
                [e.target.name]: e.target.value
            }
        })
    }

    updateCoinAmount(coin:string) {

        let newAmount = parseInt(this.state.newCoinAmounts[coin]);
        if(isNaN(newAmount)) {
            return;
        } else {
            
            let wallet = {
                coins: {
                    [coin]: {
                        amt:newAmount
                    }
                }
            }

            this.updateWallet(wallet, this.state.userid, () => {

                if(this.state.wallet !== null) {
                    let updatedWallet:IWallet = {...this.state.wallet};
                    if(updatedWallet.coins) {
                        if(updatedWallet.coins[coin] === undefined) {
                            updatedWallet.coins[coin] = {
                                amt: newAmount,
                                meanPurchasePrice: 0,
                                timestamp: new Date().getTime()
                            };
                        } else {
                            updatedWallet.coins[coin].amt = newAmount;
                        }
                        this.setState({wallet:updatedWallet});
                    }
                }
            })
        }

    }

    render() {
        return (
            <div className="admin-users-pane flex-col flex-stretch">
                <div className="control-header">
                    User Wallets
                </div>
                <div className="control-description">
                    Change the contents of a user's wallet.
                </div>
                <div className="form-container">
                    <input 
                        type="text" 
                        placeholder="User ID"
                        value={this.state.search} 
                        onChange={(e) => {this.setState({search:e.target.value})}}/>
                    <div className="simple-button" onClick={() => this.searchUser()}>Get</div>
                </div>
                {this.state.error !== '' ? 
                    <div className="error">{this.state.error}</div> : null}
                {
                    this.state.wallet !== null ?
                    <div className="wallet-info-container">
                        
                        <div className="username-wallet-header">
                            {this.state.username}'s wallet
                        </div>

                        <div className="wallet-row flex flex-col">
                            
                            {
                                this.state.showResultPrompt ?
                                <div className={"result-message " + (this.state.updateSuccess ? "success" : "error")}>
                                    {this.state.updateSuccess ?
                                        "Wallet updated successfully." :
                                        "Error occurred while updating user wallet."}
                                </div> : null
                            }

                            <div className="balance-container">
                                <div className="balance-header">Balance:</div>
                                <div className="balance-amount">${numberWithCommas(this.state.wallet.balance)}</div>
                                <div className="balance-input">
                                    <input 
                                        type="text" 
                                        value={this.state.newBalance}
                                        placeholder="Update balance..."
                                        onChange={(e) => this.handleBalanceChange(e)}/>
                                </div>
                                <div 
                                    className="simple-button"
                                    onClick={() => this.updateBalance()}>
                                        Update
                                </div>
                            </div>
                            <div className="coins-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Coin</th>
                                            <th>Amount Owned</th>
                                            <th>Update Amount</th>
                                            <th>MPP</th>
                                            <th>Last Bought</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderWalletCoins()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div> : null
                }

            </div>
        );
    }
}

const UserWallets = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserWalletsBind);

export default UserWallets;