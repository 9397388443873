import React, {Component} from 'react';
import '../../../css/leaderboard/benchmarkleaderboard.scss';
import {connect} from 'react-redux';
import numberWithCommas from '../../numberWithCommas';
import Coin from '../Coin';

import {
    FaArrowAltCircleUp,
    FaArrowAltCircleDown,
} from 'react-icons/fa';

const mapStateToProps = (state:any) => ({
    stats: state.stats,
    userinfo: state.userinfo
})

interface IBenchmarkLeaderboardItem {
    username:string,
    icon:string,
    performanceChange:number,
    performancePercent:number,
    relativePerformance:number
}

interface IBenchmarkLeaderboardProps {
    stats: {
        benchmarkLeaderboard: {
            outperformers: Array<IBenchmarkLeaderboardItem>,
            underperformers: Array<IBenchmarkLeaderboardItem>,
            intervalStart:number,
            intervalEnd:number,
            index: {
                oldVal: number,
                newVal:number,
                change:number,
                percentChange:number
            }
        }
    },
    userinfo: {
        username:string
    }
}

class BenchmarkLeaderboardBind extends Component<IBenchmarkLeaderboardProps> {
    getChangeColor(n:number) {
        return n > 0 ? "green" : "red";
    }
    isMe(name:string) {
        if(name === this.props.userinfo.username) {
            return "me";
        } else return "";
    }
    render() {
        if(this.props.stats.benchmarkLeaderboard.outperformers === undefined) return null;
        let oldIndex = this.props.stats.benchmarkLeaderboard.index.oldVal;
        let newIndex = this.props.stats.benchmarkLeaderboard.index.newVal;
        let indexChange = this.props.stats.benchmarkLeaderboard.index.change;
        let indexPercent = this.props.stats.benchmarkLeaderboard.index.percentChange;
        let changeColor = newIndex > oldIndex ? "green" : "red";
        return(
            <div className="benchmark-leaderboard-outer">
                <div className="benchmark-leaderboard-description">
                    Weekly ranking of user performance relative to the benchmark, updated every Wednesday at 12:00pmET.
                </div>
                <div className="index-comparison flex flex-row">
                    <div className="index-old-outer">
                        <div className="index-date">{new Date(this.props.stats.benchmarkLeaderboard.intervalStart).toLocaleString()}</div>
                        <div className={"index-value " + changeColor}>{numberWithCommas(this.props.stats.benchmarkLeaderboard.index.oldVal)}</div>

                        <div className={"index-change-arrow " + changeColor}>
                            {
                                newIndex > oldIndex ?
                                <FaArrowAltCircleUp /> : <FaArrowAltCircleDown />
                            }
                        </div>
                    </div>
                    <div className="index-new-outer">
                        <div className="index-date">{new Date(this.props.stats.benchmarkLeaderboard.intervalEnd).toLocaleString()}</div>
                        <div className={"index-value " + changeColor}>{numberWithCommas(this.props.stats.benchmarkLeaderboard.index.newVal)}</div>
                    </div>
                </div>
                <div className="index-change">
                    The Y&G 66 went
                    <span className={"bold " + changeColor}>
                    { newIndex > oldIndex ? " up " : " down " }
                    </span>
                    by
                    <span className={"bold " + changeColor}>
                    { " " + numberWithCommas(Math.abs(indexChange)) }
                    </span>
                    , a percent change of
                    <span className={"bold " + changeColor}>
                    { " " + numberWithCommas(indexPercent) + "%." }
                    </span>
                </div>
                <div className="benchmark-ranking flex flex-row">
                    <div className="outperformers">
                        <div className="ranking-header">
                            Outperformers
                        </div>
                        <div className="ranking-description">
                            Users who did better than the benchmark.
                        </div>
                        {
                            this.props.stats.benchmarkLeaderboard.outperformers.map((item:IBenchmarkLeaderboardItem, index:number) =>
                                <div className="benchmark-leaderboard-user">
                                    <div className="user-info">
                                        <div className="user-rank">{index + 1}.</div>
                                        <div className="user-icon"><Coin name={item.icon}/></div>
                                        <div className={"user-name " + this.isMe(item.username)}>{item.username}</div>
                                    </div>
                                    <div className="user-stats">
                                        <div className={"user-change " + this.getChangeColor(item.performanceChange)}>
                                            {
                                                item.performanceChange > 0 ?
                                                <FaArrowAltCircleUp /> : <FaArrowAltCircleDown />
                                            }
                                            ${numberWithCommas(Math.abs(item.performanceChange))}
                                        </div>
                                        <div className={"user-percent " + this.getChangeColor(item.performancePercent)}>{numberWithCommas(item.performancePercent)}%</div>
                                        <div className={"user-relative " + this.getChangeColor(item.relativePerformance)}>{numberWithCommas(item.relativePerformance)}%</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="underperformers">
                        <div className="ranking-header">
                            Underperformers
                        </div>
                        <div className="ranking-description">
                            Users who did worse than the benchmark.
                        </div>
                        {
                            this.props.stats.benchmarkLeaderboard.underperformers.map((item:IBenchmarkLeaderboardItem, index:number) =>
                                <div className="benchmark-leaderboard-user">
                                    <div className="user-info">
                                        <div className="user-rank">{index + 1}.</div>
                                        <div className="user-icon"><Coin name={item.icon}/></div>
                                        <div className={"user-name " + this.isMe(item.username)}>{item.username}</div>
                                    </div>
                                    <div className="user-stats">
                                        <div className={"user-change " + this.getChangeColor(item.performanceChange)}>
                                            {
                                                item.performanceChange > 0 ?
                                                <FaArrowAltCircleUp /> : <FaArrowAltCircleDown />
                                            }
                                            ${numberWithCommas(Math.abs(item.performanceChange))}
                                        </div>
                                        <div className={"user-percent " + this.getChangeColor(item.performancePercent)}>{numberWithCommas(item.performancePercent)}%</div>
                                        <div className={"user-relative " + this.getChangeColor(item.relativePerformance)}>{numberWithCommas(item.relativePerformance)}%</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const BenchmarkLeaderboard = connect(mapStateToProps)(BenchmarkLeaderboardBind);
export default BenchmarkLeaderboard;
