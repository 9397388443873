import React, {Component} from 'react';
import {connect} from 'react-redux';

import { Line } from 'react-chartjs-2';
import { IMutualFund, IMutualFundHistoryCollection, IMutualFunds, IMutualFundStatCollection } from '../../interfaces/MutualFunds';

import "../../../css/mutualfunds/mutualfundhistorygraph.scss";
import hexToRgb from '../../hexToRBG';
import getForegroundFromBackground from '../../getForegroundFromBackground';

import { datasetTemplate } from '../DatasetTemplate';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {

}

enum MutualFundGraphTypes {
    PRICE = "Price",
    VOLUME = "Volume",
    NETWORTH = "Net Worth",
    MEMBERS = "Members"
}

enum MutualFundGraphTimes {
    TODAY = "24H",
    WEEK = "1WK",
    MONTH = "1MO",
    THREEMONTHS = "3MO",
    SIXMONTHS = "6MO",
    ALLTIME = "ALL TIME"
}

class MutualFundHistoryGraphState {

    selectedDataType:MutualFundGraphTypes;
    selectedTimeRange:MutualFundGraphTimes;
    graphData: {
        labels: Array<string>,
        datasets: Array<any>
    }

    constructor() {
        this.selectedDataType = MutualFundGraphTypes.NETWORTH;
        this.selectedTimeRange = MutualFundGraphTimes.TODAY;
        this.graphData = {
            labels: [],
            datasets: []
        }
    }
}

interface IMutualFundHistoryGraphProps {
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
    }
    fund:string
}

class MutualFundHistoryGraphBind extends Component<IMutualFundHistoryGraphProps> {
    
    state:MutualFundHistoryGraphState;
    constructor(props:IMutualFundHistoryGraphProps) {
        super(props);
        this.state = new MutualFundHistoryGraphState();
    }

    getColor() {
        return "#" + this.props.mutualfunds.funds[this.props.fund].color;
    }

    getForegroundColor() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getForegroundFromBackground(r, g, b);
    }

    getSelectionDataTypeStyle(selection:string) {
        if(this.state.selectedDataType === selection) {
            return {
                background:this.getColor(),
                color:this.getForegroundColor()
            }
        } else {
            return {}
        }
    }

    getSelectionTimeRangeStyle(selection:string) {
        if(this.state.selectedTimeRange === selection) {
            return {
                background:this.getColor(),
                color:this.getForegroundColor()
            }
        } else {
            return {}
        }
    }

    setSelectionDataType(selection:string) {
        this.setState({selectedDataType:selection});
    }
    
    setSelectionTimeRange(selection:string) {
        this.setState({selectedTimeRange:selection});
    }

    setGraphData() {

        if(this.props.mutualfunds.fundstats === undefined) return;
        if(this.props.mutualfunds.fundstats[this.props.fund] === undefined) return;

        let datasets: Array<any> = [];
        let dataset = {...datasetTemplate};

        const graphColor = this.getColor();
        dataset.borderColor = graphColor;
        dataset.pointBorderColor = graphColor;
        dataset.pointHoverBackgroundColor = graphColor;
        dataset.pointHoverBorderColor = graphColor;

        let data:any = {};

        let dataStamps:any;
        if(this.state.selectedTimeRange === MutualFundGraphTimes.TODAY) {
            dataStamps = [...this.props.mutualfunds.fundstats[this.props.fund].history];
        } else {
            dataStamps = [...this.props.mutualfunds.fundhistory[this.props.fund]];
            const dataLength = dataStamps.length;
            let beginningIndex = 0;
            switch(this.state.selectedTimeRange) {
                case MutualFundGraphTimes.WEEK:
                    beginningIndex = Math.max(0, dataLength - 7);
                    break;                    
                case MutualFundGraphTimes.MONTH:
                    beginningIndex = Math.max(0, dataLength - 30);
                    break;                    
                case MutualFundGraphTimes.THREEMONTHS:
                    beginningIndex = Math.max(0, dataLength - 90);
                    break;                    
                case MutualFundGraphTimes.SIXMONTHS:
                    beginningIndex = Math.max(0, dataLength - 180);
                    break;                    
                case MutualFundGraphTimes.ALLTIME:
                default:              
                    break;                    
            }
            dataStamps = [...dataStamps.slice(beginningIndex)];
        }

        dataset.label = this.state.selectedDataType;
        
        data.labels = [...dataStamps.map((dataItem:any) => {
            if(this.state.selectedTimeRange === MutualFundGraphTimes.TODAY) {
                return new Date(dataItem.timestamp).toLocaleTimeString([], {hour:'2-digit', minute:'2-digit'});
            } else {
                return new Date(dataItem.timestamp).toLocaleDateString();
            }
        })];

        dataset.data = [...dataStamps.map((dataItem:any) => {
            switch(this.state.selectedDataType) {
                case MutualFundGraphTypes.PRICE:
                    return dataItem.price;
                case MutualFundGraphTypes.VOLUME:
                    return dataItem.volume;
                case MutualFundGraphTypes.MEMBERS:
                    return dataItem.members;
                case MutualFundGraphTypes.NETWORTH:
                    return dataItem.networth;
                default:
                    return 0;
            }
        })];

        if(this.state.selectedTimeRange !== MutualFundGraphTimes.TODAY) {
            data.labels.push(new Date().toLocaleTimeString([], {hour:'2-digit', minute:'2-digit'}));
            const currentStats = this.props.mutualfunds.fundstats[this.props.fund];
            switch(this.state.selectedDataType) {
                case MutualFundGraphTypes.PRICE:
                    dataset.data.push(currentStats.price);
                    break;
                case MutualFundGraphTypes.VOLUME:
                    dataset.data.push(currentStats.volume);
                    break;
                case MutualFundGraphTypes.MEMBERS:
                    dataset.data.push(currentStats.members);
                    break;
                case MutualFundGraphTypes.NETWORTH:
                    dataset.data.push(currentStats.networth);
                    break;
                default:
                    dataset.data.push(0);
            }
        }

        datasets.push(dataset);
        data.datasets = datasets;
        this.setState({
            graphData:data
        });
    }

    componentDidMount() {
        this.setGraphData();
    }

    componentDidUpdate(prevProps:IMutualFundHistoryGraphProps, prevState:MutualFundHistoryGraphState) {

        if(
            this.props.mutualfunds.fundstats === undefined || 
            prevProps.mutualfunds.fundstats === undefined) {
            return;
        }

        if(
            this.props.mutualfunds.fundstats[this.props.fund] === undefined
        ) {
            return;
        }

        if(
            this.props.mutualfunds.fundstats[this.props.fund] !== undefined &&
            prevProps.mutualfunds.fundstats[this.props.fund] === undefined) {
                this.setGraphData();
                return;
        }

        if(
            this.state.selectedDataType !== prevState.selectedDataType ||
            this.state.selectedTimeRange !== prevState.selectedTimeRange) {
            this.setGraphData();
            return;
        }

        if(this.props.mutualfunds.fundstats[this.props.fund].price !== 
            prevProps.mutualfunds.fundstats[this.props.fund].price) {
            this.setGraphData();
            return;
        }

        if(this.state.selectedTimeRange === MutualFundGraphTimes.TODAY) {
            const lastTimeInGraph = this.state.graphData.labels.slice(-1)[0];
            const lastTimeNow = this.props.mutualfunds.fundstats[this.props.fund].history.slice(-1)[0].timestamp;
            const lastTimeNowLabel = new Date(lastTimeNow).toLocaleTimeString([], {hour:'2-digit', minute:'2-digit'});
            if(lastTimeNowLabel !== lastTimeInGraph) {
                this.setGraphData();
                return;
            }
        }
    }

    render() {
        const fund:IMutualFund = this.props.mutualfunds.funds[this.props.fund];
        if(fund === undefined) return null;

        return(
            <div className="mutual-fund-history-graph-outer flex flex-col">
                <div className="mutual-fund-history-graph-selection flex flex-row">
                    <div className="selection-type-outer flex flex-row">
                        {
                            Object.values(MutualFundGraphTypes).map((dataType:string) =>
                                <div
                                    key={dataType}
                                    style={this.getSelectionDataTypeStyle(dataType)} 
                                    onClick={() => this.setSelectionDataType(dataType)}
                                    className="selection-item">
                                    {dataType}
                                </div>
                            )
                        }
                    </div>
                    <div className="selection-type-outer flex flex-row">
                        {
                            Object.values(MutualFundGraphTimes).map((timeRange:string) => 
                                <div
                                    key={timeRange}
                                    style={this.getSelectionTimeRangeStyle(timeRange)} 
                                    onClick={() => this.setSelectionTimeRange(timeRange)}
                                    className="selection-item">
                                    {timeRange}
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="mutual-fund-graph-container">
                    <Line
                        data={this.state.graphData}
                        options={{
                            animation: {
                                duration: 300
                            },
                            easing: "easeInOutCubic",
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        display:true,
                                        fontFamily:"WorkSansSemiBold"
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        fontFamily:"WorkSansSemiBold"
                                    }
                                }]
                            }
                        }}/>
                </div>
            </div>
        )
    }
}

const MutualFundHistoryGraph = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundHistoryGraphBind);

export default MutualFundHistoryGraph;