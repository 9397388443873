import Dexie, {Table} from 'dexie';

export interface IDBCoinHistory {
    [coin:string]: {
        labels: Array<string>,
        inCirculation: Array<number>,
        price: Array<number>
    }
}

export interface IDBCoinStatInfo {
    data: Array<string|number>,
    labels: Array<string>,
    name: string
}

export interface IDBCoinStats {
    [coin:string]: {
        dailySubscriberCount: IDBCoinStatInfo,
        dailyViewCount: IDBCoinStatInfo,
        subscriberCount: IDBCoinStatInfo,
        viewCount: IDBCoinStatInfo,
        weeklySubscriberCount: IDBCoinStatInfo,
        weeklyViewCount: IDBCoinStatInfo,
    }
}

export interface IDBStats {
    timestamp:number,
    stats:IDBCoinStats,
    history:IDBCoinHistory
}

export interface IDBMulticoinSave {
    coin:string,
    buy:number,
    sell:number
}

export class NasfaqDexie extends Dexie {

    stats!:Table<IDBStats>;
    multicoinSave!:Table<IDBMulticoinSave>;

    constructor() {
        super("nasfaqDatabase");
        this.version(1).stores({
            stats: '++timestamp,stats,history',
            multicoinSave: '++coin,buy,save'
        })
    }

}

export const db = new NasfaqDexie();