import { handleActions } from 'redux-actions';

const chat = handleActions(
    {
        SET_BLOCKING: (state:any, action:any) => ({
            ...state,
            blocking: action.payload.blocking
        }),
        SET_BLOCKED_BY: (state:any, action:any) => ({
            ...state,
            blockedBy: action.payload.blockedBy
        }),
        SET_DIRECT_MESSAGE_ROOMS: (state:any, action:any) => ({
            ...state,
            directMessageRooms: action.payload.directMessageRooms
        }),
        SET_DIRECT_MESSAGE_LOGS: (state:any, action:any) => ({
            ...state,
            directMessageLogs: action.payload.directMessageLogs
        }),
        SET_GLOBAL_CHAT: (state:any, action:any) => ({
            ...state,
            globalChat: action.payload.globalChat
        }),
        ADD_DIRECT_MESSAGE_ROOM: (state:any, action:any) => ({
            ...state,
            directMessageRooms: [...state.directMessageRooms, action.payload.room]
        }),
        ADD_DIRECT_MESSAGE: (state:any, action:any) => ({
            ...state,
            directMessageLogs: {
                ...state.directMessageLogs,
                [action.payload.roomid]: [...state.directMessageLogs[action.payload.roomid], action.payload.message]
            }
        }),
        ADD_GLOBAL_CHAT_MESSAGE: (state:any, action:any) => ({
            ...state,
            globalChat: [...state.globalChat, action.payload.message]
        }),
        SET_LOADED_ROOMS: (state:any, action:any) => ({
            ...state,
            loadedRooms: action.payload.loadedRooms
        })
    },
    {
        blocking: [],
        blockedBy: [],
        directMessageRooms: [],
        directMessageLogs: {},
        globalChat: [],
        loadedRooms: [],
    }
)

export { chat as default }
