import React, {Component} from 'react';
import {connect} from 'react-redux';

import "../../../css/mutualfunds/mutualfundsindex.scss";

import {
    AiFillInfoCircle
} from 'react-icons/ai';
import {
    BiBuildings
} from 'react-icons/bi';
import CreateMutualFund from './CreateFund';
import { IMutualFundHistoryCollection, IMutualFundPayouts, IMutualFunds, IMutualFundStatCollection } from '../../interfaces/MutualFunds';
import MutualFundIndexItem from './MutualFundIndexItem';
import { FaScroll } from 'react-icons/fa';
import MutualFundAllBulletins from './MutualFundAllBulletins';
import DropdownInput from '../DropdownInput';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {

}

enum MutualFundSortMethod {
    Networth = "Networth",
    Price = "Price",
    Payout = "Payout",
    PayoutPercent = "Payout %",
    Balance = "Balance",
    Members = "Members",
}

class MutualFundsIndexState {
    showCreateFundForm:boolean = false;
    showBulletins:boolean = false;
    showInfo:boolean = false;
    sortMethod:MutualFundSortMethod = MutualFundSortMethod.Networth;
}

interface MutualFundsIndexProps {
    session: {
        loggedin:boolean
    },
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
        fundPayouts: IMutualFundPayouts
    }
}

class MutualFundsIndexBind extends Component<MutualFundsIndexProps> {
    state:MutualFundsIndexState;

    constructor(props:MutualFundsIndexProps) {
        super(props);
        this.state = new MutualFundsIndexState();
    }

    toggleCreateFundForm() {
        this.setState({showCreateFundForm: !this.state.showCreateFundForm});
    }

    toggleBulletinPanel() {
        this.setState({showBulletins: !this.state.showBulletins});
    }

    toggleInfo() {
        this.setState({showInfo: !this.state.showInfo});
    }

    renderMutualFundItems() {
        let fundids:Array<string> = Object.keys(this.props.mutualfunds.funds);
        let fundstats = this.props.mutualfunds.fundstats;
        let fundinfo = this.props.mutualfunds.funds;
        let payouts = this.props.mutualfunds.fundPayouts;
        fundids.sort((a:string, b:string) => {
            switch(this.state.sortMethod) {
                case MutualFundSortMethod.Networth:
                    if(fundstats[a] === undefined || fundstats[b] === undefined) {
                        return 0;
                    } else {
                        return fundstats[b].networth - fundstats[a].networth;
                    }
                case MutualFundSortMethod.Price:
                    if(fundstats[a] === undefined || fundstats[b] === undefined) {
                        return 0;
                    } else {
                        return fundstats[b].price - fundstats[a].price;
                    }
                case MutualFundSortMethod.Payout:
                    if(payouts[a] === undefined || payouts[b] === undefined) {
                        return 0;
                    } else {
                        return payouts[b] - payouts[a];
                    }
                case MutualFundSortMethod.PayoutPercent:
                    if(payouts[a] === undefined || payouts[b] === undefined) {
                        return 0;
                    } else {
                        let percenta = payouts[a] / fundstats[a].price;
                        let percentb = payouts[b] / fundstats[b].price;
                        return percentb - percenta;
                    }
                case MutualFundSortMethod.Balance:
                    if(fundinfo[a] === undefined || fundinfo[b] === undefined) {
                        return 0;
                    } else {
                        return fundinfo[b].balance - fundinfo[a].balance;
                    }
                case MutualFundSortMethod.Members:
                    if(fundinfo[a] === undefined || fundinfo[b] === undefined) {
                        return 0;
                    } else {                            
                        return fundinfo[b].members.length - fundinfo[a].members.length;
                    }
            }
        });
        return fundids.map((fund:string) =>
            <MutualFundIndexItem 
                key={fund}
                fund={fund} />
        );
    }
    
    setSortMethod(e:MutualFundSortMethod) {
        this.setState({sortMethod: e});
    }

    render() {
        return(
            <>
            {
                this.state.showCreateFundForm ?
                <CreateMutualFund 
                    close={() => this.toggleCreateFundForm()}/> : null
            }
            {
                this.state.showBulletins ?
                <MutualFundAllBulletins
                    close={() => this.toggleBulletinPanel()}/> : null
            }
            <div className='container mutual-funds-index-container'>
                <div className="mutual-funds-title">
                    <div className="mutual-funds-title-inner flex flex-row">
                        <div 
                            className={"mutual-funds-title-box flex flex-col " + 
                                    (this.state.showInfo ? "info-visible" : "")}>
                            <div 
                                className="mutual-funds-title-text flex flex-row">
                                Mutual Funds
                                <div
                                    onClick={() => this.toggleInfo()} 
                                    className="mutual-funds-info-toggle"><AiFillInfoCircle /></div>
                            </div>
                            {
                                this.state.showInfo ?
                                <div className="mutual-fund-info">
                                    Mutual funds are a way to create specialized groups of likeminded traders. Become part of a mutual fund to manage a collaborative portfolio that other players can invest in, offering another type of financial instrument that comes with built in diversification. Buy shares of index funds, growth funds, value funds, and anything in between, all managed by other players. Don't see a fund that fits your needs? Become a CEO and create one yourself and recruit an A team for your board of directors!
                                </div> : null
                            }
                        </div>
                        <div className="sidebar-buttons flex flex-row">
                        {
                            this.props.session.loggedin ?
                            <div 
                                onClick={() => this.toggleCreateFundForm()}
                                className="create-fund-button flex flex-row">
                                Create a Fund
                                <BiBuildings />
                            </div> : null
                        }
                        <div 
                            onClick={() => this.toggleBulletinPanel()}
                            className="toggle-bulletins-button flex flex-row">
                            Bulletins
                            <FaScroll />
                        </div> 
                        </div>
                        <div className="center-line"></div>
                    </div>
                </div>
                <div className="mutual-funds-sort-method">
                    <DropdownInput 
                        label="Sort By:"
                        options={Object.values(MutualFundSortMethod)}
                        default={MutualFundSortMethod.Networth}
                        onChange={(e) => {this.setSortMethod(e)}}/>
                </div>
                <div className="mutual-funds-list-container">
                    {
                        Object.keys(this.props.mutualfunds.funds).length === 0 ?
                        <div className="no-mutual-funds">
                            There are no mutual funds.
                        </div> : null
                    }
                    {this.renderMutualFundItems()}
                </div>
            </div>
            </>
        )
    }
}

const MutualFundsIndex = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundsIndexBind);

export default MutualFundsIndex;