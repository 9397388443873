import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../css/navbar.scss';

import numberWithCommas from '../numberWithCommas';
import {IWallet} from '../interfaces/IWallet';

import storageAvailable from '../checkStorage';

import sun from '../../images/sun-black.png';
import moon from '../../images/moon-white.png';
import web from '../../images/web-white.png';
import stocking from '../../images/stocking-red.png';

import {withRouter} from 'react-router-dom';

import { Themes } from '../Themes';
import {
    settingsActions,
    snowfallActions
} from '../actions/actions';

import {
    HiMenu
} from 'react-icons/hi';
/*
import {
    BsCaretDownFill
} from 'react-icons/bs';
*/
const mapStateToProps = (state:any, props:any) => ({
    settings: state.settings,
    session: state.session,
    userinfo: state.userinfo,
    snowfall: state.snowfall
});

const mapDispatchToProps = {
    setTheme: settingsActions.setTheme,
    setShowSnowNotification: snowfallActions.setShowSnowNotification,
    setShowSnowSettings: snowfallActions.setShowSnowSettings
}

interface NavbarProps {
    settings: {
        theme: Themes,
        marketSwitch: boolean
    },
    session: {
        loggedin: boolean
    },
    location: {
        pathname:string
    },
    userinfo: {
        username:string,
        email:string,
        wallet:IWallet,
        loaded:boolean,
        admin:boolean
    },
    snowfall: {
        showSnowSettings:boolean
    },
    setTheme: (theme: Themes) => {},
    setShowSnowNotification: (show:any) => {},
    setShowSnowSettings: (show:any) => {}
}

enum MenuCategories {
    MarketActivity = "Market Activity",
    NewsInsights = "News + Insights",
    Commodities = "Commodities"
}

class NavbarState {
    dateTime: string;
    collapsed: boolean;
    showStore: boolean;
    visibleMenu: MenuCategories | null;
    prevMenu: MenuCategories | null;
    constructor() {
        this.dateTime = "";
        this.collapsed = true;
        this.showStore = false;
        this.visibleMenu = null;
        this.prevMenu = null;
    }
}

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatNumber(n: number) {
    return n < 10 ? `0${n}` : n.toString();
}

class NavbarBind extends Component<NavbarProps> {

    state:NavbarState;

    constructor(props:NavbarProps) {
        super(props);
        this.state = new NavbarState();
    }
    toggleCollapsed() {
        this.setState({
            collapsed:!this.state.collapsed
        });
    }
    toggleShowStore() {
        this.setState({
            showStore:!this.state.showStore
        })
    }
    componentDidMount() {
        if(storageAvailable()) {
            const theme = localStorage.getItem('nasfaq:theme');
            if(theme === null) return;
            this.props.setTheme(parseInt(theme));
        }
        setInterval(() => {
            const etTimeString = new Date().toLocaleString("en-US", {
                timeZone: "America/New_York"
            });
            const date = new Date(etTimeString);
            const day = days[date.getDay()];
            const month = months[date.getMonth()];
            const daynumber = date.getDate();
            const year = date.getFullYear();
            const hour = formatNumber(date.getHours());
            const minute = formatNumber(date.getMinutes());
            const seconds = formatNumber(date.getSeconds());
            const dayString = `${hour}:${minute}:${seconds} on ${day} ${month} ${daynumber}, ${year} [America/New York]`
            this.setState({
                dateTime: dayString
            })
        }, 1000);
    }
    componentDidUpdate(prevProps:NavbarProps) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({collapsed:true, visibleMenu:null});
        }
    }
    handleRightClick = (e:any) => {
        this.props.setShowSnowSettings(!this.props.snowfall.showSnowSettings);
        e.preventDefault();
    }
    setVisibleMenu(menu:MenuCategories | null) {
        this.setState({
            visibleMenu:menu,
            prevMenu:menu === null ? this.state.prevMenu : menu
        });
    }
    handleClick = () => {
        let nextTheme = this.props.settings.theme + 1;
        if(Themes[nextTheme] === undefined) nextTheme = 0;
        if(storageAvailable()) {
            localStorage.setItem('nasfaq:theme', JSON.stringify(nextTheme));

            if(nextTheme === Themes.CHRISTMAS) {
                let xmasFirstTime = localStorage.getItem('nasfaq:snownotif');
                if(!xmasFirstTime) {
                    this.props.setShowSnowNotification(true);
                    localStorage.setItem('nasfaq:snownotif', "true");
                }
            }
        }
        this.props.setTheme(nextTheme);
    }
    themeIcon = () => {
        if(this.props.settings.theme === Themes.LIGHT) {
            return sun;
        } else if(this.props.settings.theme === Themes.DARK) {
            return moon;
        } else if(this.props.settings.theme === Themes.HALLOWEEN) {
            return web;
        } else if(this.props.settings.theme === Themes.CHRISTMAS) {
            return stocking;
        }
    }
    renderMenuCategory() {
        switch(this.state.visibleMenu) {
            case MenuCategories.MarketActivity:
                return(
                    <ul className="nav-link-list"> 
                        <li><Link to="/market">Stocks</Link></li>
                        <li><Link to="/mutualfunds">Mutual Funds</Link></li>
                        <li><Link to="/activity">Activity</Link></li>
                    </ul>
                )
            case MenuCategories.NewsInsights:
                return(
                    <ul className="nav-link-list">
                        <li><Link to="/info">Info</Link></li>
                        <li><Link to="/benchmark">Benchmark</Link></li>
                        <li><Link to="/leaderboard">Leaderboards</Link></li>
                        <li><Link to="/devblog">Developer Blog</Link></li>
                        {
                            this.props.session.loggedin ?
                            <>
                            <li><Link to="/floor">Floor</Link></li>
                            <li><Link to="/polls">Polls</Link></li>
                            </> : null
                        }
                    </ul>
                )
            case MenuCategories.Commodities:
                return(
                    <ul className="nav-link-list"> 
                        <li><Link to="/superchats">Superchats</Link></li>
                        {
                            this.props.session.loggedin ?
                            <>
                            <li><Link to="/gacha">Gacha</Link></li>
                            <li><Link to="/betting">Betting</Link></li>
                            <li><Link to="/auctions">Auctions</Link></li>
                            </> : null
                        }
                    </ul>
                )
            default:
                return null
        }
    }

    renderMenuHeaders() {
        return Object.values(MenuCategories).map((cat:MenuCategories) =>
        <div
            onMouseEnter={() => this.setVisibleMenu(cat)}
            onMouseLeave={() => this.setVisibleMenu(null)} 
            className="nav-item">
            {cat}
            {
                (this.state.visibleMenu !== null) &&
                (this.state.visibleMenu === cat) ?
                <div
                    onMouseEnter={() => this.setVisibleMenu(this.state.prevMenu)}
                    onMouseLeave={() => this.setVisibleMenu(null)} 
                    className="active-menu-outer">
                    {this.renderMenuCategory()}
                </div> : null
            }
        </div>
        )
    }

    render() {
        let balance:String = '';
        if(!this.props.userinfo.loaded) {
            balance = '';
        } else {

            if(this.props.userinfo.wallet.balance < 0) {
                balance += "-";
            }
            
            balance += "$" + numberWithCommas(
                Math.round(Math.abs(this.props.userinfo.wallet.balance * 100)) / 100);

        }
        let pathname = this.props.location.pathname;
        if(pathname === "/globalchat") {
            return null;
        }

        let navbarClass = "navbar";
        if(pathname !== undefined) {
            if(pathname.indexOf("floor") === -1) {
                navbarClass = "navbar navbar-shadow"
            }
        }

        return(
            <div className={navbarClass}>
                <div 
                    className="nav-item navbar-collapse"
                    onClick={() => this.toggleCollapsed()}>
                    <HiMenu style={{verticalAlign: "middle"}} />
                </div>
                <div className="nav-item nav-title">
                    <Link to="/">nasfaq</Link>
                </div>
                <div className="nav-item datetime">
                    <div>{this.state.dateTime}
                    </div>
                </div>
                {
                    this.props.userinfo.loaded ? 
                    <div 
                        className="nav-item"
                        title={this.props.userinfo.username}>
                        <span className="username-nav">{this.props.userinfo.username}</span>
                    </div> : null
                }
                {
                    this.props.session.loggedin ?
                    <div className="nav-item balance-tag">
                        <div>
                            <span
                                title="Current Balance" 
                                className={`${this.props.userinfo.wallet.balance < 0 ?
                                "amt-negative" : "amt-positive"}`}>
                                {balance} 
                            </span>
                        </div>
                    </div> : null
                }


                <div className={`nav-pages ${this.state.collapsed ? '' : 'visible'}`}>

                    <div className="nav-item">
                        <Link to="/market">Stocks</Link>
                    </div>
                    {
                        this.renderMenuHeaders()
                    }

                    {
                        this.props.session.loggedin ?
                        <> 
                            <div className="nav-item">
                                <Link to="/profile">Profile</Link>
                            </div>
                            <div className="nav-item">
                                <Link to="/logout">Logout</Link>
                            </div>
                        </> 
                        :
                        <> 
                            <div className="nav-item">
                                <Link to="/login">Login</Link>
                            </div>
                        </>
                    }

                    {
                        this.props.userinfo.admin ?
                        <div className="nav-item">
                            <Link to="/admin">Admin</Link>
                        </div> : null
                    }

                    <div className="nav-item nav-item-toggle-dark">
                        <img 
                        src={this.themeIcon()} 
                        alt={"darkmode-img"} 
                        className="darkmode-img" 
                        onClick={(
                            ev: React.MouseEvent,
                        ): void => {this.handleClick()}}
                        onContextMenu={(e) => this.handleRightClick(e)}/>
                    </div>
                </div>
                {
                    !this.props.settings.marketSwitch ?
                    <div className="market-closed-banner flex flex-row flex-center">
                        MARKET CLOSED
                    </div> : null
                }
            </div>
        )
    }
}

const Navbar = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavbarBind);
  
export default withRouter(Navbar);