import React, {Component} from 'react';
import {connect} from 'react-redux';
import { IMutualFund, IMutualFundHistoryCollection, IMutualFundMember, IMutualFunds, IMutualFundStatCollection } from '../../../interfaces/MutualFunds';
import MutualFundSection from './MutualFundSection';

import "../../../../css/mutualfunds/mutualfundmembers.scss";
import { LeaderboardUser } from '../../leaderboard/Leaderboard';
import Coin from '../../Coin';
import numberWithCommas from '../../../numberWithCommas';
import hexToRgb from '../../../hexToRBG';
import getForegroundFromBackground from '../../../getForegroundFromBackground';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds,
    stats:state.stats
});

const mapDispatchToProps = {

}

interface IMutualFundMembersProps {
    session: {
        loggedin: boolean,
    },
    userinfo: {
        id:string
    },
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
    },
    stats: {
        leaderboard: Array<LeaderboardUser>,
    },
    fund:string
}

class MutualFundMembersBind extends Component<IMutualFundMembersProps> {
    
    getColor() {
        return "#" + this.props.mutualfunds.funds[this.props.fund].color;
    }
    
    getForegroundColor() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getForegroundFromBackground(r, g, b);
    }

    isBoardMember(userid:string) {
        let isBoardMember = false;
        this.props.mutualfunds.funds[this.props.fund].members.forEach((member:IMutualFundMember) => {
            if(member.id === userid) {
                if(member.boardMember) isBoardMember = true;
            }
        });
        return isBoardMember;
    }

    renderStatus(userid:string) {
        if(this.props.mutualfunds.funds[this.props.fund].ceo === userid) {
            return (
                <td>
                    <div 
                    className="member-status ceo"
                    style={{
                        "background":this.getColor(),
                        "color":this.getForegroundColor()
                    }}>CEO</div>
                </td>
            )
        } else {
            let isBoardMember = this.isBoardMember(userid);
            if(isBoardMember) {
                return (
                    <td>
                        <div 
                        style={{
                            "background":this.getColor(),
                            "color":this.getForegroundColor()
                        }}
                        className="member-status">Board Member</div>
                    </td>)
            } else {
                return <td></td>;
            }
        }
    }

    kickMember(userid:string) {
        if(window.confirm("Are you sure you want to kick this member?")) {
            fetch('/api/mutualFundKickMember', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fund:this.props.fund,
                    removedMember:userid
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    toggleBoardMember(userid:string) {
        let confirmString = '';
        if(this.isBoardMember(userid)) {
            confirmString = "Are you sure you want to remove this member from the board of directors?";
        } else {
            confirmString = "Are you sure you want to add this member to the board of directors?"
        }
        if(window.confirm(confirmString)) {
            fetch('/api/mutualFundToggleBoardMember', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fund:this.props.fund,
                    member:userid
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    isCEO() {
        if(!this.props.session.loggedin) return false;
        return this.props.userinfo.id === this.props.mutualfunds.funds[this.props.fund].ceo;
    }

    renderMembers() {
        let fund:IMutualFund = this.props.mutualfunds.funds[this.props.fund];
        let members:Array<IMutualFundMember> = fund.members;
        let memberSet:{[userid:string]:any} = {};
        members.forEach((member:IMutualFundMember) => {
            memberSet[member.id] = '';
        });
        let memberInfo:Array<LeaderboardUser> = [];
        this.props.stats.leaderboard.forEach((user:LeaderboardUser) => {
            if(memberSet[user.userid] !== undefined) {
                memberInfo.push(JSON.parse(JSON.stringify(user)));
            }
        })
        memberInfo.sort((a:LeaderboardUser, b:LeaderboardUser) => {
            return b.networth - a.networth;
        })
        return memberInfo.map((member:LeaderboardUser) => 
            <tr 
                key={member.userid}
                className="member-item">
                <td className="member-icon">
                    <Coin name={member.icon}/>
                </td>
                <td className="member-name">{member.username}</td>
                {
                    this.renderStatus(member.userid)
                }
                <td className="member-networth">${numberWithCommas(member.networth)}</td>
                {
                    this.isCEO() && (member.userid !== this.props.userinfo.id) ?
                    <>
                    <td className="kick-member-col">
                        <div
                            onClick={() => this.kickMember(member.userid)} 
                            className="kick-member-button">
                            Kick
                        </div>
                    </td>
                    <td className="toggle-member-on-board">
                        {
                            this.isBoardMember(member.userid) ?
                            <div
                                onClick={() => this.toggleBoardMember(member.userid)} 
                                className="remove-from-board-button">
                                Remove From Board
                            </div>
                            :
                            <div
                                onClick={() => this.toggleBoardMember(member.userid)}
                                className="add-to-board-button">
                                Add To Board
                            </div>
                        }
                    </td>
                    </> :
                    <>
                    {
                        this.isCEO() ?
                        <>
                            <th></th>
                            <th></th>
                        </> : null
                    }
                    </>
                }
            </tr>
        )
    }

    render() {
        return(
            <MutualFundSection fund={this.props.fund}>
                <div className="section-header">
                    Members
                </div>
                <div className="members-list-container flex flex-row">
                    <table>
                        <thead>
                            <tr>
                                <th>Icon</th>
                                <th>Username</th>
                                <th></th>
                                <th>Net Worth</th>
                                {
                                    this.isCEO() ?
                                    <>
                                        <th></th>
                                        <th></th>
                                    </> : null
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderMembers()}
                        </tbody>
                    </table>
                </div>
            </MutualFundSection>
        );
    }
}

const MutualFundMembers = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundMembersBind);

export default MutualFundMembers;