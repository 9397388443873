import React, {Component} from 'react';
import {connect} from 'react-redux';
import fetchData from '../../fetchData';
import postRequest from '../../postRequest';
import {marked} from 'marked';
import DOMPurify from 'dompurify';

import {devblogActions} from '../../actions/actions';
import suisus from '../../../images/suisus.png';

import "../../../css/devblog.scss";
import { IDevBlogPost } from '../../interfaces/DevBlog';
import {BiChevronDownCircle} from 'react-icons/bi';
import {MdDeleteForever} from 'react-icons/md';
import {FiEdit} from 'react-icons/fi';
import EditBlogPost from './EditBlogPost';

const mapStateToProps = (state:any, props:any) => ({
    devblog: state.devblog,
    userinfo: state.userinfo
});

const mapDispatchToProps = {
    setBlogPosts: devblogActions.setBlogPosts
}

interface IDevBlogProps {
    devblog: {
        posts:Array<IDevBlogPost>
    },
    userinfo: {
        blogPermission:boolean
    },
    setBlogPosts: (posts:Array<IDevBlogPost>) => {}
}

class DevBlogState {
    expandForm:boolean = false;
    editPost:IDevBlogPost|null = null;
    title:string = "";
    signature:string = "";
    post:string = "";
    error:string = "";
}

class DevBlogBind extends Component<IDevBlogProps> {

    state:DevBlogState;
    constructor(props:IDevBlogProps) {
        super(props);
        this.state = new DevBlogState();
    }

    componentDidMount() {
        if(this.props.devblog.posts.length === 0) {
            fetchData("/api/getBlogPosts")
            .then((data:any) => {
                if(data.success) {
                    this.props.setBlogPosts(data.blogPosts);
                } else {
                    console.log(data.message);
                }
            })
        }
    }

    updateFormItem(e:any) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    toggleForm() {
        this.setState({expandForm:!this.state.expandForm});
    }

    toggleEditPost(post:IDevBlogPost|null) {
        this.setState({editPost:post});
    }

    submitBlogPost() {
        if(
            this.state.title === "" ||
            this.state.post === "" ||
            this.state.signature === ""
        ) {
            this.setState({error: "Enter all fields."});
            return;
        }
        if(this.state.title.length > 100) {
            this.setState({error: "Title too long. Max length 100."});
            return;
        }
        if(this.state.post.length > 3000) {
            this.setState({error: "Post too long. Max length 3000."});
            return;
        }
        if(this.state.signature.length > 50) {
            this.setState({error: "Signature too long. Max length 50."});
            return;
        }
        postRequest("/api/addBlogPost",{
            signature:this.state.signature,
            post:this.state.post,
            title:this.state.title
        }).then((data:any) => {
            if(data.success) {
                this.setState({
                    title:"",
                    post:"",
                    signature:"",
                    error:""
                });
            } else {
                this.setState({error:data.message});
            }
        }).catch((err:any) => {
            console.error(err);
            this.setState({error: "Error making post."});
            return;
        })
    }

    deletePost(id:string) {
        if(!window.confirm("Are you sure you want to delete this post?")) return;
        postRequest("/api/removeBlogPost", {
            id
        }).then((data:any) => {
            if(!data.success) {
                console.error(data.message);
            }
        }).catch((err:any) => {
            console.error(err);
            return;
        })
    }

    renderBlogPost(post:IDevBlogPost) {
        let html = DOMPurify.sanitize(marked.parse(post.post));
        return (
            <div
                key={post.id} 
                className="post-container">
                {
                    this.props.userinfo.blogPermission ?
                    <div className="post-actions flex flex-row">
                    <div
                        onClick={() => this.deletePost(post.id)} 
                        className="delete-post">
                        <MdDeleteForever style={{verticalAlign:'middle'}}/>
                    </div>
                    <div
                        onClick={() => this.toggleEditPost(post)} 
                        className="edit-post">
                        <FiEdit style={{verticalAlign: 'middle'}}/>
                    </div>
                    </div> : null
                }
                <div className="post-title">
                    {post.title}
                </div>
                <div className="post-info flex flex-row">
                    <div className="post-signature">
                        {post.signature}
                    </div>
                    <div className="post-created">
                        Posted: {new Date(post.timeCreated).toLocaleString()}
                    </div>
                    {
                        post.edited ?
                        <div className="post-edited">
                            Edited: {new Date(post.edited).toLocaleString()}
                        </div> : null
                    }
                </div>
                <div
                    dangerouslySetInnerHTML={{__html:html}} 
                    className="post-content"></div>
            </div>
        )
    }

    render() {
        return(
            <div className="container">
                <div className="devblog-container-inner">
                    <div className="container-header">Dev Blog</div>
                    {
                        this.props.userinfo.blogPermission ?
                        <div className="add-blog-post-container">
                            <div className="add-post-header flex flex-row">
                                Make Post
                                <div   
                                    onClick={() => this.toggleForm()}
                                    className={
                                        !this.state.expandForm ?
                                        "toggle-form" :
                                        "toggle-form expand"
                                    }>
                                    <BiChevronDownCircle style={{verticalAlign: 'middle'}}/>
                                </div>
                            </div>
                            {
                                this.state.expandForm ?
                                <>
                                <div className="input-label">Title</div>
                                <input 
                                    name="title" 
                                    value={this.state.title}
                                    onChange={(e) => this.updateFormItem(e)} 
                                    type="text" />
                                <div className="input-label">Signature</div>
                                <input 
                                    name="signature" 
                                    value={this.state.signature}
                                    onChange={(e) => this.updateFormItem(e)} 
                                    type="text" />
                                <div className="input-label">Post (Markdown)</div>
                                <textarea 
                                    name="post" 
                                    value={this.state.post}
                                    onChange={(e) => this.updateFormItem(e)} >
                                </textarea>
                                <div
                                    onClick={() => this.submitBlogPost()} 
                                    className="simple-button">
                                    Submit
                                </div>
                                {
                                    this.state.error ?
                                    <div className="error">
                                        {this.state.error}
                                    </div> : null
                                }
                                </> : null
                            }
                        </div> : null
                    }
                    <div className="blog-posts">
                        {
                            this.props.devblog.posts.length === 0 ?
                            <div className="no-blog-posts">
                                No Blog Posts
                            </div> : null
                        }
                        {
                            this.props.devblog.posts.map((item) => 
                                this.renderBlogPost(item)
                            )
                        }
                    </div>
                    {
                        this.state.editPost ?
                        <EditBlogPost
                            blogPost={this.state.editPost}
                            toggleEdit={() => this.toggleEditPost(null)}/> : null
                    }
                </div>
                <div className="suisus">
                    <img src={suisus} alt="suisus"/>
                </div>
            </div>
        )
    }
}

const DevBlog = connect(
    mapStateToProps,
    mapDispatchToProps
)(DevBlogBind);

export default DevBlog;