import React, {Component} from 'react';
import {connect} from 'react-redux';
import { IMutualFundBulletin, IMutualFundBulletinCollection, IMutualFundHistoryCollection, IMutualFundMember, IMutualFunds, IMutualFundStatCollection } from '../../../interfaces/MutualFunds';
import MutualFundSection from './MutualFundSection';

import "../../../../css/mutualfunds/mutualfundbulletins.scss";
import hexToRgb from '../../../hexToRBG';
import getForegroundFromBackground from '../../../getForegroundFromBackground';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {

}

interface IMutualFundBulletinsProps {
    session: {
        loggedin:boolean
    },
    userinfo: {
        id:string
    },
    mutualfunds: {
        funds: IMutualFunds,
        bulletinboards: IMutualFundBulletinCollection,
    },
    fund:string
}

class MutualFundBulletinsState {
    newMessage:string = "";
    error:string = "";
}

class MutualFundBulletinsBind extends Component<IMutualFundBulletinsProps> {
    
    state:MutualFundBulletinsState;
    constructor(props:IMutualFundBulletinsProps) {
        super(props);
        this.state = new MutualFundBulletinsState();
    }

    isBoardMember() {
        if(!this.props.session.loggedin) return false;
        let members:Array<IMutualFundMember> = this.props.mutualfunds.funds[this.props.fund].members;
        for(let i = 0; i < members.length; i++) {
            if(members[i].id === this.props.userinfo.id) {
                if(members[i].boardMember) return true;
            }
        }
        return false;
    }
    
    getColor() {
        let fund = this.props.mutualfunds.funds[this.props.fund];
        return "#" + fund.color;
    }

    getForegroundColor() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getForegroundFromBackground(r, g, b);
    }

    updateMessageText(e:any) {
        this.setState({newMessage:e.target.value});
    }

    postBulletin() {
        
        if(this.state.newMessage.trim().length === 0) {
            return;
        }

        fetch('/api/mutualFundPostBulletin/', {
            method: 'POST',
            body: JSON.stringify({
                fund:this.props.fund,
                message:this.state.newMessage
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                this.setState({error:data.message});
            } else {
                this.setState({error:"", newMessage:""});
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    render() {
        const bulletins:Array<IMutualFundBulletin> = this.props.mutualfunds.bulletinboards[this.props.fund];
        if(bulletins === undefined) return null;
        return(
            <MutualFundSection fund={this.props.fund}>
                <div className="section-header">
                    Bulletins
                </div>
                {
                    this.isBoardMember() ?
                    <div className="add-bulletin-container flex flex-row">
                        <div className="add-bulletin-header">
                            Post a new bulletin:
                        </div>
                        <div className="add-bulletin-input-container">
                            <textarea
                                value={this.state.newMessage}
                                onChange={(e) => this.updateMessageText(e)}
                                placeholder="Bulletin text..."
                                maxLength={2000}
                                style={{"borderColor":this.getColor()}}></textarea>
                        </div>
                        <div
                            onClick={() => this.postBulletin()}
                            style={{
                                "background":this.getColor(),
                                "color":this.getForegroundColor()
                            }} 
                            className="add-bulletin-submit-button">
                            Post Bulletin
                        </div>
                    </div> : null
                }
                {
                    this.state.error !== "" ?
                    <div className="error flex center-child">
                        {this.state.error}
                    </div> : null
                }
                <div className="bulletin-container">
                    { 
                        bulletins.length === 0 ?
                        <div className="no-bulletins">
                            No Bulletins
                        </div> : null
                    }
                    {
                        bulletins.map((bulletin:IMutualFundBulletin) =>
                        
                        <div
                            key={bulletin.id} 
                            className="bulletin-outer flex flex-col">
                            <div className="bulletin-info flex flex-row">
                                <div className="bulletin-timestamp">
                                    {new Date(bulletin.timestamp).toLocaleString()}
                                </div>
                                <div className="bulletin-author">
                                    {bulletin.authorName}
                                </div>
                            </div>
                            <div className="bulletin-message">
                                {bulletin.message}
                            </div>
                        </div>
                        
                        )
                    }
                </div>
            </MutualFundSection>
        );
    }
}

const MutualFundBulletins = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundBulletinsBind);

export default MutualFundBulletins;