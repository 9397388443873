import React, {Component} from "react";
import '../../../css/autotrader.scss';
import Coin from "../Coin";

import { autotraderActions } from '../../actions/actions';
import {connect} from 'react-redux';

import {AutoTraderRule} from '../../interfaces/AutoTraderRule';
import ToggleSwitch from '../ToggleSwitch';
import {DragDropContext, Droppable } from 'react-beautiful-dnd';

import {lineage} from '../Icons';
import {
    MdClose
} from 'react-icons/md';
import {
    FiChevronDown,
    FiChevronUp
} from 'react-icons/fi';
import {
    BiUpArrow,
    BiDownArrow
} from 'react-icons/bi';
import {
    IoIosSave
} from 'react-icons/io';
import {
    GrUndo,
    GrRefresh
} from 'react-icons/gr';
import {
    BsGearFill
} from 'react-icons/bs';
import { ICoinDataCollection } from "../../interfaces/ICoinInfo";
import { IWallet } from "../../interfaces/IWallet";
import { Order, TransactionType } from "../../interfaces/ITransaction";
import numberWithCommas from "../../numberWithCommas";
import storageAvailable from "../../checkStorage";
import TradeRuleItem from "./TradeRuleItem";
import fetchData from "../../fetchData";
import getTransactionStatus from "../../getTransactionStatus";

const mapStateToProps = (state:any, props:any) => ({
    autotrader: state.autotrader,
    session:state.session,
    userinfo:state.userinfo,
    stats:state.stats,
    settings:state.settings
});

const mapDispatchToProps = {
    setRunning:autotraderActions.setRunning,
    setRules:autotraderActions.setRules,
    setUpdatedRules:autotraderActions.setUpdatedRules,
    setNextTradeTime:autotraderActions.setNextTradeTime,
    setPendingOrder:autotraderActions.setPendingOrder,
    setExpectedBalance:autotraderActions.setExpectedBalance
}

interface AutoTraderEditorProps {
    autotrader: {
        running:boolean,
        rules:Array<AutoTraderRule>,
        nextTradeTime:number,
        expectedBalance:number,
        pendingOrder:Array<Order>,
        updatedRules:Array<AutoTraderRule>
    },
    stats: {
        coinInfo: ICoinDataCollection,
        brokerFee: number
    },
    userinfo: {
        wallet: IWallet,
        loaded:boolean,
        brokerFeeCredits:number,
        muted:any,
        verified:boolean
    },
    session: {
        loggedin: boolean
    },
    settings: {
        marketSwitch: boolean
    },
    toggleVisible: () => void,
    setRunning: (running:boolean) => {}
    setRules: (rules:Array<AutoTraderRule>) => {},
    setUpdatedRules: (updatedRules:Array<AutoTraderRule>) => {},
    setNextTradeTime: (nextTradeTime:number) => {},
    setPendingOrder: (pendingOrder:Array<Order>) => {},
    setExpectedBalance: (expectedBalance:number) => {}
}

class AutoTraderEditorState {
    showInfo:boolean;
    timeRemaining:{minutes:number, seconds:number};
    updated:boolean;
    lastSaved:number;
    settingsDialogueVisible: boolean;
    clickReloadBuffer:boolean;
    constructor() {
        this.showInfo = false;
        this.updated = false;
        this.timeRemaining = {minutes:0, seconds:0};
        this.lastSaved = 0;
        this.settingsDialogueVisible = false;
        this.clickReloadBuffer = true;
    }
}

class AutoTraderEditorBind extends Component<AutoTraderEditorProps> {

    state:AutoTraderEditorState;
    interval:any;
    constructor(props:AutoTraderEditorProps) {
        super(props);
        this.state = new AutoTraderEditorState();
    }

    onDragEnd = (result:any) => {
        const {destination, source} = result;
        if(!destination) return;
        if(
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) return;

        let rulesCopy = [...this.props.autotrader.updatedRules];
        let oldRule = {...rulesCopy[source.index]};
        rulesCopy.splice(source.index, 1);
        rulesCopy.splice(destination.index, 0, oldRule);
        this.props.setUpdatedRules(rulesCopy);
        this.checkUpdated(rulesCopy);
    }

    checkUpdated(updatedRules:Array<AutoTraderRule>) {

        let rules = [...this.props.autotrader.rules];

        let updated = false;
        if(rules.length !== updatedRules.length) {
            updated = true;
        }

        if(rules.length === updatedRules.length) {
            for(let i = 0; i < rules.length; i++) {
                const rule1 = rules[i];
                const rule2 = updatedRules[i];
                if(rule1.coin !== rule2.coin) {
                    updated = true;
                } else {
                    if((rule1.stepQuantity !== rule2.stepQuantity) || (rule1.targetQuantity !== rule2.targetQuantity)) {
                        updated = true;
                    }
                }
            }
        }

        if(updated) {
            this.setState({lastSaved: new Date().getTime()});
        } else {
            this.setState({lastSaved: 0})
        }

        this.setState({updated});
    }

    rulesContainCoin(coin:string) {
        let rules = [...this.props.autotrader.updatedRules];
        let index = -1;
        rules.forEach((r:AutoTraderRule, i:number) => {
            if(r.coin === coin) index = i;
        });
        return index;
    }

    toggleCoin(coin:string) {

        let rules = [...this.props.autotrader.updatedRules];
        let index = this.rulesContainCoin(coin);
        if(index === -1) {
            let currentQuant = this.getMyQuant(coin);
            rules.push({
                coin,
                type:TransactionType.BUY,
                stepQuantity:1,
                targetQuantity:currentQuant
            });
        } else {
            rules.splice(index, 1);
        }
        this.props.setUpdatedRules(rules);
        this.checkUpdated(rules);

    }

    clearAllCoins() {
        let newRules:Array<AutoTraderRule> = [];
        this.props.setUpdatedRules(newRules);
        this.checkUpdated(newRules);
    }

    resetChanges() {
        this.props.setUpdatedRules(JSON.parse(JSON.stringify(this.props.autotrader.rules)));
        this.setState({updated:false});
    }

    saveChanges() {

        if(!this.state.updated) return;
        let updatedRules = [...this.props.autotrader.updatedRules];

        fetch('/api/updateAutoTraderRules', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                rules:updatedRules
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.props.setRules(JSON.parse(JSON.stringify(updatedRules)));
                this.generateOrders();
                this.setState({updated:false, lastSaved:0});
            } else {
                console.log(data.message);
            }
        })

    }

    toggleSettingsDialogue() {
        this.setState({
            settingsDialogueVisible: !this.state.settingsDialogueVisible
        });
    }

    toggleAutoTrader(setTo?:boolean) {

        let newStatus = setTo === undefined ?
            !this.props.autotrader.running : setTo;

        fetch('/api/toggleAutoTrader/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                active:newStatus
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.props.setRunning(newStatus);
                if(newStatus) {
                    this.generateOrders();
                } else {
                    this.props.setPendingOrder([]);
                }
            } else {
                console.log(data.message);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        });
    }

    setTargetQuantity(index:number, quantity:number) {
        let rules = [...this.props.autotrader.updatedRules];
        rules[index].targetQuantity = quantity;
        let myQuant = this.getMyQuant(rules[index].coin);
        if(quantity < myQuant) {
            rules[index].type = TransactionType.SELL;
        } else if(quantity > myQuant) {
            rules[index].type = TransactionType.BUY;
        }
        this.props.setUpdatedRules(rules);
        this.checkUpdated(rules);
    }

    setStepQuantity(index:number, quantity:number) {
        let rules = [...this.props.autotrader.updatedRules];
        rules[index].stepQuantity = quantity;
        this.props.setUpdatedRules(rules);
        this.checkUpdated(rules);
    }

    getCoinClass(coin:string) {
        if(this.rulesContainCoin(coin) !== -1) {
            return 'coin-outer';
        } else {
            return 'coin-outer coin-outer-inactive';
        }
    }

    filterName(coin:string) {
        return coin === "luna" ? "himemoriluna" : coin;
    }

    displayName(coin:string) {
        return coin === "himemoriluna" ? "luna" : coin;
    }

    getCoinPrice(coin:string) {
        let name = this.filterName(coin);
        return this.props.stats.coinInfo.data[name].price;
    }

    getCoinVolume(coin:string) {
        let name = this.filterName(coin);
        return this.props.stats.coinInfo.data[name].inCirculation;
    }

    getCoinSaleValue(coin:string) {
        let name = this.filterName(coin);
        return this.props.stats.coinInfo.data[name].saleValue;
    }

    getMyQuant(coin:string) {
        let wallet:IWallet = {...this.props.userinfo.wallet};
        let name = this.filterName(coin);
        if(wallet.coins[name] === undefined) {
            return 0;
        } else {
            return wallet.coins[name].amt;
        }
    }

    getStepQuantity(coin:string) {
        let rules = [...this.props.autotrader.updatedRules];
        let index = this.rulesContainCoin(coin);
        if(index !== -1) {
          return rules[index].stepQuantity;
        } else {
          return -1;
        }
    }

    getTargetQuantity(coin:string) {
        let rules = [...this.props.autotrader.updatedRules];
        let index = this.rulesContainCoin(coin);
        if(index !== -1) {
          return rules[index].targetQuantity;
        } else {
          return -1;
        }
    }

    getBubbleColour(coin:string) {
      let className = "coin-owned-bubble";
      let currentQuantity = this.getMyQuant(coin);
      let targetQuantity = this.getTargetQuantity(coin);
      if(targetQuantity !== -1) {
        if(targetQuantity == currentQuantity) {
            className += " bubble-rule-neutral";
        } else {
            if(targetQuantity > currentQuantity) {
                className += " bubble-rule-buy";
            } else {
                className += " bubble-rule-sell";
            }
        }
      } else {
        className += " bubble-rule-neutral";
      }
      return className;
    }

    getBubbleStep(coin:string) {
      let className = "coin-owned-bubble";
      let stepQuantity = this.getStepQuantity(coin);
      if(stepQuantity !== -1) {
        return stepQuantity;
      } else {
        return null;
      }
    }

    getBalance() {
        return this.props.userinfo.wallet.balance;
    }

    getMeanPurchasePrice(coin:string) {
        let wallet:IWallet = {...this.props.userinfo.wallet};
        let name = this.filterName(coin);
        if(wallet.coins[name] === undefined) {
            return 0;
        } else {
            return Math.round(wallet.coins[name].meanPurchasePrice * 100) / 100;
        }
    }

    getTimestamp(coin:string) {
        let wallet:IWallet = {...this.props.userinfo.wallet};
        let name = this.filterName(coin);
        if(wallet.coins[name] === undefined) {
            return 0;
        } else {
            return wallet.coins[name].timestamp;
        }
    }

    updateTimeRemaining() {

        let timeRemaining = (this.props.autotrader.nextTradeTime - new Date().getTime()) / 1000;
        if(timeRemaining < 0) timeRemaining = 0;

        let minutes = Math.floor(timeRemaining / 60);
        let seconds = Math.floor(timeRemaining - (minutes * 60));

        this.setState({timeRemaining: {
            minutes, seconds
        }});

    }

    autoSave() {
        if(this.state.lastSaved !== 0) {
            const now = new Date().getTime();
            if((now - this.state.lastSaved) > (1000 * 60 * 5) && this.state.updated) {
                this.saveChanges();
            }
        }
    }

    componentDidMount() {

        if(this.props.autotrader.nextTradeTime === 0) {
            fetchData('/api/getUserAutoTraderRules')
            .then((data:any) => {
                this.props.setRules(JSON.parse(JSON.stringify(data.rules)));
                this.props.setUpdatedRules(JSON.parse(JSON.stringify(data.rules)));
                this.props.setRunning(data.traderStatus);
                this.props.setNextTradeTime(data.timestamp + (1000 * 60 * 10 + 1));
                if(data.traderStatus) {
                    this.generateOrders();
                }
            });
        } else {
            this.checkUpdated(this.props.autotrader.updatedRules);
            this.generateOrders();
        }

        this.interval = setInterval(() => {
            this.updateTimeRemaining();
            this.autoSave();
        }, 1000);
    }

    componentDidUpdate(prevProps:AutoTraderEditorProps) {
        if(prevProps.autotrader.nextTradeTime !== this.props.autotrader.nextTradeTime) {
            this.generateOrders();
        }
        let currentWallet = this.props.userinfo.wallet;
        let prevWallet = prevProps.userinfo.wallet;
        if(currentWallet.balance !== prevWallet.balance) {
            this.generateOrders();
            return;
        }
        let currentCoins = Object.keys(currentWallet.coins);
        let prevCoins = Object.keys(prevWallet.coins);
        if(currentCoins.length !== prevCoins.length) {
            this.generateOrders();
            return;
        }
        for(let i = 0; i < currentCoins.length; i++) {
            let currentCoin = currentCoins[i];
            if(prevWallet.coins[currentCoin]) {
                if(prevWallet.coins[currentCoin].amt !== currentWallet.coins[currentCoin].amt) {
                    this.generateOrders();
                    return;
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    generateOrders() {

        let orders:Array<Order> = [];
        const wallet:IWallet = JSON.parse(JSON.stringify(this.props.userinfo.wallet));

        if(!this.props.autotrader.running) {
            this.props.setExpectedBalance(wallet.balance);
            this.props.setPendingOrder(orders);
            return;
        }

        const credits:number = this.props.userinfo.brokerFeeCredits;
        const brokerFee = this.props.stats.brokerFee;
        let showMuted = false;
        let muted:any = this.props.userinfo.muted;
        if(this.props.userinfo.muted !== null) {
            muted = JSON.parse(muted);
            if(muted.until < new Date().getTime()) {
                showMuted = false;
            } else {
                showMuted = true;
            }
        }

        let runningCredits = credits;
        let rules = this.props.autotrader.rules;
        for(let i = 0; i < rules.length; i++) {

            const rule = rules[i];
            const name = this.filterName(rule.coin);
            const coinInfo = this.props.stats.coinInfo.data[name];

            let stepQuantity = Math.max(0, Math.min(10, rule.stepQuantity));
            let currentAmount;
            if(wallet.coins[name] === undefined) {
                currentAmount = 0;
            } else {
                currentAmount = wallet.coins[name].amt;
            }

            if(Math.abs(rule.targetQuantity - currentAmount) < stepQuantity) {
                stepQuantity = Math.abs(rule.targetQuantity - currentAmount);
            }

            const {
                timeRemaining,
                buyDisabled,
                sellDisabled
            } = getTransactionStatus(
                wallet,
                coinInfo,
                name,
                this.props.userinfo.verified,
                showMuted,
                stepQuantity,
                stepQuantity,
                runningCredits,
                this.props.settings.marketSwitch,
                brokerFee
            );

            let timeUntilNextCycle = this.props.autotrader.nextTradeTime - new Date().getTime();
            if(timeRemaining > timeUntilNextCycle) continue;
            if(currentAmount < rule.targetQuantity) {
                if(!buyDisabled) {
                    let quantity = Math.min(rule.targetQuantity - currentAmount, stepQuantity);
                    let tax = quantity * brokerFee;
                    tax = tax * coinInfo.price * quantity;
                    if(tax >= runningCredits) {
                        runningCredits = 0;
                        tax -= runningCredits;
                    } else {
                        runningCredits -= tax;
                        tax = 0;
                    }
                    let totalCost = (quantity * coinInfo.price) + tax;
                    wallet.balance -= totalCost;
                    orders.push({coin:name, quantity, type:TransactionType.BUY})
                }
            } else if(currentAmount > rule.targetQuantity) {
                if(!sellDisabled) {
                    let quantity = Math.min(currentAmount - rule.targetQuantity, stepQuantity);
                    let tax = quantity * (brokerFee - 0.015);
                    if(rule.stepQuantity === 1) {
                        tax = 0;
                    }
                    tax = tax * coinInfo.saleValue * quantity;
                    if(tax >= runningCredits) {
                        runningCredits = 0;
                        tax -= runningCredits;
                    } else {
                        runningCredits -= tax;
                        tax = 0;
                    }
                    wallet.balance += (coinInfo.saleValue * quantity) - tax;
                    orders.push({coin:name, quantity, type:TransactionType.SELL})
                }
            }
        }

        this.props.setExpectedBalance(wallet.balance);
        this.props.setPendingOrder(orders);
    }

    getMetrics() {

        const orders:Array<Order> = this.props.autotrader.pendingOrder;
        let buys:Array<string> = [];
        let sells:Array<string> = [];

        orders.forEach((order:Order) => {
            if(order.type === TransactionType.BUY) {
                buys.push(order.quantity + "x " + order.coin);
            } else {
                sells.push(order.quantity + "x " + order.coin)
            }
        });
        return {
            total: orders.length,
            buys,
            sells
        }
    }

    toggleVisible() {
        if(this.state.updated) {
            if(window.confirm("You have unsaved changes. Are you sure you want to close the autotrader?")) {
                this.props.toggleVisible();
            }
        } else {
            this.props.toggleVisible();
        }
    }

    refetchData() {
        if(!this.state.clickReloadBuffer) return;
        this.setState({
            clickReloadBuffer:false
        }, () => {
            setTimeout(() => {
                fetchData('/api/getUserAutoTraderRules')
                .then((data:any) => {
                    this.props.setRules(JSON.parse(JSON.stringify(data.rules)));
                    this.props.setUpdatedRules(JSON.parse(JSON.stringify(data.rules)));
                    this.props.setRunning(data.traderStatus);
                    this.props.setNextTradeTime(data.timestamp + (1000 * 60 * 10 + 1));
                    if(data.traderStatus) {
                        this.generateOrders();
                    }
    
                    this.setState({clickReloadBuffer:true});
                });
            },4000);
        })
    }

    render() {
        if(!this.props.session.loggedin) return null;
        if(!this.props.userinfo.loaded) return null;
        let allCoins:Array<string> = [];
        lineage.forEach((gen:Array<string>) => {
            allCoins = [...allCoins, ...gen];
        });
        allCoins = allCoins.map((coin:string) => {
            return this.filterName(coin);
        });
        let {
            total,
            buys,
            sells
        } = this.getMetrics();

        let {
            minutes, seconds
        } = this.state.timeRemaining;

        let delta = Math.round((this.props.autotrader.expectedBalance - this.props.userinfo.wallet.balance) * 100) / 100;

        return(
            <DragDropContext
                onDragEnd={this.onDragEnd}>
                <div className={`auto-trader-editor ${this.state.updated ? "updated" : ""}`}>
                    <div
                        className="auto-trader-background"
                        onClick={() => this.toggleVisible()}></div>
                    <div className="auto-trader-inner">
                        <div
                            onClick={() => this.toggleVisible()}
                            className="close-auto-trader">
                            <MdClose />
                        </div>
                        {
                            this.state.updated ?
                            <div className="updated-controls">
                                <div
                                    className="save-changes"
                                    title="Save Changes"
                                    onClick={() => this.saveChanges()}>
                                    <IoIosSave style={{verticalAlign:'middle'}}/>
                                </div>
                                <div
                                    className="reverse-changes"
                                    onClick={() => this.resetChanges()}
                                    title="Reset Changes">
                                    <GrUndo style={{verticalAlign:'middle'}}/>
                                </div>
                            </div> : null
                        }
                        <div className="auto-trader-header">
                            auto-trader
                        </div>
                        <div
                            className="auto-trader-toggle-info"
                            onClick={() => this.setState({showInfo: !this.state.showInfo})}>
                            How to Use
                            {
                                this.state.showInfo ?
                                <FiChevronUp style={{verticalAlign: 'middle'}}/>
                                :
                                <FiChevronDown style={{verticalAlign: 'middle'}}/>
                            }
                        </div>
                        { this.state.showInfo ?
                        <div className="auto-trader-description">
                            Use the auto-trader to carry out trades for you.
                            It will only simulate player actions and will not
                            bypass cooldown or transfer more than the max multicoin limit (10) of each coin at a time.<br/>
                            Use the coin icons on the left to toggle which coins are to be traded. Their order in the list determines their priority when being traded. To change the order, use the arrow icons on the left of each bar to drag the rule to the desired spot. Each coin will be bought or sold on cooldown until the desired target quantity is reached or until all liquid/ coins have been expended.<br/>
                            Use the text input to enter the desired quantity, or use the buttons on either side to increment or decrement the value. You can also edit the amount of each coin to be traded at once using the Step Quantity input field.<br/>
                            After making a change to your autotrader rules, two buttons will appear on the top left and the border will light up to indicate changes have been made. Click the save icon to save your changes to the server, and click the undo button to revert all changes back to the previous save. <span className="important-info">The trader will only execute your saved rule list, so you can make all of your desired changes without worrying about the trader becoming out of sync as long as you do not save until you are ready.</span>The autotrader will automatically save your changes after 5 minutes of inactivity, starting after making an unsaved change.<br/>
                            After formulating your trade rule list, click the on switch to activate your trader on the server. Your cycle begins when you first turn on the trader, and continues every 10 minutes. If you turn the trader off and back on, you will reset your cycle time (for example, if a coin is not on cooldown anymore and you want it to be traded now instead of waiting for other coins, simply turn the trader off and on again).
                            All trades will be made in bulk at the next cycle. Your rules are processed on the server, so you can close the site and your trades will still be executed. Refer to the timer that appears when your trader is on to see when the next cycle is.<br/>
                            The background progress bar of each rule indicates the cooldown remaining on that coin.<br/>
                            Darkened rule items indicate coins that are not currently pending an order. This means coins that have reached their target, coins that cannot be afforded at the desired quantity, the auto-trader being off, etc.
                            Rules with a green border are being purchased. Rules with a red border are being sold. Rules with a blue border have reached their target quantity.
                        </div> : null }
                        {
                            this.props.autotrader.running ?
                            <>
                            <div className="trade-metrics">
                                <div className="trade-amounts">
                                    Trading <span className="trade-amount-quant">{total}</span> coin
                                    {
                                        total === 1 ? "" : "s"
                                    }
                                </div>
                                <div className="trade-amounts">
                                    Buying <span className="trade-amount-quant">{buys.length}</span> coin
                                    {
                                        buys.length === 1 ? "" : "s"
                                    }
                                </div>
                                <div className="trade-amounts">
                                    Selling <span className="trade-amount-quant">{sells.length}</span> coin
                                    {
                                        sells.length === 1 ? "" : "s"
                                    }
                                </div>
                            </div>
                            <div className="trade-summary">
                                {
                                    buys.length > 0 ?
                                    <div className="trade-summary-list">
                                        Buying <span className="trade-summary-names buys">{buys.join(", ")}</span>
                                    </div> : null
                                }
                                { buys.length > 0 && sells.length > 0 ? "and" : ""}
                                {
                                    sells.length > 0 ?
                                    <div className="trade-summary-list">
                                        Selling <span className="trade-summary-names sells">{sells.join(", ")}</span>
                                    </div> : null
                                }
                                {
                                    total > 0 ?
                                    "in" : ""
                                }
                            </div>
                            </>: null
                        }
                        {
                            this.props.autotrader.running
                            && total > 0
                            && (minutes > 0 || seconds > 0) ?
                            <>
                            <div className="trade-countdown">
                            {
                                minutes > 0 ?
                                <>
                                <span className="countdown-number">{minutes}</span> minute{
                                    minutes === 1 ? "" : "s"
                                }
                                {" "} and {" "}
                                </> : null
                            }

                            <span className="countdown-number">{seconds}</span> second{
                                seconds === 1 ? "" : "s"
                            }.
                            </div>
                            </> : null

                        }
                        {
                            this.props.autotrader.running
                            && this.props.autotrader.pendingOrder.length ?
                            <div className="trade-balance">
                            Expected Balance: <span>
                                ${numberWithCommas(Math.round(this.props.autotrader.expectedBalance * 100) / 100)}
                            </span> <span className={
                                delta > 0 ? "green" : "red"
                            }>({
                                delta > 0 ? <BiUpArrow style={{verticalAlign: 'middle'}}/> : <BiDownArrow style={{verticalAlign: 'middle'}}/>
                            }${numberWithCommas(Math.abs(delta))})</span>
                            </div> : null
                        }
                        <div className="trade-rules-container">
                            <div className="trader-controls">
                                <div className="trader-actions flex flex-row flex-center">
                                    <div className="trader-actions-label">
                                        Running:
                                    </div>
                                    <ToggleSwitch
                                        onLabel={"On"}
                                        offLabel={"Off"}
                                        switchState={this.props.autotrader.running}
                                        onToggle={(setTo?:boolean) => this.toggleAutoTrader(setTo)}/>

                                </div>
                                <div className="trader-coins">
                                    {
                                    allCoins.map((coin:string) =>
                                        <div
                                            className={this.getCoinClass(coin)}
                                            onClick={() => this.toggleCoin(coin)}
                                            key={coin}>
                                            {
                                                this.getMyQuant(coin) > 0 ?
                                                <div className={this.getBubbleColour(coin)}> {this.getBubbleStep(coin)}
                                                </div> : null
                                            }
                                            <Coin name={this.displayName(coin)}/>
                                        </div>
                                    )
                                    }
                                </div>
                                <div
                                    className="clear-all-coins"
                                    onClick={() => this.clearAllCoins()}>
                                    Clear All
                                </div>
                            </div>
                            {
                            this.props.autotrader.updatedRules.length === 0 ?
                            <div className="no-rules flex flex-col center-child">
                                <div className="flex flex-col">
                                You haven't added any rules yet.
                                <div
                                    className="refetch-rules"
                                    onClick={() => this.refetchData()}
                                    title="Reload trades">
                                    <GrRefresh style={{verticalAlign:'middle'}}/>
                                </div>
                                </div>
                            </div>
                            :
                            <Droppable droppableId={"test"}>
                                {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    className="trade-rules-list"
                                    {...provided.droppableProps}>

                                    {
                                        this.props.autotrader.updatedRules.map((d:AutoTraderRule, index:number) =>
                                        (
                                            <TradeRuleItem
                                                pendingOrder={this.props.autotrader.pendingOrder}
                                                coin={d.coin}
                                                isFund={false}
                                                price={this.getCoinPrice(d.coin)}
                                                saleValue={this.getCoinSaleValue(d.coin)}
                                                volume={this.getCoinVolume(d.coin)}
                                                quantity={this.getMyQuant(d.coin)}
                                                stepQuantity={d.stepQuantity ? d.stepQuantity : 1}
                                                meanPurchasePrice={this.getMeanPurchasePrice(d.coin)}
                                                balance={this.getBalance()}
                                                targetQuantity={d.targetQuantity}
                                                timestamp={this.getTimestamp(d.coin)}
                                                type={d.type}
                                                index={index}
                                                key={d.coin}
                                                setTargetQuantity={(index:number, quantity:number) =>
                                                    this.setTargetQuantity(index, quantity)}
                                                setStepQuantity={(index:number, quantity:number) =>
                                                    this.setStepQuantity(index, quantity)}/>
                                        ))
                                    }

                                    {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                            }
                        </div>
                    </div>
                </div>
            </DragDropContext>
        );
    }
}

const AutoTraderEditor = connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoTraderEditorBind);

export default AutoTraderEditor;
