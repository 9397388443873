import React, {Component} from 'react';
import {connect} from 'react-redux';
import getForegroundFromBackground from '../../getForegroundFromBackground';
import hexToRgb from '../../hexToRBG';
import { IMutualFundHistoryCollection, IMutualFundMember, IMutualFundOrder, IMutualFundOrderTotals, IMutualFundPayouts, IMutualFunds, IMutualFundsDissolve, IMutualFundStatCollection } from '../../interfaces/MutualFunds';
import { getGradientString } from './MutualFundIndexItem';

import "../../../css/mutualfunds/mutualfundpage.scss";
import Coin from '../Coin';
import numberWithCommas from '../../numberWithCommas';
import MutualFundHistoryGraph from './MutualFundHistoryGraph';
import TradeButtonWrapper from '../TradeButton';

import {
    AiOutlineMinus
} from 'react-icons/ai';
import {
    BiDownArrow,
    BiPlus,
    BiUpArrow
} from 'react-icons/bi';
import {
    BsBackspaceFill,
    BsFillBackspaceFill
} from 'react-icons/bs';
import {FaPenAlt} from 'react-icons/fa';
import Button from '../Button';
import MutualFundMembers from './page views/MutualFundMembers';
import MutualFundSection from './page views/MutualFundSection';
import MutualFundPortfolio from './page views/MutualFundPortfolio';
import MutualFundBulletins from './page views/MutualFundBulletins';
import MutualFundChat from './page views/MutualFundChat';
import MutualFundJoinRequests from './page views/MutualFundJoinRequests';
import { userinfoActions } from '../../actions/actions';
import { IItem, UserItems } from '../../interfaces/IItem';
import { Link, Redirect } from 'react-router-dom';
import { IUserFunds } from '../../interfaces/IWallet';
import { Loading } from '../Loading';
import FundAutoTraderEditor from '../autotrader/FundAutoTraderEditor';
import MutualFundShareholders from './page views/MutualFundShareholders';
import { GoDash } from 'react-icons/go';
import postRequest from '../../postRequest';
import MutualFundUpdateInfo from './page views/MutualFundUpdateInfo';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {
    setMutualFundJoinRequests:userinfoActions.setMutualFundJoinRequests
}

interface IMutualFundPageProps {
    session: {
        loggedin:boolean
    },
    userinfo: {
        id:string,
        mutualfundJoinRequests:Array<string>,
        items:UserItems,
        mutualfundOrders:Array<IMutualFundOrder>,
        mutualfunds: IUserFunds
    },
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
        orders: IMutualFundOrderTotals,
        fundPayouts: IMutualFundPayouts,
        fundsToDissolve: IMutualFundsDissolve,
        loaded:boolean
    },
    match: {
        params: any
    },
    setMutualFundJoinRequests:(joinRequests:any) => {}
}

enum MutualFundPageViews {
    History = "History",
    Portfolio = "Portfolio",
    Autotrader = "Autotrader",
    Shareholders = "Shareholders",
    Members = "Members",
    Bulletins = "Bulletins",
    Chat = "Chat",
    JoinRequests = "Join Requests"
}

class MutualFundPageState {
    activeView:MutualFundPageViews = MutualFundPageViews.History;
    targetQuantity:string = '';
    newFee:string = '';
    newShareholderDivRate:string = '';
    newceoDivRate:string = '';
    showUpdateInfo:boolean = false;
}

class MutualFundPageBind extends Component<IMutualFundPageProps> {
    state:MutualFundPageState;

    constructor(props:IMutualFundPageProps) {
        super(props);
        this.state = new MutualFundPageState();
    }

    componentDidMount() {
        this.setState({fundid: this.props.match.params.fundid});
        this.setInitialTargetAmount();
        this.setCeoControlInputs();
    }

    setCeoControlInputs() {
        const fund = this.props.mutualfunds.funds[this.getFundId()];
        if(fund === undefined) return;
        this.setState({
            newFee: fund.fee,
            newShareholderDivRate: fund.shareholderDivRate,
            newceoDivRate: fund.ceoDivRate
        })
    }

    handleControl(e:any) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    submitNewFee() {
        let fee = parseFloat(this.state.newFee);
        if(isNaN(fee)) {
            alert("Invalid fee. Must be between 0 and 20%");
            return;
        }
        if((fee < 0) || (fee > 20)) {
            alert("Invalid fee. Must be between 0 and 20%");
            return;
        }
        postRequest("/api/updateFundFee", {
            fund:this.getFundId(),
            fee
        }).then((data:any) => {
            if(!data.success) {
                console.log(data.message);
            }
        })
    }

    submitNewShareholderDivRate() {
        let rate = parseFloat(this.state.newShareholderDivRate);
        if(isNaN(rate)) {
            alert("Invalid rate. Must be between 90 and 100%");
            return;
        }
        if((rate < 90) || (rate > 100)) {
            alert("Invalid rate. Must be between 90 and 100%");
            return;
        }
        postRequest("/api/updateFundShareholderDivRate", {
            fund:this.getFundId(),
            rate
        }).then((data:any) => {
            if(!data.success) {
                console.log(data.message);
            }
        })
    }

    submitNewCEORate() {
        let rate = parseFloat(this.state.newceoDivRate);
        if(isNaN(rate)) {
            alert("Invalid rate. Must be between 0 and 50%");
            return;
        }
        if((rate < 0) || (rate > 50)) {
            alert("Invalid rate. Must be between 0 and 50%");
            return;
        }
        postRequest("/api/updateFundCeoDivRate", {
            fund:this.getFundId(),
            rate
        }).then((data:any) => {
            if(!data.success) {
                console.log(data.message);
            }
        })
    }

    componentDidUpdate(prevProps:IMutualFundPageProps) {
        if(prevProps.userinfo.mutualfundOrders.length !== this.props.userinfo.mutualfundOrders.length) {
            this.setInitialTargetAmount();
        }
        if(Object.keys(prevProps.userinfo.mutualfunds).length !== Object.keys(this.props.userinfo.mutualfunds).length) {
            this.setInitialTargetAmount();
        }
        if(Object.keys(prevProps.mutualfunds.funds).length !== Object.keys(this.props.mutualfunds.funds).length) {
            this.setCeoControlInputs();
        }
    }

    getFundId() {
        return this.props.match.params.fundid;
    }

    getColor() {
        let fund = this.props.mutualfunds.funds[this.getFundId()];
        return "#" + fund.color;
    }

    getForegroundColor() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getForegroundFromBackground(r, g, b);
    }

    getGradient() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getGradientString(r, g, b);
    }

    setActiveView(view:string) {
        this.setState({activeView:view});
    }

    getViewSelectionClass(view:string) {
        if(view === this.state.activeView) {
            return "page-select-item active";
        } else {
            return "page-select-item";
        }
    }

    getPayout() {
        if(this.props.mutualfunds.fundPayouts[this.getFundId()] === undefined) {
            return 0;
        } else {
            return this.props.mutualfunds.fundPayouts[this.getFundId()];
        }
    }

    getPayoutPercent() {
        if(this.props.mutualfunds.fundPayouts[this.getFundId()] === undefined) {
            return 0;
        } else {
            return Math.round((this.props.mutualfunds.fundPayouts[this.getFundId()] / this.props.mutualfunds.fundstats[this.getFundId()].price) * 10000) / 100;
        }
    }

    renderTag() {
        const fund = this.props.mutualfunds.funds[this.getFundId()];
        if(fund === undefined) return null;
        return (
            <div
                style={{
                    "background": this.getColor(),
                    "color": this.getForegroundColor()
                }}
                className="fund-tag">{fund.tag}</div>
        )
    }

    getCEOName() {
        const fund = this.props.mutualfunds.funds[this.getFundId()];
        const ceo = fund.ceo;
        let username = "";
        fund.members.forEach((member:IMutualFundMember) => {
            if(member.id === ceo) {
                username = member.username
            }
        });
        return username;
    }

    renderDissolving() {
        if(Object.keys(this.props.mutualfunds.fundsToDissolve).includes(this.getFundId())) {
            let timestamp = this.props.mutualfunds.fundsToDissolve[this.getFundId()];
            let days = Math.round((timestamp + (1000 * 60 * 60 * 24 * 7) - new Date().getTime()) / (1000 * 60 * 60 * 24));
            return(
                <div className="fund-is-dissolving">
                    Fund Will Be Dissolved in {days} day{days === 1 ? "" : "s"}
                </div>
            )
        } else {
            return null;
        }
    }

    isMemberOfFund() {
        if(!this.props.session.loggedin) return false;
        const members:Array<IMutualFundMember> = this.props.mutualfunds.funds[this.getFundId()].members;
        for(let i = 0; i < members.length; i++) {
            if(members[i].id === this.props.userinfo.id) {
                return true;
            }
        }
        return false;
    }

    isBoardMember() {
        if(!this.props.session.loggedin) return false;
        const members:Array<IMutualFundMember> = this.props.mutualfunds.funds[this.getFundId()].members;
        for(let i = 0; i < members.length; i++) {
            if(members[i].id === this.props.userinfo.id) {
                if(members[i].boardMember) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    renderActiveView() {
        const fund = this.getFundId();
        switch(this.state.activeView) {
            case MutualFundPageViews.History:
                return (
                    <MutualFundSection fund={fund}>
                        <div className="section-header">
                            History
                        </div>
                        <MutualFundHistoryGraph fund={fund}/>
                    </MutualFundSection>
                );
            case MutualFundPageViews.Portfolio:
                return (
                    <MutualFundPortfolio fund={fund}/>
                );
            case MutualFundPageViews.Bulletins:
                return (
                    <MutualFundBulletins fund={fund}/>
                );
            case MutualFundPageViews.Autotrader:
                return (
                    <FundAutoTraderEditor fund={fund}/>
                );
            case MutualFundPageViews.Shareholders:
                return (
                    <MutualFundShareholders fund={fund}/>
                );
            case MutualFundPageViews.Chat:
                return (
                    <MutualFundChat fund={fund}/>
                );
            case MutualFundPageViews.Members:
                return (
                    <MutualFundMembers fund={fund}/>
                );
            case MutualFundPageViews.JoinRequests:
                return (
                    <MutualFundJoinRequests fund={fund}/>
                );
        }
    }

    sendJoinRequest() {

        let items = this.props.userinfo.items;
        let hasLicense = false;
        if(items['license'] !== undefined) {
            for(let i = 0; i < items['license'].length; i++) {
                if(items['license'][i].itemType === "PortfolioManagerLicense") {
                    hasLicense = true;
                }
            }
        }

        if(!hasLicense) {
            alert("You must own a portfolio manager license in order to join a mutual fund.");
            return;
        }

        fetch('/api/mutualFundRequestJoin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fund:this.getFundId()
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                let userRequests = [...this.props.userinfo.mutualfundJoinRequests];
                userRequests.push(this.getFundId());
                this.props.setMutualFundJoinRequests(userRequests);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    cancelJoinRequest() {
        fetch('/api/mutualFundCancelJoinRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fund:this.getFundId()
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                let userRequests = [...this.props.userinfo.mutualfundJoinRequests];
                let i = userRequests.indexOf(this.getFundId());
                userRequests.splice(i, 1);
                this.props.setMutualFundJoinRequests(userRequests);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    isCEO() {
        if(!this.props.session.loggedin) return false;
        return(this.props.mutualfunds.funds[this.getFundId()].ceo === this.props.userinfo.id)
    }

    leaveFund() {
        if(window.confirm("Are you sure you want to leave?")) {
            fetch('/api/mutualFundLeaveFund', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fund:this.getFundId()
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    renderRequestButton() {
        if(!this.props.session.loggedin) {
            return null;
        }
        if(this.isMemberOfFund()) {
            if(this.isCEO()) {
                return null;
            } else {
                return(
                    <div
                        onClick={() => this.leaveFund()}
                        className="leave-fund">Leave Fund</div>
                )
            }
        }
        if(this.props.userinfo.mutualfundJoinRequests.includes(this.getFundId())) {
            return (
                <div
                    onClick={() => this.cancelJoinRequest()}
                    className="cancel-join-request">
                    Cancel Join Request
                </div>
            );
        } else {
            return (
                <div
                    onClick={() => this.sendJoinRequest()}
                    className="send-join-request-button">
                    Send Join Request
                </div>
            );
        }
    }

    makePublic() {
        if(window.confirm("Are you sure you want to go public now?")) {
            fetch('/api/mutualFundMakePublic', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fund:this.getFundId()
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    renderMakePublicButton() {
        if(!this.isCEO()) return null;
        if(this.props.mutualfunds.funds[this.getFundId()].released) return null;
        return (
            <div
                style={{
                    "background":this.getForegroundColor(),
                    "borderColor":this.getColor(),
                    "color":this.getColor()
                }}
                onClick={() => this.makePublic()}
                className="make-public-button">
                GO PUBLIC
            </div>
        )
    }

    renderDissolveButton() {
        if(!this.isCEO()) return null;
        if(!this.props.mutualfunds.funds[this.getFundId()].released) return null;
        if(this.props.mutualfunds.fundstats[this.getFundId()].networth <= 0) return null;
        if(Object.keys(this.props.mutualfunds.fundsToDissolve).includes(this.getFundId())) return null;
        let ownsCoins = false;
        Object.keys(this.props.mutualfunds.funds[this.getFundId()].portfolio).forEach((coin:string) => {
            if(this.props.mutualfunds.funds[this.getFundId()].portfolio[coin].amount > 0) {
                ownsCoins = true;
            }
        })
        if(ownsCoins) return null;
        return(
            <div
                onClick={() => this.dissolveFund()}
                className="dissolve-fund-btn">
                DISSOLVE
            </div>
        )
    }

    dissolveFund() {
        if(window.confirm("Are you absolutely sure you want to dissolve the fund? You cannot undo this. The fund will dissolve in 7 days. It must be in good standing and own no coins at the time. The process will be delayed until the next day if these conditions are not met.")) {
            if(window.confirm("Are you really sure?")) {
                fetch('/api/addFundToDissolve', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        fund:this.getFundId()
                    })
                })
                .then(response => response.json())
                .then(data => {
                    if(!data.success) {
                        console.log(data.message);
                    }
                })
                .catch(error => {
                    console.error('Error: ' +  error);
                })
            }
        }
    }

    getBuys() {
        if(this.props.mutualfunds.orders[this.getFundId()] === undefined) return 0;
        return this.props.mutualfunds.orders[this.getFundId()].buys;
    }

    getSells() {
        if(this.props.mutualfunds.orders[this.getFundId()] === undefined) return 0;
        return this.props.mutualfunds.orders[this.getFundId()].sells;
    }

    getBuySellStyle(n:number) {
        if(n === 0) {
            return "";
        } else if(n > 0) {
            return "increase";
        } else {
            return "decrease";
        }
    }

    getMyOrders() {
        let orders = 0;
        this.props.userinfo.mutualfundOrders.forEach((order:IMutualFundOrder) => {
            if(order.fundid === this.getFundId()) {
                orders = order.quantity;
            }
        });
        return orders;
    }

    getMyShares() {
        let funds = {...this.props.userinfo.mutualfunds};
        if(funds[this.getFundId()] === undefined) {
            return 0;
        } else {
            return funds[this.getFundId()].amt;
        }
    }

    setInitialTargetAmount() {
        let shares = this.getMyShares()
        this.setState({targetQuantity:shares.toString()})
    }

    setTargetQuantity(e:any) {
        let text = e.target.value;
        text = text.replace(/,/g, '');
        let amount = parseInt(text);
        if(isNaN(amount)) {
            this.setState({targetQuantity:''})
        } else {
            let myShares = this.getMyShares();
            let upperLimit = myShares + 1000;
            let lowerLimit = Math.max(myShares - 1000, 0);
            if(amount < lowerLimit) amount = lowerLimit;
            if(amount > upperLimit) amount = upperLimit;
            let s = numberWithCommas(amount);
            this.setState({targetQuantity:s});
        }
    }

    incrementTargetQuantity(n:number) {
        let text = this.state.targetQuantity;
        let amount;
        if(text === '') {
            amount = 0;
        } else {
            text = text.replace(/,/g, '');
            amount = parseInt(text);
        }
        amount += n;
        let myShares = this.getMyShares();
        let upperLimit = myShares + 1000;
        let lowerLimit = Math.max(myShares - 1000, 0);
        if(amount < lowerLimit) amount = lowerLimit;
        if(amount > upperLimit) amount = upperLimit;
        this.setState({targetQuantity:numberWithCommas(amount)});
    }

    submitOrder() {
        let text = this.state.targetQuantity;
        text = text.replace(/,/g, '');
        let amt = parseInt(text);
        if(isNaN(amt)) {
            return;
        } else {
            let myShares = this.getMyShares();
            let quantity = amt - myShares;
            fetch('/api/mutualFundAddOrder', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fund:this.getFundId(),
                    quantity
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    priceDirectionIcon(delta:number) {
        if(delta === 0) {
            return (
                <GoDash style={{verticalAlign: 'middle'}}/>
            )
        }
        if(delta < 0) {
            return(
                <BiDownArrow style={{verticalAlign: 'middle'}}/>
            )
        } else {
            return(
                <BiUpArrow style={{verticalAlign: 'middle'}}/>
            )
        }
    }

    priceDirectionClass(delta:number) {
        if(delta === 0) {
            return "";
        } else if(delta > 0) {
            return "increase";
        } else {
            return "decrease";
        }
    }

    render() {
        const fund = this.props.mutualfunds.funds[this.getFundId()];
        const fundstats = this.props.mutualfunds.fundstats[this.getFundId()];
        if(fund === undefined || fundstats === undefined) {
            if(!this.props.mutualfunds.loaded) {
                return(
                    <div className="mutual-fund-page-container">
                        <Loading />
                    </div>
                );
            } else {
                return <Redirect to="/mutualfunds" />
            }
        }

        let fundStats = this.props.mutualfunds.fundstats[this.getFundId()];
        let fundHistory = this.props.mutualfunds.fundhistory[this.getFundId()];

        let historyItem;
        if(fundHistory.length < 2) {
            historyItem = fundHistory[fundHistory.length - 1];
        } else {
            historyItem = fundHistory[fundHistory.length - 2];
        }

        let prevNetworth = historyItem.networth;
        let netWorthDelta = Math.round((fundStats.networth - prevNetworth) * 100) / 100;
        let prevPrice = historyItem.price;
        let priceDelta = Math.round((fundStats.price - prevPrice) * 100) / 100;
        let prevVolume = historyItem.volume;
        let volumeDelta = Math.round((fundStats.volume - prevVolume) * 100) / 100;

        let netWorthDeltaPercent = Math.round((netWorthDelta / prevNetworth) * 100);
        let priceDeltaPercent = Math.round((priceDelta / prevPrice) * 100);
        let volumeDeltaPercent = Math.round((volumeDelta / prevVolume) * 100);

        return(
            <div className="container  scroll-space mutual-fund-page-container">

                <div className="container-content">

                    { this.state.showUpdateInfo ?    
                        <MutualFundUpdateInfo 
                            toggleVisible={() => {this.setState({showUpdateInfo:false})}}
                            fundid={this.getFundId()}/>
                        : null 
                    }

                    <Link to="/mutualfunds">
                        <div className="back-btn">
                            <BsBackspaceFill style={{"verticalAlign":"middle"}}/>
                            Back
                        </div>
                    </Link>

                    <MutualFundSection fund={this.getFundId()}>
                        <>
                            <div className="title-bar flex flex-row">
                                {this.renderTag()}
                                {this.renderTag()}
                                {this.renderTag()}
                            </div>

                            <div className="mutual-fund-info-section flex flex-row">

                                {this.renderRequestButton()}
                                {this.renderDissolveButton()}

                                <div className="info-sidebar flex flex-col">
                                    <div className="icon-container flex center-child">
                                        <Coin name={fund.icon} />
                                    </div>

                                    {this.renderMakePublicButton()}

                                    <div className="creation-date">
                                        Founded:<br/>
                                        {new Date(fund.dateCreated).toLocaleDateString()}
                                    </div>
                                </div>
                                <div className="info-main-section flex flex-col">

                                    {
                                        this.isCEO() ?
                                        <div
                                            style={{
                                                "background":this.getColor(),
                                                "color":this.getForegroundColor()
                                            }} 
                                            onClick={() => this.setState({showUpdateInfo:true})}
                                            className="show-update-info flex flex-row">
                                            <>Edit Info</>
                                            <FaPenAlt /> 
                                        </div>
                                        : null
                                    }

                                    <div className="mutual-fund-name">
                                        {fund.name}
                                    </div>
                                    {this.renderDissolving()}
                                    <div
                                        title="CEO"
                                        className="mutual-fund-ceo flex flex-row">
                                        <Coin name={"blank"}/>
                                        {this.getCEOName()}
                                    </div>
                                    <div className="mutual-fund-mission-statement">
                                        {fund.missionStatement}
                                    </div>
                                    <div className="mutual-fund-stats-container flex flex-row">
                                        <div className="mutual-fund-stat-item stat-item-networth">
                                            <div className="stat-item-label">Net Worth</div>
                                            <div className={"stat-item-number price " + this.priceDirectionClass(netWorthDelta)}>
                                                ${numberWithCommas(fundstats.networth)}

                                                <div className={"stat-item-delta " + this.priceDirectionClass(netWorthDelta)}>
                                                    <div>
                                                    {this.priceDirectionIcon(netWorthDelta)}
                                                    </div>
                                                    <div>
                                                    ${numberWithCommas(Math.abs(netWorthDelta))}
                                                    </div>
                                                    <div>
                                                    ({numberWithCommas(Math.abs(netWorthDeltaPercent))}%)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mutual-fund-stat-item stat-item-balance">
                                            <div className="stat-item-label">Balance</div>
                                            <div className="stat-item-number price">
                                                ${numberWithCommas(fund.balance)}
                                            </div>
                                        </div>
                                        <div className="mutual-fund-stat-item stat-item-members">
                                            <div className="stat-item-label">Members</div>
                                            <div className="stat-item-number">{fund.members.length}</div>
                                        </div>
                                        <div className="mutual-fund-stat-item stat-item-public">
                                            <div className="stat-item-label">Public</div>
                                            <div className="stat-item-number">{fund.released ? "Yes" : "No"}</div>
                                        </div>
                                        <div className="mutual-fund-stat-item">
                                            <div className="stat-item-label">Volume</div>
                                            <div className="stat-item-number">
                                                {numberWithCommas(fundstats.volume)}

                                                <div className={"stat-item-delta " + this.priceDirectionClass(volumeDelta)}>
                                                    <div>
                                                    {this.priceDirectionIcon(volumeDelta)}
                                                    </div>
                                                    <div>
                                                    {numberWithCommas(Math.abs(volumeDelta))}
                                                    </div>
                                                    <div>
                                                    ({numberWithCommas(Math.abs(volumeDeltaPercent))}%)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mutual-fund-stat-item">
                                            <div className="stat-item-label">Last Payout/Share</div>
                                            <div className="stat-item-number price">
                                                ${numberWithCommas(this.getPayout())}
                                            </div>
                                        </div>
                                        <div className="mutual-fund-stat-item">
                                            <div className="stat-item-label">Fee</div>
                                            <div className="stat-item-number">
                                                {numberWithCommas(fund.fee)}%
                                            </div>
                                        </div>
                                        <div className="mutual-fund-stat-item">
                                            <div className="stat-item-label">Shareholder Div %</div>
                                            <div className="stat-item-number">
                                                {numberWithCommas(fund.shareholderDivRate)}%
                                            </div>
                                        </div>
                                        <div className="mutual-fund-stat-item">
                                            <div className="stat-item-label">CEO Div %</div>
                                            <div className="stat-item-number">
                                                {numberWithCommas(fund.ceoDivRate)}%
                                            </div>
                                        </div>
                                        <div className="mutual-fund-stat-item">
                                            <div className="stat-item-label">Payout %</div>
                                            <div className="stat-item-number">
                                                {numberWithCommas(this.getPayoutPercent())}%
                                            </div>
                                        </div>
                                        <div className="mutual-fund-stat-item">
                                            <div className="stat-item-label">Price</div>
                                            <div className={"stat-item-number price " + this.priceDirectionClass(priceDelta)}>
                                                ${numberWithCommas(fundstats.price)}

                                                <div className={"stat-item-delta " + this.priceDirectionClass(priceDelta)}>
                                                    <div>
                                                    {this.priceDirectionIcon(priceDelta)}
                                                    </div>
                                                    <div>
                                                    ${numberWithCommas(Math.abs(priceDelta))}
                                                    </div>
                                                    <div>
                                                    ({numberWithCommas(Math.abs(priceDeltaPercent))}%)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.isCEO() ?
                                        <div className="ceo-rate-controls-outer flex flex-row">
                                            <div className="control-container flex flex-col">
                                                <div className="control-label">
                                                    Set Fee: (0-20%)
                                                </div>
                                                <div className="control-input flex flex-row">
                                                    <input
                                                        value={this.state.newFee}
                                                        name="newFee"
                                                        onChange={(e) => this.handleControl(e)}
                                                        type="text" />
                                                    <div
                                                        onClick={() => this.submitNewFee()}
                                                        className="control-update-btn">
                                                        SAVE
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="control-container flex flex-col">
                                                <div className="control-label">
                                                    Set Shareholder Div Rate: (90-100%)
                                                </div>
                                                <div className="control-input flex flex-row">
                                                    <input
                                                        value={this.state.newShareholderDivRate}
                                                        name="newShareholderDivRate"
                                                        onChange={(e) => this.handleControl(e)}
                                                        type="text" />
                                                    <div
                                                        onClick={() => this.submitNewShareholderDivRate()}
                                                        className="control-update-btn">
                                                        SAVE
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="control-container flex flex-col">
                                                <div className="control-label">
                                                    Set CEO Div Rate: (0-50%)
                                                </div>
                                                <div className="control-input flex flex-row">
                                                    <input
                                                        value={this.state.newceoDivRate}
                                                        name="newceoDivRate"
                                                        onChange={(e) => this.handleControl(e)}
                                                        type="text" />
                                                    <div
                                                        onClick={() => this.submitNewCEORate()}
                                                        className="control-update-btn">
                                                        SAVE
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </>
                    </MutualFundSection>
                    {
                    fund.released && this.props.session.loggedin ?
                    <MutualFundSection
                        className={"orders-container"}
                        fund={this.getFundId()}>
                        <>
                        <div className="section-header">
                            Make an Order
                        </div>
                        <div className="orders-description">
                            Mutual fund orders are processed at 4:00pm ET each day. At this time, the new net asset value is also calculated based on total worth of the fund divided by total outstanding shares. Place a max of 1,000 orders to be transferred at the next market close.
                        </div>

                        <div className="order-information-container flex flex-row">
                            <div className="order-summary flex flex-col">
                                <div className="order-summary-header">
                                    Total Pending Orders
                                </div>
                                <div className="order-summary-items flex flex-row">
                                    <div className="order-summary-item">
                                        <div className="order-summary-label">Purchases</div>
                                        <div className="order-summary-amount increase">{numberWithCommas(this.getBuys())}</div>
                                    </div>
                                    <div className="order-summary-item">
                                        <div className="order-summary-label">Sales</div>
                                        <div className="order-summary-amount decrease">{numberWithCommas(this.getSells())}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-orders-container flex flex-row">
                                <div className="my-orders-stat flex flex-col">
                                    <div className="my-orders-label">
                                        Owned Shares
                                    </div>
                                    <div className="my-orders-amount">
                                        {this.getMyShares()}
                                    </div>
                                </div>
                                <div className="my-orders-stat flex flex-col">
                                    <div className="my-orders-label">
                                        Pending Orders
                                    </div>
                                    <div className={"my-orders-amount " + this.getBuySellStyle(this.getMyOrders())}>
                                        {this.getMyOrders()}
                                    </div>
                                </div>
                                <div className="quantity-input-container-outer flex flex-col">
                                    <div className="quantity-input-header">
                                        Target Quantity
                                    </div>
                                    <div className="quantity-input-container flex flex-row">
                                        <div onClick={() => this.incrementTargetQuantity(-1000)} className="decrement-button"><AiOutlineMinus/></div>
                                        <div onClick={() => this.incrementTargetQuantity(-100)} className="decrement-button"><AiOutlineMinus/></div>
                                        <div onClick={() => this.incrementTargetQuantity(-10)} className="decrement-button"><AiOutlineMinus/></div>
                                        <div onClick={() => this.incrementTargetQuantity(-1)} className="decrement-button"><AiOutlineMinus/></div>
                                        <input
                                            type="text"
                                            className="quantity-input"
                                            value={this.state.targetQuantity}
                                            onChange={(e) => this.setTargetQuantity(e)}/>
                                        <div onClick={() => this.incrementTargetQuantity(1)} className="increment-button"><BiPlus /></div>
                                        <div onClick={() => this.incrementTargetQuantity(10)} className="increment-button"><BiPlus /></div>
                                        <div onClick={() => this.incrementTargetQuantity(100)} className="increment-button"><BiPlus /></div>
                                        <div onClick={() => this.incrementTargetQuantity(1000)} className="increment-button"><BiPlus /></div>
                                    </div>
                                </div>
                                <Button className="green inverse" onClick={() => this.submitOrder()}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                        </>
                    </MutualFundSection> : null
                    }
                    <div className="mutual-fund-page-section view-select-outer flex flex-row">
                        {
                            Object.values(MutualFundPageViews).map((view:string) =>
                                {
                                    if(
                                        (view === MutualFundPageViews.Chat && !this.isMemberOfFund()) ||
                                        (view === MutualFundPageViews.Autotrader && !this.isMemberOfFund()) ||
                                        (view === MutualFundPageViews.JoinRequests && !this.isBoardMember())) {
                                        return null;
                                    } else {
                                        return(
                                        <div
                                            key={view}
                                            onClick={() => this.setActiveView(view)}
                                            className={this.getViewSelectionClass(view)}>
                                            {view}
                                        </div>
                                        )
                                    }
                                }
                            )
                        }
                    </div>
                    { this.renderActiveView() }
                </div>
            </div>
        )
    }
}

const MutualFundPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundPageBind);

export default MutualFundPage;
