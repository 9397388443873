import KiryuKaiBadge from '../../images/items/KiryuKaiBadge.png';
import Rope from '../../images/items/Rope.png';
import YellowCard from '../../images/items/YellowCard.png';
import AquaEnergyDrink from '../../images/items/AquaEnergyDrink.png';
import HaachamaTarantula from '../../images/items/HaachamaTarantula.png';
import LamyBooze from '../../images/items/LamyBooze.png';
import MioOmamori from '../../images/items/MioOmamori.png';
import RisuNuts from '../../images/items/RisuNuts.png';
import WatameChips from '../../images/items/WatameChips.png';
import WatsonConcoction from '../../images/items/WatsonConcoction.png';
import ZenlossMiko from '../../images/items/ZenlossMiko.png';
import Jeb from '../../images/items/Jeb.png';
import GuraLostLoaf from '../../images/items/GuraLostLoaf.png';
import SubaruWhistle from '../../images/items/SubaruWhistle.png';
import PPTShirt from '../../images/items/PPTShirt.png';
import SuiseiRingoJuice from '../../images/items/SuiseiRingoJuice.png';
import OllieBuns from '../../images/items/OllieBuns.png';
import TowaWebcam from '../../images/items/TowaWebcam.png';
import ReineAirFryer from '../../images/items/ReineAirFryer.png';
import MatsuriBandaids from '../../images/items/MatsuriBandaids.png';
import IRySoda from '../../images/items/IRySoda.png';
import FubukiBurger from '../../images/items/FubukiBurger.png';
import SoraMicrophone from '../../images/items/SoraMicrophone.png';
import NabiPen from '../../images/items/NabiPen.png';
import NoelASMRMic from '../../images/items/NoelASMRMic.png';
import OkayuOnigiri from '../../images/items/OkayuOnigiri.png';
import BotanBomb from '../../images/items/BotanBomb.png';
import MarineHat from '../../images/items/MarineHat.png';
import RushiaBun from '../../images/items/RushiaBun.png';
import FubukiHat from '../../images/items/FubukiHat.png';
import HaatoHat from '../../images/items/HaatoHat.png';
import AmeHat from '../../images/items/AmeHat.png';
import SuiseiHat from '../../images/items/SuiseiHat.png';
import SoraHat from '../../images/items/SoraHat.png';
import ShionHat from '../../images/items/ShionHat.png';
import KanataHat from '../../images/items/KanataHat.png';
import PolkaHat from '../../images/items/PolkaHat.png';
import OkayuHat from '../../images/items/OkayuHat.png';
import NoelHat from '../../images/items/NoelHat.png';
import MikoHat from '../../images/items/MikoHat.png';
import MatsuriHat from '../../images/items/MatsuriHat.png';
import MarineGothHat from '../../images/items/MarineGothHat.png';
import GuraHat from '../../images/items/GuraHat.png';
import WatameHat from '../../images/items/WatameHat.png';
import TowaHat from '../../images/items/TowaHat.png';
import InaHat from '../../images/items/InaHat.png';
import KiaraHat from '../../images/items/KiaraHat.png';
import MarineGyaruHat from '../../images/items/MarineGyaruHat.png';
import MumeiHat from '../../images/items/MumeiHat.png';
import NeneHat from '../../images/items/NeneHat.png';
import PekoraHat from '../../images/items/PekoraHat.png';
import AquaHat from '../../images/items/AquaHat.png';
import BotanHat from '../../images/items/BotanHat.png';
import CalliopeHat from '../../images/items/CalliopeHat.png';
import InaEarsHat from '../../images/items/InaEarsHat.png';
import Cash from '../../images/items/Cash.png';
import License from '../../images/items/License.png';
import AyameHat from '../../images/items/AyameHat.png';
import BaelzHat from '../../images/items/BaelzHat.png';
import ChocoHat from '../../images/items/ChocoHat.png';
import FaunaHat from '../../images/items/FaunaHat.png';
import KroniiHat from '../../images/items/KroniiHat.png';
import LunaHat from '../../images/items/LunaHat.png';
import MioHat from '../../images/items/MioHat.png';
import NabiHat from '../../images/items/NabiHat.png';
import ReineHat from '../../images/items/ReineHat.png';
import SanaHat from '../../images/items/SanaHat.png';
import AzkiHat from '../../images/items/AzkiHat.png';
import FlareHat from '../../images/items/FlareHat.png';
import KoroneHat from '../../images/items/KoroneHat.png';
import LamyHat from '../../images/items/LamyHat.png';
import MelHat from '../../images/items/MelHat.png';
import NekoHat from '../../images/items/NekoHat.png';
import OllieHat from '../../images/items/OllieHat.png';
import PochiHat from '../../images/items/PochiHat.png';
import SubaruHat from '../../images/items/SubaruHat.png';
import VoidGlasses from '../../images/items/VoidGlasses.png';
import ElfHat from '../../images/items/ElfHat.png';
import ReindeerHat from '../../images/items/ReindeerHat.png';
import SantaHat from '../../images/items/SantaHat.png';
import StockingHat from '../../images/items/StockingHat.png';
import XMasGiftHat from '../../images/items/XMasGiftHat.png';
import XMasLightsHat from '../../images/items/XMasLightsHat.png';
import WreathHat from '../../images/items/WreathHat.png';
import AkiHat from '../../images/items/AkiHat.png';
import AChanHat from '../../images/items/AChanHat.png';
import RisuHat from '../../images/items/RisuHat.png';
import MioEarsHat from '../../images/items/MioEarsHat.png';
import RobocoHat from '../../images/items/RobocoHat.png';
import IRySHat from '../../images/items/IRySHat.png';
import ChloeHat from '../../images/items/ChloeHat.png';
import UiHat from '../../images/items/UiHat.png';
import KadomatsuHat from '../../images/items/KadomatsuHat.png';
import ValentinesChocolates from '../../images/items/ValentinesChocolates.png';
import ValentinesCouplesChocolates from '../../images/items/ValentinesCouplesChocolates.png';
import CocoBill from '../../images/items/CocoBill.jpg';
import PekoraBill from '../../images/items/PekoraBill.jpg';
import RisuBill from '../../images/items/RisuBill.jpg';
import FubukiBill from '../../images/items/FubukiBill.jpg';
import CEOofMoney from '../../images/items/CEOofMoney.jpg';
import NeneCoin from '../../images/items/NeneCoin.png';
import EasterBasket from '../../images/items/EasterBasket.png';
import AyamyHat from '../../images/items/AyamyHat.png';
import CocoHat from '../../images/items/CocoHat.png';
import HaachamaHat from '../../images/items/HaachamaHat.png';
import KroniiFlowerHat from '../../images/items/KroniiFlowerHat.png';
import LaplusHat from '../../images/items/LaplusHat.png';
import MoriGamerHat from '../../images/items/MoriGamerHat.png';
import MumeiBeretHat from '../../images/items/MumeiBeretHat.png';
import MumeiFeatherHat from '../../images/items/MumeiFeatherHat.png';
import Aki2ndHat from '../../images/items/Aki2ndHat.png';
import AnyaGlasses from '../../images/items/AnyaGlasses.png';
import AstelHat from '../../images/items/AstelHat.png';
import IofiHat from '../../images/items/IofiHat.png';
import IrohaHat from '../../images/items/IrohaHat.png';
import KaelaHat from '../../images/items/KaelaHat.png';
import Kanata2ndHat from '../../images/items/Kanata2ndHat.png';
import KoboHat from '../../images/items/KoboHat.png';
import KoyoriHat from '../../images/items/KoyoriHat.png';
import LuiHat from '../../images/items/LuiHat.png';
import MatsuriNewYearRibbon from '../../images/items/MatsuriNewYearRibbon.png';
import Mel2ndHat from '../../images/items/Mel2ndHat.png';
import MoonaHat from '../../images/items/MoonaHat.png';
import Risu2ndHat from '../../images/items/Risu2ndHat.png';
import Risu3rdHat from '../../images/items/Risu3rdHat.png';
import Roboco2ndHat from '../../images/items/Roboco2ndHat.png';
import RushiaButterfly from '../../images/items/RushiaButterfly.png';
import SanaBreadDog from '../../images/items/SanaBreadDog.png';
import SuiseiOriginalHat from '../../images/items/SuiseiOriginalHat.png';
import ZetaHat from '../../images/items/ZetaHat.png';
import BloopPumpkin from '../../images/items/BloopPumpkin.png';
import Candy from '../../images/items/Candy.png';
import CandyApple from '../../images/items/CandyApple.png';
import ChocolateEyeball from '../../images/items/ChocolateEyeball.png';
import Chocolate from '../../images/items/Chocolate.png';
import Cookie from '../../images/items/Cookie.png';
import GhostHaaton from '../../images/items/GhostHaaton.png';
import HaatonPumpkin from '../../images/items/HaatonPumpkin.png';
import Lollipop from '../../images/items/Lollipop.png';
import PumpkinBasket from '../../images/items/PumpkinBasket.png';
import ShionPumpkinHat from '../../images/items/ShionPumpkinHat.png';
import TakodachiPumpkin from '../../images/items/TakodachiPumpkin.png';
import ChocolateKanataCupcake from '../../images/items/ChocolateKanataCupcake.png';
import HonkCard from '../../images/items/HonkCard.png';
import HonkPantsu from '../../images/items/HonkPantsu.png';
import TakoIceCream from '../../images/items/TakoIceCream.png';
import Ghost35P from '../../images/items/Ghost35P.png';
import DevilRosetai from '../../images/items/DevilRosetai.png';
import ZombieMatsurisu from '../../images/items/ZombieMatsurisu.png';
import PumpkinNekko from '../../images/items/PumpkinNekko.png';
import PekoraHalloweenHat from '../../images/items/PekoraHalloweenHat.png';
import DevilSSRB from '../../images/items/DevilSSRB.png';
import GhostTako from '../../images/items/GhostTako.png';
import VampireYukimin from '../../images/items/VampireYukimin.png';
import GhostZain from '../../images/items/GhostZain.png';
import GhostJoshu from '../../images/items/GhostJoshu.png';
import AkiKimonoCook from '../../images/items/AkiKimonoCook.png';
import AquaRibbon from '../../images/items/AquaRibbon.png';
import AmeFish from '../../images/items/AmeFish.png';
import BotanDahlia from '../../images/items/BotanDahlia.png';
import ChloeKimonoCharm from '../../images/items/ChloeKimonoCharm.png';
import FubukiDaisy from '../../images/items/FubukiDaisy.png';
import GuraRibbon from '../../images/items/GuraRibbon.png';
import HaachamaRose from '../../images/items/HaachamaRose.png';
import InaBuns from '../../images/items/InaBuns.png';
import IRySOrchid from '../../images/items/IRySOrchid.png';
import KanataSash from '../../images/items/KanataSash.png';
import KiaraCarnation from '../../images/items/KiaraCarnation.png';
import LamyCatEars from '../../images/items/LamyCatEars.png';
import LunaBeret from '../../images/items/LunaBeret.png';
import MarineChineseAster from '../../images/items/MarineChineseAster.png';
import MoriKitsuneMask from '../../images/items/MoriKitsuneMask.png';
import NeneCarnation from '../../images/items/NeneCarnation.png';
import PekoraCarrotCharm from '../../images/items/PekoraCarrotCharm.png';
import PolkaKimonoRibbons from '../../images/items/PolkaKimonoRibbons.png';
import SanaUnicornHat from '../../images/items/SanaUnicornHat.png';
import SoraDahlia from '../../images/items/SoraDahlia.png';
import TowaRibbon from '../../images/items/TowaRibbon.png';
import WatameSash from '../../images/items/WatameSash.png';
import OllieNarutoHeadband from '../../images/items/OllieNarutoHeadband.png';
import AZKiBarette from '../../images/items/AZKiBarette.png';
import ZetaMask from '../../images/items/ZetaMask.png';
import HajimePigTail from '../../images/items/HajimePigTail.png';
import KanadeRibbon from '../../images/items/KanadeRibbon.png';
import RadenRibbon from '../../images/items/RadenRibbon.png';
import AoNecklace from '../../images/items/AoNecklace.png';
import RirikaGlasses from '../../images/items/RirikaGlasses.png';
import NerissaUmbrella from '../../images/items/NerissaUmbrella.png';
import BibooMoai from '../../images/items/BibooMoai.png';
import KoyoriIdolHat from '../../images/items/KoyoriIdolHat.png';
import RobocoRibbon from '../../images/items/RobocoRibbon.png';
import ZetaHood from '../../images/items/ZetaHood.png';
import KoroneBoneHat from '../../images/items/KoroneBoneHat.png';
import MococoKimonoFlower from '../../images/items/MococoKimonoFlower.png';
import FuwawaKimonoFlower from '../../images/items/FuwawaKimonoFlower.png';
import BibooCrown from '../../images/items/BibooCrown.png';
import ShioriShurikenHairpin from '../../images/items/ShioriShurikenHairpin.png';
import NerissaHorn from '../../images/items/NerissaHorn.png';


export const ItemList = [
    "KiryuKaiBadge",
    "Rope",
    "YellowCard",
    "AquaEnergyDrink",
    "HaachamaTarantula",
    "LamyBooze",
    "MioOmamori",
    "RisuNuts",
    "WatameChips",
    "WatsonConcoction",
    "ZenlossMiko",
    "Jeb",
    "PPTShirt",
    "SuiseiRingoJuice",
    "SubaruWhistle",
    "GuraLostLoaf",
    "OllieBuns",
    "AuctionLicense",
    "PortfolioManagerLicense",
    "TowaWebcam",
    "ReineAirFryer",
    "MatsuriBandaids",
    "IRySoda",
    "FubukiBurger",
    "SoraMicrophone",
    "NabiPen",
    "NoelASMRMic",
    "OkayuOnigiri",
    "BotanBomb",
    "MarineHat",
    "RushiaBun",
    "FubukiHat",
    "HaatoHat",
    "AmeHat",
    "SuiseiHat",
    "SoraHat",
    "ShionHat",
    "KanataHat",
    "PolkaHat",
    "OkayuHat",
    "NoelHat",
    "MikoHat",
    "MatsuriHat",
    "MarineGothHat",
    "GuraHat",
    "WatameHat",
    "TowaHat",
    "InaHat",
    "KiaraHat",
    "MarineGyaruHat",
    "MumeiHat",
    "NeneHat",
    "PekoraHat",
    "AquaHat",
    "BotanHat",
    "CalliopeHat",
    "InaEarsHat",
    "AyameHat",
    "BaelzHat",
    "ChocoHat",
    "FaunaHat",
    "KroniiHat",
    "LunaHat",
    "MioHat",
    "NabiHat",
    "ReineHat",
    "SanaHat",
    "AzkiHat",
    "FlareHat",
    "KoroneHat",
    "LamyHat",
    "MelHat",
    "NekoHat",
    "OllieHat",
    "PochiHat",
    "SubaruHat",
    "VoidGlasses",
    "ElfHat",
    "SantaHat",
    "ReindeerHat",
    "XMasGiftHat",
    "XMasLightsHat",
    "StockingHat",
    "WreathHat",
    "AChanHat",
    "UiHat",
    "RobocoHat",
    "AkiHat",
    "IRySHat",
    "ChloeHat",
    "RisuHat",
    "MioEarsHat",
    "KadomatsuHat",
    "ValentinesChocolates",
    "ValentinesCouplesChocolates",
    "CocoBill",
    "FubukiBill",
    "RisuBill",
    "PekoraBill",
    "NeneCoin",
    "CEOofMoney",
    "EasterBasket",
    "AyamyHat",
    "CocoHat",
    "HaachamaHat",
    "KroniiFlowerHat",
    "LaplusHat",
    "MoriGamerHat",
    "MumeiBeretHat",
    "MumeiFeatherHat",
    "Aki2ndHat",
    "AnyaGlasses",
    "AstelHat",
    "IofiHat",
    "IrohaHat",
    "KaelaHat",
    "Kanata2ndHat",
    "KoboHat",
    "KoyoriHat",
    "LuiHat",
    "MatsuriNewYearRibbon",
    "Mel2ndHat",
    "MoonaHat",
    "Risu2ndHat",
    "Risu3rdHat",
    "Roboco2ndHat",
    "RushiaButterfly",
    "SanaBreadDog",
    "SuiseiOriginalHat",
    "ZetaHat",
    "BloopPumpkin",
    "Candy",
    "CandyApple",
    "Chocolate",
    "ChocolateEyeball",
    "Cookie",
    "GhostHaaton",
    "HaatonPumpkin",
    "Lollipop",
    "PumpkinBasket",
    "ShionPumpkinHat",
    "TakodachiPumpkin",
    "HonkCard",
    "TakoIceCream",
    "ChocolateKanataCupcake",
    "HonkPantsu",
    "Ghost35P",
    "DevilRosetai",
    "ZombieMatsurisu",
    "PumpkinNekko",
    "PekoraHalloweenHat",
    "DevilSSRB",
    "GhostTako",
    "VampireYukimin",
    "GhostZain",
    "GhostJoshu",
    "AkiKimonoCook",
    "AmeFish",
    "AquaRibbon",
    "BotanDahlia",
    "ChloeKimonoCharm",
    "FubukiDaisy",
    "GuraRibbon",
    "HaachamaRose",
    "InaBuns",
    "IRySOrchid",
    "KanataSash",
    "KiaraCarnation",
    "LamyCatEars",
    "LunaBeret",
    "MarineChineseAster",
    "MoriKitsuneMask",
    "NeneCarnation",
    "PekoraCarrotCharm",
    "PolkaKimonoRibbons",
    "SanaUnicornHat",
    "SoraDahlia",
    "TowaRibbon",
    "WatameSash",
    "OllieNarutoHeadband",
    "AZKiBarette",
    "ZetaMask",
    "HajimePigTail",
    "KanadeRibbon",
    "RadenRibbon",
    "AoNecklace",
    "RirikaGlasses",
    "KoyoriIdolHat",
    "RobocoRibbon",
    "ZetaHood",
    "KoroneBoneHat",
    "MococoKimonoFlower",
    "FuwawaKimonoFlower",
    "BibooCrown",
    "ShioriShurikenHairpin",
    "NerissaHorn",
    "BibooMoai",
    "NerissaUmbrella",
    "Cash",
]

export const ItemImages:{[type:string]:any} = {
    KiryuKaiBadge,
    Rope,
    YellowCard,
    AquaEnergyDrink,
    HaachamaTarantula,
    LamyBooze,
    MioOmamori,
    RisuNuts,
    WatameChips,
    WatsonConcoction,
    ZenlossMiko,
    Jeb,
    PPTShirt,
    SuiseiRingoJuice,
    SubaruWhistle,
    GuraLostLoaf,
    OllieBuns,
    TowaWebcam,
    ReineAirFryer,
    MatsuriBandaids,
    IRySoda,
    FubukiBurger,
    SoraMicrophone,
    NabiPen,
    NoelASMRMic,
    OkayuOnigiri,
    BotanBomb,
    MarineHat,
    RushiaBun,
    FubukiHat,
    AuctionLicense:License,
    PortfolioManagerLicense:License,
    HaatoHat,
    AmeHat,
    SuiseiHat,
    SoraHat,
    ShionHat,
    KanataHat,
    PolkaHat,
    OkayuHat,
    NoelHat,
    MikoHat,
    MatsuriHat,
    MarineGothHat,
    GuraHat,
    WatameHat,
    TowaHat,
    InaHat,
    KiaraHat,
    MarineGyaruHat,
    MumeiHat,
    NeneHat,
    PekoraHat,
    AquaHat,
    BotanHat,
    CalliopeHat,
    InaEarsHat,
    AyameHat,
    BaelzHat,
    ChocoHat,
    FaunaHat,
    KroniiHat,
    LunaHat ,
    MioHat,
    NabiHat,
    ReineHat,
    SanaHat,
    AzkiHat,
    FlareHat,
    KoroneHat,
    LamyHat,
    MelHat,
    NekoHat,
    OllieHat,
    PochiHat,
    SubaruHat,
    VoidGlasses,
    ElfHat,
    SantaHat,
    ReindeerHat,
    XMasGiftHat,
    XMasLightsHat,
    StockingHat,
    WreathHat,
    AChanHat,
    MioEarsHat,
    AkiHat,
    ChloeHat,
    RisuHat,
    RobocoHat,
    IRySHat,
    UiHat,
    KadomatsuHat,
    ValentinesChocolates,
    ValentinesCouplesChocolates,
    CocoBill,
    FubukiBill,
    RisuBill,
    PekoraBill,
    NeneCoin,
    CEOofMoney,
    EasterBasket,
    AyamyHat,
    CocoHat,
    HaachamaHat,
    KroniiFlowerHat,
    LaplusHat,
    MoriGamerHat,
    MumeiBeretHat,
    MumeiFeatherHat,
    Aki2ndHat,
    AnyaGlasses,
    AstelHat,
    IofiHat,
    IrohaHat,
    KaelaHat,
    Kanata2ndHat,
    KoboHat,
    KoyoriHat,
    LuiHat,
    MatsuriNewYearRibbon,
    Mel2ndHat,
    MoonaHat,
    Risu2ndHat,
    Risu3rdHat,
    Roboco2ndHat,
    RushiaButterfly,
    SanaBreadDog,
    SuiseiOriginalHat,
    ZetaHat,
    BloopPumpkin,
    Candy,
    CandyApple,
    Chocolate,
    ChocolateEyeball,
    Cookie,
    GhostHaaton,
    HaatonPumpkin,
    Lollipop,
    PumpkinBasket,
    ShionPumpkinHat,
    TakodachiPumpkin,
    ChocolateKanataCupcake,
    HonkCard,
    HonkPantsu,
    TakoIceCream,
    Ghost35P,
    DevilRosetai,
    ZombieMatsurisu,
    PumpkinNekko,
    PekoraHalloweenHat,
    DevilSSRB,
    GhostTako,
    VampireYukimin,
    GhostZain,
    GhostJoshu,
    AkiKimonoCook,
    AmeFish,
    AquaRibbon,
    BotanDahlia,
    ChloeKimonoCharm,
    FubukiDaisy,
    GuraRibbon,
    HaachamaRose,
    InaBuns,
    IRySOrchid,
    KanataSash,
    KiaraCarnation,
    LamyCatEars,
    LunaBeret,
    MarineChineseAster,
    MoriKitsuneMask,
    NeneCarnation,
    PekoraCarrotCharm,
    PolkaKimonoRibbons,
    SanaUnicornHat,
    SoraDahlia,
    TowaRibbon,
    WatameSash,
    OllieNarutoHeadband,
    AZKiBarette,
    ZetaMask,
    HajimePigTail,
    KanadeRibbon,
    RadenRibbon,
    AoNecklace,
    RirikaGlasses,
    NerissaUmbrella,
    BibooMoai,
    KoyoriIdolHat,
    RobocoRibbon,
    ZetaHood,
    KoroneBoneHat,
    MococoKimonoFlower,
    FuwawaKimonoFlower,
    BibooCrown,
    ShioriShurikenHairpin,
    NerissaHorn,
    Cash
}
