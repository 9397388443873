import React, {Component} from 'react';

import {AiTwotoneMail} from 'react-icons/ai';

import {connect} from 'react-redux';
import { IUserRoomPreview } from '../../../interfaces/IChatLog';

const mapStateToProps = (state:any) => ({
    chat:state.chat,
    userinfo:state.userinfo
})

class DirectMessagePreviewState {
    recipient:string;
    message:string;
    error:string;
    constructor() {
        this.error = "";
        this.recipient = "";
        this.message = "";
    }
}

interface DirectMessagePreviewProps {
    chat: {
        directMessageRooms: Array<IUserRoomPreview>
    },
    userinfo: {
        username:string,
    },
    onChangeRoom: (roomid:string) => void
}

class DirectMessagePreviewBind extends Component<DirectMessagePreviewProps> {

    state:DirectMessagePreviewState;
    constructor(props:DirectMessagePreviewProps) {
        super(props);
        this.state = new DirectMessagePreviewState();
    }

    handleInput(e:any) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    setActiveRoom(roomid:string) {
        this.props.onChangeRoom(roomid);
    }

    createConversation() {
        if(this.state.message === "" || this.state.recipient === "") {
            return;
        }
        if(this.state.message.length > 500 || this.state.recipient.length > 100) {
            return;
        }
        if(this.state.recipient === this.props.userinfo.username) {
            this.setState({error:"You cannot create a conversation with yourself."});
            return;
        }
        
        fetch('/api/createDM/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                receiver_username: this.state.recipient,
                message: this.state.message
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                this.setState({error:data.message});
            } else {
                this.setState({
                    message:'',
                    recipient:'',
                    error: ''
                });
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    render() {
        return(
            <div className="dm-side-panel">
                <div className="new-conversation">
                    <div className="new-conversation-header">
                        New conversation
                    </div>
                    <div className="new-conversation-recipient">
                        <input 
                            name="recipient" 
                            placeholder="Username" 
                            type="text" 
                            value={this.state.recipient} 
                            onChange={(e) => this.handleInput(e)}/>
                    </div>
                    <div className="new-conversation-message">
                        <textarea 
                            name="message" 
                            placeholder="Message" 
                            value={this.state.message}
                            onChange={(e) => this.handleInput(e)}></textarea>
                    </div>
                    <div className="send-new-convo flex center-child">
                        <div 
                            className="new-convo-btn"
                            onClick={() => this.createConversation()}>
                            <AiTwotoneMail style={{verticalAlign: 'middle'}}/>
                        </div>
                    </div>
                    {
                        this.state.error !== "" ?
                        <div className="new-convo-error">
                            {this.state.error}
                        </div> : null
                    }
                </div>
                <div className="current-conversations">
                    {
                        this.props.chat.directMessageRooms.map((room:IUserRoomPreview) =>
                            <div 
                                className="dm-item" 
                                key={room.roomid}
                                onClick={() => this.setActiveRoom(room.roomid)}>
                                <div className="dm-item-header">
                                    <div className="dm-item-recipient">
                                        {
                                            room.user1name === this.props.userinfo.username ?
                                            room.user2name : room.user1name
                                        }
                                    </div>
                                    <div className="dm-item-timestamp">
                                        {new Date(room.timestamp).toLocaleDateString()}
                                    </div>
                                </div>
                                <div 
                                    className="dm-preview"
                                    dangerouslySetInnerHTML={{__html: room.preview}}>
                                </div>
                            </div>
                        )
                    }
                   
                </div>
            </div>
        )
    }
}

const DirectMessagePreview = connect(mapStateToProps)(DirectMessagePreviewBind);
export default DirectMessagePreview;