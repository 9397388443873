import { handleActions } from 'redux-actions';

const devblog = handleActions(
    {
        SET_BLOG_POSTS: (state:any, action:any) => ({
            ...state,
            posts: action.payload.posts
        }),
        ADD_BLOG_POST: (state:any, action:any) => ({
            ...state,
            posts: [action.payload.post, ...state.posts]
        })
    },
    {
        posts:[]
    }
);

export { devblog as default }