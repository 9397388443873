import React, { Component } from 'react';

class NotVerified extends Component {
    render() {
        return(
            <div className="container-section">
                <div className="section-background"></div>
                <div className="section-content">
                    <div className="not-verified-msg">
                    <p>You must verify your account in order to trade and post on the floor.</p>
                    <p>Please read how to play here: <a href="https://nasfaq.biz/info">https://nasfaq.biz/info</a> There is shortened version near the top.</p>
                    Please check your email for the verification link. If you just did it, try refreshing.
                    If you did not receive an email within 5 minutes, please send a message into the thread with your username and/or email the email from the info page.
                    </div>
                </div>
            </div>
        )
    }
}

export default NotVerified;
