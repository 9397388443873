import React, {Component} from 'react';
import '../../../css/profile/crafting.scss';
import {ItemImages} from '../ItemImages';

import {connect} from 'react-redux';
import Button from '../Button';
import {
    userinfoActions,
} from '../../actions/actions';

import LoadingSmall from '../LoadingSmall';

const mapStateToProps = (state:any) => ({
    userinfo: state.userinfo,
    itemcatalogue: state.itemcatalogue
});
const mapDispatchToProps = {
    setItems: userinfoActions.setItems
}

interface CraftingProps {
    userinfo: {
        items:any
    },
    itemcatalogue: any,
    setItems: (items:any) => {},
}

class CraftingState {
    activeItems:Array<string>;
    crafting:boolean;
    craftResult:string;
    error:string;
    constructor() {
        this.activeItems = [];
        this.crafting = false;
        this.craftResult = "";
        this.error = "";
    }
}

class CraftingBind extends Component<CraftingProps> {

    state:CraftingState;
    constructor(props:CraftingProps) {
        super(props);
        this.state = new CraftingState();
    }

    getUserItems() {
        let items:Array<any> = [];
        Object.keys(this.props.userinfo.items).forEach((type:any) => {
            items = [...items, ...this.props.userinfo.items[type]];
        });
        items = items.filter((item:any) => {
            return this.props.itemcatalogue[item.itemType].tradeable;
        });
        return items;
    }

    toggleItem(item:string) {
        let index = this.state.activeItems.indexOf(item);
        if(index !== -1) {
            let activeItems = [...this.state.activeItems];
            activeItems.splice(index, 1);
            this.setState({activeItems});
        } else {
            if(this.state.activeItems.length >= 8) return;
            let activeItems:Array<string> = [...this.state.activeItems, item];
            this.setState({activeItems});
        }
    }

    craft() {
        if(this.state.activeItems.length < 3) return;
        this.setState({
            crafting:true
        }, () => {
            fetch('/api/craftItem', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    items:this.state.activeItems
                })
            })
            .then(response => response.json())
            .then(data => {
                setTimeout(() => {
                    if(data.success) {
                        this.setState({
                            craftResult:data.item
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    crafting:false,
                                    activeItems:[],
                                    craftResult:''
                                });
                            }, 3000)
                        });
                        this.props.setItems(data.items);
                    } else {
                        this.setState({
                            error:data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    crafting:false,
                                    activeItems:[],
                                    error:''
                                })
                            }, 3000)
                        })
                    }
                }, 2000)
            })
            .catch(error => {
                console.error('Error: ' +  error);
                this.setState({crafting:false})
            })
        });
    }

    render() {
        let items = this.getUserItems();
        let unfilled:Array<any> = [];
        for(let i = 0; i < 8 - this.state.activeItems.length; i++) {
            unfilled.push(<div key={i} className="unfilled"></div>);
        }
        let buttonClass = this.state.activeItems.length < 3 ? "inactive" : "";
        let imageClass = this.state.crafting ? "crafting" : "";
        return(
            <div className="container-section">
                <div className="section-background"></div>
                <div className="section-content">
                    <div className="header">
                        Crafting
                    </div>
                    <p style={{textAlign: "center"}}>
                        Combine items to make new ones!
                    </p>
                    <ul>
                        <li className="crafting-recipe">
                            <img src={ItemImages["SoraHat"]} alt="Sora Hat" title="Sora Hat"/> +
                            <img src={ItemImages["RobocoHat"]} alt="Roboco Hat" title="Roboco Hat"/> +
                            <img src={ItemImages["MikoHat"]} alt="Miko Hat" title="Miko Hat"/> +
                            <img src={ItemImages["SuiseiHat"]} alt="Suisei Hat" title="Suisei Hat"/> +
                            <img src={ItemImages["AzkiHat"]} alt="Azki Hat" title="Azki Hat"/> =
                            <img src={ItemImages["AChanHat"]} alt="AChan Hat" title="AChan Hat"/>
                        </li>
                        <li className="crafting-recipe nene-coin-recipe">
                            <img src={ItemImages["CocoBill"]} alt="Coco Bill" title="Coco Bill"/> +
                            <img src={ItemImages["RisuBill"]} alt="Risu Bill" title="Risu Bill"/> +
                            <img src={ItemImages["PekoraBill"]} alt="Pekora Bill" title="Pekora Bill"/> +
                            <img src={ItemImages["FubukiBill"]} alt="Fubuki Bill" title="Fubuki Bill"/> =
                            <img src={ItemImages["NeneCoin"]} alt="Nene Coin" title="Nene Coin"/>
                        </li>
                        <li className="crafting-recipe nene-coin-recipe">
                            <img src={ItemImages["HonkCard"]} alt="Honk Trading Card" title="Honk Trading Card"/> +
                            <img src={ItemImages["TakoIceCream"]} alt="Tako Ice Cream" title="Tako Ice Cream"/> +
                            <img src={ItemImages["ChocolateKanataCupcake"]} alt="Chocolate Kanata Cupcake" title="Chocolate Kanata Cupcake"/> =
                            <img src={ItemImages["HonkPantsu"]} alt="Honk Pantsu" title="Honk Pantsu"/>
                        </li>
                    </ul>
                    <div className="crafting-outer">
                        <div className="item-select">
                        {
                            items.map((item:any) => {
                                return (
                                item !== "Cash" ?
                                    <div
                                        key={item.itemType}
                                        className="user-item flex center-child"
                                        onClick={() => this.toggleItem(item.itemType)}>
                                        <div
                                            className="item-image flex flex-col center-child"
                                            title={item.itemType}>
                                            <div className="item-name">
                                                {item.itemType}
                                            </div>
                                            <img src={ItemImages[item.itemType]} alt={item.itemType}/>
                                        </div>
                                        <div className="item-quantity flex center-child">
                                            x {item.quantity}
                                        </div>
                                    </div> : null
                                )}
                            )
                        }
                        {
                            items.length === 0 ?
                            "You don't own any items!" : null
                        }
                        </div>
                        <div className="crafting-table">
                            <div className="item-slots">
                                {this.state.activeItems.map((item:string) =>
                                    <img
                                        key={item}
                                        src={ItemImages[item]}
                                        alt={item}
                                        className={imageClass}
                                        onClick={() => this.toggleItem(item)}/>
                                )}
                                {!this.state.crafting ? unfilled : null}
                                {this.state.crafting && !this.state.error && !this.state.craftResult ? <LoadingSmall /> : null}
                                {this.state.error ?
                                    <div className="crafting-error">
                                        {this.state.error}
                                    </div> : null
                                }
                                {this.state.craftResult ?
                                    <div className="craft-result">
                                        You received:
                                        <img src={ItemImages[this.state.craftResult]} alt={this.state.craftResult} />
                                    </div> : null
                                }
                            </div>
                            <div className="submit-craft flex center-child">
                                <Button
                                    className={buttonClass + " inverse green"}
                                    onClick={() => {this.craft()}}>
                                    Craft
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const Crafting = connect(
    mapStateToProps,
    mapDispatchToProps
)(CraftingBind);

export default Crafting;
