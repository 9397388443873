import React, {Component} from 'react';
import {
    GiTakeMyMoney
} from 'react-icons/gi';
import {
    FaTrophy
} from 'react-icons/fa';
import {connect} from 'react-redux';
import { IBettingOption, IBettingPool, IUserBet, IUserBetMap } from '../../interfaces/IBetting';
import { IWallet } from '../../interfaces/IWallet';
import numberWithCommas from '../../numberWithCommas';

const mapStateToProps = (state:any) => ({
    session: state.session,
    userinfo: state.userinfo,
    betting: state.betting
})

class BettingPoolOptionState {
    showInfo:boolean;
    betClicked:boolean;
    trophyClicked:boolean;
    closed:boolean;
    constructor(){
        this.showInfo = false;
        this.betClicked = false;
        this.closed = false;
        this.trophyClicked = false;
    }
}

interface BettingPoolOptionProps {

    session: {
        loggedin:boolean
    },
    userinfo: {
        id:string,
        bookie:boolean,
        wallet:IWallet
    },
    betting: {
        bettingPools:{[poolid:string]:IBettingPool}
    },
    option:IBettingOption,
    poolid:string, 

    setActiveBet: (optionid:number) => void
}

class BettingPoolOptionBind extends Component<BettingPoolOptionProps> {

    state:BettingPoolOptionState;
    intervalId:any;
    constructor(props:BettingPoolOptionProps) {
        super(props);
        this.state = new BettingPoolOptionState();
    }

    componentDidMount() {
        this.intervalId = setInterval(() => {
            let bettingPool:IBettingPool = this.props.betting.bettingPools[this.props.poolid];
            let closed = new Date().getTime() > bettingPool.closingTime || !bettingPool.open;
            this.setState({closed});
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    getTotalBetAmount() {
        let poolid:string = this.props.poolid;
        if(this.props.betting.bettingPools === undefined) return 0;
        let userBets:IUserBetMap = this.props.betting.bettingPools[poolid].userBets;
        let bet:number = 0;
        userBets[this.props.option.optionid].forEach((userBet:IUserBet) => {
            bet += userBet.betAmount;
        })
        return bet;
    }

    getProportion() {
        let amount:number = this.getTotalBetAmount();
        let totalPool = this.props.betting.bettingPools[this.props.poolid].totalPool;
        if(totalPool === 0) return "0%";
        let percentage = amount / totalPool;
        return (Math.round(percentage * 10000) / 100) + "%";
    }

    getCurrentBet() {
        let poolid:string = this.props.poolid;
        if(this.props.betting.bettingPools === undefined) return 0;
        let userBets:IUserBetMap = this.props.betting.bettingPools[poolid].userBets;
        let bet:number = 0;
        if(userBets[this.props.option.optionid]) {
            userBets[this.props.option.optionid].forEach((userBet:IUserBet) => {
                if(userBet.userid === this.props.userinfo.id) {
                    bet = userBet.betAmount;
                }
            })
        }
        return bet;
    }

    getMyPotentialWinnings() {
        let bettingPool = this.props.betting.bettingPools[this.props.poolid];
        let totalPool = bettingPool.totalPool;
        let totalBetAmount = this.getTotalBetAmount();
        let myAmount = this.getCurrentBet();
        if(totalBetAmount === 0) {
            return 0;
        } else {
            let myProportion = myAmount / totalBetAmount;
            return Math.round(100 * totalPool * myProportion) / 100;
        }
    }

    getBetterCount() {
        let userBets:IUserBetMap = this.props.betting.bettingPools[this.props.poolid].userBets;
        let betterCount:any = {};
        userBets[this.props.option.optionid].forEach((userBet:IUserBet) => {
            betterCount[userBet.userid] = 1;
        })
        return Object.keys(betterCount).length;
    }

    chooseWinner() {
        let option = this.props.option.optionid;
        let poolid:string = this.props.poolid;
        if(!this.props.userinfo.bookie) return;

        let pool = this.props.betting.bettingPools[this.props.poolid];

        if(!this.state.closed) {
            alert("Betting must be closed before you can choose a winner.");
            return;
        }

        let optionname = pool.options[option].option;
        if(window.confirm(`You are about to select ${optionname} as the winner. Correct?`)) {
            fetch('/api/setBettingPoolWinner/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    poolid,
                    winOption:option
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    render() {

        let currentBet = this.getCurrentBet();
        let betterCount = this.getBetterCount();
        let bettingPool:IBettingPool = this.props.betting.bettingPools[this.props.poolid];
        let userBets:Array<IUserBet> = bettingPool.userBets[this.props.option.optionid];

        let optionClass = "betting-pool-option-row flex flex-row ";
        if(bettingPool.winOption !== null) {
            if(bettingPool.winOption === this.props.option.optionid) {
                optionClass += " win-option";
            }
        }
        if(currentBet > 0) {
            optionClass += " has-bet";
        }

        return(
            <div className="betting-pool-option-container flex flex-col">
                <div className={optionClass}>
                    {
                        this.props.session.loggedin && !this.state.closed ?
                        <div
                            onMouseDown={() => this.setState({betClicked:true})}
                            onMouseUp={() => this.setState({betClicked:false})}
                            onMouseLeave={() => this.setState({betClicked:false})}
                            onClick={() => this.props.setActiveBet(this.props.option.optionid)}
                            className="place-bet-button flex center-child"
                            style={{
                                transform: this.state.betClicked ? 'translateX(-4px)' : ''
                            }}>
                            <GiTakeMyMoney />
                        </div> : null
                    }
                    {
                        this.props.userinfo.bookie && this.state.closed && bettingPool.winOption === null ?
                        <div 
                            onMouseDown={() => this.setState({trophyClicked:true})}
                            onMouseUp={() => this.setState({trophyClicked:false})}
                            onMouseLeave={() => this.setState({trophyClicked:false})}
                            onClick={() => this.chooseWinner()}
                            className="choose-winner-button"
                            style={{
                                transform: this.state.trophyClicked ? 'translateX(-4px)' : ''
                            }}>
                            <FaTrophy style={{verticalAlign:'middle'}}/>
                        </div> : null
                    }
                    <div className="option-description" onClick={() => {
                        this.setState({showInfo:!this.state.showInfo})
                    }}>
                        <div 
                            className="option-description-background"
                            style={{width: this.getProportion()}}></div>
                        <div className="option-description-content">
                            {this.props.option.option}
                        </div>
                    </div>
                </div>
                {
                    this.state.showInfo ?
                    <div className="betting-pool-option-row">
                        <div className="betting-pool-option-information flex flex-row">

                            <div className="option-info-col pool-info flex flex-col">
                                {
                                    currentBet > 0 ?
                                    <div className="my-bet-amount">
                                    You are currently betting {" "}
                                    <span className="figure">
                                    ${numberWithCommas(currentBet)}
                                    </span>
                                    {" "} on this option.
                                    </div>
                                    :
                                    <div className="my-bet-amount">
                                    You are not betting on this option.
                                    </div>
                                }
                                <div className="total-bet-amount">
                                    <span className="figure">
                                    ${numberWithCommas(this.getTotalBetAmount())}
                                    </span>
                                    {" "} total is being bet on this option.
                                </div>
                                <div className="potential-winnings">
                                    If this option wins, you will win {" "}
                                    <span className="figure">
                                    ${numberWithCommas(this.getMyPotentialWinnings())}
                                    </span>
                                </div>
                            </div>
                            <div className="option-info-col better-info flex flex-col">
                                <div className="better-count">
                                    There
                                    {betterCount === 1 ? " is " : " are "}
                                    <span className="figure">
                                    {betterCount} {" "}
                                    </span>
                                    {betterCount === 1 ? " person " : " people "}
                                    betting on this option
                                </div>
                                <div className="better-list">
                                    {
                                        userBets.map((userBet:IUserBet) =>
                                            <div className="better-item flex flex-row">
                                                <div className="better-username">
                                                    {userBet.username}
                                                </div>
                                                <div className="better-bet-amount">
                                                    ${numberWithCommas(userBet.betAmount)}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        )
    }
}

const BettingPoolOption = connect(
    mapStateToProps
)(BettingPoolOptionBind);

export default BettingPoolOption