import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Socket } from 'socket.io-client';
import fetchData from '../fetchData';
import { IPoll, IPollOption } from '../interfaces/Polls';

import "../../css/polls.scss";

import {TiDelete} from 'react-icons/ti';
import {MdAddCircleOutline} from 'react-icons/md';
import { FaUnlock, FaLock, FaTrashAlt } from 'react-icons/fa';
import { CgPlayListAdd } from 'react-icons/cg';
import { Redirect } from 'react-router-dom';
import numberWithCommas from '../numberWithCommas';
import postRequest from '../postRequest';

const mapStateToProps = (state:any) => ({
    userinfo:state.userinfo,
    session:state.session,
    socket:state.socket
})

const mapDispatchToProps = {

}

class PollsState {
    polls:Array<IPoll> = [];
    myVotes:{[pollid:string]:number} = {};
    newPollOptions:Array<string> = ["", ""];
    newPollSubject:string = "";
}

interface IPollsProps {
    session: {
        loggedin:boolean
    },
    userinfo: {
        id:string,
        admin:boolean,
        loaded:boolean
    },
    socket: {
        socket:any
    }
}

class PollsBind extends Component<IPollsProps> {

    state:PollsState;
    constructor(props:IPollsProps) {
        super(props);
        this.state = new PollsState();
    }

    componentDidMount() {
        fetchData('/api/getPolls')
        .then((data:any) => {
            if(data.success) {
                this.setState({polls:data.polls});
            } else {
                console.log(data.message);
            }
        });

        if(this.props.userinfo.loaded) {
            this.loadMyVotes();
        }

    }

    loadMyVotes() {
        fetchData('/api/getMyVotes')
        .then((data:any) => {
            if(data.success) {
                this.setState({myVotes:data.myVotes});
            } else {
                console.log(data.message);
            }
        })
    }

    createPoll() {
        if(!this.props.userinfo.admin) return;
        if(this.state.newPollSubject.replace(/\s/g, "") === "") {
            alert("Subject cannot be empty");
            return;
        }
        for(let option of this.state.newPollOptions) {
            if(option.replace(/\s/g, "") === "") {
                alert("You cannot have any empty options");
                return;
            }
        }

        postRequest('/api/createPoll', {
            subject:this.state.newPollSubject,
            options:this.state.newPollOptions
        }).then((data) => {
            if(!data.success) {
                console.log(data.message);
            } else {
                this.setState({newPollOptions:["", ""], newPollSubject:""});
            }
        }).catch((error) => {
            console.error('Error: ' +  error);
        })

    }

    componentDidUpdate(prevProps:IPollsProps) {
        if(this.props.userinfo.loaded && !prevProps.userinfo.loaded) {
            this.loadMyVotes();
        }
        if(this.props.socket.socket !== prevProps.socket.socket) {
            this.reconnect();
        }
    }

    reconnect() {
		let s = this.props.socket.socket;
		if(s !== null) {
            this.listenData(s);
        }
	}

    listenData(socket:Socket) {
        if(!socket.hasListeners('newPoll')) {
            socket.on('newPoll', (data:any) => {
                let polls = [...this.state.polls];
                polls.push(data);
                this.setState({polls});
            })
        }
        if(!socket.hasListeners('togglePoll')) {
            socket.on('togglePoll', (data:any) => {
                let polls = [...this.state.polls];
                for(let poll of polls) {
                    if(poll.id === data.pollid) {
                        poll.open = data.open;
                    }
                }
                this.setState({polls});
            })
        }
        if(!socket.hasListeners('updatePollVotes')) {
            socket.on('updatePollVotes', (data:any) => {
                let {pollid, old:oldVote, new:newVote} = data;
                let polls = [...this.state.polls];
                for(let i = 0; i < polls.length; i++) {
                    if(polls[i].id === pollid) {
                        for(let j = 0; j < polls[i].options.length; j++) {
                            if(polls[i].options[j].id === oldVote) {
                                polls[i].options[j].votes--;
                            }
                            if(polls[i].options[j].id === newVote) {
                                polls[i].options[j].votes++;
                            }
                        }
                        break;
                    }
                }
                this.setState(polls);
            })
        }
        if(!socket.hasListeners('deletePoll')) {
            socket.on('deletePoll', (data:any) => {
                let {pollid} = data;
                let polls = [...this.state.polls];
                for(let i = 0; i < polls.length; i++) {
                    if(polls[i].id === pollid) {
                        polls.splice(i, 1);
                        break;
                    }
                }
                this.setState({polls});
            })
        }
    }

    componentWillUnmount() {
		if(this.props.socket.socket !== null) {
            this.props.socket.socket.removeAllListeners("newPoll");
            this.props.socket.socket.removeAllListeners("togglePoll");
            this.props.socket.socket.removeAllListeners("updatePollVotes");
            this.props.socket.socket.removeAllListeners("deletePoll");
        }
    }

    togglePoll(poll:IPoll) {
        if(!this.props.userinfo.admin) return;
        postRequest('/api/togglePoll/', {
            pollid:poll.id,
            open: !poll.open
        }).then((data:any) => {
            if(!data.success) {
                console.log(data.message);
            }
        }).catch((error) => {
            console.error('Error: ' +  error);
        })
    }

    deletePoll(poll:IPoll) {
        if(!this.props.userinfo.admin) return;
        if(window.confirm("Are you sure you want to delete this poll?")) {
            postRequest('/api/deletePoll/', {
                pollid:poll.id
            }).then((data:any) => {
                if(!data.success) {
                    console.log(data.message);
                }
            }).catch((error) => {
                console.error('Error: ' +  error);
            })
        }
    }

    vote(poll:IPoll, option:number) {
        if(!poll.open) return;

        postRequest('/api/vote/', {
            pollid:poll.id,
            optionid:option
        }).then((data) => {
            if(!data.success) {
                console.log(data.message);
            } else {
                let myVotes = {...this.state.myVotes};
                myVotes[poll.id] = option;
                this.setState({myVotes});
            }

        }).catch((error) => {
            console.error('Error: ' +  error);
        });
    }

    userVoted(poll:IPoll) {
        return this.state.myVotes[poll.id] !== undefined;
    }

    myVote(poll:IPoll, optionid:number) {
        if(this.state.myVotes[poll.id] === undefined) return false;
        return this.state.myVotes[poll.id] === optionid;
    }

    getOptionWidth(poll:IPoll, option:number) {
        let totalVotes = 0;
        let optionVotes:any = {};
        for(let option of poll.options) {
            totalVotes += option.votes;
            optionVotes[option.id] = option.votes; 
        }
        if(totalVotes === 0) return "0";
        return (Math.round((optionVotes[option] * 10000)/ totalVotes) / 100).toString() + "%";
    }

    renderPoll(poll:IPoll) {

        let sortedOptions = [...poll.options];
        sortedOptions.sort((a:IPollOption, b:IPollOption) => {
            return a.id - b.id;
        })
        return(
            <div
                key={poll.id} 
                className={"poll-item-outer " + (poll.open ? "" : "closed")}>
                <div className="poll-item-subject">
                    <div className="poll-item-subject-inner">
                    {poll.subject}
                    </div>
                </div>
                {
                    !poll.open ?
                    <div className="poll-closed">Voting has ended.</div> 
                    : null
                }
                {
                    this.props.userinfo.admin ?
                    <div className="poll-controls flex flex-row">
                        <div
                            title={poll.open ? "Close Poll" : "Open Poll"} 
                            onClick={() => this.togglePoll(poll)}
                            className="toggle-poll-btn">
                            {
                                poll.open ?
                                <FaUnlock /> : <FaLock/>
                            }
                        </div>
                        <div
                            onClick={() => this.deletePoll(poll)} 
                            className="delete-poll-btn">
                            <FaTrashAlt />
                        </div>
                    </div> : null
                }
                <div className="poll-options flex flex-col">
                    {
                        sortedOptions.map((option:IPollOption) =>
                            <div
                                key={option.id} 
                                className="poll-option-item flex flex-row">
                                <div className="poll-option-value">
                                    {option.value}
                                </div>
                                { 
                                    poll.open ? 
                                    <div className="poll-option-select flex center-child">
                                        <div
                                            onClick={() => this.vote(poll, option.id)} 
                                            className={"poll-option-select-btn flex center-child " + (
                                                this.myVote(poll, option.id) ? "voted" : ""
                                            )}></div>
                                    </div> : null
                                }
                                {
                                    this.userVoted(poll) ?
                                    <>
                                    <div className="poll-option-bar">
                                        <div
                                            style={{"width":this.getOptionWidth(poll, option.id)}} 
                                            className={"poll-option-bar-inner " + (this.myVote(poll, option.id) ? "my-vote" : "")}></div>
                                    </div>
                                    <div className="poll-option-vote-count">
                                        <div>
                                        {numberWithCommas(option.votes)}
                                        </div>
                                        <div className="vote-percent">
                                        ({this.getOptionWidth(poll, option.id)})
                                        </div>
                                    </div>
                                    </> : null
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    addOption() {
        let options = [...this.state.newPollOptions];
        options.push("");
        this.setState({newPollOptions:options});
    }
    
    removeOption(i:number) {
        let options = [...this.state.newPollOptions];
        if(options.length <= 2) return;
        options.splice(i, 1);
        this.setState({newPollOptions:options});
    }

    getOptionValue(i:number) {
        let options = [...this.state.newPollOptions];
        return options[i];
    }

    setOptionValue(e:any, i:number) {
        let text = e.target.value;
        let options = [...this.state.newPollOptions];
        options[i] = text;
        this.setState({newPollOptions:options});
    }

    render() {
        if(!this.props.session.loggedin) {
            return <Redirect to={'/login/polls'}/>
        }
        return(
            <div className="container">
                <div className="polls-container-inner">
                    <div className="container-header">Polls</div>
                    {
                        this.props.userinfo.admin ?
                        <div className="create-poll">
                            <div className="create-poll-header">
                                <div>
                                Create New Poll
                                </div>
                                
                                <div className="submit-poll-outer">
                                    <div
                                        title="Create Poll"
                                        onClick={() => this.createPoll()} 
                                        className="submit-poll-btn">
                                        <MdAddCircleOutline style={{"verticalAlign":"middle"}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="create-poll-form">
                                <div className="label">Poll Subject</div>
                                <input
                                    value={this.state.newPollSubject}
                                    onChange={(e) => this.setState({newPollSubject:e.target.value})} 
                                    type="text" />

                                <div className="label">Poll Options</div>
                                {
                                    this.state.newPollOptions.map((option:string, index:number) =>
                                        <div
                                            key={index} 
                                            className="poll-option-input">
                                            {
                                                this.state.newPollOptions.length > 2 ?
                                                <div 
                                                    onClick={() => this.removeOption(index)}
                                                    title="Remove Option"
                                                    className="remove-option-btn">
                                                    <TiDelete />
                                                </div> : null
                                            }
                                            <input 
                                                onChange={(e) => this.setOptionValue(e, index)}
                                                value={this.getOptionValue(index)}
                                                type="text" />
                                        </div>
                                    )
                                }

                                <div className="add-option-outer">
                                    <div
                                        title="Add Another Option"
                                        onClick={() => this.addOption()} 
                                        className="add-option-btn flex center-child">
                                        <CgPlayListAdd/>
                                    </div>
                                </div>
                            </div>
                        </div> : null                    
                    }
                    {
                        this.state.polls.map((poll:IPoll) =>
                            this.renderPoll(poll)
                        )
                    }
                    {
                        this.state.polls.length === 0 ?
                        <div className="no-polls">
                            There are no polls at the moment.
                        </div> : null
                    }
                </div>
            </div>
        )
    }

}

const Polls = connect(
    mapStateToProps,
    mapDispatchToProps
)(PollsBind);

export default Polls;