import React, {Component} from 'react';
import {connect} from 'react-redux';
import { ICoinData, ICoinDataCollection } from '../interfaces/ICoinInfo';
import { Order, TransactionType } from '../interfaces/ITransaction';
import { IMutualFunds, IMutualFundStatCollection } from '../interfaces/MutualFunds';
import { tradeCoinsMutualFund } from '../TradeActions';
import {COOLDOWN} from './constants';
import {TradeButton} from './TradeButton';

const mapStateToProps = (state:any, props:any) => ({
    stats: state.stats,
    userinfo: state.userinfo,
    settings: state.settings,
    mutualfunds: state.mutualfunds
});

const mapDispatchToProps = {
}

interface MutualFundTradeButtonWrapperProps {
    coin:string,
    className?:string,
    userinfo: {
        id:string
    },
    stats: {
        coinInfo: ICoinDataCollection
    },
    settings: {
        marketSwitch:boolean
    },
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
    },
    fund:string,
    updateCoinQuantity: (coin:string, quantity:number) => {},
    coinQuantities:{[coin:string]:number}
}

class MutualFundTradeButtonWrapperState {
    buyDisabled:boolean;
    sellDisabled: boolean;
    timeRemaining: number;
    constructor() {
        this.buyDisabled = true;
        this.sellDisabled = true;
        this.timeRemaining = 0;
    }
}


class MutualFundTradeButtonWrapperBind extends Component<MutualFundTradeButtonWrapperProps> {
    intervalId:any;
    state:MutualFundTradeButtonWrapperState;
    constructor(props:MutualFundTradeButtonWrapperProps) {
        super(props);
        this.state = new MutualFundTradeButtonWrapperState();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    buy() {

        if(this.state.buyDisabled || this.state.timeRemaining > 0) return;
        let name = this.props.coin;
        if(name === "luna") name = "himemoriluna";
        let order:Order = {
            coin:name,
            quantity:this.props.coinQuantities[this.filterName(this.props.coin)],
            type:TransactionType.BUY
        }

        tradeCoinsMutualFund(this.props.fund, [order]);
    }

    sell() {

        if(this.state.sellDisabled || this.state.timeRemaining > 0) return;
        let name = this.props.coin;
        if(name === "luna") name = "himemoriluna";
        let order:Order = {
            coin:name,
            quantity:this.props.coinQuantities[this.filterName(this.props.coin)],
            type:TransactionType.SELL
        }
        tradeCoinsMutualFund(this.props.fund, [order]);
    }

    componentDidMount() {
        this.updateTransactionStatus();
        this.intervalId = setInterval(() => {
            this.updateTransactionStatus();
        }, 500);
    }

    componentDidUpdate(prevProps:MutualFundTradeButtonWrapperProps) {
        if(this.props.stats.coinInfo.data === undefined) return null;
        if(this.props.mutualfunds.funds[this.props.fund] === undefined) return null;
        if(prevProps.mutualfunds.funds[this.props.fund] === undefined) return null;
        if(this.props.mutualfunds.funds[this.props.fund].balance
            !== prevProps.mutualfunds.funds[this.props.fund].balance) {
            this.updateTransactionStatus();
        }
    }

    updateTransactionStatus() {

        if(this.props.stats.coinInfo.data === undefined) return null;
        if(this.props.mutualfunds.funds[this.props.fund] === undefined) return null;

        let name = this.props.coin;
        if(name === "luna") name = "himemoriluna";
        let coinData:ICoinData = this.props.stats.coinInfo.data[name];
        if(coinData === undefined) return;

        let quantity = this.props.coinQuantities[this.filterName(this.props.coin)];
        let fundPortfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        let amtOwned = (fundPortfolio[name] === undefined) ? 0 : fundPortfolio[name].amount;

        const price = coinData.price;

        let buyDisabled = false;
        let sellDisabled = false;
        let lastBought = fundPortfolio[name] === undefined ? 0 : fundPortfolio[name].timestamp;
        let balance = this.props.mutualfunds.funds[this.props.fund].balance;
        
        let tax = 0.1;

        let buyTotal = quantity * price * (1 + tax);

        buyDisabled =
            (balance < buyTotal)
            || !this.props.settings.marketSwitch;
        sellDisabled =
            (amtOwned < quantity)
            || !this.props.mutualfunds.funds[this.props.fund].released
            || !this.props.settings.marketSwitch;

        let timeRemaining = 0;
        const now = new Date().getTime();
        const e = (1000 * 60 * COOLDOWN);
        if((now - lastBought) < e) {
            timeRemaining = e - (now - lastBought);
        }

        this.setState({
            timeRemaining,
            buyDisabled,
            sellDisabled
        })
    }

    statCoinName(coin:string) {
        return coin === "luna" ? "himemoriluna" : coin;
    }

    filterName(name:string) {
        return name === "himemoriluna" ? "luna" : name;
    }

    updateQuantity(q:number) {
        this.props.updateCoinQuantity(this.filterName(this.props.coin), q);
    }

    render() {
        if(this.props.stats.coinInfo.data === undefined) return null;
        if(this.props.coinQuantities[this.filterName(this.props.coin)] === undefined) return null;
        return(
            <div className={"trade-button-container " + this.props.className}>
                <TradeButton
                    type={TransactionType.BUY}
                    disabled={this.state.buyDisabled}
                    timeRemaining={this.state.timeRemaining}
                    isMutualFund={true}
                    quantity={this.props.coinQuantities[this.filterName(this.props.coin)]}
                    updateQuantity={(q:number) => this.updateQuantity(q)}
                    trade={() => this.buy()}>
                </TradeButton>
                <TradeButton
                    type={TransactionType.SELL}
                    disabled={this.state.sellDisabled}
                    timeRemaining={this.state.timeRemaining}
                    isMutualFund={true}
                    quantity={this.props.coinQuantities[this.filterName(this.props.coin)]}
                    updateQuantity={(q:number) => this.updateQuantity(q)}
                    trade={() => this.sell()}>
                </TradeButton>
            </div>
        )
    }
}

const MutualFundTradeButtonWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundTradeButtonWrapperBind);

export default MutualFundTradeButtonWrapper;