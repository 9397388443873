import React, {Component} from 'react';
import {connect} from 'react-redux';
import { IMutualFundHistoryCollection, IMutualFunds, IMutualFundStatCollection } from '../../../interfaces/MutualFunds';
import MutualFundSection from './MutualFundSection';

import '../../../../css/mutualfunds/mutualfundjoinrequests.scss';
import { mutualfundActions } from '../../../actions/actions';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {
    setJoinRequests:mutualfundActions.setJoinRequests
}

interface IMutualFundJoinRequestsProps {
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
        joinRequests: any
    },
    fund:string,
    setJoinRequests: (joinRequests:any) => {}
}

class MutualFundJoinRequestsBind extends Component<IMutualFundJoinRequestsProps> {

    componentDidMount() {
        if(this.props.mutualfunds.joinRequests[this.props.fund] === undefined) {
            fetch('/api/getMutualFundJoinRequests', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fund:this.props.fund
                })
            })
            .then(response => response.json())
            .then(data => {
                if(data.success) {
                    let joinRequests = {...this.props.mutualfunds.joinRequests};
                    joinRequests[this.props.fund] = data.requests;
                    this.props.setJoinRequests(joinRequests);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    acceptUser(id:string) {
        fetch('/api/mutualFundAcceptJoinRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fund:this.props.fund,
                pendingUser:id
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                console.log(data.message);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }
    
    rejectUser(id:string) {
        fetch('/api/mutualFundRejectJoinRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fund:this.props.fund,
                rejectedUser:id
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                console.log(data.message);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    render() {
        if(this.props.mutualfunds.joinRequests[this.props.fund] === undefined) {
            return null;
        }
        return(
            <MutualFundSection fund={this.props.fund}>
                <div className="section-header">
                    Join Requests
                </div>
                <div className="join-requests-list flex flex-row">
                    {
                        this.props.mutualfunds.joinRequests[this.props.fund].length === 0 ?
                        <div className="no-join-requests">
                            No Join Requests
                        </div> : null
                    }
                    <table>
                        <tbody>
                        {
                            this.props.mutualfunds.joinRequests[this.props.fund].map((request:any) =>
                            <tr 
                                key={request.username}
                                className="join-request-item flex flex-row">
                                <td className="join-request-username flex center-child">{request.username}</td>
                                <td>
                                    <div 
                                        onClick={() => this.acceptUser(request.id)} 
                                        className="join-request-accept-button">
                                        Accept
                                    </div>
                                </td>
                                <td>
                                    <div
                                        onClick={() => this.rejectUser(request.id)} 
                                        className="join-request-reject-button">
                                        Reject
                                    </div>
                                </td>
                            </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </MutualFundSection>
        );
    }
}

const MutualFundJoinRequests = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundJoinRequestsBind);

export default MutualFundJoinRequests;