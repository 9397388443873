import React, {Component} from 'react';
import { IBettingOption, IBettingPool, IUserBet } from '../../interfaces/IBetting';
import Button from '../Button';
import {
    FaClock
} from 'react-icons/fa';
import numberWithCommas from '../../numberWithCommas';
import {Link} from 'react-router-dom';

interface IBettingItemProps {
    bettingPool:IBettingPool
}

class IBettingItemState {
    isClosed:boolean;
    timer:string;
    constructor() {
        this.isClosed = false;
        this.timer = '';
    }
}

class BettingItem extends Component<IBettingItemProps> {

    state:IBettingItemState;
    intervalId:any;
    constructor(props:IBettingItemProps) {
        super(props);
        this.state = new IBettingItemState();
    }

    formatTime(t:number) {
        return t < 10 ? '0' + t : t;
    }

    componentDidMount() {
        this.intervalId = setInterval(() => {
            let closingTime = this.props.bettingPool.closingTime;
            if(new Date().getTime() > closingTime || !this.props.bettingPool.open) {
                this.setState({
                    isClosed:true
                });
            } else {
                let remainingTime = Math.floor((closingTime - new Date().getTime()) / 1000);
                let days = Math.floor(remainingTime / 60 / 60 / 24);
                let hours = Math.floor(remainingTime / 60 / 60) % 24;
                let minutes = Math.floor(remainingTime / 60) % 60;
                let seconds = remainingTime % 60;

                let timeString =
                    this.formatTime(days) + ":" +
                    this.formatTime(hours) + ":" +
                    this.formatTime(minutes) + ":" +
                    this.formatTime(seconds);
                this.setState({
                    isClosed:false,
                    timer:timeString
                })
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    getBetters() {
        let totalBetters:any = {};
        Object.keys(this.props.bettingPool.userBets).forEach((option:any) => {
            this.props.bettingPool.userBets[option].forEach((userBet:IUserBet) => {
                totalBetters[userBet.userid] = 0;
            })
        });
        return Object.keys(totalBetters).length;
    }

    getTimerColor() {

        let remainingHours = (this.props.bettingPool.closingTime - new Date().getTime()) / 1000 / 60 / 60;
        if(remainingHours < 1) {
            return "red";
        } else if(remainingHours < 5) {
            return "orange";
        } else if(remainingHours < 24) {
            return "yellow";
        } else {
            return "teal";
        }
    }

    render() {
        let betterAmount = this.getBetters();
        return(
            <div className="betting-pool-item">
                <div className="betting-pool-background"></div>
                <div className="betting-pool-content">
                    <div className="betting-pool-header flex flex-row">
                        <div className="betting-pool-topic">
                            {this.props.bettingPool.topic}
                        </div>
                        <div className="betting-pool-open-status">
                            {
                                this.state.isClosed ?
                                <>
                                {
                                    this.props.bettingPool.winOption !== null ?
                                    <div className="winner-chosen">
                                        Winner Chosen
                                    </div>
                                    :
                                    <div className="betting-closed">
                                        Betting Closed
                                    </div> 
                                }
                                </>
                                :
                                <>
                                <FaClock style={{verticalAlign: 'middle'}}/>
                                {" "} Betting ends in 
                                <span className={"timer " + this.getTimerColor()}>
                                    {" "} {this.state.timer} {" "}
                                </span>
                                </>
                            }
                        </div>
                    </div>
                    <div className="betting-pool-information flex flex-row">
                        <div className="betting-pool-total-pool">
                            <span className="pool-amt">${numberWithCommas(this.props.bettingPool.totalPool)}</span> Pool
                        </div>
                        <div className="betting-pool-total-betters">
                            <span className="bet-amt">{betterAmount + " "}</span> 
                            {betterAmount === 1 ? "Person " : "People "}
                            Betting
                        </div>
                    </div>
                    <div className="betting-pool-options flex flex-row">
                        <div className="betting-pool-options-container flex flex-row">
                            {
                                this.props.bettingPool.options.map((option:IBettingOption) => 
                                    <div 
                                        className={"betting-pool-option" + (
                                            this.props.bettingPool.winOption === option.optionid ?
                                            " win-option" : ""
                                        )}
                                        key={option.optionid}>
                                        {option.option}
                                    </div>
                                )
                            }
                        </div>
                        <div className="bet-button-outer flex center-child">
                            <Link to={"/betting/" + this.props.bettingPool.id}>
                                <Button className="enter-pool-button" onClick={() => {}}>
                                    Enter
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default BettingItem;