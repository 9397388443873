import React, {Component} from 'react';
import '../../../css/betting/bettingpool.scss';

import {connect} from 'react-redux';
import {bettingActions} from '../../actions/actions';
import fetchData from '../../fetchData';
import { IBettingOption, IBettingPool, IUserBet, IUserBetMap } from '../../interfaces/IBetting';
import { Redirect } from 'react-router';
import { Loading } from '../Loading';

import { Link } from 'react-router-dom';

import horse from '../../../images/horse.png';

import {
    BsFillBackspaceFill
} from 'react-icons/bs';
import {
    FiArrowRightCircle
} from 'react-icons/fi';
import {
    GiTakeMyMoney
} from 'react-icons/gi';
import  {
    IoIosArchive
} from 'react-icons/io';
import {
    RiRefund2Line
} from 'react-icons/ri';
import {
    BsFillTrashFill
} from 'react-icons/bs';
import numberWithCommas from '../../numberWithCommas';
import BettingPoolOption from './BettingPoolOption';
import { IWallet } from '../../interfaces/IWallet';

const mapStateToProps = (state:any) => ({
    userinfo:state.userinfo,
    session:state.session,
    betting:state.betting
})

const mapDispatchToProps = {
    setBettingPools: bettingActions.setBettingPools,
    updatePool: bettingActions.updatePool
}

interface BettingPoolProps {
    userinfo: {
        bookie:boolean;
        id:string;
        wallet:IWallet;
    },
    session: {
        loggedin:boolean;
    },
    betting: {
        bettingPools: {[poolid:string]: IBettingPool}
    },
    match: {
        params: {
            poolid:string
        }
    },

    setBettingPools: (bettingPools:any) => {},
    updatePool: (bettingPool:any) => {}
}

class BettingPoolState {
    loaded:boolean;
    timer:string;
    isClosed:boolean;
    activeBet:number;
    betAmount:string;
    betError:string;
    constructor(){
        this.loaded = false;
        this.timer = "";
        this.isClosed = false;
        this.activeBet = -1;
        this.betAmount = "";
        this.betError = "";
    }
}

class BettingPoolBind extends Component<BettingPoolProps> {

    state:BettingPoolState;
    intervalId:any;
    constructor(props:BettingPoolProps) {
        super(props);
        this.state = new BettingPoolState();
    }

    formatTime(t:number) {
        return t < 10 ? '0' + t : t;
    }

    setTimer() {
        this.intervalId = setInterval(() => {
            let poolid:string = this.props.match.params.poolid;
            let bettingPool:IBettingPool = this.props.betting.bettingPools[poolid];
            let closingTime = bettingPool.closingTime;
            if(new Date().getTime() > closingTime || !bettingPool.open) {
                this.setState({
                    isClosed:true
                });
            } else {
                let remainingTime = Math.floor((closingTime - new Date().getTime()) / 1000);
                let days = Math.floor(remainingTime / 60 / 60 / 24);
                let hours = Math.floor(remainingTime / 60 / 60) % 24;
                let minutes = Math.floor(remainingTime / 60) % 60;
                let seconds = remainingTime % 60;

                let timeString =
                    this.formatTime(days) + ":" +
                    this.formatTime(hours) + ":" +
                    this.formatTime(minutes) + ":" +
                    this.formatTime(seconds);
                this.setState({
                    isClosed:false,
                    timer:timeString
                })
            }
        }, 1000)
    }

    componentDidMount() {

        if(this.props.betting.bettingPools === undefined) {

            fetchData('/api/getBettingPools')
            .then((data:any) => {
                if(data.success) {
                    this.props.setBettingPools(data.bettingPools);
                    this.setTimer();
                } else {
                    console.log(data.message);
                    this.props.setBettingPools({});
                }
                this.setState({loaded:true});
            })
            .catch((err:any) => {
                console.log(err);
                this.props.setBettingPools({})
            })

        } else {
            this.setState({loaded:true});
            this.setTimer();
        }

    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    setActiveBet(optionid:number) {
        if(this.props.session.loggedin) {
            this.setState({activeBet:optionid})
        }
    }

    getOptionName(optionid:number) {
        let option;
        let poolid:string = this.props.match.params.poolid;
        this.props.betting.bettingPools[poolid].options.forEach((o:IBettingOption) => {
            if(o.optionid === optionid) option = o.option;
        })
        return option;
    }

    handleBetAmountChange(e:any) {
        let text = e.target.value;
        text = text.replace(/\$/g, '');
        text = text.replace(/,/g, '');
        let amount = parseInt(text);
        if(isNaN(amount)) {
            this.setState({betAmount:''})
        } else {
            let betString = numberWithCommas(amount);
            betString = "$" + betString;
            this.setState({betAmount:betString});
        }
    }

    getMyBetAmount(option:number) {
        let poolid:string = this.props.match.params.poolid;
        if(this.props.betting.bettingPools === undefined) return 0;
        let userBets:IUserBetMap = this.props.betting.bettingPools[poolid].userBets;
        let bet:number = 0;
        userBets[option].forEach((userBet:IUserBet) => {
            if(userBet.userid === this.props.userinfo.id) {
                bet = userBet.betAmount;
            }
        })
        return bet;
    }

    getCurrentBet() {
        if(this.state.activeBet === -1) return 0;
        return this.getMyBetAmount(this.state.activeBet);
    }

    submitBet() {

        let poolid:string = this.props.match.params.poolid;
        let bettingPool:IBettingPool = this.props.betting.bettingPools[poolid];
        if(!bettingPool.open || new Date().getTime() > bettingPool.closingTime) {
            this.setState({betError:"Betting is closed."});
            return;
        }
        let betString = this.state.betAmount.replace(/\$/g, '');
        if(betString === "") return;
        betString = betString.replace(/,/g, '');
        let betAmount = parseInt(betString);

        let currentBet = this.getCurrentBet();

        let minBet = currentBet + 5000;
        if(betAmount < minBet || betAmount > 5000000000) {
            this.setState({betError:"Invalid bet amount. See rules."});
            return;
        }
        if(betAmount - currentBet > this.props.userinfo.wallet.balance) {
            this.setState({betError: "You do not have sufficient balance to place this bet."});
            return;
        }

        fetch('/api/placeBet/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                poolid,
                betAmount,
                option:this.state.activeBet
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.setState({betError:'', betAmount:''})
            } else {
                this.setState({
                    betError:data.message
                })
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    toggleClosed() {
        let poolid:string = this.props.match.params.poolid;
        let bettingPool:IBettingPool = this.props.betting.bettingPools[poolid];
        let setTo = !bettingPool.open;
        fetch('/api/setBettingPoolOpenStatus/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                poolid,
                openStatus:setTo
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                console.log(data.message);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    archivePool() {
        let poolid:string = this.props.match.params.poolid;
        if(!this.state.isClosed) return;
        if(!this.props.userinfo.bookie) return;

        if(window.confirm("Are you sure you want to archive the pool?")) {
            fetch('/api/archiveBettingPool/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    poolid,
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    refundBets() {
        let poolid:string = this.props.match.params.poolid;
        if(!this.props.userinfo.bookie) return;
        if(!this.state.isClosed) {
            alert("You must close the betting pool before refunding bets.");
            return;
        };

        if(window.confirm("Are you sure you want to refund all bets?")) {
            fetch('/api/refundBets/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    poolid,
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    deletePool() {
        let poolid:string = this.props.match.params.poolid;
        if(!this.props.userinfo.bookie) return;
        if(!this.state.isClosed) {
            alert("You must close the betting pool before refunding bets.");
            return;
        };
        let bettingPool:IBettingPool = this.props.betting.bettingPools[poolid];
        if(bettingPool.totalPool > 0) {
            alert("Bets must be refunded before you can delete the pool.");
            return;
        }

        if(window.confirm("Are you sure you want to delete this pool?")) {
            fetch('/api/deleteBettingPool/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    poolid,
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    console.log(data.message);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }
    }

    getWinningOption() {
        let poolid:string = this.props.match.params.poolid;
        let bettingPool:IBettingPool = this.props.betting.bettingPools[poolid];
        let winOption = bettingPool.winOption;
        let optiondesc = '';
        bettingPool.options.forEach((option:IBettingOption) => {
            if(option.optionid === winOption) {
                optiondesc = option.option;
            }
        });
        return optiondesc;
    }

    getTotalBetAmount(option:number) {
        let poolid:string = this.props.match.params.poolid;
        let bettingPool:IBettingPool = this.props.betting.bettingPools[poolid];
        let userBets:IUserBetMap = bettingPool.userBets;
        let bet:number = 0;
        userBets[option].forEach((userBet:IUserBet) => {
            bet += userBet.betAmount;
        })
        return bet;
    }

    getWinningAmount() {

        let poolid:string = this.props.match.params.poolid;
        let bettingPool:IBettingPool = this.props.betting.bettingPools[poolid];
        let totalPool = bettingPool.totalPool;
        if(bettingPool.winOption === null) return {winAmount: 0, winPercent: 0};
        let totalBetAmount = this.getTotalBetAmount(bettingPool.winOption);
        let myAmount = this.getMyBetAmount(bettingPool.winOption);
        if(totalBetAmount === 0) {
            return {winAmount: 0, winPercent: 0};
        } else {
            let myProportion = myAmount / totalBetAmount;
            return {
                winAmount: Math.round(100 * totalPool * myProportion) / 100,
                winPercent: Math.round(10000 * myProportion) / 100
            }
        }
    }

    render() {

        if(!this.state.loaded) {
            return(
                <div className="betting-pool-container">
                    <Loading />
                </div>
            )
        }
        if(this.props.betting.bettingPools === undefined) return null;

        let poolid:string = this.props.match.params.poolid;
        if(poolid === undefined) {
            return(
                <Redirect to="/betting" />
                )
            }

        let bettingPool:IBettingPool = this.props.betting.bettingPools[poolid];
        if(bettingPool === undefined) {
            return(
                <Redirect to="/betting" />
            )
        }

        let bettersMap:any = {};
        Object.keys(bettingPool.userBets).forEach((option:any) => {
            bettingPool.userBets[option].forEach((userbet:IUserBet) => {
                bettersMap[userbet.userid] = 1;
            })
        });
        let totalBetters = Object.keys(bettersMap).length;

        let currentBet = this.getCurrentBet();

        let closed = (new Date().getTime() > bettingPool.closingTime) || !bettingPool.open;

        let { winAmount, winPercent } = this.getWinningAmount();

        return(
            <div className="container betting-pool-container">
                <div className="betting-pool-header flex center-child">
                    {bettingPool.topic}

                    <Link to="/betting">
                        <div className="back-betting">
                                <BsFillBackspaceFill style={{verticalAlign:'middle'}}/>
                                {" "} Back
                        </div>
                    </Link>
                </div>

                <div className="betting-pool-content flex flex-row">
                    <div className="image-banner">
                    </div>
                    <div className="betting-pool-information flex flex-col">

                        {
                            this.props.userinfo.bookie ?
                            <div className="close-pool flex flex-row">
                                Closed:
                                <div
                                    className={"close-pool-btn " + (bettingPool.open ? "":"closed")}
                                    onClick={() => this.toggleClosed()}>
                                </div>
                            </div> : null
                        }

                        {
                            this.props.userinfo.bookie && this.state.isClosed ?
                            <>
                            <div
                                className="archive-pool flex flex-row"
                                title="Archive the betting pool"
                                onClick={() => this.archivePool()}>
                                <IoIosArchive style={{verticalAlign: 'middle'}}/>
                            </div>
                            <div
                                className="refund-bets"
                                title="Refund all bets"
                                onClick={() => this.refundBets()}>
                                <RiRefund2Line style={{verticalAlign: 'middle'}}/>
                            </div>
                            <div
                                className="delete-pool"
                                title="Delete the betting pool"
                                onClick={() => this.deletePool()}>
                                <BsFillTrashFill style={{verticalAlign: 'middle'}}/>
                            </div>
                            </> : null
                        }

                        <div className="horse-outer">
                            <img src={horse} alt="horse"/>
                        </div>
                        <div className="pool-total-amount">
                            ${numberWithCommas(bettingPool.totalPool)}
                        </div>
                        <div className="pool-total-betters">
                            {totalBetters + " "} Total {" "}
                            {totalBetters === 1 ? "Better" : "Betters"}
                        </div>
                        {
                            this.state.isClosed ?
                            <div className="betting-closed">
                                Betting Closed
                            </div>
                            :
                            <div className="pool-timer">
                                {this.state.timer + " "} Remaining
                            </div>
                        }
                        {
                            bettingPool.winOption !== null ?
                            <div className="winner-container">
                                <div className="winner">
                                    <span className="winner-name">
                                    {this.getWinningOption()} {" "}
                                    </span>
                                    has won!
                                </div>
                                <div className="amount-won">
                                    You won {" "}
                                    <span className="amount-won-figure">
                                    ${numberWithCommas(winAmount)}
                                    </span>
                                    .
                                </div>
                                <div className="percentage-won">
                                    <span className="win-percent-figure">
                                    {winPercent}%
                                    </span>
                                    {" "} of the pot.
                                </div>
                            </div> : null
                        }
                        {
                            this.state.activeBet === -1 ?
                            <>
                            {
                                this.props.session.loggedin ?
                                <>
                                {
                                    this.state.isClosed ?
                                    null :
                                    <div className="prompt-place-bet">
                                        Select an option to the right to place a bet {" "}
                                        <FiArrowRightCircle style={{verticalAlign: 'middle'}}/>
                                    </div>
                                }
                                </>
                                :
                                <div className="prompt-place-bet">
                                    You must be logged in to place bets
                                </div>
                            }
                            </>
                            :
                            <div className="place-bet-form">
                                <div className="place-bet-header">
                                    Betting on {" "}
                                    <span className="place-bet-selected">
                                    {this.getOptionName(this.state.activeBet)}
                                    </span>
                                </div>
                                {
                                    currentBet > 0 ?
                                    <div className="current-bet">
                                        You are currently betting {" "}
                                        <span className="current-bet-amount">
                                        ${numberWithCommas(currentBet) + " "}
                                        </span>
                                        on this option.
                                    </div>
                                    :
                                    <div className="current-bet">
                                        You are not currently betting on this option.
                                    </div>
                                }
                                <div className="place-bet-rules">
                                    Minimum starting bet is $5,000. Once a bet is placed, it cannot be lowered or removed. You may increase a bet by a minimum of $5,000 increments to a maximum bet of $5,000,000,000. Bets may be placed on multiple options.
                                </div>
                                <div className="input-row flex flex-row center-child">
                                    <input
                                        type="text"
                                        placeholder="Set bet..."
                                        value={this.state.betAmount}
                                        onChange={(e:any) => this.handleBetAmountChange(e)} />
                                    <div
                                        className={
                                            "place-bet-submit " +
                                            (closed ? "disabled" : "")
                                        }
                                        onClick={() => this.submitBet()}>
                                        <GiTakeMyMoney style={{verticalAlign: 'middle'}}/>
                                    </div>
                                </div>
                                {
                                    this.state.betError !== "" ?
                                    <div className="bet-error">
                                        {this.state.betError}
                                    </div> : null
                                }
                            </div>
                        }
                    </div>
                    <div className="betting-pool-options flex flex-col">
                        {
                            bettingPool.open ?
                            <div className="betting-pool-options-header">
                                Choose a response:
                            </div> : null
                        }
                        {
                            bettingPool.options.map((option:IBettingOption) =>
                                <BettingPoolOption
                                    poolid={this.props.match.params.poolid}
                                    option={option}
                                    key={option.optionid}
                                    setActiveBet={(optionid:number) => this.setActiveBet(optionid)}/>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

}

const BettingPool = connect(
    mapStateToProps,
    mapDispatchToProps
)(BettingPoolBind);

export default BettingPool;
