import { handleActions } from 'redux-actions';

const benchmark = handleActions(
    {
        SET_BENCHMARK_ALL: (state:any, action:any) => ({
            index: action.payload.index,
            marketCap: action.payload.marketCap,
            totalShares: action.payload.totalShares,
            weights: action.payload.weights,
            runningHistory: action.payload.runningHistory,
            history: action.payload.history
        }),
        SET_BENCHMARK_INDEX: (state:any, action:any) => ({
            ...state,
            index: action.payload.index
        }),
        SET_BENCHMARK_MARKET_CAP: (state:any, action:any) => ({
            ...state,
            marketCap: action.payload.marketCap
        }),
        SET_BENCHMARK_TOTAL_SHARES: (state:any, action:any) => ({
            ...state,
            totalShares: action.payload.totalShares
        }),
        SET_BENCHMARK_WEIGHTS: (state:any, action:any) => ({
            ...state,
            weights: action.payload.weights
        }),
        SET_BENCHMARK_RUNNING_HISTORY: (state:any, action:any) => ({
            ...state,
            runningHistory: action.payload.runningHistory
        }),
        SET_BENCHMARK_HISTORY: (state:any, action:any) => ({
            ...state,
            history: action.payload.history
        }),
    },
    {
        index:0,
        marketCap:0,
        totalShares:0,
        weights:{},
        runningHistory:[],
        history:[]
    }
)

export { benchmark as default }

