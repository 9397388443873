import React, {Component} from 'react';
import Button from '../Button';
import Calendar from 'react-calendar';

class CreateBettingPoolState {
    topic:string;
    options:string;
    closingDate:Date;
    closingTime:string;
    error:string;
    constructor() {
        this.topic = "";
        this.options = "";
        this.closingDate = new Date();
        this.closingTime = "";
        this.error = "";
    }
}

class CreateBettingPool extends Component {

    state:CreateBettingPoolState;

    constructor(props:any) {
        super(props);
        this.state = new CreateBettingPoolState();
    }

    createAuction() {

        if(this.state.topic === '' || this.state.topic.length > 1000) return;
        if(this.state.options === '' || this.state.options.length > 1000) return;
        if(this.state.closingTime === '') return;

        const closingDate = this.state.closingDate;
        const hours = this.state.closingTime.split(":")[0];
        const minutes = this.state.closingTime.split(":")[1];
        closingDate.setHours(parseInt(hours), parseInt(minutes));

        if(closingDate.getTime() - new Date().getTime() < 1000 * 60 * 2) {
            alert("Invalid closing time. Must be at least 2 minutes.");
            return;
        }
        if(closingDate.getTime() - new Date().getTime() > 1000 * 60 * 60 * 24 * 7 * 4) {
            alert("Invalid closing time. Must be at most 28 days.");
            return;
        }

        let options:Array<string> = this.state.options.split("\n").filter((option:string) => {
            return option !== "";
        });
        let optionsSet = new Set(options);
        options = Array.from(optionsSet);

        if(options.length < 2) {
            alert("You need at least 2 options.");
            return;
        }
        if(options.length > 100) {
            alert("Max amount of options is 100.")
            return;
        }

        fetch('/api/createBettingPool/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                topic: this.state.topic,
                options,
                closingTime: closingDate.getTime()
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.setState({
                    topic:'',
                    options:'',
                    closingTime:'',
                    closingDate: new Date()
                });
            } else {
                this.setState({
                    error:data.message
                })
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })

    }

    render() {
        return(
            <div className="create-betting-pool flex flex-row">
                <div className="create-betting-pool-inner flex flex-col">

                    <div className="create-betting-pool-header">
                        Create Betting Pool
                    </div>

                    <div className="input-label">Bet Topic</div>
                    <input 
                        type="text" 
                        value={this.state.topic}
                        onChange={(e:any) => this.setState({topic:e.target.value})}/>

                    <div className="input-label">Options (Separate with new lines)</div>
                    <textarea 
                        rows={10} 
                        value={this.state.options}
                        onChange={(e:any) => this.setState({options:e.target.value})}>
                    </textarea>

                    <div className="input-label">Closing Time</div>
                    <div className="input-row flex flex-row">
                        <div className="input-col flex flex-col">
                            <Calendar 
                                calendarType="US"
                                onClickDay={(value:any) => {this.setState({closingDate:value})}}
                                defaultValue={new Date()}
                                className={"date-updater-calendar"}/>
                        
                                <input type="time" className="expiration-time-input" value={this.state.closingTime} onChange={(e:any) => {this.setState({closingTime:e.target.value})}}/>
                        </div>
                            
                        <div className="input-col button-col flex flex-col">
                            <Button className="create-pool-button" onClick={() => {this.createAuction()}}>
                                Create
                            </Button>

                            {
                                this.state.error !== "" ?
                                <div className="error">
                                    {this.state.error}
                                </div> : null
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

export default CreateBettingPool;