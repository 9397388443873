import React, {Component} from 'react';
import Button from '../Button';
import {connect} from 'react-redux';

import '../../../css/betting/betting.scss';
import CreateBettingPool from './CreateBettingPool';
import fetchData from '../../fetchData';
import { IBettingOption, IBettingPool } from '../../interfaces/IBetting';

import {bettingActions} from '../../actions/actions';
import BettingItem from './BettingItem';

const mapStateToProps = (state:any) => ({
    userinfo:state.userinfo,
    session:state.session,
    betting:state.betting
})

const mapDispatchToProps = {
    setBettingPools: bettingActions.setBettingPools,
    updatePool: bettingActions.updatePool
}

class BettingState {
    showCreate:boolean;
    constructor() {
        this.showCreate = false;
    }
}

interface IBettingProps {
    userinfo: {
        bookie:boolean;
    },
    session: {
        loggedin:boolean;
    },
    betting: {
        bettingPools: {[poolid:string]: IBettingPool}
    },

    setBettingPools: (bettingPools:any) => {},
    updatePool: (bettingPool:any) => {}
}

class BettingBind extends Component<IBettingProps> {

    state:BettingState;

    constructor(props:IBettingProps) {
        super(props);
        this.state = new BettingState();
    }

    handleToggleCreate() {
        if(!this.props.userinfo.bookie) {
            return;
        } else {
            this.setState({showCreate: !this.state.showCreate});
        }
    }

    componentDidMount() {

        if(this.props.betting.bettingPools === undefined) {

            fetchData('/api/getBettingPools')
            .then((data:any) => {
                if(data.success) {
                    this.props.setBettingPools(data.bettingPools);
                } else {
                    console.log(data.message);
                    this.props.setBettingPools({})
                }
            })
            .catch((err:any) => {
                console.log(err);
                this.props.setBettingPools({})
            })

        }

    }

    render() {

        let pools:Array<IBettingPool> = [];
        if(this.props.betting.bettingPools !== undefined) {
            Object.keys(this.props.betting.bettingPools).forEach((poolid:string) => {
                pools.push(this.props.betting.bettingPools[poolid])
            })
        }
        pools.sort((a:IBettingPool, b:IBettingPool) => {
            return a.closingTime - b.closingTime;
        });
        return(
            <div className="container betting-container">

                <div className="betting-container-header flex center-child">
                    <div className="betting-header-inner flex flex-col">
                        <div className="betting-header-title">HoloBook</div>
                        <div className="betting-header-description">Bet to win big on Hololive events!</div>
                    </div>
                </div>

                {
                    this.props.userinfo.bookie ?
                    <div className="toggle-create-form">
                        <div
                            className="toggle-create-form-btn"
                            onClick={() => this.handleToggleCreate()}>
                            Create Pool
                        </div>
                    </div> : null
                }

                {
                    this.state.showCreate ?
                    <CreateBettingPool /> : null
                }

                {
                    pools.length > 0 ?
                    <div className="betting-pools-container">
                        {
                            pools.map((pool:IBettingPool) =>
                                <BettingItem key={pool.id} bettingPool={pool}/>
                            )
                        }
                    </div>
                    :
                    <div className="no-betting-pools">
                        There are no betting pools open at the moment!
                    </div>
                }
            </div>
        )
    }
}

const Betting = connect(
    mapStateToProps,
    mapDispatchToProps
)(BettingBind);

export default Betting;
