import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import '../../../css/admin.scss';
import { adminActions, statsActions } from '../../actions/actions';

import Users from './Users';
import Filters from './Filters';
import Reports from './Reports';
import AdjustmentControls from './AdjustmentControls';
import DownloadPane from './DownloadPane';
import SpamTracker from './SpamTracker';
import DividendToggles from './DividendToggles';
import VolatilityMultipliers from './VolatilityMultipliers';
import ClearSessions from './ClearSessions';
import BrokerFee from './BrokerFee';

import ToggleSwitch from '../ToggleSwitch';
import ChatReports from './ChatReports';
import UserWallets from './UserWallets';
import BlogPermissions from './BlogPermissions';

const mapStateToProps = (state:any, props:any) => ({
    userinfo:state.userinfo,
    session:state.session,
    settings:state.settings,
    admin:state.admin
});

const mapDispatchToProps = {
    setAdminUsers: adminActions.setAdminUsers,
    setAdminFilters: adminActions.setAdminFilters,
    setAdminReports: adminActions.setAdminReports,
    setAdminAdjustmentControls: adminActions.setAdminAdjustmentControls,
    setSpamTracker: adminActions.setSpamTracker,
    setDividendToggles: adminActions.setDividendToggles,
    setVolatilityMultipliers: adminActions.setVolatilityMultipliers,
    setBrokerFee: statsActions.setBrokerFee,
    setOverbought: adminActions.setOverbought,
    setOversold: adminActions.setOversold,
    setBogrationLevel: adminActions.setBogrationLevel,
    setUpwardsReductionLevel: adminActions.setUpwardsReductionLevel,
    setBookies: adminActions.setBookies,
    setBlogPermissions: adminActions.setBlogPermissions
};

interface AdminProps {
    userinfo: {
        username:string,
        admin:boolean
    },
    session: {
        loggedin:boolean
    },
    settings: {
        marketSwitch:boolean
    },
    admin: {
        users: Array<any>,
        filters: any,
        reports: Array<any>,
        adjustmentControls: any,
        volatilityMultipliers: any
    },
    setAdminFilters: (filters:any) => {},
    setAdminReports: (reports:any) => {},
    setAdminUsers: (users:any) => {},
    setAdminAdjustmentControls: (adjustmentControls:any) => {},
    setSpamTracker: (spamTracker:any) => {},
    setDividendToggles: (dividendToggles:any) => {},
    setVolatilityMultipliers: (setVolatilityMultipliers:any) => {},
    setBrokerFee: (brokerFee:any) => {},
    setOverbought: (overbought: boolean) => {}
    setOversold: (oversold: boolean) => {}
    setBogrationLevel: (bogrationLevel: number) => {}
    setUpwardsReductionLevel: (upwardsReductionLevel: number) => {},
    setBookies: (bookies:any) => {},
    setBlogPermissions: (blogPermissions:any) => {}
}

enum AdminPanels {
    USERS,
    USER_WALLETS,
    BLOG_PERMISSIONS,
    REPORTS,
    CHAT_REPORTS,
    FILTERS,
    ADJUSTMENT_CONTROLS,
    SPAM_TRACKER,
    DIVIDEND_TOGGLES,
    VOLATILITY_MULTIPLIERS,
    BROKER_FEE,
    CLEAR_SESSIONS,
    DATA
}

class AdminState {
    active:AdminPanels
    constructor() {
        this.active = AdminPanels.USERS;
    }
}

class AdminBind extends Component<AdminProps> {
    state:AdminState;
    constructor(props:AdminProps) {
        super(props);
        this.state = new AdminState();
    }
    componentDidMount() {
        fetch('/api/getAdminInfo', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.props.setAdminUsers(data.users);
                this.props.setAdminFilters(data.filters.words);
                this.props.setAdminReports(data.reports);
                this.props.setAdminAdjustmentControls(data.adjustmentToggles);
                this.props.setSpamTracker(data.spamTracker);
                this.props.setDividendToggles(data.dividendToggles);
                this.props.setVolatilityMultipliers(data.volatilityMultipliers);
                this.props.setOverbought(data.adjustmentParams.overbought);
                this.props.setOversold(data.adjustmentParams.oversold);
                this.props.setBogrationLevel(data.adjustmentParams.bogrationLevel);
                this.props.setUpwardsReductionLevel(data.adjustmentParams.upwardsReduction);
                this.props.setBookies(data.bookies);
                this.props.setBlogPermissions(data.blogPermissions);
            } else {
                console.log(data);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }
    toggleMarketSwitch() {
        fetch('/api/setMarketSwitch', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                console.log("Error setting market switch");
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }
    marketSwitchClass() {
        return this.props.settings.marketSwitch ? "on" : "off"
    }
    setActiveView(v:number) {
        if(this.props.admin.users === undefined) return;
        this.setState({active:v});
    }
    renderPanel() {
        switch(this.state.active) {
            case AdminPanels.USERS:
                return <Users />;
            case AdminPanels.FILTERS:
                return <Filters />;
            case AdminPanels.USER_WALLETS:
                return <UserWallets />;
            case AdminPanels.BLOG_PERMISSIONS:
                return <BlogPermissions />
            case AdminPanels.ADJUSTMENT_CONTROLS:
                return <AdjustmentControls />;
            case AdminPanels.REPORTS:
                return <Reports />;
            case AdminPanels.CHAT_REPORTS:
                return <ChatReports />;
            case AdminPanels.DATA:
                return <DownloadPane />;
            case AdminPanels.DIVIDEND_TOGGLES:
                return <DividendToggles />;
            case AdminPanels.VOLATILITY_MULTIPLIERS:
                return <VolatilityMultipliers />;
            case AdminPanels.BROKER_FEE:
                return <BrokerFee />;
            case AdminPanels.CLEAR_SESSIONS:
                return <ClearSessions />;
            case AdminPanels.SPAM_TRACKER:
                return <SpamTracker />;
            default:
                return null;
        }
    }
    render() {
        if(!this.props.session.loggedin) {
            return(
                <Redirect to='/login/admin'/>
            )
        }
        if(!this.props.userinfo.admin) {
            return(
                <Redirect to='/' />
            )
        }
        const panelNames = ["Users", "User Wallets", "Blog Permissions", "Reports", "Chat Reports", "Filters", "Adjustment Controls", "Spam Tracker", "Dividend Toggles", "Volatility Multipliers", "Broker Fee", "Clear Sessions", "Data Download"];
        return(
            <div className="container fill">
                <div className="container-inner flex-col flex-stretch">
                    <div className="admin-header">
                        Admin
                    </div>
                    <div className="admin-space flex-row">
                        <div className="admin-panel-select">
                            <div className="tabbed-view-select">
                                {
                                    panelNames.map((panel:string, index:number) =>
                                    <div
                                        key={panel}
                                        className={`view-item ${index === this.state.active ? 'view-item-active' : ''}`}
                                        onClick={() => this.setActiveView(index)}>
                                        {panel}
                                    </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="admin-panel flex flex-col flex-stretch">
                            {
                                this.renderPanel()
                            }
                        </div>
                    </div>
                    <div className="market-switch flex flex-row flex-center">
                        Market Status:
                        <ToggleSwitch
                            onLabel={"OPEN"}
                            offLabel={"CLOSED"}
                            switchState={this.props.settings.marketSwitch}
                            className={""}
                            onToggle={() => this.toggleMarketSwitch()} />
                    </div>
                </div>
            </div>
        )
    }
}

const Admin = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminBind);

export default Admin;
