import React, {Component} from "react";
import { io, Socket } from 'socket.io-client'

import {
    statsActions,
    userinfoActions,
    transactionActions,
    settingsActions,
    socketActions,
    gachaActions,
    auctionsActions,
    benchmarkActions,
    bettingActions,
    chatActions,
    autotraderActions,
    mutualfundActions,
    devblogActions
} from '../actions/actions';
import { connect, ReactReduxContext } from 'react-redux';
import { ITransaction } from "../interfaces/ITransaction";
import { IUserFunds, IWallet } from "../interfaces/IWallet";
import { ICoinDataCollection, ICoinInfo } from "../interfaces/ICoinInfo";
import { UserItems, IItemCatalogue, IItem } from '../interfaces/IItem';
import checkStorage from '../checkStorage';
import { IAuctionItem, IAuctionFeedItem, IAuctionHistoryEntry, IAuctionNotificationItem } from "./auctions/IAuction";
import { IBettingPool, IUserBet } from "../interfaces/IBetting";
import { IUserRoomPreview } from "../interfaces/IChatLog";
import { IMutualFundChatLog, IMutualFundBulletinCollection, IMutualFundHistoryCollection, IMutualFunds, IMutualFundStatCollection, IMutualFund, IMutualFundStats, IMutualFundHistory, IMutualFundChatLogCollection, IMutualFundMember, IMutualFundOrder, IMutualFundOrderTotals, IMutualFundPayouts, IMutualFundsDissolve, IMutualFundPortfolio } from "../interfaces/MutualFunds";
import { IAutotrader } from "./autotrader/FundAutoTraderEditor";
import { db } from "../db";
import { IDevBlogPost } from "../interfaces/DevBlog";

const mapStateToProps = (state:any, props:any) => ({
    session: state.session,
    userinfo: state.userinfo,
    stats: state.stats,
    socket: state.socket,
    itemcatalogue: state.itemcatalogue,
    auctions: state.auctions,
    benchmark: state.benchmark,
    betting: state.betting,
    chat: state.chat,
    mutualfunds: state.mutualfunds,
    devblog: state.devblog
});

const mapDispatchToProps = {
    setStats: statsActions.setStats,
    setHistory: statsActions.setHistory,
    setCoinInfo: statsActions.setCoinInfo,
    setTodayPrices: statsActions.setTodayPrices,
    setLeaderboard: statsActions.setLeaderboard,
    setOshiboard: statsActions.setOshiboard,
    setBenchmarkLeaderboard: statsActions.setBenchmarkLeaderboard,
    addTransaction: transactionActions.addTransaction,
    setWallet: userinfoActions.setWallet,
    setMarketSwitch: settingsActions.setMarketSwitch,
    setItems: userinfoActions.setItems,
    setPerformance: userinfoActions.setPerformance,
    setSocket: socketActions.setSocket,
    removeSocket: socketActions.removeSocket,
    setReceivedItems: gachaActions.setReceivedItems,
    setBrokerTotal: statsActions.setBrokerTotal,
    setBrokerFeeTotal: userinfoActions.setBrokerFeeTotal,
    setBrokerFeeCredits: userinfoActions.setBrokerFeeCredits,
    setActiveAuctions: auctionsActions.setActiveAuctions,
    setPastAuctions: auctionsActions.setPastAuctions,
    setAuctionPriceHistory: auctionsActions.setAuctionPriceHistory,
    setAuctionFeeds: auctionsActions.setAuctionFeeds,
    setAuctionNotifications: auctionsActions.setAuctionNotifications,
    setBenchmarkIndex: benchmarkActions.setBenchmarkIndex,
    setBenchmarkMarketCap: benchmarkActions.setBenchmarkMarketCap,
    setBenchmarkTotalShares: benchmarkActions.setBenchmarkTotalShares,
    setBenchmarkWeights: benchmarkActions.setBenchmarkWeights,
    setBenchmarkRunningHistory: benchmarkActions.setBenchmarkRunningHistory,
    setBenchmarkHistory: benchmarkActions.setBenchmarkHistory,
    setBettingPools: bettingActions.setBettingPools,
    updatePool: bettingActions.updatePool,
    addGlobalChatMessage: chatActions.addGlobalChatMessage,
    setBlocking: chatActions.setBlocking,
    setBlockedBy: chatActions.setBlockedBy,
    setGlobalChat: chatActions.setGlobalChat,
    setDirectMessageRooms: chatActions.setDirectMessageRooms,
    setDirectMessageLogs: chatActions.setDirectMessageLogs,
    addDirectMessageRoom: chatActions.addDirectMessageRoom,
    addDirectMessage: chatActions.addDirectMessage,
    setNextTradeTime: autotraderActions.setNextTradeTime,
    setFunds: mutualfundActions.setFunds,
    setFundStats: mutualfundActions.setFundStats,
    setFundHistory: mutualfundActions.setFundHistory,
    setFundBulletinBoards: mutualfundActions.setFundBulletinBoards,
    setFundSocketIds: mutualfundActions.setFundSocketIds,
    setJoinRequests: mutualfundActions.setJoinRequests,
    setFundChat: mutualfundActions.setFundChat,
    setMutualFundJoinRequests: userinfoActions.setMutualFundJoinRequests,
    setFundOrderTotals: mutualfundActions.setFundOrderTotals,
    setMutualFundOrders: userinfoActions.setMutualFundOrders,
    setMutualFundCollection: userinfoActions.setMutualFundCollection,
    removeFundInfo: userinfoActions.removeFundInfo,
    removeFund: mutualfundActions.removeFund,
    setFundPayouts: mutualfundActions.setFundPayouts,
    setFundsToDissolve: mutualfundActions.setFundsToDissolve,
    setFundAutotrader: mutualfundActions.setFundAutotrader,
    setBlogPosts: devblogActions.setBlogPosts,
    addBlogPost: devblogActions.addBlogPost
}

interface SocketHandlerProps {
    session: {
        loggedin: boolean
    },
    userinfo: {
        id:string,
        wallet: IWallet,
        items: UserItems,
        username: string,
        brokerFeeTotal: number,
        brokerFeeCredits: number,
        performance:any,
        socketid: string,
        mutualfundJoinRequests: Array<string>,
        mutualfundOrders: Array<IMutualFundOrder>,
        mutualfunds: IUserFunds
    },
    stats: {
        coinInfo:ICoinDataCollection,
        coinHistory:any,
        stats:any,
        brokerTotal:number
    },
    socket: {
        socket: any,
        query: any
    },
    auctions: {
        activeAuctions: Array<IAuctionItem>,
        auctionFeeds: {},
        pastAuctions: Array<IAuctionHistoryEntry>,
        auctionPriceHistory: {},
        subscriptions: Array<string>,
        auctionNotifications: Array<IAuctionNotificationItem>
    },
    benchmark: {
        runningHistory:[],
        history:[]
    },
    betting: {
        bettingPools: Array<IBettingPool>
    },
    chat: {
        blocking:any,
        blockedBy:any,
        directMessageRooms:any,
        directMessageLogs:any,
        globalChat:any
    },
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
        bulletinboards: IMutualFundBulletinCollection,
        chat: IMutualFundChatLogCollection,
        joinRequests: {[fund:string]: Array<any>},
        socketIds: {[fund:string]:string},
        orders: IMutualFundOrderTotals,
        fundPayouts: IMutualFundPayouts,
        fundsToDissolve: IMutualFundsDissolve,
        autotraders: {[fund:string]:IAutotrader}
    },
    devblog: {
        posts: Array<IDevBlogPost>
    },
    itemcatalogue: IItemCatalogue,
    setStats: (stats:{}) => {},
    setHistory: (coinHistory:{}) => {},
    setCoinInfo: (coinInfo: {}) => {},
    setTodayPrices: (todayPrices:{}) => {},
    setLeaderboard: (leaderboard:{}) => {},
    setOshiboard: (oshiboard:{}) => {},
    
    setBenchmarkLeaderboard: (benchmarkLeaderboard:any) => {},
    
    setWallet: (wallet:any) => {},
    addTransaction: (transaction:ITransaction) => {},
    setPerformance: (performance:any) => {},
    
    setMarketSwitch: (open:boolean) => {},
    
    setItems: (items:any) => {},
    setSocket: (socket:any) => {},
    removeSocket: () => {},
    
    setReceivedItems: (receivedItems:Array<string>) => {},
    
    setBrokerTotal: (brokerTotal:number) => {},
    setBrokerFeeTotal: (brokerFeeTotal:number) => {},
    setBrokerFeeCredits: (brokerFeeCredits:number) => {}
    
    setActiveAuctions: (activeAuctions:any) => {},
    setAuctionFeeds: (auctionFeeds:any) => {},
    setPastAuctions: (pastAuctions:any) => {},
    setAuctionPriceHistory: (auctionPriceHistory:any) => {},
    setAuctionNotifications: (auctionNotifications:any) => {},
    
    setBenchmarkIndex: (index:number) => {},
    setBenchmarkMarketCap: (marketCap:number) => {},
    setBenchmarkTotalShares: (totalShares:number) => {},
    setBenchmarkWeights: (weights:any) => {},
    setBenchmarkRunningHistory: (runningHistory:any) => {},
    setBenchmarkHistory: (history:any) => {},

    setBettingPools: (bettingPools:any) => {},
    updatePool: (pool:any) => {},

    addGlobalChatMessage: (message:any) => {}
    setBlocking: (blocking:any) => {},
    setBlockedBy: (blockedBy:any) => {},
    setGlobalChat: (globalChat:any) => {},
    setDirectMessageRooms: (directMessageRooms:any) => {},
    setDirectMessageLogs: (directMessageLogs:any) => {},
    addDirectMessageRoom: (room:any) => {},
    addDirectMessage: (roomid:any, message:any) => {},

    setNextTradeTime: (nextTradeTime:number) => {},

    setFunds: (funds:IMutualFunds) => {},
    setFundStats: (fundstats:IMutualFundStatCollection) => {},
    setFundHistory: (fundhistory:IMutualFundHistoryCollection) => {},
    setFundBulletinBoards: (bulletinBoards:IMutualFundBulletinCollection) => {},
    setFundChat: (chat: IMutualFundChatLogCollection) => {},
    setJoinRequests: (joinRequests:any) => {},
    setFundSocketIds: (socketIds:any) => {},
    setFundPayouts: (fundPayouts:IMutualFundPayouts) => {},
    setFundsToDissolve: (fundsToDissolve:IMutualFundsDissolve) => {},

    setMutualFundJoinRequests: (joinRequests:any) => {},
    setFundOrderTotals: (orders:IMutualFundOrderTotals) => {},
    
    setMutualFundOrders: (orders:any) => {},
    setMutualFundCollection: (funds:any) => {},
    removeFundInfo: (fund:string) => {},
    removeFund: (fund:string) => {},
    setFundAutotrader: (fund:string, autotrader:IAutotrader) => {},

    setBlogPosts: (posts:Array<IDevBlogPost>) => {},
    addBlogPost: (post:IDevBlogPost) => {}

}

class SocketHandlerBind extends Component<SocketHandlerProps> {
	
    reconnect() {
        let s = this.props.socket.socket;
        if(s !== null) s.disconnect();

        let loggedin = false;
        if(
            this.props.session.loggedin && 
            this.props.userinfo.id !== undefined) {   
            loggedin = true;
        }

        let query = {...this.props.socket.query};
        if(loggedin) {
            query.user = this.props.userinfo.id;
            query.socketid = this.props.userinfo.socketid;
            query.fundids = JSON.stringify(this.props.mutualfunds.socketIds);
        }

        // const socketUrl = 'http://localhost:3500';
        // const socketPath = '';
        const socketUrl = 'https://nasfaq.biz';
        const socketPath = '/socket';
        let newSocket = io(socketUrl, {
            path: socketPath,
            query
        });
        
        this.listenData(newSocket);
        if(loggedin) this.listenTransaction(newSocket);

        this.props.setSocket(newSocket);
    }

    componentDidUpdate(prevProps:SocketHandlerProps) {
        if(
            prevProps.session.loggedin !== this.props.session.loggedin ||
            prevProps.socket.query !== this.props.socket.query) {
                this.reconnect();
            }

        if(this.props.session.loggedin && this.props.userinfo.id && (Object.keys(prevProps.mutualfunds.funds).length !== Object.keys(this.props.mutualfunds.funds).length)) {
            let funds = this.props.mutualfunds.funds;
            let fundsImIn:Array<string> = [];
            Object.keys(funds).forEach((fund:string) => {
                let fundInfo:IMutualFund = funds[fund];
                fundInfo.members.forEach((member:IMutualFundMember) => {
                    if(member.id === this.props.userinfo.id) {
                        fundsImIn.push(fund);
                    }
                })
            });

            fetch('/api/getMutualFundSocketIds/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    funds:fundsImIn
                })
            })
            .then(response => response.json())
            .then(data => {
                if(data.success) {
                    const socketIds = data.socketIds;
                    this.props.setFundSocketIds(socketIds);
                }
            })
            .catch(error => {
                console.error('Error: ' +  error);
            })
        }

        if(Object.keys(this.props.mutualfunds.socketIds).length !== 
            Object.keys(prevProps.mutualfunds.socketIds).length) {
                this.reconnect();
        }
    }

    listenTransaction(socket:Socket) {
        socket.on('transactionUpdate', (data:any) => {
            let transactions:Array<ITransaction> = data.transactions;
            transactions.forEach((t:ITransaction) => {
                this.props.addTransaction(t);
            })
            if(data.wallet !== null) {
                let wallet = {...this.props.userinfo.wallet};
                Object.keys(data.wallet.coins).forEach((coin:string) => {
                    wallet.coins[coin] = {...data.wallet.coins[coin]};
                });
                wallet.balance = data.wallet.balance;
                this.props.setWallet(wallet);
            }
        })
    }

    parseCoinInfo(data:any) {
        let coinInfo = {...this.props.stats.coinInfo};
        let coinData = coinInfo.data[data.coin];
        if(coinData === undefined) return;
        coinData.inCirculation = data.info.inCirculation;
        coinData.price = data.info.price;
        coinData.saleValue = data.info.saleValue;

        coinInfo.data[data.coin] = coinData;
        this.props.setCoinInfo(coinInfo);
    }

    async addHistoryItem(info:ICoinInfo) {
        let history = {...this.props.stats.coinHistory};
        let date = new Date(info.timestamp).toLocaleDateString();
        Object.keys(info.data).forEach((coin:string) => {
            if(history[coin] === undefined) {
                history[coin] = {
                    labels:[],
                    inCirculation:[],
                    price:[]
                }
            }
            history[coin].labels.push(date);
            history[coin].inCirculation.push(info.data[coin].inCirculation);
            history[coin].price.push(info.data[coin].price);
        })
        this.props.setHistory(history);

        let statsArray = await db.stats.toArray();
        if(statsArray.length > 0) {
            await db.stats.clear();
            let stats = statsArray[0];
            stats.timestamp = new Date().getTime();
            stats.history = history;
            await db.stats.add(stats);
        } else {
            let newStats = {
                timestamp:new Date().getTime(),
                stats:this.props.stats.stats, 
                history
            }
            await db.stats.add(newStats);
        }
    }

    parsePriceUpdate(data:any) {
        let coinInfo:ICoinDataCollection = {...this.props.stats.coinInfo};
        let history = [...coinInfo.data[data.coin].history];
        history.push(data.priceStamp);
        coinInfo.data[data.coin].history = history;
        this.props.setCoinInfo(coinInfo);
    }

    async appendStatisticsUpdate(data:any) {
        let oldStats:any = {...this.props.stats.stats};
        Object.keys(data).forEach((coin:string) => {
            if(oldStats[coin] === undefined) {
                oldStats[coin] = {}
                Object.keys(data[coin]).forEach((stat:string) => {
                    oldStats[coin][stat] = {
                        name:stat,
                        data:[],
                        labels:[]
                    }
                })
            }
        })
        Object.keys(oldStats).forEach((coin:string) => {
            let coinStats:any = oldStats[coin];
            Object.keys(coinStats).forEach((statType:string) => {

                let newData = data[coin][statType].data[0];
                let newLabel = data[coin][statType].labels[0];
                oldStats[coin][statType].data.push(newData);
                oldStats[coin][statType].labels.push(newLabel);

            })
        })
        this.props.setStats(oldStats);

        let statsArray = await db.stats.toArray();
        if(statsArray.length > 0) {

            await db.stats.clear();
            let stats = statsArray[0];
            stats.timestamp = new Date().getTime();
            stats.stats = oldStats;
            await db.stats.put(stats);

        } else {

            let newStats = {
                timestamp:new Date().getTime(),
                stats:oldStats, 
                history:this.props.stats.coinHistory
            }
            await db.stats.add(newStats);
        }

    }

    gachaUpdate(d:Array<string>, cashDrops:number) {
        
        let drops = d.filter((item:string) => {
            return item !== 'Nothing'
        });
        let catalogue = {...this.props.itemcatalogue};
        let userItems = {...this.props.userinfo.items};
        for(let i = 0; i < drops.length; i++) {
            let itemName = drops[i];
            let itemClass = catalogue[itemName].modifier;
            let ownsItem = false;
            if(userItems[itemClass] !== undefined) {
                userItems[itemClass].forEach((i:IItem) => {
                    if(i.itemType === itemName) {
                        i.quantity++;
                        ownsItem = true;
                    }
                })
            } else {
                userItems[itemClass] = [];
            }
            if(!ownsItem) {
                let newItem:IItem = {
                    itemType:itemName,
                    acquiredTimestamp: new Date().getTime(),
                    lastPurchasePrice:0,
                    quantity:1
                };
                userItems[itemClass].push(newItem);
            }
        }
        for(let i = 0; i < cashDrops; i++) {
            drops.push('Cash');
        }
        this.props.setReceivedItems(drops);
        this.props.setItems(userItems);
    }

    removeActiveBettingPool(poolid:string) {
        if(this.props.betting.bettingPools === undefined) {
            return;
        }
        let pools:any = {...this.props.betting.bettingPools};
        let bettingPool:IBettingPool = pools[poolid];
        if(bettingPool === undefined) {
            return;
        }
        delete pools[poolid];
        this.props.setBettingPools(pools);
    }

    listenData(socket:Socket) {
        socket.on('coinPriceUpdate', (data:any) => {
            this.parseCoinInfo(data);
        })
        
        socket.on('statisticsUpdate', (data:any) => {
            this.appendStatisticsUpdate(JSON.parse(data));
        })
        
        socket.on('coinHistoryUpdate', (data:any) => {
            this.addHistoryItem(JSON.parse(data));
        })

        socket.on('todayPricesUpdate', (data:any) => {
            this.parsePriceUpdate(JSON.parse(data));
        })

        socket.on('leaderboardUpdate', (data:any) => {
            let boards = JSON.parse(data);
            this.props.setLeaderboard(boards.leaderboard.leaderboard);
            this.props.setOshiboard(boards.oshiboard);
        })

        socket.on('oshiboardUpdate', (data:any) => {
            this.props.setOshiboard(JSON.parse(data))
        })

        socket.on('dividendUpdate', (data:any) => {
            let wallet = {...this.props.userinfo.wallet};
            wallet.balance = data.balance;
            this.props.setWallet(wallet);
        })

        socket.on('marketSwitch', (data:any) => {
            this.props.setMarketSwitch(data);
        })

        socket.on('itemsUpdate', (data:any) => {
            this.props.setItems(data);
        })

        socket.on('gachaUpdate', (data:any) => {
            this.gachaUpdate(data.drops, data.cashDrops);
        })

        socket.on('brokerFeeUpdate', (data:any) => {
            let d = JSON.parse(data);
            let amount = d.amount;
            if(d.userid === this.props.userinfo.id) {
                this.props.setBrokerFeeTotal(Math.round((this.props.userinfo.brokerFeeTotal + amount) * 100) / 100);
            }
            this.props.setBrokerTotal(Math.round((this.props.stats.brokerTotal + amount) * 100) / 100);
        })

        socket.on('creditsUpdate', (data:any) => {
            this.props.setBrokerFeeCredits(Math.round((this.props.userinfo.brokerFeeCredits + data.amount) * 100) / 100);
        })

        socket.on('walletUpdate', (data:any) => {
            let wallet = {...this.props.userinfo.wallet};
            Object.keys(data.wallet.coins).forEach((coin:string) => {
                wallet.coins[coin] = {...data.wallet.coins[coin]};
            });
            wallet.balance = data.wallet.balance;
            this.props.setWallet(wallet);
        })

        socket.on('auctionFeed', (data:any) => {

            const feedData = JSON.parse(data);
            
            const auctionID = feedData.auctionID;
            let auctionFeeds:any = {...this.props.auctions.auctionFeeds};
            let feedItem;
            if(feedData.bidLog) {
                feedItem = feedData.bidLog;
            } else {
                feedItem = feedData.messageItem;
            }
            if(auctionFeeds[auctionID]) {
                auctionFeeds[auctionID].push(feedItem);
            }
            this.props.setAuctionFeeds(auctionFeeds);

        })

        socket.on('auctionUpdate', (data:any) => {

            const message = JSON.parse(data);
            const auction:IAuctionItem = message.auction;
            const updateType:string = message.type;

            let activeAuctions = [...this.props.auctions.activeAuctions];

            if(updateType === "newAuction") {
                activeAuctions.push(auction);
            } else {
                for(let i = 0; i < activeAuctions.length; i++) {
                    if(activeAuctions[i].auctionID === auction.auctionID) {
                        if(updateType === 'removeAuction' || updateType === 'completeAuction') {
                            activeAuctions.splice(i, 1);
                        } else {
                            activeAuctions[i] = auction;
                        }
                        break;
                    }
                }
            }
            this.props.setActiveAuctions(activeAuctions);

            if(updateType === 'completeAuction') {
                let auctionHistoryEntry:IAuctionHistoryEntry = {
                    expiration:auction.expiration,
                    sellerID:auction.sellerid,
                    seller:auction.seller,
                    bidder:auction.bidder,
                    bidderID:auction.bidderid,
                    amount:auction.amount,
                    currentBid:auction.currentBid,
                    item:auction.item
                }

                let pastAuctions = [auctionHistoryEntry, ...this.props.auctions.pastAuctions];
                let auctionPriceHistory:any = {...this.props.auctions.auctionPriceHistory};

                this.props.setPastAuctions(pastAuctions);

                if(auctionPriceHistory[auction.item] === undefined) {
                    auctionPriceHistory[auction.item] = []
                }
                auctionPriceHistory[auction.item].push(auction.currentBid);
                this.props.setAuctionPriceHistory(auctionPriceHistory);
            }

            if(updateType === 'newBid') {
                if(this.props.auctions.subscriptions.indexOf(auction.auctionID) !== -1) {
                    let auctionNotification:IAuctionNotificationItem = {
                        seller:auction.seller,
                        bidder:auction.bidder,
                        amount:auction.amount,
                        bid:auction.currentBid,
                        item:auction.item,
                        timestamp: new Date().getTime()
                    }
                    let auctionNotifications:Array<IAuctionNotificationItem> = [
                        ...this.props.auctions.auctionNotifications,
                        auctionNotification
                    ];
                    this.props.setAuctionNotifications(auctionNotifications);
                }
            }

        })

        socket.on('benchmarkUpdate', (data:any) => {
            this.props.setBenchmarkIndex(data.benchmark.index);
            this.props.setBenchmarkMarketCap(data.benchmark.marketCap);
            this.props.setBenchmarkTotalShares(data.benchmark.totalShares);
            this.props.setBenchmarkWeights(data.benchmark.weights);
            
            let runningHistory = [...this.props.benchmark.runningHistory, {...data.benchmarkLog}];
            this.props.setBenchmarkRunningHistory(runningHistory);
        })

        socket.on('benchmarkLeaderboardUpdate', (data:any) => {
            this.props.setBenchmarkLeaderboard(data);
        })

        socket.on('benchmarkHistoryUpdate', (data:any) => {
            let history = [...this.props.benchmark.history, {...data}];
            this.props.setBenchmarkHistory(history);
        })

        socket.on('userWorthUpdate', (data:any) => {
            let logItem = {
                timestamp:data.timestamp,
                worth: data.networth.toString()
            }
            let performance = [...this.props.userinfo.performance, logItem];
            this.props.setPerformance(performance);
        })

        socket.on('bettingPoolUpdate', (data:any) => {
            let bettingPools = {...this.props.betting.bettingPools};
            bettingPools[data.bettingPool.id] = data.bettingPool;
            this.props.setBettingPools(bettingPools);
        })

        socket.on('userBetUpdate', (data:any) => {
            if(this.props.betting.bettingPools === undefined) {
                return;
            }
            let pools:any = {...this.props.betting.bettingPools};
            let bettingPool:IBettingPool = pools[data.poolid];
            if(bettingPool === undefined) {
                return;
            }
            let userBet:IUserBet = data.userBet;
            let betAmount = userBet.betAmount;
            let option = userBet.option;
            let userBets = bettingPool.userBets[option];
            let oldBet = 0;
            let userIndex = -1;
            userBets.forEach((bet:IUserBet, index:number) => {
                if(bet.userid === userBet.userid) {
                    oldBet = bet.betAmount;
                    userIndex = index;
                }
            })
            let increaseAmount = betAmount - oldBet;
            if(oldBet > 0) {
                userBets[userIndex].betAmount = betAmount;
            } else {
                userBets.push(userBet);
            }
            bettingPool.totalPool += increaseAmount;
            bettingPool.userBets[option] = userBets;

            this.props.updatePool(bettingPool);
            
        })

        socket.on("bettingPoolOpen", (data:any) => {
            if(this.props.betting.bettingPools === undefined) {
                return;
            }
            let pools:any = {...this.props.betting.bettingPools};
            let bettingPool:IBettingPool = pools[data.poolid];
            if(bettingPool === undefined) {
                return;
            }
            bettingPool.open = data.isOpen;
            this.props.updatePool(bettingPool);
        })

        socket.on("distributeBettingPool", (data:any) => {
            if(this.props.betting.bettingPools === undefined) {
                return;
            }
            let pools:any = {...this.props.betting.bettingPools};
            let bettingPool:IBettingPool = pools[data.poolid];
            if(bettingPool === undefined) {
                return;
            }
            bettingPool.winOption = data.winOption;
            this.props.updatePool(bettingPool);
        })

        socket.on("bettingPoolArchived", (data:any) => {
            this.removeActiveBettingPool(data.poolid);
        })

        socket.on("bettingPoolDeleted", (data:any) => {
            this.removeActiveBettingPool(data.poolid);
        })

        socket.on("poolRefunded", (data:any) => {
            if(this.props.betting.bettingPools === undefined) {
                return;
            }
            let pools:any = {...this.props.betting.bettingPools};
            let bettingPool:IBettingPool = pools[data.poolid];
            if(bettingPool === undefined) {
                return;
            }
            Object.keys(bettingPool.userBets).forEach((option:any) => {
                bettingPool.userBets[option] = [];
            });
            bettingPool.totalPool = 0;
            this.props.updatePool(bettingPool);
        })

        socket.on("addMessageGlobal", (data:any) => {
            const globalChat = [...this.props.chat.globalChat];
            globalChat.push(data);
            if(globalChat.length > 100) {
                globalChat.splice(0, 1);
            }
            this.props.setGlobalChat(globalChat);
        })

        socket.on("addMessageDM", (data:any) => {
            let {roomid, message} = data;
            let roomexists = false;
            let rooms = [...this.props.chat.directMessageRooms];
            rooms.forEach((room:IUserRoomPreview) => {
                if(room.roomid === roomid) {
                    roomexists = true;
                    room.preview = message.message;
                    room.timestamp = message.timestamp;
                }
            });
            if(!roomexists) {
                if(data.newRoom) {
                    rooms.push(data.newRoom);
                }
            }

            rooms.sort((a:IUserRoomPreview, b:IUserRoomPreview) => {
                return b.timestamp - a.timestamp;
            })
            
            let logs = {...this.props.chat.directMessageLogs};
            let log:Array<any>;
            if(logs[roomid] === undefined) {
                log = []
            } else {
                log = [...logs[roomid]]
            }
            log.push(message);
            if(log.length > 100) {
                log.splice(0, 1);
            }
            logs[roomid] = log;
            this.props.setDirectMessageRooms(rooms);
            this.props.setDirectMessageLogs(logs);

        })

        socket.on("addChatBlock", (data:any) => {
            const {blocker, blocked} = data;
            let blocking = [...this.props.chat.blocking];
            let blockedBy = [...this.props.chat.blockedBy];
            if(blocker === this.props.userinfo.id) {
                blocking.push(blocked);
                this.props.setBlocking(blocking);
            } else {
                blockedBy.push(blocker);
                this.props.setBlockedBy(blockedBy);
            }
        })

        socket.on("removeChatBlock", (data:any) => {
            const {blocker, blocked} = data;
            let blocking = [...this.props.chat.blocking];
            let blockedBy = [...this.props.chat.blockedBy];
            if(blocker === this.props.userinfo.id) {
                let index = blocking.indexOf(blocked);
                blocking.splice(index, 1);
                this.props.setBlocking(blocking);
            } else {
                let index = blockedBy.indexOf(blocker);
                blockedBy.splice(index, 1);
                this.props.setBlockedBy(blockedBy);
            }
        })

        socket.on("autoTraderTimestampUpdate", (data:any) => {
            this.props.setNextTradeTime(data + (1000 * (60 * 10 + 1)));
        })

        socket.on("newMutualFund", (data:any) => {
            const funds:IMutualFunds = {...this.props.mutualfunds.funds};
            const fundstats:IMutualFundStatCollection = {...this.props.mutualfunds.fundstats};
            const fundhistory:IMutualFundHistoryCollection  = {...this.props.mutualfunds.fundhistory};
            const newFund:IMutualFund = data.mutualFundInfo;
            const newFundStats:IMutualFundStats = data.mutualFundStats;
            const newFundHistory:IMutualFundHistory = data.mutualFundHistory;

            funds[newFund.id] = newFund;
            fundstats[newFund.id] = newFundStats;
            fundhistory[newFund.id] = newFundHistory;

            this.props.setFunds(funds);
            this.props.setFundStats(fundstats);
            this.props.setFundHistory(fundhistory);
            this.props.setFundChat({[newFund.id]:[]});
            this.props.setFundBulletinBoards({[newFund.id]:[]});
        });

        socket.on("mutualFundJoinRequestsUpdate", (data:any) => {
            const joinRequests = {...this.props.mutualfunds.joinRequests};
            if(joinRequests[data.fund] === undefined) {
                return;
            } else {
                if(data.remove) {
                    let i = -1;
                    for(let j = 0; j < joinRequests[data.fund].length; j++) {
                        if(joinRequests[data.fund][j].id === data.id) {
                            i = j
                        }
                    }
                    if(i !== -1) {
                        joinRequests[data.fund].splice(i, 1);
                    }
                } else {
                    joinRequests[data.fund].push({
                        id:data.id,
                        username:data.username
                    });
                }
                this.props.setJoinRequests(joinRequests);
            }
        });

        socket.on("mutualFundMakePublicUpdate", (data:any) => {
            let {fund} = data;
            let funds:IMutualFunds = JSON.parse(JSON.stringify({...this.props.mutualfunds.funds}));
            funds[fund].released = true;
            this.props.setFunds(funds);
        });

        socket.on("mutualFundRemoveUserRequest", (data:any) => {
            const {fund} = data;
            const joinRequests = [...this.props.userinfo.mutualfundJoinRequests];
            let i = joinRequests.indexOf(fund);
            if(i !== -1) {
                joinRequests.splice(i, 1);
                this.props.setMutualFundJoinRequests(joinRequests);
            }
        });

        socket.on("mutualFundMembersUpdate", (data:any) => {

            const {
                fund,
                type,
                username,
                userid
            } = data;

            const funds:IMutualFunds = {...this.props.mutualfunds.funds};
            const fundStats:IMutualFundStatCollection = {...this.props.mutualfunds.fundstats};
            let i = -1;
            for(let j = 0; j < funds[fund].members.length; j++) {
                if(funds[fund].members[j].id === userid) {
                    i = j;
                    break;
                }
            }
            switch(type) {
                case "add":
                    const newMember:IMutualFundMember = {
                        id:userid,
                        username,
                        boardMember:false
                    };
                    funds[fund].members.push(newMember);
                    fundStats[fund].members++;
                    break;
                case "remove":
                    if(i !== -1) {
                        funds[fund].members.splice(i, 1);
                        fundStats[fund].members--;
                    }
                    break;
                case "makeBoardMember":
                    if(i !== -1) {
                        funds[fund].members[i].boardMember = true;
                    }
                    break;
                case "removeBoardMember":
                    if(i !== -1) {
                        funds[fund].members[i].boardMember = false;
                    }
                    break;
            }

            this.props.setFunds(funds);
            this.props.setFundStats(fundStats);

        });

        socket.on("mutualFundBulletinUpdate", (data:any) => {

            const {
                fund, bulletin
            } = data;

            const bulletins = JSON.parse(JSON.stringify({...this.props.mutualfunds.bulletinboards}));
            if(bulletins[fund] !== undefined) {
                bulletins[fund] = [bulletin, ...bulletins[fund]];
                this.props.setFundBulletinBoards(bulletins);
            }

        });

        socket.on("mutualFundChatUpdate", (data:any) => {

            const {fund, message} = data;

            const chatLogs:IMutualFundChatLogCollection = JSON.parse(JSON.stringify({...this.props.mutualfunds.chat}));
            if(chatLogs[fund] !== undefined) {
                chatLogs[fund].push(message);
                this.props.setFundChat(chatLogs);
            }

        });

        socket.on("mutualFundRunningHistoryUpdate", (data:any) => {

            const fundStats:IMutualFundStatCollection = JSON.parse(JSON.stringify({...this.props.mutualfunds.fundstats}));
            Object.keys(data).forEach((fund:string) => {
                if(fundStats[fund] !== undefined) {
                    fundStats[fund].history.push(data[fund]);
                    if(fundStats[fund].history.length > 96) {
                        fundStats[fund].history.splice(0, 1);
                    }
                }
            });

            this.props.setFundStats(fundStats);

        });

        socket.on("mutualFundDailyHistoryUpdate", (data:any) => {
            
            const fundHistory:IMutualFundHistoryCollection = JSON.parse(JSON.stringify({...this.props.mutualfunds.fundhistory}));
            Object.keys(data).forEach((fund:string) => {
                if(fundHistory[fund] !== undefined) {
                    fundHistory[fund].push(data[fund]);
                }
            })

            this.props.setFundHistory(fundHistory);

        });

        socket.on("mutualFundOrderUpdate", (data:any) => {

            const {fund, buys, sells} = data;
            let orders = JSON.parse(JSON.stringify(this.props.mutualfunds.orders));
            if(orders[fund] === undefined) {
                orders[fund] = { 
                    buys,
                    sells
                }
            } else {
                orders[fund].buys += buys;
                orders[fund].sells += sells;
            }

            this.props.setFundOrderTotals(orders);
        });

        socket.on("mutualFundUserOrderUpdate", (data:any) => {

            const {fund, buys, sells} = data;
            let userOrders = JSON.parse(JSON.stringify([...this.props.userinfo.mutualfundOrders]));
            let found = false;
            for(let i = 0; i < userOrders.length; i++) {
                if(userOrders[i].fundid === fund) {
                    found = true;
                    userOrders[i].quantity += buys + (-1 * sells);
                }
            }
            
            if(!found) {
                userOrders.push({fundid:fund, quantity: (-1 * sells) + buys});
            }

            this.props.setMutualFundOrders(userOrders);
        });

        socket.on("mutualFundResetOrdersUpdate", (data:any) => {
            this.props.setFundOrderTotals({});
            this.props.setMutualFundOrders([]);
        });

        socket.on("mutualFundDissolved", (data:any) => {
            this.props.removeFundInfo(data);
            this.props.removeFund(data);
        });

        socket.on("mutualFundAddPendingDissolve", (data:any) => {
            let dissolves = {...this.props.mutualfunds.fundsToDissolve};
            dissolves[data.fund] = data.timestamp;
            this.props.setFundsToDissolve(dissolves);
        });

        socket.on("mutualFundUserFundsUpdate", (data:any) => {
            let userFunds = data.userFunds;
            this.props.setMutualFundCollection(userFunds);
        });

        socket.on("mutualFundPayoutsUpdate", (data:any) => {
            this.props.setFundPayouts(data);
        });

        socket.on("mutualFundStatUpdate", (data:any) => {
            let stats:any = data.funds;
            let fundstats = {...this.props.mutualfunds.fundstats};
            Object.keys(stats).forEach((fund:string) => {
                fundstats[fund] = {
                    history: fundstats[fund].history,
                    ...stats[fund]
                }
            });
            this.props.setFundStats(fundstats);
        });

        socket.on("mutualFundBalanceUpdate", (data:any) => {
            let fundinfo = {...this.props.mutualfunds.funds};
            Object.keys(data.funds).forEach((fund:string) => {
                fundinfo[fund].balance = data.funds[fund];
            });
            this.props.setFunds(fundinfo);
        });

        socket.on("mutualFundFeeRateUpdate", (data:any) => {
            let fundinfo = {...this.props.mutualfunds.funds};
            if(fundinfo[data.fund] !== undefined) {
                fundinfo[data.fund].fee = data.fee;
                this.props.setFunds(fundinfo);
            }
        });

        socket.on("mutualFundShareholderRateUpdate", (data:any) => {
            let fundinfo = {...this.props.mutualfunds.funds};
            if(fundinfo[data.fund] !== undefined) {
                fundinfo[data.fund].shareholderDivRate = data.rate;
                this.props.setFunds(fundinfo);
            }
        });

        socket.on("mutualFundCeoRateUpdate", (data:any) => {
            let fundinfo = {...this.props.mutualfunds.funds};
            if(fundinfo[data.fund] !== undefined) {
                fundinfo[data.fund].ceoDivRate = data.rate;
                this.props.setFunds(fundinfo);
            }
        });

        socket.on("mutualFundPortfolioUpdate", (data:any) => {

            let {fund, tUpdate} = data;
            let transactions:Array<ITransaction> = tUpdate.transactions;

            let funds:IMutualFunds = JSON.parse(JSON.stringify({...this.props.mutualfunds.funds}));

            if(this.props.session.loggedin) {
                if(funds[fund] !== undefined) {
                    let members = funds[fund].members;
                    let isMember = members.findIndex((m:IMutualFundMember) => {
                        return m.id === this.props.userinfo.id;
                    });
                    if(isMember !== -1) {
                        transactions.forEach((t:ITransaction) => {
                            this.props.addTransaction(t);
                        })
                    }
                }
            }

            let portfolio:IMutualFundPortfolio = tUpdate.portfolio;
            if(portfolio !== null) {
                Object.keys(portfolio).forEach((coin:string) => {
                    funds[fund].portfolio[coin] = {...portfolio[coin]};
                });
                this.props.setFunds(funds);
            }
        })

        socket.on("mutualFundAutotraderUpdate", (data:any) => {

            let { fund, autotrader } = data;
            
            if(this.props.mutualfunds.autotraders[fund] === undefined) return;

            let currentAutotrader:IAutotrader = JSON.parse(JSON.stringify(this.props.mutualfunds.autotraders[fund]));
            if(autotrader.running === null) {
                currentAutotrader.rules = autotrader.rules;
            } else {
                currentAutotrader.running = autotrader.running;
            }

            this.props.setFundAutotrader(fund, currentAutotrader);

        });

        socket.on("mutualFundAutotraderTimestampUpdate", (data:any) => {

            let { fund, timestamp } = data;
            
            if(this.props.mutualfunds.autotraders[fund] === undefined) return;
            let currentAutotrader:IAutotrader = JSON.parse(JSON.stringify(this.props.mutualfunds.autotraders[fund]));
            currentAutotrader.nextTradeTime = timestamp + (1000 * (60 * 10 + 1));

            this.props.setFundAutotrader(fund, currentAutotrader);

        });

        socket.on("mutualFundUpdateInfo", (data:any) => {

            let {
                fundid,
                name,
                color,
                description,
                icon
            } = data;

            let funds:IMutualFunds = JSON.parse(JSON.stringify({...this.props.mutualfunds.funds}));
            funds[fundid].name = name;
            funds[fundid].color = color;
            funds[fundid].missionStatement = description;
            funds[fundid].icon = icon;

            this.props.setFunds(funds);

        });

        socket.on("newBlogPost", (data:any) => {
            this.props.addBlogPost(data.blogPost);
        });

        socket.on("updateBlogPost", (data:any) => {
            let posts:Array<IDevBlogPost> = JSON.parse(JSON.stringify(this.props.devblog.posts));
            for(let post of posts) {
                if(post.id === data.id) {
                    post.post = data.post;
                    post.edited = data.timestamp;
                    post.title = data.title;
                    post.signature = data.signature;
                    break;
                }
            }
            this.props.setBlogPosts(posts);
        });

        socket.on("removeBlogPost", (data:any) => {
            let posts:Array<IDevBlogPost> = JSON.parse(JSON.stringify(this.props.devblog.posts));
            let newPosts = [];
            for(let post of posts) {
                if(post.id !== data.id) {
                    newPosts.push(post);
                }
            }
            this.props.setBlogPosts(newPosts);
        });

    }

    componentDidMount() {
        this.reconnect();
    }

    componentWillUnmount() {
		let s = this.props.socket.socket;
		if(s !== null) s.disconnect();
        this.props.removeSocket();
    }

    render() {
        return <div></div>;
    }
}

const SocketHandler = connect(
	mapStateToProps,
	mapDispatchToProps
)(SocketHandlerBind);

export default SocketHandler;
