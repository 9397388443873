import React, {Component} from 'react';
import fetchData from '../../fetchData';

import {connect} from 'react-redux';
import { adminActions } from '../../actions/actions';

import "../../../css/admin/chatreports.scss";

const mapStateToProps = (state:any) => ({
    admin:state.admin
});

const mapDispatchToProps = {
    setChatReports: adminActions.setChatReports
}

interface ChatReportsProps {
    admin: {
        chatReports: any
    },
    setChatReports: (chatReports:any) => {}
}

class ChatReportsBind extends Component<ChatReportsProps> {

    componentDidMount() {
        
        fetchData('/api/getChatReports')
        .then((data:any) => {
            if(data.reports) {
                this.props.setChatReports(data.reports);
            }
        })
    }

    deleteReport(id:string) {
        fetch('/api/removeChatReport/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                reportid:id
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                alert(data.message);
                return;
            } else {
                let reports = [...this.props.admin.chatReports];
                let i = -1;
                reports.forEach((r:any, index:number) => {
                    if(r.reportid === id) {
                        i = index; 
                    }
                })
                if(i !== -1) {
                    reports.splice(i, 1);
                }
                this.props.setChatReports(reports);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    render() {
        return(
            <div className="chat-reports-outer">
                <div className="chat-reports-header">
                    Chat Reports
                </div>
                <div className="chat-reports-description">
                    Reports made from the global chat.
                </div>
                <div className="chat-reports-container">
                    {
                        this.props.admin.chatReports.map((report:any, index:number) => 
                            <div className="chat-report-item" key={index}>
                                <div className="chat-report-row">
                                    <span className="chat-report-label">Report ID:</span>
                                    {report.reportid}
                                </div>
                                <div className="chat-report-row">
                                    <span className="chat-report-label">Author:</span>
                                    {report.author}
                                </div>
                                <div className="chat-report-row">
                                    <span className="chat-report-label">Author Name:</span>
                                    {report.authorname}
                                </div>
                                <div className="chat-report-row">
                                    <span className="chat-report-label">Creator:</span>
                                    {report.creator}
                                </div>
                                <div className="chat-report-row">
                                    <span className="chat-report-label">Creator Name:</span>
                                    {report.creatorname}
                                </div>
                                <div className="chat-report-row">
                                    <span className="chat-report-label">Time Reported:</span>
                                    {new Date(report.timereported).toLocaleString()}
                                </div>
                                <div className="chat-report-row">
                                    <span className="chat-report-label">Message Time:</span>
                                    {new Date(JSON.parse(report.message).timestamp).toLocaleString()}
                                </div>
                                <div className="chat-report-message">
                                    {JSON.parse(report.message).message}
                                </div>

                                <div 
                                    className="delete-report"
                                    onClick={() => this.deleteReport(report.reportid)}>
                                    Delete
                                </div>
                            </div>
                        
                        )
                    }
                </div>
            </div>
        )
    }

}

const ChatReports = connect(mapStateToProps, mapDispatchToProps)(ChatReportsBind);

export default ChatReports;