import React, {Component} from 'react';
import {connect} from 'react-redux';
import { IMutualFundUserTotal } from '../../../interfaces/MutualFunds';
import numberWithCommas from '../../../numberWithCommas';
import Coin from '../../Coin';
import MutualFundSection from './MutualFundSection';
import '../../../../css/mutualfunds/mutualfundshareholders.scss';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds,
    stats:state.stats
});

const mapDispatchToProps = {

}

interface IMutualFundShareholdersProps {
    mutualfunds: {
        fundUserTotals: {[fund:string]: Array<IMutualFundUserTotal>}
    },
    fund:string
}

class MutualFundShareholdersBind extends Component<IMutualFundShareholdersProps> {
    render() {
        if(this.props.mutualfunds.fundUserTotals[this.props.fund] === undefined) return null;
        return(
            <MutualFundSection fund={this.props.fund}>
                <div className="section-header">
                    Shareholders
                </div>
                <div className="shareholders-container flex flex-row">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Icon</th>
                                <th>Username</th>
                                <th>Shares Owned</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.mutualfunds.fundUserTotals[this.props.fund].map((userTotal:IMutualFundUserTotal, i:number) =>
                                    <tr key={i}>
                                        <td>{i + 1}.</td>
                                        <td><Coin name={userTotal.icon}/></td>
                                        <td>{userTotal.username}</td>
                                        <td>{numberWithCommas(userTotal.quantity)}</td>
                                    </tr>
                                
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </MutualFundSection>
        );
    }
}

const MutualFundShareholders = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundShareholdersBind);

export default MutualFundShareholders;