import React, {Component} from 'react';
import {connect} from 'react-redux';
import { adminActions } from '../../actions/actions';
import postRequest from '../../postRequest';
import "../../../css/admin/blogpermissions.scss";

import {TiDeleteOutline} from 'react-icons/ti';

const mapStateToProps = (state:any, props:any) => ({
    admin:state.admin
});

const mapDispatchToProps = {
    setBlogPermissions: adminActions.setBlogPermissions,
};

class BlogPermissionsState {
    userid:string = "";
    error:string = "";
}

interface BlogPermissionsProps {
    admin: {
        blogPermissions: Array<any>
    },
    setBlogPermissions: (blogPermissions:any) => {}
}

class BlogPermissionsBind extends Component<BlogPermissionsProps> {
    state:BlogPermissionsState;
    constructor(props:BlogPermissionsProps) {
        super(props);
        this.state = new BlogPermissionsState();
    }
    addUser() {
        if(this.state.userid === "") return;
        if(this.props.admin.blogPermissions.findIndex((user:any) => {
            return user.userid === this.state.userid;
        }) !== -1) {
            this.setState({error:"User already has permission."});
            return;
        }
        postRequest("/api/addBlogPermission", {
            requestedUser:this.state.userid
        }).then((data:any) => {
            if(!data.success) {
                this.setState({error:data.message});
                return;
            }        
            this.setState({error:""});
            let permissions = JSON.parse(JSON.stringify(this.props.admin.blogPermissions));
            permissions.push({
                userid:this.state.userid,
                username:data.username
            })
            this.props.setBlogPermissions(permissions);
        }).catch((err:any) => {
            console.log(err);
        });
    }
    removeUser(userid:string) {
        postRequest("/api/removeBlogPermission", {
            requestedUser:userid
        }).then((data:any) => {
            if(!data.success) {
                this.setState({error:data.message});
                return;
            }
            let permissions = JSON.parse(JSON.stringify(this.props.admin.blogPermissions))
                .filter((user:any) => {
                return user.userid !== userid;
            });
            this.props.setBlogPermissions(permissions);
        }).catch((err:any) => {
            console.log(err);
        });
    }
    render() {
        return(
            <div className="admin-users-pane flex-col flex-stretch">
                <div className="control-header">
                    Blog Permissions
                </div>
                <div className="control-description">
                    Users with permission to make and edit posts on the developer blog.
                </div>
                <div className="form-container">
                    <input 
                        type="text" 
                        placeholder="User ID"
                        value={this.state.userid} 
                        onChange={(e) => {this.setState({userid:e.target.value})}}/>
                    <div
                        onClick={() => this.addUser()} 
                        className="simple-button">
                        Add User
                    </div>
                </div>
                <div className="permissions-container">
                {
                    this.state.error !== "" ?
                    <div className="error">{this.state.error}</div> : null
                }
                {
                    this.props.admin.blogPermissions.length === 0 ?
                    <div className="no-permissions">
                        No users have blog permissions.
                    </div>
                    :
                    <table>
                        <tr>
                            <th>User ID</th>
                            <th>Username</th>
                            <th></th>
                        </tr>
                        {
                            this.props.admin.blogPermissions.map((row:any) =>   
                                <tr key={row.userid}>
                                    <td>{row.userid}</td>
                                    <td>{row.username}</td>
                                    <td>
                                        <div
                                            onClick={() => this.removeUser(row.userid)}
                                            className="remove-permissions">
                                            <TiDeleteOutline style={{verticalAlign:'middle'}}/>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    </table>
                }
                </div>
            </div>
        )
    }
}

const BlogPermissions = connect(
    mapStateToProps,
    mapDispatchToProps
)(BlogPermissionsBind);

export default BlogPermissions;


