import React, {Component} from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import "../../../css/leaderboard/halloweeneventstats.scss";
import fetchData from '../../fetchData';

interface HalloweenEventResultsProps {
    closeWindow:() => void
}

class HalloweenEventResultsState {
    data:any = {};
    error:string = "";
}

class HalloweenEventResults extends Component<HalloweenEventResultsProps> {

    state:HalloweenEventResultsState;
    constructor(props:HalloweenEventResultsProps) {
        super(props);
        this.state = new HalloweenEventResultsState();
    }

    componentDidMount() {
        fetchData("/api/getHalloweenEventStats")
        .then((data:any) => {
            this.setState({data:data.stats})
        })
        .catch((err:any) => {
            console.log(err);
        });
    }

    renderCategory(category:string) {

        let {
            title,
            description,
            amount,
            users
        } = this.state.data[category];

        return(
            <div className="event-category">
                <div className="event-category-header">
                    {title}
                </div>
                <div className="event-category-description">
                    {description}
                </div>
                <div className="event-category-amount">
                    Treat count: <span>{amount}</span>
                </div>
                <div className="event-category-users">
                    {
                        users.map((user:string) =>
                            <div className="user-winner-container">
                            {user}
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    render() {
        return(
            <div className="halloween-event-results flex flex-column">
                <div className="halloween-event-results-header">
                    Halloween 2023 Event Results
                    <div
                        onClick={() => this.props.closeWindow()} 
                        className="close-window">
                        <AiFillCloseCircle/>
                    </div>
                </div>
                {
                    Object.keys(this.state.data).map((category:string) =>
                        this.renderCategory(category)
                    )
                }
            </div>
        )
    }

}

export default HalloweenEventResults;
