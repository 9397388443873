import React, {Component} from 'react';
import {AiFillCaretDown} from 'react-icons/ai';

interface ReportButtonProps {
    id:number
}

class ReportButtonState {
    showReport:boolean;
    showResult:boolean;
    result:string;
    constructor() {
        this.showReport = false;
        this.showResult = false;
        this.result = "";
    }
}

class ReportButton extends Component<ReportButtonProps> {
    state:ReportButtonState;
    constructor(props:ReportButtonProps) {
        super(props);
        this.state = new ReportButtonState();
    }
    report() {
        if(this.state.showResult) return;
        fetch('/api/addChatReport/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                messageid:this.props.id
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                this.setState({showResult:true, result:data.message});
            } else {
                this.setState({showResult:true, result:"Success."});
            }
            setTimeout(() => {
                this.setState({showResult:false, showReport:false, result: ""});
            }, 2000);
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }
    toggleAction() {
        this.setState({showReport:!this.state.showReport});
    }
    render() {
        return(
            <div className="report-button">
                <span 
                    className="report-carat"
                    onClick={() => this.toggleAction()}>
                    <AiFillCaretDown style={{verticalAlign: 'middle'}}/>
                </span>
                {
                    this.state.showReport ?
                    <div 
                        className="report-action"
                        onClick={() => this.report()}>
                        {
                            this.state.showResult ? this.state.result : "Report"
                        }
                    </div> : null
                }
            </div>
        )
    }
}

export default ReportButton;