import React, {Component} from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
    IoIosArrowDown,
    IoIosArrowUp
} from 'react-icons/io';
import { Order, TransactionType } from '../../interfaces/ITransaction';
import numberWithCommas from '../../numberWithCommas';
import Coin from '../Coin';

import {
    BiPlus,
    BiMinus
} from 'react-icons/bi';

interface TradeRuleItemProps {
    pendingOrder:Array<Order>,
    coin:string,
    price:number,
    saleValue:number,
    volume:number,
    quantity:number,
    stepQuantity:number,
    meanPurchasePrice:number,
    balance:number,
    targetQuantity:number,
    timestamp:number,
    type:TransactionType,
    index:number,
    isFund:boolean,
    setTargetQuantity:(index:number, quantity:number) => void,
    setStepQuantity:(index:number, quantity:number) => void,
}

class TradeRuleItemState {
    remainingTime:number;
    constructor(){
        this.remainingTime = 0;
    }
}

type inputEvent = React.ChangeEvent<HTMLInputElement>;

class TradeRuleItem extends Component<TradeRuleItemProps> {

    intervalId:any;
    state:TradeRuleItemState;
    constructor(props:TradeRuleItemProps) {
        super(props);
        this.state = new TradeRuleItemState();
    }
    handleAmt(a:inputEvent) {
        let amt:number;
        if(a.target.value.length === 0) {
            amt = 0;
        } else {
            let textv:string = a.target.value;
            if(textv.slice(-1) === ".") textv = textv + "0";
            amt = parseFloat(textv);
        }
        if(isNaN(amt)) amt = 0;
        if(amt < 0) amt = -1 * amt;
        amt = Math.round(amt);
        this.props.setTargetQuantity(this.props.index, amt);
    }

    increment(n:number) {
        let target = this.props.targetQuantity;
        target += n;
        if(target < 0) target = 0;
        if(target > 9999999) target = 9999999;
        this.props.setTargetQuantity(this.props.index, target);
    }

    incrementStep(n:number) {
        let step = this.props.stepQuantity;
        step += n;
        if(step < 1) step = 1;
        let max = this.props.isFund ? 100 : 10;
        if(step > max) step = max;
        this.props.setStepQuantity(this.props.index, step);
    }

    handleStepQuantity(e:any) {
        let step = parseInt(e.target.value);
        if(isNaN(step)) {
            step = 1;
        }
        let max = this.props.isFund ? 100 : 10;
        if(step > max) step = max;
        if(step < 1) step = 1;
        this.props.setStepQuantity(this.props.index, step);
    }

    getCooldownWidth() {
        let total = 1000 * 60 * 10;
        return (this.state.remainingTime / total) * 100;
    }

    setRemainingTime() {
        let targetTime = this.props.timestamp + (1000 * 60 * 10);
        let remaining = targetTime - new Date().getTime();
        if(remaining < 0) {
            remaining = 0;
        }
        this.setState({
            remainingTime:remaining
        })
    }

    componentDidMount() {
        this.setRemainingTime();
        this.intervalId = setInterval(() => {
            this.setRemainingTime();
        }, 4000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    filterName(coin:string) {
        return coin === 'himemoriluna' ? 'luna' : coin;
    }

    renderTradeRuleType() {
        if(this.props.quantity === this.props.targetQuantity) {
            return <div className="trade-rule-done">DONE</div>
        } else {
            if(this.props.quantity < this.props.targetQuantity) {
                return(
                    <div className={`trade-rule-buy`}>
                        BUY</div>
                )
            } else {
                return(
                    <div className={`trade-rule-sell `}>
                    SELL</div>
                )
            }
        }
    }

    renderTradeTax() {
        if(this.props.quantity === this.props.targetQuantity) {
            return 0
        } else {
            if(this.props.quantity < this.props.targetQuantity) {
              if(this.props.stepQuantity > (this.props.targetQuantity-this.props.quantity)) {
                if(this.props.isFund) {
                  if((this.props.targetQuantity-this.props.quantity) < 51) {
                    return 10
                  } else {
                    return (this.props.targetQuantity-this.props.quantity) * 0.008 - 0.3
                  }
                } else {
                  return (this.props.targetQuantity-this.props.quantity) * 4.5
                }
              } else {
                if(this.props.isFund) {
                  if(this.props.stepQuantity < 51) {
                    return 10
                  } else {
                    return (this.props.stepQuantity * 0.008 - 0.3) * 100
                  }
                } else {
                  return this.props.stepQuantity * 4.5
                }
              }
            } else {
              if((((this.props.quantity-this.props.targetQuantity) === 1) || (this.props.stepQuantity === 1)) && (this.props.isFund===false)) {
                return 0
              }
              if(this.props.stepQuantity > (this.props.quantity-this.props.targetQuantity)) {
                if(this.props.isFund) {
                  if((this.props.quantity-this.props.targetQuantity) < 51) {
                    return 10
                  } else {
                    return (this.props.quantity-this.props.targetQuantity) * 0.008 - 0.3
                  }
                } else {
                  return (this.props.quantity-this.props.targetQuantity) * 3
                }
              } else {
                if(this.props.isFund) {
                  if(this.props.stepQuantity < 51) {
                    return 10
                  } else {
                    return (this.props.stepQuantity * 0.008 - 0.3) * 100
                  }
                } else {
                  return this.props.stepQuantity * 3
                }
              }
            }
        }
    }

    render() {
        let className = "trade-rule-item";
        let buying = false;
        let selling = false;
        if(this.props.quantity === this.props.targetQuantity) {
            className += " trade-rule-neutral";
        } else {
            if(this.props.targetQuantity > this.props.quantity) {
                className += " trade-rule-buy";
                buying = true;
            } else {
                className += " trade-rule-sell";
                selling = true;
            }
        }

        let stepTax = 0;
        let quanMult = 1;

        if(this.props.isFund) {
          quanMult = 10;
          if(buying) {
            stepTax = this.props.price;
            if((this.props.stepQuantity < 51) || ((this.props.targetQuantity-this.props.quantity) < 51)) {
              stepTax = stepTax * 1.1;
              if(((this.props.targetQuantity-this.props.quantity) < 51) && ((this.props.targetQuantity-this.props.quantity) < this.props.stepQuantity)) {
                stepTax = stepTax * (this.props.targetQuantity-this.props.quantity);
              } else {
                stepTax = stepTax * this.props.stepQuantity;
              }
            } else {
              if(this.props.stepQuantity > (this.props.targetQuantity-this.props.quantity)) {
                stepTax += stepTax * ((this.props.targetQuantity-this.props.quantity) * 0.008 - 0.3);
                stepTax = stepTax * (this.props.targetQuantity-this.props.quantity);
              } else {
                stepTax += stepTax * (this.props.stepQuantity * 0.008 - 0.3);
                stepTax = stepTax * this.props.stepQuantity;
              }
            }
          } else {
            if(selling) {
              stepTax = this.props.saleValue;
              if((this.props.stepQuantity < 51) || ((this.props.quantity-this.props.targetQuantity) < 51)) {
                stepTax -= stepTax * 0.1;
                if(((this.props.quantity-this.props.targetQuantity) < 51) && ((this.props.quantity-this.props.targetQuantity) < this.props.stepQuantity)) {
                  stepTax = stepTax * (this.props.quantity-this.props.targetQuantity);
                } else {
                  stepTax = stepTax * this.props.stepQuantity;
                }
              } else {
                if(this.props.stepQuantity > (this.props.quantity-this.props.targetQuantity)) {
                  stepTax -= stepTax * ((-this.props.quantity-this.props.targetQuantity) * 0.008 - 0.3);
                  stepTax = stepTax * (this.props.quantity-this.props.targetQuantity);
                }
                else {
                  stepTax -= stepTax * (this.props.stepQuantity * 0.008 - 0.3);
                  stepTax = stepTax * this.props.stepQuantity;
                }
              }
            }
          }
        } else {
          if(buying) {
            stepTax = this.props.price;
            if(this.props.stepQuantity > (this.props.targetQuantity-this.props.quantity)) {
              stepTax += (stepTax * ((4.5 * (this.props.targetQuantity-this.props.quantity))/100));
              stepTax = stepTax * (this.props.targetQuantity-this.props.quantity);
            } else {
              stepTax += stepTax * ((4.5 * this.props.stepQuantity)/100);
              stepTax = stepTax * this.props.stepQuantity;
            }
          } else {
            if(selling) {
              stepTax = this.props.saleValue;
              if(this.props.stepQuantity > (this.props.quantity-this.props.targetQuantity)) {
                stepTax -= (stepTax * ((3 * (this.props.quantity-this.props.targetQuantity))/100));
                stepTax = stepTax * (this.props.quantity-this.props.targetQuantity);
              } else {
                if(this.props.stepQuantity === 1) {
                  stepTax = stepTax * this.props.stepQuantity;
                } else {
                  stepTax -= (stepTax * ((3 * this.props.stepQuantity)/100));
                  stepTax = stepTax * this.props.stepQuantity;
                }
              }
            }
          }
        }
        stepTax = (Math.round(stepTax * 100) / 100);

        let tradingTax = this.renderTradeTax();
        tradingTax = (Math.round(tradingTax * 100) / 100);


        let inOrder = false;
        for(let i = 0; i < this.props.pendingOrder.length; i++) {
            let coin = this.props.pendingOrder[i].coin;
            if(coin === this.props.coin) {
                inOrder = true;
                break;
            }
        }
        if(!inOrder)
            className += " trade-rule-disabled";

        return(
            <Draggable
                draggableId={this.props.coin}
                index={this.props.index}
                key={this.props.coin}>

                {(providedDrag:any) => (
                <div
                    className={className}
                    {...providedDrag.draggableProps}
                    ref={providedDrag.innerRef}>
                    <div className="trade-rule-item-content">
                        <div
                            className="trade-priority flex flex-col flex-center"
                            {...providedDrag.dragHandleProps}>
                            <IoIosArrowUp />
                            <IoIosArrowDown />
                        </div>
                        <div className="trade-rule-coin">
                            <Coin name={this.filterName(this.props.coin)}/>
                        </div>
                        <div
                            className="trade-rule-info"
                            id="trade-rule-info-price"
                            title={"Mean purchase price: $" + numberWithCommas(this.props.meanPurchasePrice)}>
                            <div className="trade-rule-info-label">Current Price</div>
                            <div className="trade-rule-price">${numberWithCommas(this.props.price)}</div>
                        </div>
                        <div
                            className="trade-rule-info"
                            id="trade-rule-info-saleval"
                            title={"Mean purchase price: $" + numberWithCommas(this.props.meanPurchasePrice)}>
                            <div className="trade-rule-info-label">Sale Value</div>
                            <div className="trade-rule-price">${numberWithCommas(this.props.saleValue)}</div>
                        </div>
                        <div
                            className="trade-rule-info" id="trade-rule-info-volume">
                            <div className="trade-rule-info-label">Volume</div>
                            <div className="trade-rule-volume">{numberWithCommas(this.props.volume)}</div>
                        </div>
                        <div className="trade-rule-info" id="trade-rule-info-owned">
                            <div className="trade-rule-info-label">Owned</div>
                            <div className="trade-rule-quant">{this.props.quantity}</div>
                        </div>
                        <div className="trade-rule-type">
                            {this.renderTradeRuleType()}
                        </div>
                        <div className="trade-rule-step-quant">
                            <div className="trade-rule-step-label">Step Quantity</div>
                            <div className="step-outer">
                                <div className="step-quant flex center-child">
                                    <input
                                        onChange={(e) => this.handleStepQuantity(e)}
                                        value={this.props.stepQuantity}/>
                                </div>
                                <div className="step-change-quant-outer">
                                    <div className="step-increase" onClick={() => this.incrementStep(1*quanMult)}><BiPlus/></div>
                                    <div className="step-decrease" onClick={() => this.incrementStep(-1*quanMult)}><BiMinus/></div>
                                </div>
                            </div>
                        </div>
                        <div className="trade-tax-rate">
                          Tax: {tradingTax}%
                        </div>
                        <div className="trade-rule-target">
                            <div className="trade-rule-target-label">Target Quantity</div>
                            <div className="target-input">
                                <div className="decrement" title="Subtract All" onClick={() => this.increment(-9999999)}><BiMinus/></div>
                                <div className="decrement" title="Subtract 100" onClick={() => this.increment(-100*quanMult)}><BiMinus/></div>
                                <div className="decrement" title="Subtract 10" onClick={() => this.increment(-10*quanMult)}><BiMinus/></div>
                                <div className="decrement" title="Subtract 1" onClick={() => this.increment(-1*quanMult)}><BiMinus/></div>
                                <input
                                    type="text"
                                    maxLength={7}
                                    value={this.props.targetQuantity}
                                    onChange={(
                                        ev: inputEvent
                                    ): void => this.handleAmt(ev)}/>
                                <div className="increment"
                                title="Add 1" onClick={() => this.increment(1*quanMult)}><BiPlus/></div>
                                <div className="increment" title="Add 10" onClick={() => this.increment(10*quanMult)}><BiPlus/></div>
                                <div className="increment" title="Add 100" onClick={() => this.increment(100*quanMult)}><BiPlus/></div>
                                <div className="increment" title="Add Max" onClick={() => this.increment(9999999)}><BiPlus/></div>
                            </div>
                        </div>
                        <div className="trade-rule-total">
                          <div
                              className="trade-rule-info">
                              <div className="trade-rule-info-label">Transaction Total</div>
                              <div className="trade-rule-price">${numberWithCommas(stepTax)}</div>
                          </div>
                        </div>
                    </div>
                    <div
                        className="trade-rule-item-cooldown"
                        style={{
                            width:this.getCooldownWidth() + "%"
                        }}>
                    </div>
                </div>
                )}
            </Draggable>
        )
    }
}

export default TradeRuleItem;
