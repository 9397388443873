import React, {Component} from 'react';
import {connect} from 'react-redux';
import getForegroundFromBackground from '../../getForegroundFromBackground';
import hexToRgb from '../../hexToRBG';
import { IUserFunds } from '../../interfaces/IWallet';
import { IMutualFunds } from '../../interfaces/MutualFunds';
import numberWithCommas from '../../numberWithCommas';
import Coin from '../Coin';

import "../../../css/profile/profilemutualfunds.scss";
import { Link } from 'react-router-dom';

const mapStateToProps = (state:any) => ({
    userinfo:state.userinfo,
    session:state.session,
    mutualfunds:state.mutualfunds
})

interface ProfileMutualFundContainerProps {
    userinfo: {
        mutualfunds: IUserFunds
    },
    mutualfunds: {
        funds: IMutualFunds
    },
    session: {
        loggedin:boolean
    }
}

class ProfileMutualFundContainerBind extends Component<ProfileMutualFundContainerProps> {

    getColor(fund:string) {
        let f = this.props.mutualfunds.funds[fund];
        if(f === undefined) return "#000000";
        return "#" + f.color;
    }

    getForegroundColor(fund:string) {
        let {r, g, b} = hexToRgb(this.getColor(fund));
        return getForegroundFromBackground(r, g, b);
    }

    render() {
        if(!this.props.session.loggedin) return null;
        let funds = Object.keys(this.props.userinfo.mutualfunds);
        funds = funds.filter((f:string) => {
            return this.props.userinfo.mutualfunds[f].amt > 0;
        });
        return(
            <div className="container-section">
                <div className="section-background"></div>
                <div className="section-content">
                    <div className="header">
                        Mutual Funds
                    </div>
                    <div className="mutual-funds-container flex flex-row">
                        {
                            funds.map((fund:string) => 
                                <Link to={"/mutualfund/" + fund}>
                                <div className="user-mutual-fund-item flex flex-row">
                                    <div 
                                        style={{
                                            "backgroundColor":this.getColor(fund),
                                            "color":this.getForegroundColor(fund)
                                        }}
                                        className="mutual-fund-item-container flex flex-row">
                                        <Coin name={this.props.mutualfunds.funds[fund].icon} />
                                        <div className="mutual-fund-item-name">
                                            {this.props.mutualfunds.funds[fund].name}
                                        </div>
                                    </div>
                                    <div className="mutual-fund-item-amount">
                                        {numberWithCommas(this.props.userinfo.mutualfunds[fund].amt)}
                                    </div>
                                </div>
                                </Link>
                            )
                        }
                        {
                            funds.length === 0 ?
                            <div className="no-funds">You don't own any mutual fund shares.</div> : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const ProfileMutualFundContainer = connect(mapStateToProps)(ProfileMutualFundContainerBind);
export default ProfileMutualFundContainer;