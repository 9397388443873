import aki from "../../images/holosymbols/aki.svg";
import amelia from "../../images/holosymbols/amelia.svg";
import aqua from "../../images/holosymbols/aqua.svg";
import ayame from "../../images/holosymbols/ayame.svg";
import ayamy from "../../images/holosymbols/ayamy.svg";
import azki from "../../images/holosymbols/azki.svg";
import baelz from "../../images/holosymbols/baelz.svg";
import botan from "../../images/holosymbols/botan.svg";
import calliope from "../../images/holosymbols/calliope.svg";
import chloe from "../../images/holosymbols/chloe.svg";
import choco from "../../images/holosymbols/choco.svg";
import civia from "../../images/holosymbols/civia.svg";
import coco from "../../images/holosymbols/coco.svg";
import fauna from '../../images/holosymbols/fauna.svg';
import flare from "../../images/holosymbols/flare.svg";
import fubuki from "../../images/holosymbols/fubuki.svg";
import gura from "../../images/holosymbols/gura.svg";
import haato from "../../images/holosymbols/haato.svg";
import hololive from "../../images/holosymbols/hololive.png";
import inanis from "../../images/holosymbols/inanis.svg";
import iofi from "../../images/holosymbols/iofi.svg";
import iroha from "../../images/holosymbols/iroha.svg";
import irys from "../../images/holosymbols/irys.svg";
import kanaeru from "../../images/holosymbols/kanaeru.svg";
import kanata from "../../images/holosymbols/kanata.svg";
import kiara from "../../images/holosymbols/kiara.svg";
import korone from "../../images/holosymbols/korone.svg";
import kovalskia from "../../images/holosymbols/kovalskia.svg";
import koyori from "../../images/holosymbols/koyori.svg";
import kronii from "../../images/holosymbols/kronii.svg";
import lamy from "../../images/holosymbols/lamy.svg";
import laplus from "../../images/holosymbols/laplus.svg";
import lui from "../../images/holosymbols/lui.svg";
import luna from "../../images/holosymbols/luna.svg";
import marine from "../../images/holosymbols/marine.svg";
import matsuri from "../../images/holosymbols/matsuri.svg";
import mel from "../../images/holosymbols/mel.svg";
import melfissa from "../../images/holosymbols/melfissa.svg";
import miko from "../../images/holosymbols/miko.svg";
import mio from "../../images/holosymbols/mio.svg";
import moona from "../../images/holosymbols/moona.svg";
import mumei from "../../images/holosymbols/mumei.svg";
import nabi from "../../images/holosymbols/nabi.svg";
import nachoneko from "../../images/holosymbols/nachoneko.svg";
import nana from "../../images/holosymbols/nana.svg";
import nene from "../../images/holosymbols/nene.svg";
import noel from "../../images/holosymbols/noel.svg";
import okayu from "../../images/holosymbols/okayu.svg";
import ollie from "../../images/holosymbols/ollie.svg";
import pekora from "../../images/holosymbols/pekora.svg";
import pochimaru from '../../images/holosymbols/pochimaru.svg';
import polka from "../../images/holosymbols/polka.svg";
import reine from "../../images/holosymbols/reine.svg";
import risu from "../../images/holosymbols/risu.svg";
import roboco from "../../images/holosymbols/roboco.svg";
import rurudo from "../../images/holosymbols/rurudo.svg";
import rushia from "../../images/holosymbols/rushia.svg";
import sana from "../../images/holosymbols/sana.svg";
import shion from "../../images/holosymbols/shion.svg";
import sora from "../../images/holosymbols/sora.svg";
import subaru from "../../images/holosymbols/subaru.svg";
import suisei from "../../images/holosymbols/suisei.svg";
import towa from "../../images/holosymbols/towa.svg";
import ui from "../../images/holosymbols/ui.svg";
import watame from "../../images/holosymbols/watame.svg";
import zeta from "../../images/holosymbols/zeta.svg";
import altare from "../../images/holosymbols/altare.svg";
import dezmond from "../../images/holosymbols/dezmond.svg";
import syrios from "../../images/holosymbols/syrios.svg";
import vesper from "../../images/holosymbols/vesper.svg";
import miyabi from "../../images/holosymbols/miyabi.svg";
import izuru from "../../images/holosymbols/izuru.svg";
import aruran from "../../images/holosymbols/aruran.svg";
import rikka from "../../images/holosymbols/rikka.svg";
import temma from "../../images/holosymbols/temma.svg";
import astel from "../../images/holosymbols/astel.svg";
import roberu from "../../images/holosymbols/roberu.svg";
import shien from "../../images/holosymbols/shien.svg";
import kaoru from "../../images/holosymbols/kaoru.svg";
import oga from "../../images/holosymbols/oga.svg";
import shiori from "../../images/holosymbols/shiori.svg";
import koseki from "../../images/holosymbols/koseki.svg";
import nerissa from "../../images/holosymbols/nerissa.svg";
import fuwamoco from "../../images/holosymbols/fuwamoco.svg";
import ao from "../../images/holosymbols/ao.svg";
import kanade from "../../images/holosymbols/kanade.svg";
import ririka from "../../images/holosymbols/ririka.svg";
import raden from "../../images/holosymbols/raden.svg";
import hajime from "../../images/holosymbols/hajime.svg";
import bettel from "../../images/holosymbols/bettel.svg";
import flayon from "../../images/holosymbols/flayon.svg";
import hakka from "../../images/holosymbols/hakka.svg";
import shinri from "../../images/holosymbols/shinri.svg";
import rexford from "../../images/holosymbols/rexford.svg";
import goldbullet from "../../images/holosymbols/goldbullet.svg";
import octavio from "../../images/holosymbols/octavio.svg";
import ruze from "../../images/holosymbols/ruze.svg";
import gigi from "../../images/holosymbols/gigi.svg";
import elizabeth from "../../images/holosymbols/elizabeth.svg";
import cecilia from "../../images/holosymbols/cecilia.svg";
import raora from "../../images/holosymbols/raora.svg";

interface IIconMap {
    [key:string]: any
}

const iconMap: IIconMap = {
    "aki": aki,
    "altare": altare,
    "amelia": amelia,
    "aqua": aqua,
    "ayame": ayame,
    "ayamy": ayamy,
    "azki": azki,
    "baelz": baelz,
    "botan": botan,
    "calliope": calliope,
    "chloe":chloe,
    "choco": choco,
    "civia": civia,
    "coco": coco,
    "dezmond": dezmond,
    "fauna": fauna,
    "flare": flare,
    "fubuki": fubuki,
    "gura": gura,
    "haato": haato,
    "hololive": hololive,
    "inanis": inanis,
    "iofi": iofi,
    "iroha": iroha,
    "irys": irys,
    "kanaeru": kanaeru,
    "kanata": kanata,
    "kiara": kiara,
    "korone": korone,
    "kovalskia": kovalskia,
    "koyori":koyori,
    "kronii": kronii,
    "lamy": lamy,
    "laplus":laplus,
    "lui":lui,
    "luna": luna,
    "marine": marine,
    "matsuri": matsuri,
    "mel": mel,
    "melfissa": melfissa,
    "miko": miko,
    "mio": mio,
    "moona": moona,
    "mumei": mumei,
    "nabi": nabi,
    "nachoneko":nachoneko,
    "nana": nana,
    "nene": nene,
    "noel": noel,
    "okayu": okayu,
    "ollie": ollie,
    "pekora": pekora,
    "pochimaru":pochimaru,
    "polka": polka,
    "reine": reine,
    "risu": risu,
    "roboco": roboco,
    "rurudo": rurudo,
    "rushia": rushia,
    "sana": sana,
    "shion": shion,
    "sora": sora,
    "subaru": subaru,
    "suisei": suisei,
    "syrios": syrios,
    "towa": towa,
    "ui": ui,
    "vesper": vesper,
    "watame": watame,
    "zeta": zeta,
    "miyabi": miyabi,
    "izuru": izuru,
    "aruran": aruran,
    "rikka": rikka,
    "temma": temma,
    "astel": astel,
    "roberu": roberu,
    "shien": shien,
    "kaoru": kaoru,
    "oga": oga,
    "shiori": shiori,
    "koseki": koseki,
    "nerissa": nerissa,
    "fuwamoco": fuwamoco,
    "ao": ao,
    "kanade": kanade,
    "ririka": ririka,
    "raden": raden,
    "hajime": hajime,
    "bettel": bettel,
    "flayon": flayon,
    "hakka": hakka,
    "shinri": shinri,
    "rexford": rexford,
    "goldbullet": goldbullet,
    "octavio": octavio,
    "ruze": ruze,
    "gigi": gigi,
    "elizabeth": elizabeth,
    "cecilia": cecilia,
    "raora": raora
}

export const lineage = [
    [
        "hololive"
    ],
    [
        "sora",
        "roboco",
        "miko",
        "suisei",
        "azki"
    ],
    [
        "mel",
        "fubuki",
        "matsuri",
        "aki",
        "haato",
    ],
    [
        "aqua",
        "shion",
        "ayame",
        "choco",
        "subaru"
    ],
    [
        "mio",
        "okayu",
        "korone"
    ],
    [
        "pekora",
        "rushia",
        "flare",
        "noel",
        "marine"
    ],
    [
        "kanata",
        "coco",
        "watame",
        "towa",
        "luna"
    ],
    [
        "lamy",
        "nene",
        "botan",
        "polka"
    ],
    [
        "laplus",
        "lui",
        "koyori",
        "chloe",
        "iroha"
    ],
    [
        "calliope",
        "kiara",
        "inanis",
        "gura",
        "amelia"
    ],
    [
        "irys"
    ],
    [
        "sana",
        "fauna",
        "kronii",
        "mumei",
        "baelz"
    ],
    [
        "shiori",
        "koseki",
        "nerissa",
        "fuwamoco"
    ],
    [
        "gigi",
        "elizabeth",
        "cecilia",
        "raora"
    ],
    [
        "risu",
        "moona",
        "iofi"
    ],
    [
        "ollie",
        "melfissa",
        "reine"
    ],
    [
        "zeta",
        "kovalskia",
        "kanaeru"
    ],
    [
        "ao",
        "kanade",
        "ririka",
        "raden",
        "hajime"
    ],
    [
        "ui",
        "nana",
        "pochimaru",
        "ayamy",
        "nabi",
        "nachoneko",
        "rurudo"
    ],
    [
        "miyabi",
        "izuru",
        "aruran",
        "rikka"
    ],
    [
        "astel",
        "temma",
        "roberu"
    ],
    [
        "shien",
        "kaoru",
        "oga"
    ],
    [
        "altare",
        "dezmond",
        "syrios",
        "vesper",
        "bettel",
        "flayon",
        "hakka",
        "shinri"
    ],
    [
        "rexford",
        "goldbullet",
        "octavio",
        "ruze"
    ],
    [
        "civia"
    ]
]

export const lineageMap:{[generation:string]:number} = {
    'Hololive': 0,
    'HoloJP Gen 0': 1,
    'HoloJP Gen 1': 2,
    'HoloJP Gen 2': 3,
    'Gamers': 4,
    'HoloJP Gen 3': 5,
    'HoloJP Gen 4': 6,
    'HoloJP Gen 5': 7,
    'HoloJP Gen 6': 8,
    'HoloEN Myth': 9,
    'HoloEN Project Hope': 10,
    'HoloEN Council': 11,
    'HoloEN Advent': 12,
    'HoloEN Justice': 13,
    'HoloID Gen 1': 14,
    'HoloID Gen 2': 15,
    'HoloID Gen 3': 16,
    'HoloDevIs Gen 1': 17,
    'HoloMamas': 18,
    'HoloStars Gen 1': 19,
    'HoloStars Gen 2': 20,
    'HoloStars Gen 3': 21,
    'HoloStarsEN Tempus': 22,
    'HoloCN': 23
}

export default iconMap;
