const getForegroundFromBackground = (r: number, g: number, b: number) => {
    let hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b) 
    )
    if(hsp > 127.5) {
        return "black";
    } else {
        return "white";
    }
}

export default getForegroundFromBackground;