import { lineage } from "./components/Icons";

export const SortByGen = (coins:Array<string>) => {
    let gen:Array<string> = [];
    lineage.forEach((g:Array<any>) => {
        gen = [...gen, ...g];
    })
    gen = gen.map((coin:string) => {
        if(coin === "luna") {
            return "himemoriluna";
        } else {
            return coin;
        }
    })
    coins.sort((a:string, b:string) => {
        return gen.indexOf(a) - gen.indexOf(b);
    })
}