import {
    createStore,
    combineReducers
} from 'redux';

import settings from './reducers/settings';
import session from './reducers/session';
import stats from './reducers/stats';
import userinfo from './reducers/userinfo';
import transactions from './reducers/transactions';
import floor from './reducers/floor';
import superchats from './reducers/superchats';
import autotrader from './reducers/autotrader';
import itemcatalogue from './reducers/itemcatalogue';
import admin from './reducers/admin';
import socket from './reducers/socket';
import gacha from './reducers/gacha';
import multicoinSave from './reducers/multicoinSave';
import itemmarketprices from './reducers/itemMarketPrices';
import auctions from './reducers/auctions';
import snowfall from './reducers/snowfall';
import benchmark from './reducers/benchmark';
import betting from './reducers/betting';
import chat from './reducers/chat';
import mutualfunds from './reducers/mutualfunds';
import devblog from './reducers/devblog';

let initState = {
    socket: {
        socket: null,
        query: {}
    },
    settings: {
        theme: 0,
        marketSwitch: true,
        tradeNotifications: true,
        noMales: false
    },
    session: {
        loggedin: undefined,
        admins: []
    },
    userinfo: {
        username: undefined,
        email: undefined,
        wallet: undefined,
        icon: undefined,
        id:undefined,
        performance: undefined,
        verified: undefined,
        admin: undefined,
        settings: undefined,
        muted: undefined,
        color: undefined,
        loaded: false,
        items: {},
        brokerFeeTotal: 0,
        brokerFeeCredits: 0,
        bookie: false,
        socketid: undefined,
        hat: undefined,
        mutualfundJoinRequests: [],
        mutualfundOrders: [],
        mutualfunds: {},
        blogPermission: false
    },
    stats: {
        stats: {},
        coinHistory: [],
        coinInfo: {},
        leaderboard: [],
        oshiboard: {},
        gachaboard: [],
        benchmarkLeaderboard: {},
        brokerTotal: 0,
        brokerFee:0,
    },
    floor: {
        floorSpace: null,
        activeRoom: null,
        chatLog: [],
        repliesVisible: false,
        replies: [],
        replyStack: [],
        replySource: null,
        replySourceCoords: {
            x: 0,
            y: 0
        },
        loading: false,
        cooldown: {
            room: 0,
            post: 0
        }
    },
    transactions: [],
    superchats: {
        daily: undefined,
        history: undefined,
        enableDanmaku: false,
        cooldown: 0
    },
    autotrader: {
        running:false,
        nextTradeTime:0,
        expectedBalance:0,
        rules:[],
        pendingOrder:[],
        updatedRules:[]
    },
    itemcatalogue: {},
    itemmarketprices: {},
    auctions: {
        activeAuctions:[],
        pastAuctions:[],
        auctionFeeds:{},
        subscriptions:[],
        auctionPriceHistory:{},
        auctionNotifications:[],
        filters:{
            myBids:false,
            mySales:false,
            watching:false,
            item:null,
            hasBid:false,
            noBidders:false,
            sortMethod:1,
            hide:[]
        }
    },
    admin: {
        users: undefined,
        filters: undefined,
        reports: undefined,
        adjustmentControls: undefined,
        spamTracker: undefined,
        dividendToggles: undefined,
        volatilityMultipliers: undefined,
        overbought: false,
        oversold: false,
        bogrationLevel: 0,
        blogPermissions: [],
        upwardsReductionLevel: 0,
        bookies: undefined,
        chatReports: []
    },
    gacha: {
        receivedItems: []
    },
    multicoinSave: {},
    snowfall: {
        showSnowNotification:false,
        showSnowSettings:false,
        snowSize:0.1,
        snowSpeed:0.3,
        snowAmount:0.5
    },
    benchmark: {
        index:0,
        marketCap:0,
        totalShares:0,
        weights:{},
        runningHistory:[],
        history:[]
    },
    betting: {
        bettingPools: undefined
    },
    chat: {
        blocking: [],
        blockedBy: [],
        directMessageRooms: [],
        directMessageLogs: {},
        globalChat: [],
        loadedRooms: []
    },
    mutualfunds: {
        funds: {},
        fundstats: {},
        fundhistory: {},
        bulletinboards: {},
        joinRequests: {},
        fundUserTotals: {},
        chat: {},
        socketIds: {},
        orders: {},
        fundPayouts: {},
        fundsToDissolve: {},
        loaded:false,
        autotraders: {}
            // running:false,
            // nextTradeTime:0,
            // expectedBalance:0,
            // rules:[],
            // pendingOrder:[],
            // updatedRules:[]
    },
    devblog: {
        posts: []
    }
}

const holoReducer = combineReducers({
    socket,
    settings,
    session,
    stats,
    userinfo,
    floor,
    transactions,
    superchats,
    autotrader,
    itemcatalogue,
    itemmarketprices,
    auctions,
    admin,
    gacha,
    snowfall,
    multicoinSave,
    benchmark,
    betting,
    chat,
    mutualfunds,
    devblog
});

const configureStore = (reducer:any, initState:{}) => {
    return createStore(reducer, initState);
}

export default configureStore(holoReducer, initState);
