import React, {Component} from 'react';
import {connect} from 'react-redux';
import { IMutualFundChatLog, IMutualFundChatLogCollection, IMutualFundChatMessage, IMutualFundHistoryCollection, IMutualFundMember, IMutualFunds, IMutualFundStatCollection } from '../../../interfaces/MutualFunds';
import MutualFundSection from './MutualFundSection';
import {
    RiSendPlaneFill
} from 'react-icons/ri';

import '../../../../css/mutualfunds/mutualfundchat.scss';
import hexToRgb from '../../../hexToRBG';
import getForegroundFromBackground from '../../../getForegroundFromBackground';
import fetchData from '../../../fetchData';
import { mutualfundActions } from '../../../actions/actions';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {
    setFundChat: mutualfundActions.setFundChat,
}

class MutualFundChatState {
    newMessage:boolean = false;
    message:string = "";
    error:string = "";
    scrolledBottom:boolean = true;
}

interface IMutualFundChatProps {
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
        chat: IMutualFundChatLogCollection
    },
    fund:string,
    setFundChat: (chat: IMutualFundChatLogCollection) => {},
}

class MutualFundChatBind extends Component<IMutualFundChatProps> {

    state:MutualFundChatState;
    private bumper = React.createRef<HTMLDivElement>();
    private scrollContainer = React.createRef<HTMLDivElement>();

    constructor(props:IMutualFundChatProps) {
        super(props);
        this.state = new MutualFundChatState();
    }

    handleMessage(e:any) {
        this.setState({message:e.target.value});
    }

    handleKey(e:any) {
        if(e.which === 13) {
            e.preventDefault();
            this.sendMessage();
        }
    }

    sendMessage() {
        let message = this.state.message;
        if(message.length > 500) {
            alert("Message too long. Max length 500 characters. Current Length: " + message.length);
            return;
        }
        message = message.replace("\n", " ");
        if(message.length === 0) {
            return;
        }
        fetch('/api/mutualFundSendChat/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fund: this.props.fund,
                message
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                this.setState({error:data.message});
            } else {
                this.setState({message:'', error: ''});
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    componentDidMount() {
        if(this.props.mutualfunds.chat[this.props.fund] === undefined) {
            fetchData("/api/getMutualFundChat?fund=" + this.props.fund)
            .then((data:any) => {
                if(data.success) {
                    const chat:IMutualFundChatLogCollection = {...this.props.mutualfunds.chat};
                    chat[data.fund] = data.chatLog;
                    this.props.setFundChat(chat);
                } else {
                    console.log(data.message);
                }
            });
        }
    }

    getColor() {
        let fund = this.props.mutualfunds.funds[this.props.fund];
        return "#" + fund.color;
    }

    getForegroundColor() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getForegroundFromBackground(r, g, b);
    }

    getCEOUsername() {
        let members = this.props.mutualfunds.funds[this.props.fund].members;
        let ceo = this.props.mutualfunds.funds[this.props.fund].ceo;
        for(let i = 0; i < members.length; i++) {
            if(members[i].id === ceo) {
                return members[i].username;
            }
        }
    }

    renderStatus(username:string) {
        const ceo = this.getCEOUsername();
        let status = "";
        if(username === ceo) {
            status = "CEO";
        } else {
            let isBoardMember = false;
            this.props.mutualfunds.funds[this.props.fund].members.forEach((member:IMutualFundMember) => {
                if(member.username === username) {
                    if(member.boardMember) isBoardMember = true;
                }
            });
            if(isBoardMember) {
                status = "Board Member";
            } else {
                return null;
            }
        }
        return (
            <div
                style={{
                    "background":this.getColor(),
                    "color":this.getForegroundColor()
                }}
                className="chat-user-status">{status}</div>
        )
    }

    componentDidUpdate(prevProps:IMutualFundChatProps) {
        if(prevProps.mutualfunds.chat[this.props.fund] === undefined) {
          if(this.bumper.current) {
            this.bumper.current.scrollIntoView();
          }
          return;
        }
        if(prevProps.mutualfunds.chat[this.props.fund].length !== this.props.mutualfunds.chat[this.props.fund].length) {
            if(this.bumper.current && this.state.scrolledBottom) {
                this.bumper.current.scrollIntoView();
            } else {
                if(!this.state.scrolledBottom) {
                    this.setState({newMessage:true});
                }
            }
        } else {
            if(prevProps.mutualfunds.chat[this.props.fund].length > 1) {
                let l = prevProps.mutualfunds.chat[this.props.fund].length;
                let prevLast = prevProps.mutualfunds.chat[this.props.fund][l - 1];
                let currentLast = this.props.mutualfunds.chat[this.props.fund][l - 1];
                if((prevLast.timestamp !== currentLast.timestamp)) {
                    if(this.state.scrolledBottom) {
                        if(this.bumper.current)
                            this.bumper.current.scrollIntoView();
                    } else {
                        this.setState({newMessage:true});
                    }
                }
            }
        }
    }

    checkScroll() {
        if(this.bumper.current !== null && this.scrollContainer.current !== null) {
            let contTop = this.scrollContainer.current.scrollTop;
            let scrollHeight = this.scrollContainer.current.scrollHeight;
            let contHeight = this.scrollContainer.current.clientHeight;
            let bottom = scrollHeight - contTop <= contHeight;
            this.setState({scrolledBottom:bottom});
            if(bottom) {
                this.setState({newMessage:false});
            }
        }
    }

    render() {
        let chat = this.props.mutualfunds.chat[this.props.fund] || [];
        return(
            <MutualFundSection fund={this.props.fund}>
                <div className="section-header">
                    Chat
                </div>
                <div
                    ref={this.scrollContainer}
                    onScroll={() => this.checkScroll()}
                    className="chat-container">
                    {
                        chat.length === 0 ?
                        <div className="no-messages">
                            No Messages
                        </div> : null
                    }
                    <div className="messages">
                    {
                        chat.map((chatMessage:IMutualFundChatMessage) =>
                            <div
                                key={chatMessage.timestamp}
                                className="chat-item flex flex-col">
                                <div className="chat-info flex flex-row">
                                    <div className="chat-username">{chatMessage.user}</div>
                                    {
                                        this.renderStatus(chatMessage.user)
                                    }
                                    <div className="chat-timestamp">
                                        {new Date(chatMessage.timestamp).toLocaleString()}
                                    </div>
                                </div>
                                <div className="chat-content">
                                    {chatMessage.message}
                                </div>
                            </div>
                        )
                    }
                    </div>
                    <div className="bumper" ref={this.bumper}></div>
                    {
                        this.state.newMessage ?
                        <div className="new-message-notification">
                            New Message
                        </div> : null
                    }
                </div>
                <div
                    className="chat-input-container flex flex-row">

                    <textarea
                        rows={2}
                        maxLength={500}
                        onChange={(e) => this.handleMessage(e)}
                        onKeyUp={(e) => this.handleKey(e)}
                        value={this.state.message}
                        placeholder="Type message here..."></textarea>
                    <div
                        style={{
                            "background":this.getColor(),
                            "color":this.getForegroundColor()
                        }}
                        onClick={() => this.sendMessage()}
                        className="submit-chat-button flex center-child">
                        <RiSendPlaneFill />
                    </div>
                </div>
                {
                    this.state.error !== "" ?
                    <div className="error">
                        {this.state.error}
                    </div> : null
                }
            </MutualFundSection>
        );
    }
}

const MutualFundChat = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundChatBind);

export default MutualFundChat;
