import { handleActions } from 'redux-actions';

const session = handleActions(
    {
        LOGIN: (state:any, action:any) => ({
            ...state,
            loggedin: true
        }),
        LOGOUT: (state:any, action:any) => ({
            ...state,
            loggedin: false
        }),
        SET_ADMINS: (state:any, action:any) => ({
            ...state,
            admins: [...action.payload.admins]
        })
    },
    {
        loggedin: undefined,
        admins: []
    }
)

export { session as default }