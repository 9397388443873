import React, {Component} from 'react';
import '../../../css/benchmark/benchmark.scss';

import {connect} from 'react-redux';
import numberWithCommas from '../../numberWithCommas';
import { Line } from 'react-chartjs-2';
import { datasetTemplate } from '../DatasetTemplate';
import ToggleSwitch from '../ToggleSwitch';

import Coin from "../Coin";

import flare from '../../../images/flare.png';
import chloe from '../../../images/chloe.png';
import {lineage, lineageMap} from '../Icons';

import {
    FaArrowAltCircleUp,
    FaArrowAltCircleDown,
} from 'react-icons/fa';
import {
    AiOutlineInfoCircle
} from 'react-icons/ai';
import { IWallet } from '../../interfaces/IWallet';
import BenchmarkInfo from './BenchmarkInfo';

const mapStateToProps = (state:any) => ({
    benchmark:state.benchmark,
    stats:state.stats,
    userinfo:state.userinfo,
    session:state.session
})

interface BenchmarkProps {
    benchmark: {
        index:number,
        marketCap:number,
        totalShares:number,
        weights:{[coin:string]:number},
        runningHistory:Array<any>,
        history:any
    },
    stats: {
        coinInfo:any
    },
    userinfo: {
        wallet:IWallet,
        performance:any
    },
    session: {
        loggedin:boolean
    }
}

enum BenchmarkStats {
    INDEX,
    MCAP,
    SHARES
}

enum BenchmarkWeightOrder {
    GEN,
    COIN,
    WEIGHT,
    MYWEIGHT,
    ACTIVEWEIGHT,
    PRICE,
    VOLUME,
    MYSHARES
}

const statLabels:Array<string> = ['Index', 'Market Cap', 'Outstanding Shares'];

interface BenchmarkStatProps {
    value:any,
    className?:string
}
class BenchmarkStatState {
    blink:boolean;
    constructor() {
        this.blink = false;
    }
}
class BenchmarkStat extends Component<BenchmarkStatProps> {
    state:BenchmarkStatState;
    constructor(props:BenchmarkStatProps) {
        super(props);
        this.state = new BenchmarkStatState();
    }
    componentDidUpdate(prevProps:BenchmarkStatProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({blink:true});
            setTimeout(() => {
                this.setState({blink:false});
            }, 5000);
        }
    }
    render() {
        let className = "gen-member-stat";
        if(this.props.className) className += " " + this.props.className;
        if(this.state.blink) className += " blink";
        return(
            <th className={className}>
                {this.props.value}
            </th>
        )
    }
}

class BenchmarkState {
    activeStat:BenchmarkStats;
    allTime:boolean;
    weightOrder:BenchmarkWeightOrder;
    increasing:boolean;
    showInfo:boolean;
    constructor() {
        this.activeStat = BenchmarkStats.INDEX;
        this.allTime = false;
        this.weightOrder = BenchmarkWeightOrder.GEN;
        this.increasing = true;
        this.showInfo = false;
    }
}

class BenchmarkBind extends Component<BenchmarkProps> {

    state:BenchmarkState;
    constructor(props:BenchmarkProps) {
        super(props);
        this.state = new BenchmarkState();
    }
    renderGraphSelectItem(stat:BenchmarkStats) {
        let className = "graph-select-item";
        if(this.state.activeStat === stat) className += " active";
        let label = statLabels[stat];
        return(
            <div
                className={className}
                onClick={() => this.setState({activeStat:stat})}>{label}</div>
        )
    }
    getActiveStatFromItem(statItem:any) {
        if(this.state.activeStat === BenchmarkStats.INDEX) {
            return statItem.index;
        } else if(this.state.activeStat === BenchmarkStats.MCAP) {
            return statItem.marketCap;
        } else if(this.state.activeStat === BenchmarkStats.SHARES) {
            return statItem.totalShares;
        } else {
            return;
        }
    }
    renderArrow(oldVal:number, newVal:number) {
        if(newVal > oldVal) {
            return <FaArrowAltCircleUp style={{verticalAlign:'middle'}}/>
        } else if(newVal < oldVal) {
            return <FaArrowAltCircleDown style={{verticalAlign:'middle'}}/>
        } else {
            return "-";
        }
    }
    formatPercent(oldVal:number, newVal:number) {
        let p = (newVal - oldVal) / oldVal;
        let m = Math.abs(Math.round((p * 100) * 1000) / 1000);
        return numberWithCommas(m) + "%";
    }
    renderBenchmarkStat(label:string, oldVal:number, newVal:number, showDollar:boolean, showDelta:boolean) {
        let deltaClass = "benchmark-stat-delta";
        if(newVal > oldVal) {
            deltaClass += " increase";
        } else if(newVal < oldVal) {
            deltaClass += " decrease";
        }
        return(
            <div className="benchmark-stat-row">
                <div className="benchmark-stat-label">{label}</div>
                <div className="benchmark-stat-value">
                    {showDollar ? "$" : ""}
                    {numberWithCommas(newVal)}
                </div>
                {
                    showDelta ?
                    <div className={deltaClass}>
                        {this.renderArrow(oldVal, newVal)}
                        {" "}
                        {numberWithCommas(Math.abs(Math.round((newVal - oldVal) * 100)) / 100) + " "}
                        ({this.formatPercent(oldVal, newVal)})
                    </div> : null
                }
            </div>
        )
    }
    getAveragePrice() {
        let coinInfo = this.props.stats.coinInfo;
        let total = 0;
        Object.keys(coinInfo.data).forEach((coin:string) => {
            total += coinInfo.data[coin].price;
        })
        return Math.round(total / Object.keys(coinInfo.data).length);
    }
    getAverageShares() {
        let coinInfo = this.props.stats.coinInfo;
        let total = 0;
        Object.keys(coinInfo.data).forEach((coin:string) => {
            total += coinInfo.data[coin].inCirculation;
        })
        return Math.round(total / Object.keys(coinInfo.data).length);
    }
    getGenWeight(gen:number) {
        let genList:Array<string> = lineage[gen];
        let total = 0;
        genList.forEach((member:string) => {
            let coinname = member === 'luna' ? 'himemoriluna' : member;
            total += this.props.benchmark.weights[coinname];
        });
        return Math.round(total * 1000) / 1000;
    }
    filterCoin(coin:string) {
        if(coin === 'luna') return 'himemoriluna';
        return coin;
    }
    calculatePersonalWeights():{[coin:string]:number} {
        if(!this.props.session.loggedin) return {};

        let weights:{[coin:string]:number} = {};
        let wallet:IWallet = {...this.props.userinfo.wallet};
        let netWorth = wallet.balance;
        Object.keys(wallet.coins).forEach((coin:string) => {
            netWorth += wallet.coins[coin].amt * this.props.stats.coinInfo.data[this.filterCoin(coin)].price;
        });
        Object.keys(wallet.coins).forEach((coin:string) => {
            weights[coin] = Math.round(100000 * (wallet.coins[coin].amt * this.props.stats.coinInfo.data[this.filterCoin(coin)].price) / netWorth) / 1000;
        });
        lineage.forEach((gen:Array<string>) => {
            gen.forEach((coin:string) => {
                if(weights[coin] === undefined) {
                    weights[coin] = 0;
                }
            })
        })
        return weights;
    }
    getCoinAmount(coin:string) {
        if(this.props.userinfo.wallet.coins[coin]) {
            return this.props.userinfo.wallet.coins[coin].amt;
        } else {
            return 0;
        }
    }
    getActiveWeight(myWeights:any, coin:string) {
        if(myWeights[coin]) {
            return Math.round((myWeights[coin] - this.props.benchmark.weights[coin]) * 1000) / 1000;
        } else {
            return Math.round(-1 * this.props.benchmark.weights[coin] * 1000) / 1000;
        }
    }
    setOrder(order:BenchmarkWeightOrder) {
        if(order === this.state.weightOrder) {
            this.setState({increasing: !this.state.increasing});
        } else {
            this.setState({weightOrder: order, increasing:true});
        }
    }
    renderWeightRow(coin:string, myWeights:any) {

        let activeWeight = this.getActiveWeight(myWeights, this.filterCoin(coin));
        let activeWeightClass = activeWeight > 0 ? "active-positive" : "active-negative";
        if(activeWeight === 0) {
            activeWeightClass = "active-neutral";
        }
        return(
                <tr className="gen-member-weight">
                    <th></th>
                    <th></th>
                    <th>
                        <Coin name={coin}/>
                    </th>
                    <BenchmarkStat value={this.props.benchmark.weights[this.filterCoin(coin)] + "%"} className={"green"}/>
                    {
                        this.props.session.loggedin ?
                        <BenchmarkStat value={numberWithCommas(myWeights[this.filterCoin(coin)] ? myWeights[this.filterCoin(coin)] : 0) + "%"} className={"myweights"}/> : null
                    }
                    {
                        this.props.session.loggedin ?
                        <BenchmarkStat value={numberWithCommas(activeWeight) + "%"} className={activeWeightClass}/> : null
                    }
                    <BenchmarkStat value={"$" + numberWithCommas(this.props.stats.coinInfo.data[this.filterCoin(coin)].price)}/>
                    <BenchmarkStat value={numberWithCommas(this.props.stats.coinInfo.data[this.filterCoin(coin)].inCirculation)}/>
                    {
                        this.props.session.loggedin ?
                        <BenchmarkStat value={numberWithCommas(this.getCoinAmount(this.filterCoin(coin)))}/> : null
                    }
                </tr>
        )
    }
    renderCoinWeightsOrder(weights:any) {
        let coins:Array<string> = [];
        lineage.forEach((gen:Array<string>) => {
            coins = [...coins, ...gen];
        });
        switch(this.state.weightOrder) {
            case BenchmarkWeightOrder.COIN:
                coins.sort();
                break;
            case BenchmarkWeightOrder.WEIGHT:
                coins.sort((a:string, b:string) => {
                    return this.props.benchmark.weights[this.filterCoin(a)] - this.props.benchmark.weights[this.filterCoin(b)]
                });
                break;
            case BenchmarkWeightOrder.MYWEIGHT:
                coins.sort((a:string, b:string) => {
                    return weights[this.filterCoin(a)] - weights[this.filterCoin(b)];
                })
                break;
            case BenchmarkWeightOrder.ACTIVEWEIGHT:
                coins.sort((a:string, b:string) => {
                    return this.getActiveWeight(weights, this.filterCoin(a)) - this.getActiveWeight(weights, this.filterCoin(b));
                })
                break;
            case BenchmarkWeightOrder.PRICE:
                coins.sort((a:string, b:string) => {
                    return this.props.stats.coinInfo.data[this.filterCoin(a)].price - this.props.stats.coinInfo.data[this.filterCoin(b)].price;
                })
                break;
            case BenchmarkWeightOrder.VOLUME:
                coins.sort((a:string, b:string) => {
                    return this.props.stats.coinInfo.data[this.filterCoin(a)].inCirculation - this.props.stats.coinInfo.data[this.filterCoin(b)].inCirculation;
                })
                break;
            case BenchmarkWeightOrder.MYSHARES:
                coins.sort((a:string, b:string) => {
                    return this.getCoinAmount(this.filterCoin(a)) - this.getCoinAmount(this.filterCoin(b));
                })
                break;
        }
        if(!this.state.increasing) {
            coins.reverse();
        }
        return(
            <>
            {
                coins.map((coin:string) => this.renderWeightRow(coin, weights))
            }
            </>
        )
    }
    getUserNetWorth() {
        const wallet:IWallet = {...this.props.userinfo.wallet};
        let netWorth:number = wallet.balance;
        Object.keys(wallet.coins).forEach((coin:string) => {
            netWorth += this.props.stats.coinInfo.data[coin].price * wallet.coins[coin].amt;
        });
        return Math.round(netWorth * 100) / 100;
    }
    getPrevNetWorth() {
        let lastPerformance = Math.round(parseFloat(this.props.userinfo.performance.at(-1).worth) * 100) / 100;
        return lastPerformance;
    }
    render() {

        if(this.props.stats.coinInfo.data === undefined) return null;
        if(this.props.benchmark.index === undefined) return null;

        let datasets: Array<any> = [];
        let dataset = {...datasetTemplate};
        dataset.backgroundColor = 'rgb(0,0,0)';
        dataset.borderColor = 'rgb(98, 248, 223)';
        dataset.pointBorderColor = 'rgb(98, 121, 248)';
        dataset.pointHoverBackgroundColor = 'rgb(13, 23, 80)';
        dataset.pointHoverBorderColor = 'rgb(197, 255, 189)';
        let data:any = {};
        dataset.label = statLabels[this.state.activeStat];

        if(this.state.allTime) {
            data.labels = this.props.benchmark.history.map((histItem:any) => {
                return new Date(histItem.timestamp).toLocaleDateString();
            });
            dataset.data = this.props.benchmark.history.map((histItem:any) => {
                return this.getActiveStatFromItem(histItem);
            });
            data.labels.push(new Date().toLocaleTimeString());
            if(this.state.activeStat === BenchmarkStats.INDEX) {
                dataset.data.push(this.props.benchmark.index);
            } else if(this.state.activeStat === BenchmarkStats.MCAP) {
                dataset.data.push(this.props.benchmark.marketCap);
            } else if(this.state.activeStat === BenchmarkStats.SHARES) {
                dataset.data.push(this.props.benchmark.totalShares);
            }
        } else {
            data.labels = this.props.benchmark.runningHistory.map((histItem:any) => {
                return new Date(histItem.timestamp).toLocaleTimeString();
            });
            dataset.data = this.props.benchmark.runningHistory.map((histItem:any) => {
                return this.getActiveStatFromItem(histItem);
            });
        }

        datasets.push(dataset);
        data.datasets = datasets;

        let currentIndex = this.props.benchmark.index;
        let currentMarketCap = this.props.benchmark.marketCap;
        let currentShares = this.props.benchmark.totalShares;
        let prevIndex = currentIndex;
        let prevMarketCap = currentMarketCap;
        let prevShares = currentShares;
        if(this.props.benchmark.history.length > 0) {
            prevIndex = this.props.benchmark.history.at(-1).index;
            prevMarketCap = this.props.benchmark.history.at(-1).marketCap;
            prevShares = this.props.benchmark.history.at(-1).totalShares;
        }

        let indexDeltaClass = "index-delta";
        if(currentIndex > prevIndex) {
            indexDeltaClass += " increase";
        } else if(currentIndex < prevIndex) {
            indexDeltaClass += " decrease";
        }

        const averagePrice = this.getAveragePrice();
        const averageShares = this.getAverageShares();

        let myWeights:{[coin:string]:number} = {};
        if(this.props.session.loggedin) {
            myWeights = this.calculatePersonalWeights();
        }

        let lineageNames = Object.keys(lineageMap);
        if(!this.state.increasing) {
            lineageNames.reverse();
        }

        let marketPercentChange = Math.round(((currentIndex - prevIndex) / prevIndex) * 100000) / 1000;

        let percentageNetWorth = 0;
        let netWorthChange = 0;
        let netWorth = 0;
        let prevNetWorth = 0;
        if(this.props.session.loggedin) {
            netWorth = this.getUserNetWorth();
            prevNetWorth = this.getPrevNetWorth();
            netWorthChange = Math.round((this.getUserNetWorth() - this.getPrevNetWorth()) * 100) / 100;
            percentageNetWorth = Math.round((netWorthChange / prevNetWorth) * 100000) / 1000;
        }

        return(
            <div className="container fill">
                <div className="container-inner container-benchmark">
                    <div className="benchmark-header flex center-child">
                        <h1 className="benchmark-title">Y&G 66</h1>
                        <div onClick={() => {
                            this.setState({showInfo:!this.state.showInfo})
                        }} className="show-info">
                            <AiOutlineInfoCircle/>
                        </div>
                    </div>
                    {
                        this.state.showInfo ?
                        <BenchmarkInfo /> : null
                    }
                    <div className="benchmark-stats-outer flex flex-row">
                        <div className="benchmark-graph flex flex-col">
                            <div className="benchmark-toggle-range">
                                <ToggleSwitch
                                    offLabel="All Time"
                                    onLabel="24 Hours"
                                    switchState={!this.state.allTime}
                                    onToggle={() => {
                                        this.setState({allTime:!this.state.allTime})
                                    }}/>
                            </div>
                            <div className="graph-select flex flex-row">
                                {this.renderGraphSelectItem(BenchmarkStats.INDEX)}
                                {this.renderGraphSelectItem(BenchmarkStats.MCAP)}
                                {this.renderGraphSelectItem(BenchmarkStats.SHARES)}
                            </div>
                            <Line
                                data={data}
                                options={{
                                    animation: {
                                        duration: 400
                                    },
                                    easing: "easeInOutCubic",
                                    scales: {
                                        xAxes: [{
                                            ticks: {
                                                display:true,
                                                fontFamily:"BabasNeue",
                                            }
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                fontFamily:"BabasNeue"
                                            }
                                        }]
                                    },
                                    legend: {
                                        display:false
                                    }
                                }}/>
                        </div>
                        <div className="benchmark-stats">
                            <div className="benchmark-index-info flex flex-row">
                                <div className="benchmark-index">{numberWithCommas(currentIndex)}</div>
                                <div className={indexDeltaClass}>
                                    {this.renderArrow(prevIndex,currentIndex)}
                                    {" "}
                                    {numberWithCommas(Math.abs(Math.round((prevIndex - currentIndex) * 100)) / 100) + " "}
                                    { "(" + this.formatPercent(prevIndex,currentIndex) + ")" }
                                </div>
                            </div>
                            {
                                this.props.session.loggedin ?
                                <div className="performance-comparison flex flex-row">
                                    <div className="performance-stats flex flex-col">
                                        Your networth:
                                        <div className="net-worth-compare">
                                            ${numberWithCommas(netWorth)}
                                        </div>
                                        <div className={
                                            "net-worth-delta " +
                                            (
                                                (netWorthChange === 0) ?
                                                    "blue" :
                                                    (netWorthChange > 0) ?
                                                        "green" : "red"
                                            )
                                        }>
                                            {this.renderArrow(prevNetWorth, netWorth)}
                                            {" $" + numberWithCommas(Math.abs(netWorthChange)) + " "}
                                            {"(" + numberWithCommas(Math.abs(percentageNetWorth)) + "%)"}
                                        </div>
                                    </div>
                                    <div className="performance-description">
                                        You
                                        <span className={
                                            "performance-text-compare " +
                                            (
                                                percentageNetWorth === marketPercentChange ?
                                                    "blue" :
                                                    (percentageNetWorth > marketPercentChange) ?
                                                        "green" : "red"
                                            )
                                        }>
                                        {
                                            percentageNetWorth === marketPercentChange ?
                                            " matched " :
                                            (percentageNetWorth > marketPercentChange) ?
                                                " outperformed " : " underperformed "
                                        }
                                        </span>
                                        the market
                                        {
                                            percentageNetWorth !== marketPercentChange ?
                                            " by " : ""
                                        }
                                        {
                                            percentageNetWorth !== marketPercentChange ?
                                            <span className={
                                                "percent-dif " +
                                                (
                                                    percentageNetWorth === marketPercentChange ?
                                                        "blue" :
                                                        (percentageNetWorth > marketPercentChange) ?
                                                            "green" : "red"
                                                )
                                            }>
                                            { " " + Math.abs(Math.round((percentageNetWorth - marketPercentChange) * 1000) / 1000)}%
                                            </span> : null
                                        }
                                    </div>
                                </div> : null
                            }
                            {this.renderBenchmarkStat('Market Cap', prevMarketCap, currentMarketCap, true, true)}
                            {this.renderBenchmarkStat('Total Outstanding Shares', prevShares, currentShares, false, true)}
                            {this.renderBenchmarkStat('Average Share Price', averagePrice, averagePrice, true, false)}
                            {this.renderBenchmarkStat('Average Outstanding Shares', averageShares, averageShares, false, false)}
                            <div className="flare-outer">
                                <img src={flare} alt="flare" />
                            </div>
                        </div>
                    </div>
                    <div className="benchmark-weights flex flex-row">
                        <div className="benchmark-weights-header">
                            Coin Weights
                        </div>
                        <div className="benchmark-weights-list">
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan={2} onClick={() => this.setOrder(BenchmarkWeightOrder.GEN)}>Generation</th>
                                        <th onClick={() => this.setOrder(BenchmarkWeightOrder.COIN)}>Coin</th>
                                        <th onClick={() => this.setOrder(BenchmarkWeightOrder.WEIGHT)}>Weight</th>
                                        {
                                            this.props.session.loggedin ?
                                            <th onClick={() => this.setOrder(BenchmarkWeightOrder.MYWEIGHT)}>My Weights</th> : null
                                        }
                                        {
                                            this.props.session.loggedin ?
                                            <th onClick={() => this.setOrder(BenchmarkWeightOrder.ACTIVEWEIGHT)}>Active Weight</th> : null
                                        }
                                        <th onClick={() => this.setOrder(BenchmarkWeightOrder.PRICE)}>Price</th>
                                        <th onClick={() => this.setOrder(BenchmarkWeightOrder.VOLUME)}>Volume</th>
                                        {
                                            this.props.session.loggedin ?
                                            <th onClick={() => this.setOrder(BenchmarkWeightOrder.MYSHARES)}>My Shares</th> : null
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.weightOrder === BenchmarkWeightOrder.GEN ?
                                    lineageNames.map((gen:string, index:number) =>
                                        <>
                                        <tr>
                                            <th className="gen-name">
                                                {gen}
                                            </th>
                                            <th className="gen-weight">
                                                {this.getGenWeight(index)}%
                                            </th>

                                        </tr>
                                        {lineage[lineageMap[gen]].map((coin:string) =>
                                            this.renderWeightRow(coin, myWeights)
                                        )}
                                        </>
                                    ) : this.renderCoinWeightsOrder(myWeights)
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="chloe-corner">
                            <img src={chloe} alt="chloe" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const Benchmark = connect(mapStateToProps)(BenchmarkBind);
export default Benchmark;
