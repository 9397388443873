import React, {Component} from 'react';

import {
    RiSendPlaneFill
} from 'react-icons/ri';
import {
    MdChatBubble
} from 'react-icons/md';

import { connect } from 'react-redux';
import { chatActions } from '../../../actions/actions';
import fetchData from '../../../fetchData';
import { IChatLog, IChatLogMessage, IUserRoomPreview } from '../../../interfaces/IChatLog';
import { Loading } from '../../Loading';

const mapStateToProps = (state:any) => ({
    chat:state.chat,
    userinfo:state.userinfo
});

const mapDispatchToProps = {
    setLoadedRooms:chatActions.setLoadedRooms,
    setDirectMessageLogs: chatActions.setDirectMessageLogs
}

interface DirectMessageLogsProps {
    activeRoom:string|null,

    chat: {
        loadedRooms: Array<string>,
        directMessageLogs: any,
        directMessageRooms: Array<IUserRoomPreview>,
        blocking:Array<string>,
        blockedBy:Array<string>,
    },
    userinfo: {
        username:string,
        id:string,
    },

    setLoadedRooms:(loadedRooms:any) => {},
    setDirectMessageLogs: (directMessageLogs:any) => {}
}

class DirectMessageLogsState {
    message:string;
    error:string;
    scrolledBottom:boolean;
    showNotif:boolean;
    constructor() {
        this.message = "";
        this.error = "";
        this.scrolledBottom = true;
        this.showNotif = false;
    }
}

class DirectMessageLogsBind extends Component<DirectMessageLogsProps> {

    state:DirectMessageLogsState;
    private bumper = React.createRef<HTMLDivElement>();
    private scrollContainer = React.createRef<HTMLDivElement>();
    constructor(props:DirectMessageLogsProps) {
        super(props);
        this.state = new DirectMessageLogsState();
    }

    componentDidUpdate(prevProps:DirectMessageLogsProps) {
        if(this.props.activeRoom !== prevProps.activeRoom) {

            if(this.props.activeRoom === null) return;
            let roomid:string = this.props.activeRoom;

            const loadedRooms = [...this.props.chat.loadedRooms];
            if(loadedRooms.indexOf(roomid) === -1) {
                fetchData('/api/getDirectMessages?roomid='+roomid)
                .then((data:any) => {
                    const chatLog:IChatLog = data.chat;
                    let logs = {...this.props.chat.directMessageLogs};
                    logs[roomid] = chatLog;
                    loadedRooms.push(roomid);
                    this.props.setDirectMessageLogs(logs);
                    this.props.setLoadedRooms(loadedRooms);
                    if(this.bumper.current) 
                        this.bumper.current.scrollIntoView();   
                });
                return;
            } else {
                if(this.bumper.current) 
                    this.bumper.current.scrollIntoView();   
            }

        }
        
        if(this.props.activeRoom === null) return;
        if(prevProps.activeRoom === null) return;
        let prevLog = prevProps.chat.directMessageLogs[this.props.activeRoom];
        let currentLog = this.props.chat.directMessageLogs[this.props.activeRoom];
        if(prevLog === undefined || currentLog === undefined) return;

        if(prevLog.length !== currentLog.length) {
            if(this.bumper.current && this.state.scrolledBottom) {
                this.bumper.current.scrollIntoView();
            } else {
                if(!this.state.scrolledBottom) {
                    this.setState({showNotif:true});
                }
            }
        } else {
            if(prevLog.length > 1) {
                let l = prevLog.length;
                let prevLast = prevLog[l - 1];
                let currentLast = currentLog[l - 1];
                if(prevLast.messageid !== currentLast.messageid) {
                    if(this.state.scrolledBottom) {
                        if(this.bumper.current) 
                            this.bumper.current.scrollIntoView();   
                    } else {
                        this.setState({showNotif:true});
                    }
                }
            }
        }
    }

    isMe(message:IChatLogMessage) {
        return message.userid === this.props.userinfo.id;
    }

    toggleBlocking() {
        
        let {recipient, recipientId} = this.getRecipient();
        let blocking = this.props.chat.blocking.indexOf(recipientId) !== -1;

        let url;
        if(blocking) {
            url = "/api/removeChatBlock";
        } else {
            url = "/api/addChatBlock";
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                blocked:recipientId
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                alert("Error updating block: " + data.message);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    handleKey(e:any) {
        if(e.which === 13) this.sendMessage();
    }
    
    checkScroll() {
        if(this.bumper.current !== null && this.scrollContainer.current !== null) {
            let contTop = this.scrollContainer.current.scrollTop;
            let scrollHeight = this.scrollContainer.current.scrollHeight;
            let contHeight = this.scrollContainer.current.clientHeight;
            let bottom = scrollHeight - contTop <= contHeight;

            this.setState({scrolledBottom:bottom});
            if(bottom) {
                this.setState({showNotif:false});
            }
        }
    }

    getRecipient() {
        let recipient = "";
        let recipientId = "";
        this.props.chat.directMessageRooms.forEach((room:IUserRoomPreview) => {
            if(room.roomid === this.props.activeRoom) {
                if(this.props.userinfo.username === room.user1name) {
                    recipient = room.user2name;
                    recipientId = room.user2;
                } else {
                    recipient = room.user1name;
                    recipientId = room.user1;
                }
            }
        });
        return {recipient, recipientId};
    }

    sendMessage() {
        if(this.state.message === "") return;
        if(this.state.message.length > 500) return;
        
        let {recipient, recipientId} = this.getRecipient();
        fetch('/api/addChatDM/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                receiver:recipientId,
                message:this.state.message
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.success) {
                this.setState({error:data.message});
            } else {
                this.setState({
                    error:'',
                    message:''
                });
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    render() {

        if(this.props.activeRoom === null) {
            return(
                <div className="dm-main-panel">
                    <div className="dm-no-room">
                        No Room Selected
                    </div>
                </div>
            )
        }

        const loadedRooms = [...this.props.chat.loadedRooms];
        if(loadedRooms.indexOf(this.props.activeRoom) === -1) {
            return(
                <div className="dm-main-panel">
                    <Loading />
                </div>
            )
        }

        let {recipient, recipientId} = this.getRecipient();

        let blocking = this.props.chat.blocking.indexOf(recipientId) !== -1;
        let blocked = this.props.chat.blockedBy.indexOf(recipientId) !== -1;

        let log = [...this.props.chat.directMessageLogs[this.props.activeRoom]];

        return(
            <div className="dm-main-panel">
                <div className="dm-header">
                    <div className="dm-recipient">{recipient}</div>
                    <div className="block-toggle flex flex-row flex-center">
                        Blocked
                        <div 
                            className={"block-toggle-btn " + (blocking ? "blocking" : "")}
                            onClick={() => this.toggleBlocking()}>
                        </div>
                    </div> 
                </div>
                <div 
                    className="dm-messages" 
                    ref={this.scrollContainer}
                    onScroll={() => this.checkScroll()}>

                    {
                        log.map((message:IChatLogMessage) =>
                        <div
                            key={message.timestamp} 
                            className={"dm-message-item " + (this.isMe(message) ? "me" : "you")}>
                            <div className="dm-message-item-inner">
                                {
                                    this.isMe(message) ?
                                    <>
                                    <div
                                        title={new Date(message.timestamp).toLocaleDateString()} 
                                        className="dm-message-timestamp">
                                        {new Date(message.timestamp).toLocaleTimeString()}
                                    </div>
                                    <div 
                                        className="dm-message-content"
                                        dangerouslySetInnerHTML={{__html: message.message}}>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div 
                                        className="dm-message-content"
                                        dangerouslySetInnerHTML={{__html: message.message}}>
                                    </div>
                                    <div
                                        title={new Date(message.timestamp).toLocaleDateString()}  
                                        className="dm-message-timestamp">
                                        {new Date(message.timestamp).toLocaleTimeString()}
                                    </div>
                                    </>
                                }
                                <div className="message-tail">
                                    <MdChatBubble />
                                </div>
                            </div>
                        </div>
                        )
                    }

                    <div className="bottom" ref={this.bumper}></div>
                    
                </div>
                <div className={"dm-input flex flex-row " + (blocked ? "inactive" : "")}>
                    <input 
                        placeholder="Message..." 
                        type="text"
                        maxLength={500}
                        value={this.state.message}
                        onKeyUp={(e) => this.handleKey(e)}
                        onChange={(e) => this.setState({message:e.target.value})} />
                    <div className="send-dm flex center-child">
                        <div 
                            onClick={() => this.sendMessage()}
                            className="send-dm-btn">
                            <RiSendPlaneFill style={{verticalAlign: 'middle'}}/>
                        </div>
                    </div>
                    {
                        this.state.error ?
                        <div className="dm-input dm-input-error">
                            {this.state.error}
                        </div> : null
                    }
                    {
                        blocked ?
                        <div className="dm-input-blocked flex center-child">
                            <div>
                            {recipient} has blocked you.
                            </div>
                        </div> : null
                    }
                    
                    {
                        this.state.showNotif ?
                        <div className="msg-notif">
                            New Message
                        </div> : null
                    }
                </div>
            </div>
        )
    }
}

const DirectMessageLogs = connect(
    mapStateToProps,
    mapDispatchToProps
)(DirectMessageLogsBind);

export default DirectMessageLogs;