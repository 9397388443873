import React, {Component} from "react";
import '../../../css/autotrader.scss';
import '../../../css/fundautotrader.scss';
import Coin from "../Coin";

import { mutualfundActions } from '../../actions/actions';
import {connect} from 'react-redux';

import {AutoTraderRule} from '../../interfaces/AutoTraderRule';
import ToggleSwitch from '../ToggleSwitch';
import {DragDropContext, Droppable } from 'react-beautiful-dnd';

import {lineage} from '../Icons';
import {
    MdClose
} from 'react-icons/md';
import {
    FiChevronDown,
    FiChevronUp
} from 'react-icons/fi';
import {
    BiUpArrow,
    BiDownArrow
} from 'react-icons/bi';
import {
    IoIosSave
} from 'react-icons/io';
import { 
    GrUndo
} from 'react-icons/gr';
import { ICoinDataCollection, ICoinInfo } from "../../interfaces/ICoinInfo"; 
import { Order, TransactionType } from "../../interfaces/ITransaction";
import numberWithCommas from "../../numberWithCommas";
import TradeRuleItem from "./TradeRuleItem";
import fetchData from "../../fetchData";
import getTransactionStatus from "../../getTransactionStatus";
import { IMutualFundPortfolio, IMutualFunds } from "../../interfaces/MutualFunds";
import MutualFundSection from "../mutualfunds/page views/MutualFundSection";

export interface IAutotrader {
    running:boolean,
    rules:Array<AutoTraderRule>,
    nextTradeTime:number,
    expectedBalance:number,
    pendingOrder:Array<Order>
}

const mapStateToProps = (state:any, props:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    stats:state.stats,
    settings:state.settings,
    mutualfunds: state.mutualfunds
});

const mapDispatchToProps = {
    setFundAutotrader: mutualfundActions.setFundAutotrader
}

interface FundAutoTraderEditorProps {
    stats: {
        coinInfo: ICoinDataCollection,
        brokerFee: number
    },
    userinfo: {
        loaded:boolean,
        brokerFeeCredits:number,
        muted:any,
        verified:boolean
    },
    session: {
        loggedin: boolean
    },
    settings: {
        marketSwitch: boolean
    },
    mutualfunds: {
        funds: IMutualFunds,
        autotraders: {[fund:string]:IAutotrader}
    },
    fund:string,
    setFundAutotrader: (fund:string, autotrader:IAutotrader) => {}
}

class FundAutoTraderEditorState {
    timeRemaining:{minutes:number, seconds:number};
    updated:boolean;
    lastSaved:number;
    updatedRules:Array<AutoTraderRule>;
    constructor() {
        this.updated = false;
        this.timeRemaining = {minutes:0, seconds:0};
        this.lastSaved = 0;
        this.updatedRules = [];
    }
}

class FundAutoTraderEditorBind extends Component<FundAutoTraderEditorProps> {

    state:FundAutoTraderEditorState;
    interval:any;
    constructor(props:FundAutoTraderEditorProps) {
        super(props);
        this.state = new FundAutoTraderEditorState();
    }

    onDragEnd = (result:any) => {
        const {destination, source} = result;
        if(!destination) return;
        if(
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) return;

        let rulesCopy = [...this.state.updatedRules];
        let oldRule = {...rulesCopy[source.index]};
        rulesCopy.splice(source.index, 1);
        rulesCopy.splice(destination.index, 0, oldRule);
        this.setState({updatedRules:rulesCopy});
        this.checkUpdated(rulesCopy);
    }

    newRules(oldRules:Array<AutoTraderRule>, newRules:Array<AutoTraderRule>) {
        let updated = false;
        if(oldRules.length !== newRules.length) {
            updated = true;
        }

        if(oldRules.length === newRules.length) {
            for(let i = 0; i < oldRules.length; i++) {
                const rule1 = oldRules[i];
                const rule2 = newRules[i];
                if(rule1.coin !== rule2.coin) {
                    updated = true;
                } else {
                    if((rule1.stepQuantity !== rule2.stepQuantity) || (rule1.targetQuantity !== rule2.targetQuantity)) {
                        updated = true;
                    }
                }
            }
        }

        return updated;
    }

    checkUpdated(updatedRules:Array<AutoTraderRule>) {

        let rules = JSON.parse(JSON.stringify(this.props.mutualfunds.autotraders[this.props.fund].rules));
        
        let updated = this.newRules(rules, updatedRules);

        if(updated) {
            this.setState({lastSaved: new Date().getTime()});
        } else {
            this.setState({lastSaved: 0})
        }
        this.setState({updated});
    }

    rulesContainCoin(coin:string) {
        let rules = [...this.state.updatedRules];
        let index = -1;
        rules.forEach((r:AutoTraderRule, i:number) => {
            if(r.coin === coin) index = i;
        });
        return index;
    }

    toggleCoin(coin:string) {

        let rules = [...this.state.updatedRules];
        let index = this.rulesContainCoin(coin);
        if(index === -1) {
            let currentQuant = this.getMyQuant(coin);
            rules.push({
                coin,
                type:TransactionType.BUY,
                stepQuantity:50,
                targetQuantity:currentQuant
            });
        } else {
            rules.splice(index, 1);
        }
        this.setState({updatedRules:rules});
        this.checkUpdated(rules);

    }

    clearAllCoins() {
        let newRules:Array<AutoTraderRule> = [];
        this.setState({updatedRules:newRules});
        this.checkUpdated(newRules);
    }

    resetChanges() {
        this.setState({
            updatedRules: JSON.parse(JSON.stringify(this.props.mutualfunds.autotraders[this.props.fund].rules)),
            updated:false
        });
    }

    saveChanges() {

        if(!this.state.updated) return;
        let updatedRules = [...this.state.updatedRules];

        fetch('/api/updateFundAutoTraderRules', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fund:this.props.fund,
                rules:updatedRules
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.setState({updated:false, lastSaved:0});
            } else {
                console.log(data.message);
            }
        })

    }

    toggleAutoTrader(setTo?:boolean) {

        let newStatus = setTo === undefined ?
            !this.props.mutualfunds.autotraders[this.props.fund].running : setTo;

        fetch('/api/toggleFundAutoTrader/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fund:this.props.fund,
                active:newStatus
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
            } else {
                console.log(data.message);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        });
    }

    setTargetQuantity(index:number, quantity:number) {
        let rules = [...this.state.updatedRules];
        rules[index].targetQuantity = quantity;
        let myQuant = this.getMyQuant(rules[index].coin);
        if(quantity < myQuant) {
            rules[index].type = TransactionType.SELL;
        } else if(quantity > myQuant) {
            rules[index].type = TransactionType.BUY;
        }
        this.setState({updatedRules:rules});
        this.checkUpdated(rules);
    }

    setStepQuantity(index:number, quantity:number) {
        let rules = [...this.state.updatedRules];
        rules[index].stepQuantity = quantity;
        this.setState({updatedRules:rules});
        this.checkUpdated(rules);
    }

    getCoinClass(coin:string) {
        if(this.rulesContainCoin(coin) !== -1) {
            return 'coin-outer';
        } else {
            return 'coin-outer coin-outer-inactive';
        }
    }

    filterName(coin:string) {
        return coin === "luna" ? "himemoriluna" : coin;
    }

    displayName(coin:string) {
        return coin === "himemoriluna" ? "luna" : coin;
    }

    getCoinPrice(coin:string) {
        let name = this.filterName(coin);
        return this.props.stats.coinInfo.data[name].price;
    }

    getCoinVolume(coin:string) {
        let name = this.filterName(coin);
        return this.props.stats.coinInfo.data[name].inCirculation;
    }

    getCoinSaleValue(coin:string) {
        let name = this.filterName(coin);
        return this.props.stats.coinInfo.data[name].saleValue;
    }

    getMyQuant(coin:string) {
        let portfolio:IMutualFundPortfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        let name = this.filterName(coin);
        if(portfolio[name] === undefined) {
            return 0;
        } else {
            return portfolio[name].amount;
        }
    }

    getTargetQuantity(coin:string) {
        let rules = [...this.state.updatedRules];
        let index = this.rulesContainCoin(coin);
        if(index !== -1) {
          return rules[index].targetQuantity;
        } else {
          return -1;
        }
    }

    getBubbleColour(coin:string) {
      let className = "coin-owned-bubble";
      let currentQuantity = this.getMyQuant(coin);
      let targetQuantity = this.getTargetQuantity(coin);
      if(targetQuantity !== -1) {
        if(targetQuantity == currentQuantity) {
            className += " bubble-rule-neutral";
        } else {
            if(targetQuantity > currentQuantity) {
                className += " bubble-rule-buy";
            } else {
                className += " bubble-rule-sell";
            }
        }
      } else {
        className += " bubble-rule-neutral";
      }
      return className;
    }

    getBalance() {
        return this.props.mutualfunds.funds[this.props.fund].balance;
    }

    getMeanPurchasePrice(coin:string) {
        let portfolio:IMutualFundPortfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        let name = this.filterName(coin);
        if(portfolio[name] === undefined) {
            return 0;
        } else {
            return Math.round(portfolio[name].meanPurchasePrice * 100) / 100;
        }
    }

    getTimestamp(coin:string) {
        let portfolio:IMutualFundPortfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        let name = this.filterName(coin);
        if(portfolio[name] === undefined) {
            return 0;
        } else {
            return portfolio[name].timestamp;
        }
    }

    updateTimeRemaining() {

        let timeRemaining = (this.props.mutualfunds.autotraders[this.props.fund].nextTradeTime - new Date().getTime()) / 1000;
        if(timeRemaining < 0) timeRemaining = 0;

        let minutes = Math.floor(timeRemaining / 60);
        let seconds = Math.floor(timeRemaining - (minutes * 60));

        this.setState({timeRemaining: {
            minutes, seconds
        }});

    }

    autoSave() {
        if(this.state.lastSaved !== 0) {
            const now = new Date().getTime();
            if((now - this.state.lastSaved) > (1000 * 60 * 5) && this.state.updated) {
                this.saveChanges();
            }
        }
    }

    componentDidMount() {
        if(this.props.mutualfunds.autotraders[this.props.fund] === undefined) {

            fetchData('/api/getFundAutoTraderRules?fund=' + this.props.fund)
            .then((data:any) => {

                let autotrader:IAutotrader = {
                    running:data.traderStatus,
                    rules:data.rules,
                    nextTradeTime:data.timestamp + (1000 * 60 * 10 + 1),
                    expectedBalance:0,
                    pendingOrder:[]
                };

                this.props.setFundAutotrader(this.props.fund, autotrader);
                this.setState({updatedRules:JSON.parse(JSON.stringify(data.rules))});
                this.generateOrders();
            });
        } else {
            this.setState({updatedRules: JSON.parse(JSON.stringify(this.props.mutualfunds.autotraders[this.props.fund].rules))}, () => {
                this.checkUpdated(this.state.updatedRules);
            });
            this.generateOrders();
        }

        this.interval = setInterval(() => {
            this.updateTimeRemaining();
            this.autoSave();
        }, 1000);
    }

    componentDidUpdate(prevProps:FundAutoTraderEditorProps) {
        if(prevProps.mutualfunds.autotraders[this.props.fund] === undefined || this.props.mutualfunds.autotraders[this.props.fund] === undefined) return;
        
        if(prevProps.mutualfunds.autotraders[this.props.fund].nextTradeTime !== this.props.mutualfunds.autotraders[this.props.fund].nextTradeTime) {
            this.generateOrders();
        }

        if(this.newRules(prevProps.mutualfunds.autotraders[this.props.fund].rules, this.props.mutualfunds.autotraders[this.props.fund].rules)) {
            this.setState({updatedRules: this.props.mutualfunds.autotraders[this.props.fund].rules}, () => {
                this.generateOrders();
            });
            return;
        }

        if(prevProps.mutualfunds.autotraders[this.props.fund].running !== this.props.mutualfunds.autotraders[this.props.fund].running) {
            this.generateOrders();
            return;
        }

        let currentBalance = this.props.mutualfunds.funds[this.props.fund].balance;
        let prevBalance = prevProps.mutualfunds.funds[this.props.fund].balance;
        if(currentBalance !== prevBalance) {
            this.generateOrders();
            return;
        }
        let currentCoins = this.props.mutualfunds.funds[this.props.fund].portfolio;
        let prevCoins = prevProps.mutualfunds.funds[this.props.fund].portfolio;
        let coinsList = Object.keys(currentCoins);
        if(coinsList.length !== Object.keys(prevCoins).length) {
            this.generateOrders();
            return;
        }
        for(let i = 0; i < coinsList.length; i++) {
            let currentCoin:string = coinsList[i];
            if(prevCoins[currentCoin]) {
                if(prevCoins[currentCoin].amount !== currentCoins[currentCoin].amount) {
                    this.generateOrders();
                    return;
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    generateOrders() {

        let orders:Array<Order> = [];
        let portfolio:IMutualFundPortfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        let currentBalance = this.props.mutualfunds.funds[this.props.fund].balance;

        let currentAutotrader:IAutotrader = JSON.parse(JSON.stringify({...this.props.mutualfunds.autotraders[this.props.fund]}));

        if(!this.props.mutualfunds.autotraders[this.props.fund].running) {
            currentAutotrader.expectedBalance = currentBalance;
            currentAutotrader.pendingOrder = orders;
            this.props.setFundAutotrader(this.props.fund, currentAutotrader); 
            return;
        }

        let rules = currentAutotrader.rules;
        for(let i = 0; i < rules.length; i++) {

            const rule = rules[i];
            const name = this.filterName(rule.coin);
            const coinInfo = this.props.stats.coinInfo.data[name];
            
            let stepQuantity = Math.max(0, Math.min(100, rule.stepQuantity));
            let currentAmount;
            if(portfolio[name] === undefined) {
                currentAmount = 0;
            } else {
                currentAmount = portfolio[name].amount;
            }

            if(Math.abs(rule.targetQuantity - currentAmount) < stepQuantity) {
                stepQuantity = Math.abs(rule.targetQuantity - currentAmount);
            }

            const {
                timeRemaining,
                buyDisabled,
                sellDisabled
            } = this.getFundTransactionStatus(
                name,
                stepQuantity
            );

            let timeUntilNextCycle = this.props.mutualfunds.autotraders[this.props.fund].nextTradeTime - new Date().getTime();
            if(timeRemaining > timeUntilNextCycle) continue;
            if(currentAmount < rule.targetQuantity) {
                if(!buyDisabled) {
                    let quantity = Math.min(rule.targetQuantity - currentAmount, stepQuantity);
                    let taxRate = Math.max(0.1, quantity * 0.008 - 0.3);
                    let totalCost = (quantity * coinInfo.price) * (1 + taxRate);
                    currentBalance -= totalCost;
                    orders.push({coin:name, quantity, type:TransactionType.BUY})
                }
            } else if(currentAmount > rule.targetQuantity) {
                if(!sellDisabled) {
                    let quantity = Math.min(currentAmount - rule.targetQuantity, stepQuantity);
                    let taxRate = Math.max(0.1, quantity * 0.008 - 0.3);
                    let tax = taxRate * coinInfo.saleValue * quantity;
                    currentBalance += (coinInfo.saleValue * quantity) - tax;
                    orders.push({coin:name, quantity, type:TransactionType.SELL})
                }
            }
        }

        currentAutotrader.expectedBalance = currentBalance;
        currentAutotrader.pendingOrder = orders;

        this.props.setFundAutotrader(this.props.fund, currentAutotrader);

    }

    
    getFundTransactionStatus(
        coin:string,
        stepQuantity:number
    ) {

        let name = coin;
        if(name === "luna") name = "himemoriluna";

        let portfolio = {...this.props.mutualfunds.funds[this.props.fund].portfolio};
        let balance = this.props.mutualfunds.funds[this.props.fund].balance;
        
        let amtOwned = (portfolio[name] === undefined) ? 0 : portfolio[name].amount;

        const price = this.props.stats.coinInfo.data[name].price;

        let buyDisabled = false;
        let sellDisabled = false;
        let lastBought = portfolio[name] === undefined ? 0 : portfolio[name].timestamp;
        
        let tax = Math.max(0.1, stepQuantity * 0.008 - 0.3);

        let buyTotal = stepQuantity * price * (1 + tax);

        buyDisabled =
            (balance < buyTotal);
        sellDisabled =
            (amtOwned < stepQuantity)
            || !this.props.mutualfunds.funds[this.props.fund].released;

        let timeRemaining = 0;
        const now = new Date().getTime();
        const e = (1000 * 60 * 10);
        if((now - lastBought) < e) {
            timeRemaining = e - (now - lastBought);
        }

        return {
            timeRemaining,
            buyDisabled,
            sellDisabled
        }
    }

    getMetrics() {

        const orders:Array<Order> = this.props.mutualfunds.autotraders[this.props.fund].pendingOrder;
        let buys:Array<string> = [];
        let sells:Array<string> = [];

        orders.forEach((order:Order) => {
            if(order.type === TransactionType.BUY) {
                buys.push(order.quantity + "x " + order.coin);
            } else {
                sells.push(order.quantity + "x " + order.coin)
            }
        });
        return {
            total: orders.length,
            buys,
            sells
        }
    }

    render() {
        if(this.props.mutualfunds.autotraders[this.props.fund] === undefined) return null;
        if(!this.props.session.loggedin) return null;
        if(!this.props.userinfo.loaded) return null;
        let allCoins:Array<string> = [];
        lineage.forEach((gen:Array<string>) => {
            allCoins = [...allCoins, ...gen];
        });
        allCoins = allCoins.map((coin:string) => {
            return this.filterName(coin);
        });
        let {
            total,
            buys,
            sells
        } = this.getMetrics();

        let {
            minutes, seconds
        } = this.state.timeRemaining;

        let delta = Math.round((this.props.mutualfunds.autotraders[this.props.fund].expectedBalance - this.props.mutualfunds.funds[this.props.fund].balance) * 100) / 100;

        return(
            <MutualFundSection
                className="auto-trader-section" 
                fund={this.props.fund}>
            <DragDropContext
                onDragEnd={this.onDragEnd}>
                <div className={`auto-trader-editor fund-trader ${this.state.updated ? "updated" : ""}`}>
                    <div className="auto-trader-inner">
                        {
                            this.state.updated ?
                            <div className="updated-controls">
                                <div 
                                    className="save-changes"
                                    title="Save Changes"
                                    onClick={() => this.saveChanges()}>
                                    <IoIosSave style={{verticalAlign:'middle'}}/>
                                </div>
                                <div 
                                    className="reverse-changes"
                                    onClick={() => this.resetChanges()}
                                    title="Reset Changes">
                                    <GrUndo style={{verticalAlign:'middle'}}/>
                                </div>
                            </div> : null
                        }
                        <div className="auto-trader-header">
                            auto-trader
                        </div>
                        {
                            this.props.mutualfunds.autotraders[this.props.fund].running ?
                            <>
                            <div className="trade-metrics">
                                <div className="trade-amounts">
                                    Trading <span className="trade-amount-quant">{total}</span> coin
                                    {
                                        total === 1 ? "" : "s"
                                    }
                                </div>
                                <div className="trade-amounts">
                                    Buying <span className="trade-amount-quant">{buys.length}</span> coin
                                    {
                                        buys.length === 1 ? "" : "s"
                                    }
                                </div>
                                <div className="trade-amounts">
                                    Selling <span className="trade-amount-quant">{sells.length}</span> coin
                                    {
                                        sells.length === 1 ? "" : "s"
                                    }
                                </div>
                            </div>
                            <div className="trade-summary">
                                {
                                    buys.length > 0 ?
                                    <div className="trade-summary-list">
                                        Buying <span className="trade-summary-names buys">{buys.join(", ")}</span>
                                    </div> : null
                                }
                                { buys.length > 0 && sells.length > 0 ? "and" : ""}
                                {
                                    sells.length > 0 ?
                                    <div className="trade-summary-list">
                                        Selling <span className="trade-summary-names sells">{sells.join(", ")}</span>
                                    </div> : null
                                }
                                {
                                    total > 0 ?
                                    "in" : ""
                                }
                            </div>
                            </>: null
                        }
                        {
                            this.props.mutualfunds.autotraders[this.props.fund].running
                            && total > 0
                            && (minutes > 0 || seconds > 0) ?
                            <>
                            <div className="trade-countdown">
                            {
                                minutes > 0 ?
                                <>
                                <span className="countdown-number">{minutes}</span> minute{
                                    minutes === 1 ? "" : "s"
                                }
                                {" "} and {" "}
                                </> : null
                            }

                            <span className="countdown-number">{seconds}</span> second{
                                seconds === 1 ? "" : "s"
                            }.
                            </div>
                            </> : null
                            
                        }
                        {
                            this.props.mutualfunds.autotraders[this.props.fund].running
                            && this.props.mutualfunds.autotraders[this.props.fund].pendingOrder.length ?
                            <div className="trade-balance">
                            Expected Balance: <span>
                                ${numberWithCommas(Math.round(this.props.mutualfunds.autotraders[this.props.fund].expectedBalance * 100) / 100)}
                            </span> <span className={
                                delta > 0 ? "green" : "red"
                            }>({
                                delta > 0 ? <BiUpArrow style={{verticalAlign: 'middle'}}/> : <BiDownArrow style={{verticalAlign: 'middle'}}/>
                            }${numberWithCommas(Math.abs(delta))})</span>
                            </div> : null
                        }
                        <div className="trade-rules-container">
                            <div className="trader-controls">
                                <div className="trader-actions flex flex-row flex-center">
                                    <div className="trader-actions-label">
                                        Running:
                                    </div>
                                    <ToggleSwitch
                                        onLabel={"On"}
                                        offLabel={"Off"}
                                        switchState={this.props.mutualfunds.autotraders[this.props.fund].running}
                                        onToggle={(setTo?:boolean) => this.toggleAutoTrader(setTo)}/>
                                </div>
                                <div className="trader-coins">
                                    {
                                    allCoins.map((coin:string) =>
                                        <div
                                            className={this.getCoinClass(coin)}
                                            onClick={() => this.toggleCoin(coin)}
                                            key={coin}>
                                            {
                                                this.getMyQuant(coin) > 0 ?
                                                <div className={this.getBubbleColour(coin)}>
                                                </div> : null
                                            }
                                            <Coin name={this.displayName(coin)}/>
                                        </div>
                                    )
                                    }
                                </div>
                                <div
                                    className="clear-all-coins"
                                    onClick={() => this.clearAllCoins()}>
                                    Clear All
                                </div>
                            </div>
                            {
                            this.state.updatedRules.length === 0 ?
                            <div className="no-rules">
                                You haven't added any rules yet.
                            </div>
                            :
                            <Droppable droppableId={"test"}>
                                {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    className="trade-rules-list"
                                    {...provided.droppableProps}>

                                    {
                                        this.state.updatedRules.map((d:AutoTraderRule, index:number) =>
                                        (
                                            <TradeRuleItem
                                                pendingOrder={this.props.mutualfunds.autotraders[this.props.fund].pendingOrder}
                                                coin={d.coin}
                                                price={this.getCoinPrice(d.coin)}
                                                saleValue={this.getCoinSaleValue(d.coin)}
                                                volume={this.getCoinVolume(d.coin)}
                                                quantity={this.getMyQuant(d.coin)}
                                                stepQuantity={d.stepQuantity ? d.stepQuantity : 1}
                                                meanPurchasePrice={this.getMeanPurchasePrice(d.coin)}
                                                balance={this.getBalance()}
                                                targetQuantity={d.targetQuantity}
                                                timestamp={this.getTimestamp(d.coin)}
                                                type={d.type}
                                                isFund={true}
                                                index={index}
                                                key={d.coin}
                                                setTargetQuantity={(index:number, quantity:number) =>
                                                    this.setTargetQuantity(index, quantity)}
                                                setStepQuantity={(index:number, quantity:number) =>
                                                    this.setStepQuantity(index, quantity)}/>
                                        ))
                                    }

                                    {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                            }
                        </div>
                    </div>
                </div>
            </DragDropContext>
            </MutualFundSection>
        );
    }
}

const FundAutoTraderEditor = connect(
    mapStateToProps,
    mapDispatchToProps
)(FundAutoTraderEditorBind);

export default FundAutoTraderEditor;
