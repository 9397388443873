import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Redirect } from 'react-router';
import GlobalChatModule from './GlobalChatModule';

import "../../../css/chat/globalchatpage.scss";

const mapStateToProps = (state:any) => ({
    session:state.session
})

interface GlobalChatPageProps {
    session: {
        loggedin:boolean
    }
}

class GlobalChatPageBind extends Component<GlobalChatPageProps> {

    render() {
        if(!this.props.session.loggedin) {
            return(
                <Redirect to="/login/globalchat" />
            )
        }
        return(
            <div className="global-chat-page-outer">
                <GlobalChatModule visible={true}/>
            </div>
        )
    }
}

const GlobalChatPage = connect(mapStateToProps)(GlobalChatPageBind);
export default GlobalChatPage;