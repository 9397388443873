import React, {Component} from 'react';
import fubukiconfused from '../../../images/fubuki-confused.png';

class BenchmarkInfo extends Component {
    render() {
        return(
            <div className="benchmark-info flex flex-row">
                <div className="benchmark-info-text">
                The Y&G 66 is a benchmark index that keeps track of the NASFAQ market. You can use the index to compare your own individual performance to the market's overall performance. The index is a dimensionless value that takes into account the market capitalization and total outstanding shares of each coin. Market capitalization is the current value of a single share of a coin multiplied by the number of outstanding shares of that coin. The index is recalculated every quarter hour and recorded once daily at 9:00amET. If logged in, you can also see a comparison of your daily performance in relation to the market performance. Try to outperform the market! Below the main index information, you will also find the weight of each coin. The weight of a coin is the market cap of that coin divided by the total market cap of the entire market. Adjacent to this value you will find the weight of that coin in your own portfolio, which is the proportion of that coin's total value in your wallet to your entire net worth. The active weight is simply the difference between your weight and the market weight. To have a positive active weight is to be bullish on that coin. Likewise, to have a negative active weight is to be bearish on that coin. Click the column headers to sort the table by that metric in ascending order, and click again to sort in descending order.
                </div>
                <div className="fubuki-question">
                    <img src={fubukiconfused} alt="confused fubuki" />
                </div>

            </div>
        )
    }
}

export default BenchmarkInfo;
