import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

import '../../../css/mutualfunds/mutualfundindexitem.scss';
import getForegroundFromBackground from '../../getForegroundFromBackground';
import hexToRgb from '../../hexToRBG';
import { IMutualFundHistoryCollection, IMutualFundPayouts, IMutualFunds, IMutualFundStatCollection } from '../../interfaces/MutualFunds';
import numberWithCommas from '../../numberWithCommas';
import Coin from '../Coin';
import TradeButtonWrapper from '../TradeButton';
import MutualFundHistoryGraph from './MutualFundHistoryGraph';

import {
    IoIosArrowDropdown
} from 'react-icons/io';
import { GoDash } from 'react-icons/go';
import { BiDownArrow, BiUpArrow } from 'react-icons/bi';

export const getGradientString = (r:number, g:number, b:number) => {
    let darkR = Math.round(r * 0.7);
    let darkG = Math.round(g * 0.8);
    let darkB = Math.round(b * 0.8);
    let newR = Math.round(Math.min(r * 1.3, 255));
    let newG = Math.round(Math.min(g * 1.4, 255));
    let newB = Math.round(Math.min(b * 1.5, 255));

    let colorString = `linear-gradient(157deg, rgba(${darkR},${darkG},${darkB},1) 0%, rgba(${r},${g},${b},1) 50%, rgba(${newR},${newG},${newB},1) 100%)`;
    return colorString;
}

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {

}

class MutualFundIndexItemState {
    showGraph:boolean = false;
}

interface IMutualFundIndexItemProps {
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
        fundPayouts: IMutualFundPayouts
    }
    fund:string
}

class MutualFundIndexItemBind extends Component<IMutualFundIndexItemProps> {

    state:MutualFundIndexItemState;
    constructor(props:IMutualFundIndexItemProps) {
        super(props);
        this.state = new MutualFundIndexItemState();
    }

    getColor() {
        let fund = this.props.mutualfunds.funds[this.props.fund];
        return "#" + fund.color;
    }

    getStyle() {
        return {"borderColor": this.getColor()}
    }

    getGradient() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getGradientString(r, g, b);
    }

    getForegroundColor() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getForegroundFromBackground(r, g, b);
    }

    toggleGraph() {
        this.setState({showGraph:!this.state.showGraph});
    }

    getPayout() {
        if(this.props.mutualfunds.fundPayouts[this.props.fund] === undefined) {
            return 0;
        } else {
            return this.props.mutualfunds.fundPayouts[this.props.fund];
        }
    }

    getPayoutPercent() {
        if(this.props.mutualfunds.fundPayouts[this.props.fund] === undefined) {
            return 0;
        } else {
            return Math.round((this.props.mutualfunds.fundPayouts[this.props.fund] / this.props.mutualfunds.fundstats[this.props.fund].price) * 10000) / 100;
        }
    }

    priceDirectionIcon(delta:number) {
        if(delta === 0) {
            return (
                <GoDash style={{verticalAlign: 'middle'}}/>
            )
        }
        if(delta < 0) {
            return(
                <BiDownArrow style={{verticalAlign: 'middle'}}/>
            )
        } else {
            return(
                <BiUpArrow style={{verticalAlign: 'middle'}}/>
            )
        }
    }

    priceDirectionClass(delta:number) {
        if(delta === 0) {
            return "";
        } else if(delta > 0) {
            return "increase";
        } else {
            return "decrease";
        }
    }

    render() {
        let fund = this.props.mutualfunds.funds[this.props.fund];
        let fundStats = this.props.mutualfunds.fundstats[this.props.fund];
        let fundHistory = this.props.mutualfunds.fundhistory[this.props.fund];
        if(fund === undefined || fundStats === undefined) return null;

        let historyItem;
        if(fundHistory.length < 2) {
            historyItem = fundHistory[fundHistory.length - 1];
        } else {
            historyItem = fundHistory[fundHistory.length - 2];
        }

        let prevNetworth = historyItem.networth;
        let netWorthDelta = Math.round((fundStats.networth - prevNetworth) * 100) / 100;
        let prevPrice = historyItem.price;
        let priceDelta = Math.round((fundStats.price - prevPrice) * 100) / 100;
        let prevVolume = historyItem.volume;
        let volumeDelta = Math.round((fundStats.volume - prevVolume) * 100) / 100;

        let netWorthDeltaPercent = Math.round((netWorthDelta / prevNetworth) * 100);
        let priceDeltaPercent = Math.round((priceDelta / prevPrice) * 100);
        let volumeDeltaPercent = Math.round((volumeDelta / prevVolume) * 100);

        return(
            <div
                className="mutual-fund-index-item">

                <div
                    style={{"background":this.getGradient()}}
                    className="mutual-fund-index-item-background"></div>
                <div
                    style={{"borderColor":this.getColor()}}
                    className="mutual-fund-index-item-inner">

                    <Link to={"/mutualfund/" + this.props.fund}>
                        <div className="info-bar flex flex-row">
                            <div
                                style={{
                                    "background":this.getColor(),
                                    "color":this.getForegroundColor()
                                }}
                                className="mutual-fund-tag">
                                {fund.tag}
                            </div>
                            <div className="mutual-fund-name">
                                {fund.name}
                            </div>
                            <div className="mutual-fund-icon">
                                <Coin name={fund.icon}/>
                            </div>

                        </div>
                    </Link>

                    <div className="mutual-fund-item-content">
                        <div className="mutual-fund-stats">
                            <div className={"mutual-fund-current-stats " + (!this.state.showGraph ? "mutual-fund-current-stats-compact" : "")}>
                                <div className="mutual-fund-current-stats-row">
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Share Price
                                        </div>
                                        <div className={"stat-item-data price " + this.priceDirectionClass(priceDelta)}>
                                            ${numberWithCommas(fundStats.price)}
                                            <div className={"delta-stats " + this.priceDirectionClass(priceDelta)}>
                                                <div>
                                                {this.priceDirectionIcon(priceDelta)}
                                                </div>
                                                <div>
                                                ${numberWithCommas(Math.abs(priceDelta))}
                                                </div>
                                                <div>
                                                {numberWithCommas(Math.abs(priceDeltaPercent))}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Volume
                                        </div>
                                        <div className="stat-item-data">
                                            {numberWithCommas(fundStats.volume)}
                                            <div className={"delta-stats " + this.priceDirectionClass(volumeDelta)}>
                                                <div>
                                                {this.priceDirectionIcon(volumeDelta)}
                                                </div>
                                                <div>
                                                {numberWithCommas(Math.abs(volumeDelta))}
                                                </div>
                                                <div>
                                                {numberWithCommas(Math.abs(volumeDeltaPercent))}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Net Worth
                                        </div>
                                        <div className={"stat-item-data price " + this.priceDirectionClass(netWorthDelta)}>
                                            ${numberWithCommas(fundStats.networth)}
                                            <div className={"delta-stats " + this.priceDirectionClass(netWorthDelta)}>
                                                <div>
                                                {this.priceDirectionIcon(netWorthDelta)}
                                                </div>
                                                <div>
                                                ${numberWithCommas(Math.abs(netWorthDelta))}
                                                </div>
                                                <div>
                                                {numberWithCommas(Math.abs(netWorthDeltaPercent))}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Div. Payout/Share
                                        </div>
                                        <div className="stat-item-data price">
                                            ${numberWithCommas(this.getPayout())}
                                        </div>
                                    </div>
                                </div>
                                <div className="mutual-fund-current-stats-row">
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Payout %
                                        </div>
                                        <div className="stat-item-data">
                                            {numberWithCommas(this.getPayoutPercent())}%
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Fee
                                        </div>
                                        <div className="stat-item-data">
                                            {numberWithCommas(fund.fee)}%
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Shareholder Div %
                                        </div>
                                        <div className="stat-item-data">
                                            {numberWithCommas(fund.shareholderDivRate)}%
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            CEO Div %
                                        </div>
                                        <div className="stat-item-data">
                                            {numberWithCommas(fund.ceoDivRate)}%
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Balance
                                        </div>
                                        <div className="stat-item-data price">
                                            ${numberWithCommas(fund.balance)}
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Public
                                        </div>
                                        <div className="stat-item-data">
                                            {fund.released ? "Yes" : "No"}
                                        </div>
                                    </div>
                                    <div className="mutual-fund-stat-item">
                                        <div className="stat-item-label">
                                            Members
                                        </div>
                                        <div className="stat-item-data">
                                            {numberWithCommas(fundStats.members)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                onClick={() => this.toggleGraph()}
                                className={"toggle-graph flex center-child " +
                                    (this.state.showGraph ? "graph-visible" : "")}>
                                <IoIosArrowDropdown />
                            </div>
                            {
                                this.state.showGraph ?
                                <div className="mutual-fund-index-graph-container">
                                    <MutualFundHistoryGraph fund={this.props.fund}/>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>


            </div>
        )
    }

}

const MutualFundIndexItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundIndexItemBind);

export default MutualFundIndexItem;
