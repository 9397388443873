export const CoinColors:{[coin:string]:string} = {
    "hololive":"rgb(45, 189, 255)",
    "sora":"rgb(255, 122, 204)",
    "roboco":"rgb(35, 36, 39)",
    "miko":"rgb(247, 85, 185)",
    "suisei":"rgb(95, 170, 255)",
    "azki":"rgb(245, 42, 110)",
    "mel":"rgb(255, 239, 166)",
    "fubuki":"rgb(143, 204, 255)",
    "matsuri":"rgb(255, 174, 68)",
    "aki":"rgb(104, 255, 210)",
    "haato":"rgb(255, 161, 208)",
    "aqua":"rgb(198, 124, 241)",
    "shion":"rgb(116, 65, 163)",
    "ayame":"rgb(228, 66, 60)",
    "choco":"rgb(255, 241, 111)",
    "subaru":"rgb(197, 248, 115)",
    "mio":"rgb(32, 32, 32)",
    "okayu":"rgb(210, 170, 255)",
    "korone":"rgb(242, 245, 90)",
    "pekora":"rgb(255, 154, 60)",
    "rushia":"rgb(64, 235, 192)",
    "flare":"rgb(41, 51, 107)",
    "noel":"rgb(41, 49, 77)",
    "marine":"rgb(194, 59, 100)",
    "kanata":"rgb(34, 185, 255)",
    "coco":"rgb(252, 139, 34)",
    "watame":"rgb(255, 253, 151)",
    "towa":"rgb(152, 255, 149)",
    "luna":"rgb(255, 155, 202)",
    "lamy":"rgb(255, 255, 255)",
    "nene":"rgb(255, 140, 45)",
    "botan":"rgb(187, 187, 187)",
    "polka":"rgb(59, 95, 255)",
    "laplus":"rgb(83, 71, 189)",
    "lui":"rgb(202, 51, 101)",
    "koyori":"rgb(252, 158, 255)",
    "chloe":"rgb(131, 12, 12)",
    "iroha":"rgb(36, 109, 91)",
    "calliope":"rgb(231, 60, 117)",
    "kiara":"rgb(231, 71, 60)",
    "inanis":"rgb(73, 43, 97)",
    "gura":"rgb(60, 114, 231)",
    "amelia":"rgb(255, 237, 132)",
    "irys":"rgb(165, 17, 111)",
    "sana":"rgb(255, 153, 58)",
    "fauna":"rgb(191, 255, 223)",
    "kronii":"rgb(40, 110, 240)",
    "mumei":"rgb(165, 111, 82)",
    "baelz":"rgb(255, 59, 59)",
    "risu":"rgb(247, 220, 226)",
    "moona":"rgb(115, 97, 216)",
    "iofi":"rgb(250, 91, 144)",
    "ollie":"rgb(206, 32, 26)",
    "melfissa":"rgb(255, 249, 158)",
    "reine":"rgb(65, 116, 255)",
    "zeta":"rgb(153, 160, 173)",
    "kovalskia":"rgb(255, 223, 78)",
    "kanaeru":"rgb(177, 220, 255)",
    "ui":"rgb(255, 255, 255)",
    "nana":"rgb(205, 222, 241)",
    "pochimaru":"rgb(255, 209, 109)",
    "ayamy":"rgb(255, 175, 244)",
    "nabi":"rgb(138, 210, 240)",
    "nachoneko":"rgb(73, 183, 197)",
    "rurudo":"rgb(255, 238, 140)",
    "civia":"rgb(131, 185, 255)",
    "altare": "rgb(40, 185, 247)",
    "dezmond": "rgb(244, 232, 95)",
    "syrios" : "rgb(255, 161, 29)",
    "vesper": "rgb(98, 98, 98)",
    "miyabi": "rgb(228, 71, 71)",
    "izuru": "rgb(43, 53, 125)",
    "aruran": "rgb(60, 125, 93)",
    "rikka": "rgb(250, 203, 255)",
    "roberu": "rgb(243, 148, 15)",
    "astel": "rgb(26, 17, 120)",
    "temma": "rgb(225, 215, 104)",
    "kaoru": "rgb(255, 200, 251)",
    "shien": "rgb(98, 52, 197)",
    "oga": "rgb(70, 160, 54)",
    "shiori": "rgb(185, 130, 251)",
    "koseki": "rgb(104, 36, 222)",
    "nerissa": "rgb(85, 62, 236)",
    "fuwamoco": "rgb(127, 175, 253)",
    "ao": "rgb(116, 138, 247)",
    "kanade": "rgb(247, 242, 186)",
    "ririka": "rgb(216, 94, 81)",
    "raden": "rgb(72, 45, 60)",
    "hajime": "rgb(234, 200, 177)",
    "bettel": "rgb(210, 58, 152)",
    "flayon": "rgb(155, 159, 199)",
    "hakka": "rgb(142, 78, 172)",
    "shinri": "rgb(237, 185, 99)",
    "rexford": "rgb(199, 21, 39)",
    "goldbullet": "rgb(242, 113, 122)",
    "octavio": "rgb(198, 48, 103)",
    "ruze": "rgb(239, 133, 69)",
    "gigi": "rgb(229, 72, 74)",
    "elizabeth": "rgb(189, 61, 82)",
    "cecilia": "rgb(255, 109, 119)",
    "raora": "rgb(215, 102, 138)",
}