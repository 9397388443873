export const links:{[name:string]:{[link:string]:string}} = {
    aki: {
        youtube: 'https://www.youtube.com/channel/UCFTLzh12_nrtzqBPsTCqenA',
        twitter: 'https://twitter.com/akirosenthal'
    },
    altare: {
        youtube: 'https://www.youtube.com/channel/UCyxtGMdWlURZ30WSnEjDOQw',
        twitter: 'https://twitter.com/regisaltare'
    },
    amelia: {
        youtube: 'https://www.youtube.com/channel/UCyl1z3jo3XHR1riLFKG5UAg',
        twitter: 'https://twitter.com/watsonameliaEN'
    },
    ao: {
        youtube: 'https://www.youtube.com/channel/UCMGfV7TVTmHhEErVJg1oHBQ',
        twitter: 'https://twitter.com/hiodoshiao'
    },
    aqua: {
        youtube: 'https://www.youtube.com/channel/UC1opHUrw8rvnsadT-iGp7Cg',
        twitter: 'https://twitter.com/minatoaqua'
    },
    aruran: {
        youtube: 'https://www.youtube.com/channel/UCKeAhJvy8zgXWbh9duVjIaQ',
        twitter: 'https://twitter.com/arurandeisu'
    },
    astel: {
        youtube: 'https://www.youtube.com/channel/UCNVEsYbiZjH5QLmGeSgTSzg',
        twitter: 'https://twitter.com/astelleda'
    },
    ayame: {
        youtube: 'https://www.youtube.com/channel/UC7fk0CB07ly8oSl0aqKkqFg',
        twitter: 'https://twitter.com/nakiriayame'
    },
    ayamy: {
        youtube: 'https://www.youtube.com/channel/UCr9p1ZjLKgfaoqNorY7PiWQ',
        twitter: 'https://twitter.com/ayamy_garubinu'
    },
    azki: {
        youtube: 'https://www.youtube.com/channel/UC0TXe_LYZ4scaW2XMyi5_kw',
        twitter: 'https://twitter.com/AZKi_VDiVA'
    },
    baelz: {
        youtube: 'https://www.youtube.com/channel/UCgmPnx-EEeOrZSg5Tiw7ZRQ',
        twitter: 'https://twitter.com/hakosbaelz'
    },
    bettel: {
        youtube: 'https://www.youtube.com/channel/UCHP4f7G2dWD4qib7BMatGAw',
        twitter: 'https://twitter.com/gavisbettel'
    },
    botan: {
        youtube: 'https://www.youtube.com/channel/UCUKD-uaobj9jiqB-VXt71mA/',
        twitter: 'https://twitter.com/shishirobotan'
    },
    calliope: {
        youtube: 'https://www.youtube.com/channel/UCL_qhgtOy0dy1Agp8vkySQg',
        twitter: 'https://twitter.com/moricalliope'
    },
    cecilia: {
        youtube: 'https://www.youtube.com/channel/UCvN5h1ShZtc7nly3pezRayg',
        twitter: 'https://x.com/ceciliaimgreen'
    },
    chloe: {
        youtube: 'https://www.youtube.com/channel/UCIBY1ollUsauvVi4hW4cumw',
        twitter: 'https://twitter.com/sakamatachloe'
    },
    choco: {
        youtube: 'https://www.youtube.com/channel/UC1suqwovbL1kzsoaZgFZLKg',
        twitter: 'https://twitter.com/yuzukichococh'
    },
    civia: {
        youtube: 'https://www.youtube.com/channel/UCgNVXGlZIFK96XdEY20sVjg/',
        twitter: 'https://twitter.com/_Civia'
    },
    coco: {
        youtube: 'https://www.youtube.com/channel/UCS9uQI-jC3DE0L4IpXyvr6w',
        twitter: 'https://twitter.com/kiryucoco'
    },
    dezmond: {
        youtube: 'https://www.youtube.com/channel/UC7MMNHR-kf9EN1rXiesMTMw',
        twitter: 'https://twitter.com/magnidezmond'
    },
    elizabeth: {
        youtube: 'https://www.youtube.com/channel/UCW5uhrG1eCBYditmhL0Ykjw',
        twitter: 'https://x.com/ERBloodflame'
    },
    fauna: {
        youtube: 'https://www.youtube.com/channel/UCO_aKKYxn4tvrqPjcTzZ6EQ',
        twitter: 'https://twitter.com/ceresfauna'
    },
    flare: {
        youtube: 'https://www.youtube.com/channel/UCvInZx9h3jC2JzsIzoOebWg',
        twitter: 'https://twitter.com/shiranuiflare'
    },
    flayon: {
        youtube: 'https://www.youtube.com/channel/UC060r4zABV18vcahAWR1n7w',
        twitter: 'https://twitter.com/machinaxflayon'
    },
    fubuki: {
        youtube: 'https://www.youtube.com/channel/UCdn5BQ06XqgXoAxIhbqw5Rg',
        twitter: 'https://twitter.com/shirakamifubuki'
    },
    fuwamoco: {
        youtube: 'https://www.youtube.com/channel/UCt9H_RpQzhxzlyBxFqrdHqA',
        twitter: 'https://twitter.com/fuwamoco_en'
    },
    gigi: {
        youtube: 'https://www.youtube.com/channel/UCDHABijvPBnJm7F-KlNME3w',
        twitter: 'https://x.com/gigimurin'
    },
    goldbullet: {
        youtube: 'https://www.youtube.com/channel/UCJv02SHZgav7Mv3V0kBOR8Q',
        twitter: 'https://twitter.com/goldbullet_en'
    },
    gura: {
        youtube: 'https://www.youtube.com/channel/UCoSrY_IQQVpmIRZ9Xf-y93g',
        twitter: 'https://twitter.com/gawrgura'
    },
    haato: {
        youtube: 'https://www.youtube.com/channel/UC1CfXB_kRs3C-zaeTG3oGyg',
        twitter: 'https://twitter.com/akaihaato'
    },
    hajime: {
        youtube: 'https://www.youtube.com/channel/UC1iA6_NT4mtAcIII6ygrvCw',
        twitter: 'https://twitter.com/todoroki_hajime'
    },
    hakka: {
        youtube: 'https://www.youtube.com/channel/UC7gxU6NXjKF1LrgOddPzgTw',
        twitter: 'https://twitter.com/banzoinhakka'
    },
    hololive: {
        youtube: 'https://www.youtube.com/channel/UCJFZiqLMntJufDCHc6bQixg',
        twitter: 'https://twitter.com/hololivetv'
    },
    inanis: {
        youtube: 'https://www.youtube.com/channel/UCMwGHR0BTZuLsmjY_NT5Pwg',
        twitter: 'https://twitter.com/ninomaeinanis'
    },
    iofi: {
        youtube: 'https://www.youtube.com/channel/UCAoy6rzhSf4ydcYjJw3WoVg',
        twitter: 'https://twitter.com/airaniiofifteen'
    },
    iroha: {
        youtube: 'https://www.youtube.com/channel/UC_vMYWcDjmfdpH6r4TTn1MQ',
        twitter: 'https://twitter.com/kazamairohach'
    },
    irys: {
        youtube: 'https://www.youtube.com/channel/UC8rcEBzJSleTkf_-agPM20g',
        twitter: 'https://twitter.com/irys_en'
    },
    izuru: {
        youtube: 'https://www.youtube.com/channel/UCZgOv3YDEs-ZnZWDYVwJdmA',
        twitter: 'https://twitter.com/kanadeizuru'
    },
    kanade: {
        youtube: 'https://www.youtube.com/channel/UCWQtYtq9EOB4-I5P-3fh8lA',
        twitter: 'https://twitter.com/otonosekanade'
    },
    kanaeru: {
        youtube: 'https://www.youtube.com/channel/UCjLEmnpCNeisMxy134KPwWw',
        twitter: 'https://twitter.com/kobokanaeru'
    },
    kanata: {
        youtube: 'https://www.youtube.com/channel/UCZlDXzGoo7d44bwdNObFacg',
        twitter: 'https://twitter.com/amanekanatach'
    },
    kaoru: {
        youtube: 'https://www.youtube.com/channel/UCsehvfwaWF6nWuFnXI0AqZQ',
        twitter: 'https://twitter.com/tsukishitakaoru'
    },
    kiara: {
        youtube: 'https://www.youtube.com/channel/UCHsx4Hqa-1ORjQTh9TYDhww',
        twitter: 'https://twitter.com/takanashikiara'
    },
    korone: {
        youtube: 'https://www.youtube.com/channel/UChAnqc_AY5_I3Px5dig3X1Q',
        twitter: 'https://twitter.com/inugamikorone'
    },
    koseki: {
      youtube: 'https://www.youtube.com/channel/UC9p_lqQ0FEDz327Vgf5JwqA',
      twitter: 'https://twitter.com/kosekibijou'
    },
    kovalskia: {
        youtube: 'https://www.youtube.com/channel/UCZLZ8Jjx_RN2CXloOmgTHVg',
        twitter: 'https://twitter.com/kaelakovalskia'
    },
    koyori: {
        youtube: 'https://www.youtube.com/channel/UC6eWCld0KwmyHFbAqK3V-Rw',
        twitter: 'https://twitter.com/hakuikoyori'
    },
    kronii: {
        youtube: 'https://www.youtube.com/channel/UCmbs8T6MWqUHP1tIQvSgKrg',
        twitter: 'https://twitter.com/ourokronii'
    },
    lamy: {
        youtube: 'https://www.youtube.com/channel/UCFKOVgVbGmX65RxO3EtH3iw/',
        twitter: 'https://twitter.com/yukihanalamy'
    },
    laplus: {
        youtube: 'https://www.youtube.com/channel/UCENwRMx5Yh42zWpzURebzTw',
        twitter: 'https://twitter.com/LaplusDarknesss'
    },
    lui: {
        youtube: 'https://www.youtube.com/channel/UCs9_O1tRPMQTHQ-N_L6FU2g',
        twitter: 'https://twitter.com/takanelui'
    },
    luna: {
        youtube: 'https://www.youtube.com/channel/UCa9Y57gfeY0Zro_noHRVrnw',
        twitter: 'https://twitter.com/himemoriluna'
    },
    marine: {
        youtube: 'https://www.youtube.com/channel/UCCzUftO8KOVkV4wQG1vkUvg',
        twitter: 'https://twitter.com/houshoumarine'
    },
    matsuri: {
        youtube: 'https://www.youtube.com/channel/UCQ0UDLQCjY0rmuxCDE38FGg',
        twitter: 'https://twitter.com/natsuiromatsuri'
    },
    mel: {
        youtube: 'https://www.youtube.com/channel/UCD8HOxPs4Xvsm8H0ZxXGiBw',
        twitter: 'https://twitter.com/yozoramel'
    },
    melfissa: {
        youtube: 'https://www.youtube.com/channel/UC727SQYUvx5pDDGQpTICNWg',
        twitter: 'https://twitter.com/anyamelfissa'
    },
    miko: {
        youtube: 'https://www.youtube.com/channel/UC-hM6YJuNYVAmUWxeIr9FeA',
        twitter: 'https://twitter.com/sakuramiko35'
    },
    mio: {
        youtube: 'https://www.youtube.com/channel/UCp-5t9SrOQwXMU7iIjQfARg',
        twitter: 'https://twitter.com/ookamimio'
    },
    miyabi: {
        youtube: 'https://www.youtube.com/channel/UC6t3-_N8A6ME1JShZHHqOMw',
        twitter: 'https://twitter.com/miyabihanasaki',
    },
    moona: {
        youtube: 'https://www.youtube.com/channel/UCP0BspO_AMEe3aQqqpo89Dg',
        twitter: 'https://twitter.com/moonahoshinova'
    },
    mumei: {
        youtube: 'https://www.youtube.com/channel/UC3n5uGu18FoCy23ggWWp8tA',
        twitter: 'https://twitter.com/nanashimumei_en'
    },
    nabi: {
        youtube: 'https://www.youtube.com/channel/UCzKkwB84Y0ql0EvyOWRSkEw',
        twitter: 'https://twitter.com/aoinabi'
    },
    nachoneko: {
        youtube: 'https://www.youtube.com/channel/UCvNn6mRroGDctnqU-FUsszg',
        twitter: 'https://twitter.com/amsrntk3'
    },
    nana: {
        youtube: 'https://www.youtube.com/channel/UCbfv8uuUXt3RSJGEwxny5Rw',
        twitter: 'https://twitter.com/nana_kaguraaa'
    },
    nene: {
        youtube: 'https://www.youtube.com/channel/UCAWSyEs_Io8MtpY3m-zqILA/',
        twitter: 'https://twitter.com/momosuzunene'
    },
    nerissa: {
        youtube: 'https://www.youtube.com/channel/UC_sFNM0z0MWm9A6WlKPuMMg',
        twitter: 'https://twitter.com/nerissa_en'
    },
    noel: {
        youtube: 'https://www.youtube.com/channel/UCdyqAaZDKHXg4Ahi7VENThQ',
        twitter: 'https://twitter.com/shiroganenoel'
    },
    octavio: {
        youtube: 'https://www.youtube.com/channel/UCLk1hcmxg8rJ3Nm1_GvxTRA',
        twitter: 'https://twitter.com/octavio_holo'
    },
    oga: {
        youtube: 'https://www.youtube.com/channel/UCwL7dgTxKo8Y4RFIKWaf8gA',
        twitter: 'https://twitter.com/aragamioga'
    },
    okayu: {
        youtube: 'https://www.youtube.com/channel/UCvaTdHTWBGv3MKj3KVqJVCw',
        twitter: 'https://twitter.com/nekomataokayu'
    },
    ollie: {
        youtube: 'https://www.youtube.com/channel/UCYz_5n-uDuChHtLo7My1HnQ',
        twitter: 'https://twitter.com/kureijiollie'
    },
    pekora: {
        youtube: 'https://www.youtube.com/channel/UC1DCedRgGHBdm81E1llLhOQ',
        twitter: 'https://twitter.com/usadapekora'
    },
    pochimaru: {
        youtube: 'https://www.youtube.com/channel/UC22BVlBsZc6ta3Dqz75NU6Q',
        twitter: 'https://twitter.com/lizhi3'
    },
    polka: {
        youtube: 'https://www.youtube.com/channel/UCK9V2B22uJYu3N7eR_BT9QA/',
        twitter: 'https://twitter.com/omarupolka'
    },
    raden: {
        youtube: 'https://www.youtube.com/channel/UCdXAk5MpyLD8594lm_OvtGQ',
        twitter: 'https://twitter.com/juufuuteiraden'
    },
    raora: {
        youtube: 'https://www.youtube.com/channel/UCl69AEx4MdqMZH7Jtsm7Tig',
        twitter: 'https://x.com/raorapanthera'
    },
    reine: {
        youtube: 'https://www.youtube.com/channel/UChgTyjG-pdNvxxhdsXfHQ5Q',
        twitter: 'https://twitter.com/pavoliareine'
    },
    rexford: {
        youtube: 'https://www.youtube.com/channel/UCTVSOgYuSWmNAt-lnJPkEEw',
        twitter: 'https://twitter.com/jurardtrexford'
    },
    rikka: {
        youtube: 'https://www.youtube.com/channel/UC9mf_ZVpouoILRY9NUIaK-w',
        twitter: 'https://twitter.com/rikkaroid'
    },
    ririka: {
        youtube: 'https://www.youtube.com/channel/UCtyWhCj3AqKh2dXctLkDtng',
        twitter: 'https://twitter.com/ichijouririka'
    },
    risu: {
        youtube: 'https://www.youtube.com/channel/UCOyYb1c43VlX9rc_lT6NKQw',
        twitter: 'https://twitter.com/ayunda_risu'
    },
    roberu: {
        youtube: 'https://www.youtube.com/channel/UCANDOlYTJT7N5jlRC3zfzVA',
        twitter: 'https://twitter.com/yukokuroberu'
    },
    roboco: {
        youtube: 'https://www.youtube.com/channel/UCDqI2jOz0weumE8s7paEk6g',
        twitter: 'https://twitter.com/robocosan'
    },
    rurudo: {
        youtube: 'https://www.youtube.com/channel/UCim0N3tvLijU_I3jbJeJV8g',
        twitter: 'https://twitter.com/rurudo_'
    },
    rushia: {
        youtube: 'https://www.youtube.com/channel/UCl_gCybOJRIgOXw6Qb4qJzQ',
        twitter: 'https://twitter.com/uruharushia'
    },
    ruze: {
        youtube: 'https://www.youtube.com/channel/UCajbFh6e_R8QZdHAMbbi4rQ',
        twitter: 'https://twitter.com/crimzonruze'
    },
    sana: {
        youtube: 'https://www.youtube.com/channel/UCsUj0dszADCGbF3gNrQEuSQ',
        twitter: 'https://twitter.com/tsukumosana'
    },
    shien: {
        youtube: 'https://www.youtube.com/channel/UChSvpZYRPh0FvG4SJGSga3g',
        twitter: 'https://twitter.com/kageyamashien'
    },
    shinri: {
        youtube: 'https://www.youtube.com/channel/UCMqGG8BRAiI1lJfKOpETM_w',
        twitter: 'https://twitter.com/josuijishinri'
    },
    shion: {
        youtube: 'https://www.youtube.com/channel/UCXTpFs_3PqI41qX2d9tL2Rw',
        twitter: 'https://twitter.com/murasakishionch'
    },
    shiori: {
        youtube: 'https://www.youtube.com/channel/UCgnfPPb9JI3e9A4cXHnWbyg',
        twitter: 'https://twitter.com/shiorinovella'
    },
    sora: {
        youtube: 'https://www.youtube.com/channel/UCp6993wxpyDPHUpavwDFqgg',
        twitter: 'https://twitter.com/tokino_sora'
    },
    subaru: {
        youtube: 'https://www.youtube.com/channel/UCvzGlP9oQwU--Y0r9id_jnA',
        twitter: 'https://twitter.com/oozorasubaru'
    },
    suisei: {
        youtube: 'https://www.youtube.com/channel/UC5CwaMl1eIgY8h02uZw7u8A',
        twitter: 'https://twitter.com/suisei_hosimati'
    },
    syrios: {
        youtube: 'https://www.youtube.com/channel/UC2hx0xVkMoHGWijwr_lA01w',
        twitter: 'https://twitter.com/axelsyrios'
    },
    temma: {
        youtube: 'https://www.youtube.com/channel/UCGNI4MENvnsymYjKiZwv9eg',
        twitter: 'https://twitter.com/kishidotemma',
    },
    towa: {
        youtube: 'https://www.youtube.com/channel/UC1uv2Oq6kNxgATlCiez59hw',
        twitter: 'https://twitter.com/tokoyamitowa'
    },
    ui: {
        youtube: 'https://www.youtube.com/channel/UCt30jJgChL8qeT9VPadidSw',
        twitter: 'https://twitter.com/ui_shig'
    },
    vesper: {
        youtube: 'https://www.youtube.com/channel/UCDRWSO281bIHYVi-OV3iFYA',
        twitter: 'https://twitter.com/noirvesper_en'
    },
    watame: {
        youtube: 'https://www.youtube.com/channel/UCqm3BQLlJfvkTsX_hvm0UmA',
        twitter: 'https://twitter.com/tsunomakiwatame'
    },
    zeta: {
        youtube: 'https://www.youtube.com/channel/UCTvHWSfBZgtxE4sILOaurIQ',
        twitter: 'https://twitter.com/vestiazeta'
    }
}
