import React, { Component } from 'react';
import {connect} from 'react-redux';

import {FaPenAlt} from 'react-icons/fa';
import {IoIosClose} from 'react-icons/io';

import "../../../../css/mutualfunds/mutualfundupdateinfo.scss";
import { IMutualFunds } from '../../../interfaces/MutualFunds';
import { lineage } from '../../Icons';
import Coin from '../../Coin';
import postRequest from '../../../postRequest';

const mapStateToProps = (state:any) => ({
    userinfo: state.userinfo,
    mutualfunds: state.mutualfunds
});

const mapDispatchToProps = {

}

interface MutualFundUpdateInfoProps {
    fundid:string,
    userinfo: {
        id:string
    },
    mutualfunds: {
        funds:IMutualFunds,
    },
    toggleVisible: () => void,
}

class MutualFundUpdateInfoState {
    newName:string = "";
    newDescription:string = "";
    newColor:string = "";
    newIcon:string = "";
    error:string = "";
}

class MutualFundUpdateInfoBind extends Component<MutualFundUpdateInfoProps> {

    state:MutualFundUpdateInfoState;
    constructor(props:MutualFundUpdateInfoProps) {
        super(props);
        this.state = new MutualFundUpdateInfoState();
    }

    componentDidMount() {
        if(this.props.mutualfunds.funds === undefined) return;
        if(this.props.mutualfunds.funds[this.props.fundid] === undefined) return;
        let fund = this.props.mutualfunds.funds[this.props.fundid];
        this.setState({
            newName:fund.name,
            newDescription:fund.missionStatement,
            newColor:"#" + fund.color,
            newIcon:fund.icon
        })
    }

    updateValue(e:any) {
        this.setState({[e.target.name]:e.target.value});
    }

    selectIcon(coin:string) {
        this.setState({newIcon:coin});
    }

    renderAllCoins() {
        const coinsLineage = [...lineage];
        const coins:Array<any> = [];
        coinsLineage.forEach((gen:Array<string>) => {
            gen.forEach((coinname:string) => {
                coins.push(
                     <div
                        key={coinname}
                        onClick={() => this.selectIcon(coinname)}
                        className={"coin-item " + (
                            coinname === this.state.newIcon ?
                            "selected" : ""
                        )}>
                            <Coin name={coinname} />
                     </div>
                )
            })
        })
        return coins;
    }

    submitInfo() {
        if(this.props.mutualfunds.funds === undefined) return;
        if(this.props.mutualfunds.funds[this.props.fundid] === undefined) return;
        let oldInfo = this.props.mutualfunds.funds[this.props.fundid];
        if(
            this.state.newColor === ("#" + oldInfo.color) &&
            this.state.newIcon === oldInfo.icon &&
            this.state.newName === oldInfo.name &&
            this.state.newDescription === oldInfo.missionStatement 
        ) {
            this.props.toggleVisible();
            return;
        }

        let icon = this.state.newIcon === "luna" ? "himemoriluna" : this.state.newIcon; 

        postRequest("/api/updateFundInfo", {
            fundid:this.props.fundid,
            color:this.state.newColor,
            name:this.state.newName,
            icon,
            description:this.state.newDescription
        }).then((data) => {
            if(data.success) {
                this.props.toggleVisible();
                return;
            } else {
                this.setState({error:data.message})
            }
        }).catch((err:any) => {
            this.setState({error:err});
            return;
        })

    }

    render() {
        return(
            <div className="mutual-fund-update-info flex flex-col center-child">
                <div className="mutual-fund-update-info-inner flex flex-col">
                    <div
                        onClick={() => {this.props.toggleVisible()}} 
                        className="close-fund-update-info-btn flex center-child">
                        <IoIosClose />
                    </div>
                    <div className="mutual-fund-update-info-header">
                        Edit Fund Information
                    </div>
                    <label htmlFor="newName">
                        Name
                    </label>
                    <input 
                        type="text" 
                        name="newName"
                        value={this.state.newName} 
                        onChange={(e) => this.updateValue(e)}/>
                    <label htmlFor="newDescription">
                        Description
                    </label>
                    <textarea 
                        name="newDescription"
                        value={this.state.newDescription}
                        cols={50}
                        onChange={(e) => this.updateValue(e)}>
                    </textarea>
                    <label htmlFor="newIcon">
                        Icon
                    </label>
                    <div className="icon-select">
                        {this.renderAllCoins()}
                    </div>
                    <label htmlFor="newColor">
                        Color
                    </label>
                    <input 
                        name="newColor"
                        type="color"
                        value={this.state.newColor}
                        onChange={(e) => this.updateValue(e)}/>
                    <div className="fund-info-submit-outer">
                        <div
                            onClick={() => {this.submitInfo()}}
                            className="fund-info-submit-btn flex center-child">
                            <>Submit</>
                            <FaPenAlt />
                        </div>
                    </div>
                    {
                        this.state.error !== "" ?
                        <div className="error">
                            {this.state.error}
                        </div> : null
                    }
                </div>
            </div>
        )
    }

}

const MutualFundUpdateInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundUpdateInfoBind);

export default MutualFundUpdateInfo;