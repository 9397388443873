import React, {Component} from 'react';

import "../../../../css/profile/directmessages.scss";
import DirectMessageLogs from './DirectMessageLogs';

import DirectMessagePreview from './DirectMessagePreviews';

interface DirectMessagesProps {
    verified:boolean;
}

class DirectMessageState {
    activeRoom:string|null;
    constructor() {
        this.activeRoom = null;
    }
}

class DirectMessages extends Component<DirectMessagesProps> {

    state:DirectMessageState;
    constructor(props:DirectMessagesProps) {
        super(props);
        this.state = new DirectMessageState();
    }

    setActiveRoom(roomid:string) {
        this.setState({activeRoom:roomid});
    }

    render() {
        return( 
            <div className="container-section">
                <div className="section-background"></div>
                <div className="section-content">
                    <div className="header">
                        Direct Messages
                    </div>
                    {
                        this.props.verified ?
                        <div className="direct-messages-outer">
                            
                            <DirectMessagePreview 
                                onChangeRoom={(roomid:string) => this.setActiveRoom(roomid)}/>
                            <DirectMessageLogs activeRoom={this.state.activeRoom}/>
                            
                        </div>
                        : 
                        <div className="not-verified">
                            You must be verified to send direct messages.
                        </div>
                    }
                </div>
            </div>
        );
    }

}

export default DirectMessages;