import React, {Component} from 'react';
import {connect} from 'react-redux';
import getForegroundFromBackground from '../../../getForegroundFromBackground';
import hexToRgb from '../../../hexToRBG';
import { IMutualFundHistoryCollection, IMutualFunds, IMutualFundStatCollection } from '../../../interfaces/MutualFunds';
import { getGradientString } from '../MutualFundIndexItem';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {

}

interface IMutualFundSectionProps {
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
    },
    fund:string,
    children:any,
    className?:string
}

class MutualFundSectionBind extends Component<IMutualFundSectionProps> {
    
    getColor() {
        let fund = this.props.mutualfunds.funds[this.props.fund];
        return "#" + fund.color;
    }

    getForegroundColor() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getForegroundFromBackground(r, g, b);
    }

    getGradient() {
        let {r, g, b} = hexToRgb(this.getColor());
        return getGradientString(r, g, b);
    }
    
    render() {
        let className = this.props.className ? this.props.className : "";
        return(
            <div className={"mutual-fund-page-section " + className}>
                <div 
                    style={{"background": this.getGradient()}}
                    className="mutual-fund-page-background"></div>
                <div
                    style={{"borderColor": this.getColor()}} 
                    className="mutual-fund-page-content">

                    {this.props.children}

                </div>
            </div>
        );
    }
}

const MutualFundSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundSectionBind);

export default MutualFundSection;