import React, { Component } from 'react';

import '../css/master.scss';
import '../css/themes/halloween.scss';
import '../css/themes/christmas.scss';

import Home from './components/Home';
import Profile from './components/profile/Profile';
import Market from './components/Market';
import Leaderboard from './components/leaderboard/Leaderboard';
import Login from './components/Login';
import Logout from './components/Logout';
import Navbar from './components/Navbar';
import PageNotFound from './components/PageNotFound';
import TransactionUpdater from './components/TransactionUpdater';
import Activity from './components/activity/Activity';
import VerifyAccount from './components/VerifyAccount';
import Floor from './components/floor/Floor';
import Admin from './components/admin/Admin';
import Announcement from './components/Announcement';
import ResetPassword from './components/ResetPassword';
import Superchats from './components/superchats/Superchats';
import SuperchatDanmaku from './components/superchats/SuperchatDanmaku';
import AutoTrader from './components/autotrader/AutoTrader';
import Gacha from './components/gacha/Gacha';
import Docs from './components/docs/Docs';
import Auctions from './components/auctions/Auctions';
import Auction from './components/auctions/Auction';
import Benchmark from './components/benchmark/Benchmark';
import Betting from './components/betting/Betting';
import GlobalChat from './components/chat/GlobalChat';
import GlobalChatPage from './components/chat/GlobalChatPage';
import DevBlog from './components/devblog/DevBlog';

import SessionHandler from './components/SessionHandler';
import SocketHandler from './components/SocketHandler';

import fetchData from './fetchData';

import { parseCoinHistory } from './parsers';
import checkStorage from './checkStorage';

import {lineage} from './components/Icons';

import { connect } from 'react-redux';

import {
  settingsActions,
  statsActions,
  itemcatalogueActions,
  multicoinSaveActions,
  itemmarketpricesActions,
  auctionsActions,
  snowfallActions,
  benchmarkActions,
  sessionActions,
  mutualfundActions,
  devblogActions
} from './actions/actions';

import {
  Switch,
  Route
} from 'react-router-dom';
import Info from './components/Info';
import LoginAdmin from './components/LoginAdmin';
import { Themes } from './Themes';
import AuctionNotifications from './components/auctions/AuctionNotification';
import Snowfall from './components/christmas/Snowfall';
import SnowfallSettings from './components/christmas/SnowfallSettings';
import SnowfallNotification from './components/christmas/SnowfallNotification';
import BettingPool from './components/betting/BettingPool';
import MutualFundsIndex from './components/mutualfunds/MutualFundsIndex';
import MutualFundPage from './components/mutualfunds/MutualFundPage';
import { IMutualFundBulletinCollection, IMutualFundHistoryCollection, IMutualFundOrderTotals, IMutualFundPayouts, IMutualFunds, IMutualFundsDissolve, IMutualFundStatCollection } from './interfaces/MutualFunds';
import Polls from './components/Polls';

import { db, IDBStats } from "./db";

const mapStateToProps = (state:any, props:any) => ({
  settings: state.settings,
  session: state.session,
  snowfall: state.snowfall
});

const mapDispatchToProps = {
  setStats: statsActions.setStats,
  setHistory: statsActions.setHistory,
  setCoinInfo: statsActions.setCoinInfo,
  setLeaderboard: statsActions.setLeaderboard,
  setOshiboard: statsActions.setOshiboard,
  setMarketSwitch: settingsActions.setMarketSwitch,
  setItemCatalogue: itemcatalogueActions.setCatalogue,
  setBrokerTotal: statsActions.setBrokerTotal,
  setBrokerFee: statsActions.setBrokerFee,
  setBenchmarkLeaderboard: statsActions.setBenchmarkLeaderboard,
  setMulticoinSave: multicoinSaveActions.setMulticoinSave,
  setItemMarketPrices: itemmarketpricesActions.setItemMarketPrices,
  setActiveAuctions: auctionsActions.setActiveAuctions,
  setPastAuctions: auctionsActions.setPastAuctions,
  setAuctionSubscriptions: auctionsActions.setAuctionSubscriptions,
  setSnowSize: snowfallActions.setSnowSize,
  setSnowSpeed: snowfallActions.setSnowSpeed,
  setSnowAmount: snowfallActions.setSnowAmount,
  setBenchmarkAll: benchmarkActions.setBenchmarkAll,
  setAdmins: sessionActions.setAdmins,
  setFunds: mutualfundActions.setFunds,
  setFundStats: mutualfundActions.setFundStats,
  setFundHistory: mutualfundActions.setFundHistory,
  setFundBulletinBoards: mutualfundActions.setFundBulletinBoards,
  setFundOrderTotals: mutualfundActions.setFundOrderTotals,
  setFundPayouts: mutualfundActions.setFundPayouts,
  setFundsToDissolve: mutualfundActions.setFundsToDissolve,
  setFundsLoaded: mutualfundActions.setFundsLoaded,
  setFundUserTotals: mutualfundActions.setFundUserTotals,
  setNoMales: settingsActions.setNoMales
}

interface AppProps {
  settings: {
    theme: Themes
  },
  session: {
    loggedin: boolean
  },
  snowfall: {
    showSnowSettings: boolean,
    showSnowNotification: boolean,
  },
  setStats: (stats:{}) => {},
  setHistory: (coinHistory:{}) => {},
  setCoinInfo: (coinInfo: {}) => {},
  setLeaderboard: (leaderboard:{}) => {},
  setBenchmarkLeaderboard: (benchmarkLeaderboard:{}) => {},
  setOshiboard: (oshiboard:{}) => {},
  setMarketSwitch: (open:boolean) => {},
  setItemCatalogue: (catalogue:any) => {},
  setBrokerTotal: (brokerTotal:any) => {},
  setMulticoinSave: (multicoinSave:any) => {},
  setItemMarketPrices: (itemmarketprices:any) => {},
  setActiveAuctions: (activeAuctions:any) => {},
  setPastAuctions: (pastAuctions:any) => {},
  setAuctionSubscriptions: (subscriptions:any) => {}
  setBrokerFee: (brokerFee:number) => {},
  setSnowSize: (snowSize:number) => {},
  setSnowSpeed: (snowSpeed:number) => {},
  setSnowAmount: (snowAmount:number) => {},
  setAdmins: (admins:Array<string>) => {},
  setBenchmarkAll: (index:number, marketCap:number, totalShares:number, weights:any, runningHistory:any, history:any) => {},
  setFunds: (funds:IMutualFunds) => {},
  setFundStats: (fundStats:IMutualFundStatCollection) => {},
  setFundHistory: (fundHistory:IMutualFundHistoryCollection) => {},
  setFundBulletinBoards: (fundBulletinBoards:IMutualFundBulletinCollection) => {},
  setFundOrderTotals: (orders:IMutualFundOrderTotals) => {},
  setFundPayouts: (fundPayouts:IMutualFundPayouts) => {},
  setFundsToDissolve: (fundsToDissolve:IMutualFundsDissolve) => {},
  setFundsLoaded: (loaded:boolean) => {},
  setFundUserTotals: (fundUserTotals:any) => {},
  setNoMales: (noMales:boolean) => {}
}

const adjustmentTime = {
  HOUR:9,
  MINUTE:5
}

class AppBind extends Component<AppProps> {

  async componentDidMount() {

    fetchData('/api/getLeaderboard?leaderboard&oshiboard')
    .then((data:any) => {
      this.props.setLeaderboard(data.leaderboard.leaderboard);
      this.props.setOshiboard(data.oshiboard);
    });
    fetchData('/api/getItemCatalogue')
    .then((data:any) => {
      if(data.success) {
        this.props.setItemCatalogue(data.catalogue);
      }
    });

    fetchData('/api/getItemMarketPrices')
    .then((data:any) => {
      if(data.success) {
        this.props.setItemMarketPrices(data.catalogue)
      }
    })

    fetchData('/api/getBenchmarkInfo')
    .then((data:any) => {
      this.props.setBenchmarkAll(
        data.benchmark.index,
        data.benchmark.marketCap,
        data.benchmark.totalShares,
        data.benchmark.weights,
        data.rolling,
        data.history);
    })

    fetchData('/api/getBenchmarkLeaderboard')
    .then((data:any) => {
      if(data.success) {
        this.props.setBenchmarkLeaderboard({...data.benchmarkLeaderboard});
      }
    })

    fetchData('/api/getAdmins')
    .then((data:any) => {
      this.props.setAdmins(data.admins);
    })

    fetchData('/api/getMutualFundData')
    .then((data:any) => {
      if(data.success) {
        this.props.setFunds(data.funds);
        this.props.setFundHistory(data.fundHistory);
        this.props.setFundStats(data.fundStats);
        this.props.setFundBulletinBoards(data.bulletins);
        this.props.setFundOrderTotals(data.orders);
        this.props.setFundPayouts(data.fundPayouts);
        this.props.setFundsToDissolve(data.fundsToDissolve);
        this.props.setFundUserTotals(data.fundUserTotals);
        this.props.setFundsLoaded(true);
      }
    })

    if(checkStorage()) {
      localStorage.removeItem("nasfaq:stats");
      localStorage.removeItem("nasfaq:multicoinSave");
    }

    fetchData('/api/getMarketInfo?all&history&brokerFeeTotal&brokerFee')
    .then(async (data:any) => {
      this.props.setCoinInfo(data.coinInfo);
      this.props.setMarketSwitch(data.marketSwitch);
      this.props.setBrokerTotal(data.brokerFeeTotal);
      this.props.setBrokerFee(data.brokerFee);
      let shouldRequest = true;
      
      const statsArray = await db.stats.toArray();
      if(statsArray.length > 0) {

        let stats:IDBStats = statsArray[0];

        const minToMil = 60 * 1000;
        const hoursMilli = adjustmentTime.HOUR * 60 * minToMil;
        const minMilli = adjustmentTime.MINUTE * minToMil;
        const jumpBack = hoursMilli + minMilli;

        let statsTimestamp = stats.timestamp - jumpBack;
        const storedTimeString = new Date(statsTimestamp).toLocaleString("en-US", {
          timeZone: "America/New_York"
        });
        const storedDay = new Date(storedTimeString).getDay();

        const nowTimestamp = new Date().getTime() - jumpBack;
        const nowTimeString = new Date(nowTimestamp).toLocaleString("en-US", {
          timeZone: "America/New_York"
        });
        const nowDay = new Date(nowTimeString).getDay();
        if(nowDay === storedDay) {
          this.props.setStats(stats.stats);
          this.props.setHistory(stats.history);
          shouldRequest = false;
        }
      }

      if(shouldRequest) {
        fetchData('/api/getStats')
        .then(async (data:any) => {

          let coinHistory = parseCoinHistory(JSON.parse(data.coinHistory));
          this.props.setStats(data.stats);
          this.props.setHistory(coinHistory);

          let statsArray = await db.stats.toArray();
          if(statsArray.length > 0) {
            let timestamp = statsArray[0].timestamp;
            await db.stats.delete(timestamp);
          }

          await db.stats.add({
            timestamp:new Date().getTime(),
            stats:data.stats,
            history:coinHistory
          });

        })
      }
    });

    let multicoinArray = await db.multicoinSave.toArray();
    let multicoinSaveData:any = {};
    for(let multicoinItem of multicoinArray) {
      multicoinSaveData[multicoinItem.coin] = {
        ...multicoinItem
      }
      delete multicoinSaveData[multicoinItem.coin].coin;
    }
    lineage.forEach((gen:Array<string>) => {
      gen.forEach(async (coin:string) => {
        if(multicoinSaveData[coin] === undefined) {
          multicoinSaveData[coin] = {buy:1, sell:1};
          await db.multicoinSave.put({
            coin,
            ...multicoinSaveData[coin]
          })
        }
      })
    })
    this.props.setMulticoinSave(multicoinSaveData);

    if(checkStorage()) {

      let subscriptions = localStorage.getItem("nasfaq:auction_subscriptions");
      if(subscriptions !== null) {
        this.props.setAuctionSubscriptions(JSON.parse(subscriptions));
      }

      let snowfallSettings:any = localStorage.getItem("nasfaq:snowfallSettings");
      if(snowfallSettings) {
        snowfallSettings = JSON.parse(snowfallSettings);
        this.props.setSnowSize(snowfallSettings.snowSize);
        this.props.setSnowSpeed(snowfallSettings.snowSpeed);
        this.props.setSnowAmount(snowfallSettings.snowAmount);
      }

      let noMales:any = localStorage.getItem("nasfaq:nomales");
      if(noMales !== null) {
        this.props.setNoMales(JSON.parse(noMales).noMales);
      }
    }
  }

  componentDidUpdate(prevProps:AppProps) {
    if(this.props.session.loggedin && !prevProps.session.loggedin) {
      fetchData('/api/getAuctionCurrent')
      .then((data:any) => {
          this.props.setActiveAuctions(data.auctions);
      })
      fetchData('/api/getAuctionHistory')
      .then((data:any) => {
          this.props.setPastAuctions(data.auctionHistory);
      })
    }
  }

  themeClass = () => {
    if(this.props.settings.theme === Themes.LIGHT) {
      return "";
    } else if(this.props.settings.theme === Themes.DARK) {
      return "dark";
    } else if(this.props.settings.theme === Themes.HALLOWEEN) {
      return "halloween";
    } else if(this.props.settings.theme === Themes.CHRISTMAS) {
      return "christmas";
    }
  }

  render() {

    return(
      <div className={this.themeClass()}>

        { this.props.settings.theme === Themes.CHRISTMAS ?        
          <Snowfall /> : null
        }
        {
          this.props.snowfall.showSnowNotification ?
          <SnowfallNotification /> : null
        }
        { 
          this.props.snowfall.showSnowSettings ?
          <SnowfallSettings /> : null
        }

        <SessionHandler />
        <SocketHandler />

        <Navbar />

        <TransactionUpdater />
        <SuperchatDanmaku />

        <Announcement />

        <AuctionNotifications />

        <GlobalChat />

        <Switch>

          <Route
            exact
            path="/"
            component={Home}/>

          <Route
            exact
            path="/info"
            component={Info}/>

          <Route
            exact
            path="/profile"
            component={Profile}/>

          <Route
            exact
            path="/market"
            component={Market}/>

          <Route
            exact
            path="/floor/:room?"
            component={Floor}/>

          <Route
            exact
            path="/activity"
            component={Activity}/>

          <Route
            exact
            path="/leaderboard"
            component={Leaderboard}/>

          <Route
            exact
            path="/login/:from?"
            component={Login}/>

          <Route
            exact
            path="/loginAdmin"
            component={LoginAdmin}/>

          <Route
            exact
            path="/logout"
            component={Logout}/>

          <Route
            exact
            path="/admin"
            component={Admin}/>

          <Route
            exact
            path="/resetPassword"
            component={ResetPassword}/>

          <Route
            exact
            path="/superchats"
            component={Superchats}/>

          <Route
            exact
            path="/gacha"
            component={Gacha}/>

          <Route
            exact
            path="/auctions/:auctionid"
            component={Auction}/>

          <Route
            exact
            path="/auctions"
            component={Auctions}/>

          <Route
            exact
            path="/docs"
            component={Docs}>
          </Route>

          <Route
            exact
            path="/benchmark"
            component={Benchmark}/>

          <Route
            exact
            path="/betting/:poolid"
            component={BettingPool} />

          <Route
            exact
            path="/betting"
            component={Betting}/>

          <Route
            exact
            path="/globalchat"
            component={GlobalChatPage}/>

          <Route
            exact
            path="/mutualfunds"
            component={MutualFundsIndex}/>

          <Route
            exact
            path="/polls"
            component={Polls}/>

          <Route
            exact
            path="/devblog"
            component={DevBlog}/>
            
          <Route
            exact
            path="/mutualfund/:fundid"
            component={MutualFundPage}/>

          <Route
            exact
            path="/verifyAccount/:userid/:key"
            component={VerifyAccount}/>

          <Route component={PageNotFound}/>

        </Switch>

      </div>
    )
  }
}

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppBind);

export default App;
