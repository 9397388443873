import { handleActions } from 'redux-actions';

const betting = handleActions(
    {
        SET_BETTING_POOLS: (state:any, action:any) => ({
            ...state,
            bettingPools: action.payload.bettingPools
        }),
        UPDATE_POOL: (state:any, action:any) => ({
            bettingPools: {
                ...state.bettingPools,
                [action.payload.bettingPool.id]: action.payload.bettingPool
            }
        })
    },
    {
        bettingPools: undefined
    }
)

export { betting as default }
