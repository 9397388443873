import React, {Component} from 'react';
import {connect} from 'react-redux';
import { ICoinData, ICoinDataCollection, ICoinHistory } from '../../../interfaces/ICoinInfo';
import { IMutualFundHistoryCollection, IMutualFundMember, IMutualFundPortfolio, IMutualFunds, IMutualFundStatCollection } from '../../../interfaces/MutualFunds';
import { SortByGen } from '../../../sort';
import Coin from '../../Coin';
import MutualFundSection from './MutualFundSection';
import {CoinColors} from '../../../CoinColors';
import { 
    BiUpArrow,
    BiDownArrow
} from 'react-icons/bi';
import {
    GoDash
} from 'react-icons/go';
import numberWithCommas from '../../../numberWithCommas';

import "../../../../css/mutualfunds/mutualfundportfolio.scss";
import { lineage } from '../../Icons';
import MutualFundTradeButtonWrapper from '../../MutualFundTradeButton';
import { Pie } from 'react-chartjs-2';
import { datasetTemplate } from '../../DatasetTemplate';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds,
    stats:state.stats
});

const mapDispatchToProps = {

}

interface IMutualFundPortfolioProps {
    session: {
        loggedin:boolean
    },
    userinfo: {
        id:string
    },
    mutualfunds: {
        funds: IMutualFunds,
        fundstats: IMutualFundStatCollection,
        fundhistory: IMutualFundHistoryCollection,
    },
    stats: {
        stats: {
            [key:string]:any
        },
        coinHistory: ICoinHistory,
        coinInfo: ICoinDataCollection
    },
    fund:string
}

class MutualFundPortfolioState {
    coinMultiValues:any = {};
    chartData:{
        labels: Array<string>,
        datasets:any
    } = {
        labels: [],
        datasets: []
    };
}

class MutualFundPortfolioBind extends Component<IMutualFundPortfolioProps> {
 
    state:MutualFundPortfolioState;
    constructor(props:IMutualFundPortfolioProps) {
        super(props);
        this.state = new MutualFundPortfolioState();
    }

    componentDidMount() {
        let coins = this.getAllCoins();
        let coinMultiValues:any = {};
        coins.forEach((coin:string) => {
            coinMultiValues[coin] = 1
        });
        this.setChartData();
        this.setState({coinMultiValues});
    }

    componentDidUpdate(prevProps:IMutualFundPortfolioProps) {
        if(this.props.mutualfunds.funds[this.props.fund] === undefined) return;
        if(prevProps.mutualfunds.funds[this.props.fund] === undefined) return;
        if(prevProps.mutualfunds.funds[this.props.fund].portfolio !== this.props.mutualfunds.funds[this.props.fund].portfolio) {
            this.setChartData();
        }
    }

    setChartData() {
        
        if(this.props.mutualfunds.funds[this.props.fund] === undefined) return null;
        let portfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        
        let datasets: Array<any> = [];
        let dataset = {...datasetTemplate};
        dataset.borderColor = "rbga(50,50,50,1)";

        let data:any = {};
        dataset.label = "Portfolio Spread";
        data.labels = this.getAllCoins();

        let coinAmounts:Array<number> = [];
        let backgroundColors:Array<string> = [];
        data.labels.forEach((coin:string) => {
            let name = this.statCoinName(coin);
            if(portfolio[name] === undefined) {
                coinAmounts.push(0);
            } else {
                coinAmounts.push(portfolio[name].amount);
            }
            backgroundColors.push(CoinColors[coin]);
        });

        dataset.data = coinAmounts;
        dataset.backgroundColor = backgroundColors;

        datasets.push(dataset);
        data.datasets = datasets;
        this.setState({
            chartData:data
        });
    }

    priceDirectionIcon(delta:number) {
        if(delta === 0) {
            return (
                <GoDash style={{verticalAlign: 'middle'}}/>
            )
        }
        if(delta < 0) {
            return(
                <BiDownArrow style={{verticalAlign: 'middle'}}/>
            )
        } else {
            return(
                <BiUpArrow style={{verticalAlign: 'middle'}}/>
            )
        }
    }

    isMemberOfFund() {
        if(!this.props.session.loggedin) return false;
        const members:Array<IMutualFundMember> = this.props.mutualfunds.funds[this.props.fund].members;
        for(let i = 0; i < members.length; i++) {
            if(members[i].id === this.props.userinfo.id) {
                return true;
            }
        }
        return false;
    }

    handleSlider(e:any) {
        let value = parseInt(e.target.value);
        let coin = e.target.name;
        this.updateCoinMultiValue(coin, value);
    }

    updateCoinMultiValue(coin:string, quantity:number) {
        let multi = {...this.state.coinMultiValues};
        multi[coin] = quantity;
        this.setState({coinMultiValues: multi});
    }

    statCoinName(coin:string) {
        return coin === "luna" ? "himemoriluna" : coin;
    }

    renderAsset(coin:string) {
        let coinStatName:string = this.statCoinName(coin);
        let coinData:ICoinData = this.props.stats.coinInfo.data[coinStatName];
        if(coinData === undefined) return null;

        const ask = coinData.price;
        const bid = coinData.saleValue;
        let prevPrice;
        let hist = this.props.stats.coinHistory[coinStatName];
        if(hist === undefined) {
            prevPrice = ask;
        } else {
            prevPrice = hist.price.slice(-1)[0];
        }

        const portfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        let amt = 0;
        if(portfolio[coinStatName] !== undefined) {
            amt = portfolio[coinStatName].amount;
        }

        const delta = Math.round((ask - prevPrice)*100) / 100;
        const deltaP = Math.round((delta / prevPrice) * 10000) / 100;
        let dir = delta > 0 ? "green" : "red";
        if(delta === 0) dir = "stagnant";

        let meanPurchasePrice = 0;
        if(portfolio[coinStatName] !== undefined) {
            meanPurchasePrice = Math.round(portfolio[coinStatName].meanPurchasePrice * 100) / 100;
        }

        return (
            <tr key={coin}>
                <td className="coin-name-outer flex center-child">
                    <div>
                    <Coin name={coin} />
                    <div className="coin-name">
                        {coin.toUpperCase()}
                    </div>
                    </div>
                </td>
                <td className="coin-fund-shares">
                    {numberWithCommas(amt)}
                </td>
                <td 
                    title={"Mean purchase price: $" + numberWithCommas(meanPurchasePrice)}
                    className="coin-ask">
                    ${numberWithCommas(ask)}
                </td>
                <td className="coin-bid">
                    ${numberWithCommas(bid)}
                </td>
                <td className="fund-coin-delta">
                    <div className={"delta " + dir}>
                        <span className="arrow">
                            {this.priceDirectionIcon(delta)}
                        </span>
                        ${numberWithCommas(Math.abs(delta))}
                    </div>
                    <div className={"delta-p " + dir}>
                        <span className="arrow">
                            {this.priceDirectionIcon(delta)}
                        </span>
                        {numberWithCommas(Math.abs(deltaP))}%
                    </div>
                </td>
                <td>
                    <input 
                        type="range" 
                        min={1} 
                        max={100}
                        name={coin}
                        value={this.state.coinMultiValues[coin]}
                        onChange={(e) => this.handleSlider(e)}/>
                </td>
                <td>
                    <MutualFundTradeButtonWrapper
                        coin={coin}
                        className={"flex flex-row"}
                        fund={this.props.fund}
                        updateCoinQuantity={(coin:string, quantity:number) => this.updateCoinMultiValue(coin, quantity)}
                        coinQuantities={this.state.coinMultiValues} />
                </td>
            </tr>
        )
    }

    getAllCoins() {

        let coins:Array<string> = [];
        lineage.forEach((gen:Array<string>) => {
            coins = [...coins, ...gen];
        });
        return coins;
    }

    getCoinAmountOwned(coin:string) {
        if(this.props.mutualfunds.funds[this.props.fund] === undefined) return 0;
        let portfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        if(portfolio[coin] === undefined) {
            return 0;
        } else {
            return portfolio[coin].amount;
        }
    }

    renderCoinAmounts() {
        let coins = this.getAllCoins();
        return coins.map((coin:string) => {
            let coinAmount = this.getCoinAmountOwned(coin);
            if(coinAmount > 0) {
                return (
                    <div
                        key={coin} 
                        className="coin-amount-item flex flex-row">
                        <Coin name={coin} />
                        <div className="amount">
                            {numberWithCommas(this.getCoinAmountOwned(coin))}
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        })
    }

    renderAssetItems() {
        // let fundPortfolio:IMutualFundPortfolio = this.props.mutualfunds.funds[this.props.fund].portfolio;
        // let coinsInPortfolio = Object.keys(fundPortfolio);
        // coinsInPortfolio = coinsInPortfolio.filter((coin:string) => {
        //     return fundPortfolio[coin].amount > 0;
        // });
        let coins = this.getAllCoins();
        return(
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Fund Shares</th>
                        <th>Ask</th>
                        <th>Bid</th>
                        <th className="delta"></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                    coins.map((coin:string) => {
                        return this.renderAsset(coin);
                    })
                    }
                </tbody>
            </table>
        )
    }

    render() {
        return(
            <>
            <MutualFundSection fund={this.props.fund}>
                <div className="section-header">
                    Portfolio
                </div>
                <div className="fund-portfolio-container flex flex-col">
                    <div className="portfolio-pie-chart-outer">
                        <Pie
                            data={this.state.chartData}
                            options={{
                                animation: {
                                    duration: 300
                                },
                                easing: "easeInOutCubic"
                            }}/>
                    </div>
                    <div className="portfolio-coin-amounts">
                        {this.renderCoinAmounts()}
                    </div>
                </div>
            </MutualFundSection>
            {
                this.isMemberOfFund() ?
                <MutualFundSection fund={this.props.fund}>
                    <div className="section-header">
                        Manage Portfolio
                    </div>
                    <div className="fund-manage-portfolio-container">
                        {this.renderAssetItems()}
                    </div>
                </MutualFundSection> : null
            }
            </>
        );
    }
}

const MutualFundPortfolio = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundPortfolioBind);

export default MutualFundPortfolio;