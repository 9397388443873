import { createActions } from 'redux-actions';

export const settingsActions = createActions(
    {
        SET_THEME: theme => ({ theme }),
        SET_MARKET_SWITCH: open => ({ open }),
        SET_TRADE_NOTIFICATIONS: tradeNotifications => ({ tradeNotifications }),
        SET_NO_MALES: noMales => ({ noMales })
    }
)

export const sessionActions = createActions(
    {
        SET_ADMINS: admins => ({ admins })
    },
    "LOGIN",
    "LOGOUT"
)

export const statsActions = createActions(
    {
        SET_STATS: stats => ({ stats }),
        SET_HISTORY: coinHistory=> ({ coinHistory }),
        SET_COIN_INFO: coinInfo => ({ coinInfo }),
        SET_LEADERBOARD: leaderboard => ({ leaderboard }),
        SET_OSHIBOARD: oshiboard => ({ oshiboard }),
        SET_GACHABOARD: gachaboard => ({ gachaboard }),
        SET_BENCHMARK_LEADERBOARD: benchmarkLeaderboard => ({ benchmarkLeaderboard }),
        SET_BROKER_TOTAL: brokerTotal => ({ brokerTotal }),
        SET_BROKER_FEE: brokerFee => ({ brokerFee })
    }
)

export const userinfoActions = createActions(
    {
        SET_USERNAME: username => ({ username }),
        SET_EMAIL: email => ({ email }),
        SET_ID: id => ({ id }),
        SET_WALLET: wallet => ({ wallet }),
        SET_ICON: icon => ({ icon }),
        SET_PERFORMANCE: performance => ({ performance }),
        SET_ADMIN: admin => ({ admin }),
        SET_VERIFIED: verified => ({ verified }),
        SET_SETTINGS: settings => ({ settings }),
        SET_COLOR: color => ({ color }),
        SET_ITEMS: items => ({ items }),
        SET_MUTED: muted => ({ muted }),
        SET_BROKER_FEE_TOTAL: brokerFeeTotal => ({ brokerFeeTotal }),
        SET_BROKER_FEE_CREDITS: brokerFeeCredits => ({ brokerFeeCredits }),
        SET_HAT: hat => ({ hat }),
        SET_BOOKIE: bookie => ({ bookie }),
        SET_SOCKET_ID: socketid => ({ socketid }),
        SET_MUTUAL_FUND_JOIN_REQUESTS: mutualfundJoinRequests => ({ mutualfundJoinRequests }),
        SET_MUTUAL_FUND_ORDERS: orders => ({ orders }),
        SET_BLOG_PERMISSION: blogPermission => ({ blogPermission }),
        SET_MUTUAL_FUND_COLLECTION: mutualfundCollection => ({ mutualfundCollection }),
        REMOVE_FUND_INFO: fund => ({ fund }),
    },
    "SET_LOADED",
    "UNLOAD"
)

export const transactionActions = createActions(
    {
        ADD_TRANSACTION: transaction => ({ transaction })
    },
    "REMOVE_TRANSACTION"
)

export const auctionsActions = createActions(
    {
        SET_ACTIVE_AUCTIONS: activeAuctions => ({ activeAuctions }),
        SET_PAST_AUCTIONS: pastAuctions => ({ pastAuctions }),
        SET_AUCTION_FEEDS: auctionFeeds => ({ auctionFeeds }),
        SET_AUCTION_SUBSCRIPTIONS: subscriptions => ({ subscriptions }),
        SET_AUCTION_PRICE_HISTORY: auctionPriceHistory => ({ auctionPriceHistory }),
        SET_AUCTION_NOTIFICATIONS: auctionNotifications => ({ auctionNotifications }),
        SET_AUCTION_FILTERS: auctionFilters => ({ auctionFilters })
    }
)

export const floorActions = createActions(
    {
        SET_FLOOR_SPACE: floorSpace => ({ floorSpace }),
        SET_ACTIVE_ROOM: activeRoom => ({ activeRoom }),
        SET_CHAT_LOG: chat => ({ chat }),
        SET_REPLIES_VISIBLE: visible => ({ visible }),
        SET_REPLIES: replies => ({ replies }),
        SET_REPLY_STACK: replyStack => ({ replyStack }),
        SET_LOADING: loading => ({ loading }),
        SET_POST_COOLDOWN: post => ({ post }),
        SET_ROOM_COOLDOWN: room => ({ room }),
        SET_REPLY_SOURCE: message => ({ message }),
        SET_REPLY_SOURCE_COORDS: coords => ({ coords })
    }
)

export const superchatsActions = createActions(
    {
        SET_COOLDOWN: cooldown => ({ cooldown }),
        SET_SUPA_DAILY: daily => ({ daily }),
        SET_SUPA_HISTORY: history => ({ history }),
        SET_ENABLE_DANMAKU: enableDanmaku => ({ enableDanmaku })
    }
)

export const autotraderActions = createActions(
    {
        SET_RUNNING: running => ({ running }),
        SET_RULES: rules => ({ rules }),
        SET_NEXT_TRADE_TIME: nextTradeTime => ({ nextTradeTime }),
        SET_EXPECTED_BALANCE: expectedBalance => ({expectedBalance}),
        SET_PENDING_ORDER: pendingOrder => ({ pendingOrder }),
        SET_UPDATED_RULES: updatedRules => ({ updatedRules })
    }
)

export const itemcatalogueActions = createActions(
    {
        SET_CATALOGUE: catalogue => ({ catalogue })
    }
)

export const itemmarketpricesActions = createActions(
    {
        SET_ITEM_MARKET_PRICES: itemMarketPrices => ({ itemMarketPrices })
    }
)

export const adminActions = createActions(
    {
        SET_ADMIN_USERS: users => ({ users }),
        SET_ADMIN_FILTERS: filters => ({ filters }),
        SET_ADMIN_REPORTS: reports => ({ reports }),
        SET_ADMIN_ADJUSTMENT_CONTROLS: adjustmentControls => ({ adjustmentControls }),
        SET_SPAM_TRACKER: spamTracker => ({ spamTracker }),
        SET_DIVIDEND_TOGGLES: dividendToggles => ({ dividendToggles }),
        SET_VOLATILITY_MULTIPLIERS: volatilityMultipliers => ({ volatilityMultipliers }),
        SET_OVERBOUGHT: overbought => ({ overbought }),
        SET_OVERSOLD: oversold => ({ oversold }),
        SET_BOGRATION_LEVEL: bogrationLevel => ({ bogrationLevel }),
        SET_UPWARDS_REDUCTION_LEVEL: upwardsReductionLevel => ({ upwardsReductionLevel }),
        SET_BOOKIES: bookies => ({ bookies }),
        SET_CHAT_REPORTS: chatReports => ({ chatReports }),
        SET_BLOG_PERMISSIONS: blogPermissions => ({ blogPermissions }),
    }
)

export const snowfallActions = createActions(
    {
        SET_SHOW_SNOW_NOTIFICATION: showSnowNotification => ({ showSnowNotification }),
        SET_SNOW_SIZE: snowSize => ({ snowSize }),
        SET_SHOW_SNOW_SETTINGS: showSnowSettings => ({ showSnowSettings}),
        SET_SNOW_SPEED: snowSpeed => ({ snowSpeed }),
        SET_SNOW_AMOUNT: snowAmount => ({ snowAmount }),
    }
)

export const gachaActions = createActions(
    {
        SET_RECEIVED_ITEMS: receivedItems => ({ receivedItems })
    }
)

export const socketActions = createActions(
    {
        SET_SOCKET: socket => ({ socket }),
        SET_SOCKET_QUERY: query => ({ query })
    },
    "REMOVE_SOCKET"
)

export const multicoinSaveActions = createActions(
    {
        SET_MULTICOIN_SAVE: multicoinSave => ({ multicoinSave })
    }
)

export const benchmarkActions = createActions(
    {
        SET_BENCHMARK_ALL: (index, marketCap, totalShares, weights, runningHistory, history) => ({
            index,
            marketCap,
            totalShares,
            weights,
            runningHistory,
            history
        }),
        SET_BENCHMARK_INDEX: index => ({ index }),
        SET_BENCHMARK_MARKET_CAP: marketCap => ({ marketCap }),
        SET_BENCHMARK_TOTAL_SHARES: totalShares => ({ totalShares}),
        SET_BENCHMARK_WEIGHTS: weights => ({ weights }),
        SET_BENCHMARK_RUNNING_HISTORY: runningHistory => ({ runningHistory }),
        SET_BENCHMARK_HISTORY: history => ({ history }),
    }
)

export const bettingActions = createActions(
    {
        SET_BETTING_POOLS: (bettingPools) => ({ bettingPools }),
        UPDATE_POOL: (bettingPool) => ({ bettingPool })
    }
)

export const chatActions = createActions(
    {
        SET_BLOCKING: (blocking) => ({ blocking }),
        SET_BLOCKED_BY: (blockedBy) => ({ blockedBy }),
        SET_DIRECT_MESSAGE_ROOMS: (directMessageRooms) => ({ directMessageRooms }),
        SET_DIRECT_MESSAGE_LOGS: (directMessageLogs) => ({ directMessageLogs }),
        SET_GLOBAL_CHAT: (globalChat) => ({ globalChat }),
        ADD_DIRECT_MESSAGE_ROOM: (room) => ({ room }),
        ADD_DIRECT_MESSAGE: (roomid, message) => ({ roomid, message }),
        ADD_GLOBAL_CHAT_MESSAGE: (message) => ({ message }),
        SET_LOADED_ROOMS: (loadedRooms) => ({ loadedRooms }),
    }
)

export const mutualfundActions = createActions(
    {
        SET_FUNDS: (funds) => ({ funds }),
        SET_FUND_STATS: (fundstats) => ({ fundstats }),
        SET_FUND_HISTORY: (fundhistory) => ({ fundhistory }),
        SET_FUND_BULLETIN_BOARDS: (bulletinBoards) => ({ bulletinBoards }),
        SET_FUND_CHAT: (chat) => ({ chat }),
        SET_JOIN_REQUESTS: (joinRequests) => ({ joinRequests }),
        SET_FUND_SOCKET_IDS: (socketIds) => ({ socketIds }),
        SET_FUND_ORDER_TOTALS: (orders) => ({ orders }),
        REMOVE_FUND: (fund) => ({ fund }),
        SET_FUND_PAYOUTS: (fundPayouts) => ({fundPayouts}),
        SET_FUNDS_TO_DISSOLVE: (fundsToDissolve) => ({ fundsToDissolve }),
        SET_FUND_USER_TOTALS: (fundUserTotals) => ({ fundUserTotals }),
        SET_FUNDS_LOADED: (loaded) => ({ loaded }),
        SET_FUND_AUTOTRADER: (fund, autotrader) => ({ fund, autotrader })
    }
)

export const devblogActions = createActions(
    {
        SET_BLOG_POSTS: (posts) => ({posts}),
        ADD_BLOG_POST: (post) => ({ post })
    }
)