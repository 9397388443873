import React, {Component} from 'react';
import { IDevBlogPost } from '../../interfaces/DevBlog';

import {CgCloseO} from 'react-icons/cg';
import postRequest from '../../postRequest';

interface EditBlogPostProps {
    blogPost:IDevBlogPost;
    toggleEdit: () => void;
}

class EditBlogPostState {
    title:string = "";
    signature:string = "";
    post:string = "";
    updated:boolean = false;
    error:string = "";
}

class EditBlogPost extends Component<EditBlogPostProps> {

    state:EditBlogPostState;
    constructor(props:EditBlogPostProps) {
        super(props);
        this.state = new EditBlogPostState();
    }

    componentDidMount() {
        this.setState({
            title:this.props.blogPost.title,
            signature:this.props.blogPost.signature,
            post:this.props.blogPost.post
        });
    }

    updateValue(e:any) {
        this.setState({[e.target.name]:e.target.value}, () => {
            this.isUpdated();
        });
    }

    isUpdated() {
        let updated = 
            this.state.title !== this.props.blogPost.title ||
            this.state.post !== this.props.blogPost.post ||
            this.state.signature !== this.props.blogPost.signature;
        this.setState({updated});
    }

    editPost() {
        if(!this.state.updated) return;
        if(
            this.state.title === "" ||
            this.state.post === "" ||
            this.state.signature === ""
        ) {
            this.setState({error: "Enter all fields."});
            return;
        }
        if(this.state.title.length > 100) {
            this.setState({error: "Title too long. Max length 100."});
            return;
        }
        if(this.state.post.length > 3000) {
            this.setState({error: "Post too long. Max length 3000."});
            return;
        }
        if(this.state.signature.length > 50) {
            this.setState({error: "Signature too long. Max length 50."});
            return;
        }
        postRequest("/api/editBlogPost", {
            id:this.props.blogPost.id,
            post:this.state.post,
            title:this.state.title,
            signature:this.state.signature
        }).then((data:any) => {
            if(!data.success) {
                this.setState({error:data.message});
            } else {
                this.props.toggleEdit();
            }
        }).catch((err:any) => {
            console.error(err);
            this.setState({error:"Error updating post"});
            return;
        })
    }

    render() {
        let className = "edit-blog-post-container";
        if(this.state.updated) className += " updated";  
        return(
            <div className={className}>
                <div className="edit-blog-post-inner flex flex-col">
                    <div
                        onClick={() => this.props.toggleEdit()} 
                        className="close-blog">
                        <CgCloseO style={{verticalAlign: 'middle'}}/>
                    </div>

                    <div className="edit-header">
                        Edit Post
                    </div>
                    <div className="input-label">Title</div>
                    <input
                        name="title"
                        value={this.state.title}
                        onChange={(e) => this.updateValue(e)} 
                        type="text" />
                    <div className="input-label">Signature</div>
                    <input
                        name="signature"
                        value={this.state.signature}
                        onChange={(e) => this.updateValue(e)} 
                        type="text" />
                    <div className="input-label">Post</div>
                    <textarea
                        value={this.state.post}
                        onChange={(e) => this.updateValue(e)} 
                        name="post"></textarea>
                    {
                        this.state.updated ?
                        <div   
                            onClick={() => this.editPost()} 
                            className="simple-button">
                            Update
                        </div> : null
                    }
                    {
                        this.state.error ?
                        <div className="error">
                            {this.state.error}
                        </div> : null
                    }
                </div>
            </div>
        )
    }
}

export default EditBlogPost;