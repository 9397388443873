import React, {Component} from 'react';
import {connect} from 'react-redux';

import "../../../css/mutualfunds/mutualfundsidebar.scss";
import { lineage } from '../Icons';

import {
    IoIosCloseCircle
} from 'react-icons/io';
import {
    FiScissors
} from 'react-icons/fi';
import Coin from '../Coin';
import numberWithCommas from '../../numberWithCommas';
import { IWallet } from '../../interfaces/IWallet';
import { userinfoActions } from '../../actions/actions';
import { ItemImages } from '../ItemImages';
import Button from '../Button';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    stats:state.stats,
    itemmarketprices:state.itemmarketprices
});

const mapDispatchToProps = {
    setWallet: userinfoActions.setWallet,
    setItems: userinfoActions.setItems
}

class CreateMutualFundState {
    closing:boolean = false;

    fundName:string = "";
    icon:string|null = null;
    missionStatement:string = "";
    tag:string = "";
    color:string = "";
    initialInvestment:string = "";
    startingShares:string = "0";

    showCoinSelection:boolean = false;
    error:string = '';
}

interface CreateMutualFundProps {
    userinfo: {
        wallet:IWallet,
        items:any
    },
    stats: {
        coinInfo:any
    },
    itemmarketprices:any,
    close:() => void;
    setWallet: (wallet:IWallet) => {},
    setItems: (items:{}) => {}
}

class CreateMutualFundBind extends Component<CreateMutualFundProps> {
    
    state:CreateMutualFundState;
    constructor(props:CreateMutualFundProps) {
        super(props);
        this.state = new CreateMutualFundState();
    }

    close() {
        this.setState({closing:true});
        setTimeout(() => {
            this.props.close();
        }, 50);
    }

    renderAllCoins() {
        const coinsLineage = [...lineage];
        const coins:Array<any> = [];
        coinsLineage.forEach((gen:Array<string>) => {
            gen.forEach((coinname:string) => {
                coins.push(
                     <div
                        key={coinname}
                        onClick={() => this.selectCoin(coinname)}
                        className="coin-item">
                            <Coin name={coinname} />
                     </div>
                )
            })
        })
        return coins;
    }

    updateTextValue(e:any) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    toggleCoinSelection() {
        this.setState({showCoinSelection:!this.state.showCoinSelection});
    }

    selectCoin(coin:string) {
        this.setState({
            icon:coin,
            showCoinSelection:false
        });
    }

    handleInvestment(e:any) {
        let text = e.target.value;
        text = text.replace(/\$/g, '');
        text = text.replace(/,/g, '');
        let amount = parseInt(text);
        if(isNaN(amount)) {
            this.setState({initialInvestment:''})
        } else {
            let amountString = numberWithCommas(amount);
            amountString = "$" + amountString;
            this.setState({initialInvestment:amountString});
        }
    }

    getMinInvestmentAmount() {
        let coinInfo = this.props.stats.coinInfo;
        let total = 0;
        Object.keys(coinInfo.data).forEach((coin:string) => {
            total += coinInfo.data[coin].price;
        })
        return Math.round((total / Object.keys(coinInfo.data).length) * 100);
    }

    hasLicense() {
        let items = this.props.userinfo.items;
        if(items['license'] === undefined) {
            return false;
        } else {
            for(let i = 0; i < items['license'].length; i++) {
                if(items['license'][i].itemType === "PortfolioManagerLicense") {
                    return true;
                }
            }
            return false;
        }
    }

    buyLicense() {
        
        if(this.props.userinfo.wallet.balance < this.props.itemmarketprices['PortfolioManagerLicense'].price) {
            return;
        }
        fetch('/api/placeItemMarketBuy/', {
            method: 'POST',
            body: JSON.stringify({
                item:"PortfolioManagerLicense"
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.props.setWallet(data.wallet);
                this.props.setItems(data.inventory);
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        })
    }

    submitFund() {
        
        if(this.state.fundName === '' || this.state.fundName.length > 50) {
            this.setState({error:"Invalid name."});
            return;
        }
        
        if(this.state.icon === null) {
            this.setState({error:"You must choose an icon."});
            return;
        }
        
        if(this.state.missionStatement === '') { 
            this.setState({error:"Invalid mission statement."});
            return;
        } 

        if(this.state.missionStatement.length > 1000) {
            this.setState({error:"Invalid mission statement. Must not exceed 1000 characters."});
            return;
        }

        if(this.state.color === '') {
            this.setState({error:"You must pick a color."});
            return;
        }

        if(this.state.tag === '') {
            this.setState({error:"You must enter a tag."});
            return;
        }

        if(this.state.tag.length > 15) {
            this.setState({error:"Invalid tag. Must not exceed 15 characters."});
            return;
        }

        if(this.state.startingShares === '') {
            this.setState({error:"You must enter as starting share amount of 1,000-100,000."});
            return;
        }

        let startingShares:number = parseInt(this.state.startingShares.replace(/,/g, ''));
        if(isNaN(startingShares)) {
            this.setState({error:"You must enter as starting share amount of 1,000-100,000."});
            return;
        }

        if(startingShares < 1000 || startingShares > 100000) {
            this.setState({error:"You must enter as starting share amount of 1,000-100,000."});
            return;
        }

        let investmentAmount:number = parseInt(this.state.initialInvestment.replace(/,/g, '').replace(/\$/g, ''));
        if(isNaN(investmentAmount)) {
            this.setState({error:"Invalid investment."})
            return;
        }
        
        if(investmentAmount > this.props.userinfo.wallet.balance) {
            this.setState({error:"Invalid investment. Insufficient balance to invest."})
            return;
        }
        
        let minAmount:number = this.getMinInvestmentAmount();
        if(investmentAmount < minAmount) {
            this.setState({error:"Invalid investment. You must invest at least the minimum amount."})
            return;
        }

        let icon = this.state.icon === "luna" ? "himemoriluna" : this.state.icon;

        fetch('/api/createMutualFund/', {
            method: 'POST',
            body: JSON.stringify({
                name:this.state.fundName,
                icon,
                missionStatement:this.state.missionStatement,
                tag:this.state.tag,
                color:this.state.color,
                startingShares:startingShares,
                initialInvestment:investmentAmount
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                this.close();
            } else {
                this.setState({error:data.message});
            }
        })
        .catch(error => {
            console.error('Error: ' +  error);
        });
        
    }

    getLicenseButtonClass() {
        let className = "inverse green ";
        if(this.props.userinfo.wallet.balance < this.props.itemmarketprices['PortfolioManagerLicense'].price) {
            className += "disabled";
        }
        return className;
    }

    render() {
        return(
            <div className="mutual-funds-sidebar-container flex flex-row">
                <div 
                    className="close-sidebar-space"
                    onClick={() => this.close()}></div>
                <div 
                    className={"create-fund-form-outer " + 
                        (this.state.closing ? "closing" : "")}>

                    <div
                        onClick={() => this.close()} 
                        className="close-form-button">
                        <IoIosCloseCircle />
                    </div>

                    <div className="form-header">Create a Mutual Fund</div>
                    <div className="form-description">
                        Become a CEO of your own mutual fund! First, you must purchase a portfolio manager license. Once you've done this, you can create your very own company. You will invest a lump sum of your own cash to start out, and from there you can recruit more portfolio managers and get other players to invest in you!
                    </div>

                    {
                        this.hasLicense() ?
                        <>
                        <div className="form-item">
                            <div className="form-label">Mutual Fund Name</div>
                            <div className="form-input-description">
                                The name of your mutual fund.
                            </div>
                            <div className="form-input">
                                <input 
                                    type="text" 
                                    name="fundName" 
                                    maxLength={50}
                                    value={this.state.fundName}
                                    onChange={(e) => this.updateTextValue(e)}/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-label">Icon</div>
                            <div className="form-input-description">
                                Choose a coin to represent your fund.
                            </div>
                            <div className="form-input">
                                {
                                    this.state.icon === null ?
                                    <div
                                        onClick={() => this.toggleCoinSelection()}  
                                        className="no-coin-selected">
                                        None
                                    </div>
                                    :
                                    <div
                                        onClick={() => this.toggleCoinSelection()} 
                                        className="selected-coin">
                                        <Coin name={this.state.icon}/>
                                    </div>
                                }
                                { 
                                    this.state.showCoinSelection ?
                                    <div className="coin-selection">
                                        {this.renderAllCoins()}
                                    </div> : null
                                }
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-label">Mission Statement</div>
                            <div className="form-input-description">
                                Provide a brief statement that says a little about your fund, from goals to motivation to world domination.
                            </div>
                            <div className="form-input">
                                <textarea
                                    name="missionStatement" 
                                    value={this.state.missionStatement}
                                    maxLength={1000}
                                    onChange={(e) => this.updateTextValue(e)}></textarea>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-label">Tag</div>
                            <div className="form-input-description">
                                A short tag that will appear next to you and your members' names throughout the site.
                            </div>
                            <div className="form-input">
                                <input 
                                    type="text" 
                                    name="tag" 
                                    maxLength={15}
                                    value={this.state.tag}
                                    onChange={(e) => this.updateTextValue(e)}/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-label">Color</div>
                            <div className="form-input-description">
                                Choose an accent color that will represent your fund.
                            </div>
                            <div className="form-input">
                                <input 
                                    name="color" 
                                    value={this.state.color}
                                    onChange={(e) => this.updateTextValue(e)} 
                                    id="fund-color-input" type="color"/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-label">Initial Investment</div>
                            <div className="form-input-description">
                                The amount of money you would like to invest into your fund. Must be MINIMUM 100x the mean coin price. You will earn more cash to invest with as players buy shares of your fund.
                            </div>
                            <div className="min-investment flex flex-row">
                                <div className="min-investment-label">Current Minimum:</div>
                                <div className="min-investment-value">
                                    ${numberWithCommas(this.getMinInvestmentAmount())}
                                </div>
                            </div>
                            <div className="min-investment flex flex-row">
                                <div className="min-investment-label">My Balance</div>
                                <div className="min-investment-value">
                                    ${numberWithCommas(Math.round(this.props.userinfo.wallet.balance))}
                                </div>
                            </div>
                            <div className="form-input">
                                <input
                                    type="text" 
                                    name="initialInvestment" 
                                    value={this.state.initialInvestment}
                                    onChange={(e) => this.handleInvestment(e)}/>
                            </div>
                        </div>
                        <div className="form-item">
                            <div className="form-label">Starting Shares</div>
                            <div className="form-input-description">
                                The number of shares you want to start out with. You, as the CEO, will own all of these shares. More shares means a lower price per share. You can start with 1,000 to 100,000 shares.
                            </div>
                            <div className="form-input">
                                <input
                                    type="text" 
                                    name="startingShares" 
                                    value={this.state.startingShares}
                                    onChange={(e) => this.updateTextValue(e)}/>
                            </div>
                        </div>

                        <div
                            onClick={() => this.submitFund()} 
                            className="create-fund-button">
                            Create <FiScissors />
                        </div>
                            
                        {
                            this.state.error !== '' ?
                            <div className="create-fund-error">
                                {this.state.error}                            
                            </div> : null
                        }
                        </>
                        :
                        <div className="buy-license-outer flex center-child flex-col">
                            <div className="buy-license-header">
                                Portfolio Manager License: 
                                <span className="price">
                                    ${numberWithCommas(this.props.itemmarketprices['PortfolioManagerLicense'].price)}
                                </span>
                            </div>
                            <div className="buy-license-action flex flex-row">
                                <div className="license-image">
                                    <img src={ItemImages["PortfolioManagerLicense"]} alt="A License" />
                                </div>
                                <Button 
                                    onClick={() => this.buyLicense()}
                                    className={this.getLicenseButtonClass()}>
                                    BUY
                                </Button>
                            </div>
                        </div>
                    }

                    

                </div>
            </div>
        )
    }
}

const CreateMutualFund = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateMutualFundBind);

export default CreateMutualFund;