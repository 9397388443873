import React, {Component} from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import {connect} from 'react-redux';
import { IMutualFundBulletin, IMutualFundBulletinCollection, IMutualFunds } from '../../interfaces/MutualFunds';
import "../../../css/mutualfunds/mutualfundsidebar.scss";
import "../../../css/mutualfunds/allbulletins.scss";
import hexToRgb from '../../hexToRBG';
import getForegroundFromBackground from '../../getForegroundFromBackground';

const mapStateToProps = (state:any) => ({
    session:state.session,
    userinfo:state.userinfo,
    mutualfunds:state.mutualfunds
});

const mapDispatchToProps = {
}

interface IMutualFundAllBulletinsProps {
    mutualfunds: {
        funds: IMutualFunds,
        bulletinboards: IMutualFundBulletinCollection,
    },
    close: () => void
}

const pageSize = 50;

class MutualFundAllBulletinsState {
    closing:boolean = false;
    page:number = 0;
}

class MutualFundAllBulletinsBind extends Component<IMutualFundAllBulletinsProps> {

    state:MutualFundAllBulletinsState;
    constructor(props:IMutualFundAllBulletinsProps) {
        super(props);
        this.state = new MutualFundAllBulletinsState();
    }
    
    close() {
        this.setState({closing:true});
        setTimeout(() => {
            this.props.close();
        }, 50);
    }

    getColor(fund:string) {
        let f = this.props.mutualfunds.funds[fund];
        return "#" + f.color;
    }

    getForegroundColor(fund:string) {
        let {r, g, b} = hexToRgb(this.getColor(fund));
        return getForegroundFromBackground(r, g, b);
    }

    getAllBulletins() {
        let bulletins:Array<IMutualFundBulletin> = [];
        Object.keys(this.props.mutualfunds.bulletinboards).forEach((fund:string) => {
            bulletins = [...bulletins, ...this.props.mutualfunds.bulletinboards[fund]];
        });
        return bulletins;
    }

    renderBulletins() {
        let bulletins:Array<IMutualFundBulletin> = this.getAllBulletins();
        
        bulletins.sort((a:IMutualFundBulletin, b:IMutualFundBulletin) => {
            return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        });

        if(bulletins.length === 0) {
            return(
                <div className="no-bulletins">
                    There are no bulletins.
                </div>
            )
        } else {

            let startIndex = this.state.page * pageSize;
            let endIndex = startIndex + pageSize;
            let visibleBulletins = bulletins.slice(startIndex, endIndex);

            return visibleBulletins.map((bulletin:IMutualFundBulletin) =>
                <div className="bulletin-item">
                    <div className="bulletin-info flex flex-row">
                        <div className="bulletin-timestamp">
                            {new Date(bulletin.timestamp).toLocaleString()}
                        </div>
                        <div
                            style={{
                                "backgroundColor":this.getColor(bulletin.fund),
                                "color":this.getForegroundColor(bulletin.fund)
                            }} 
                            className="bulletin-fund">
                            {this.props.mutualfunds.funds[bulletin.fund].name}
                        </div>
                    </div>
                    <div className="bulletin-author">
                        {bulletin.authorName}
                    </div>
                    <div className="bulletin-message">
                        {bulletin.message}
                    </div>
                </div>
            )
        }
    }

    renderPageSelect() {
        let bulletins: Array<IMutualFundBulletin> = this.getAllBulletins();
        let pages = Math.ceil(bulletins.length / pageSize);
        let pageItems:Array<any> = [];
        for(let i = 0; i < pages; i++) {
            pageItems.push(
            <div
                key={i}
                onClick={() => this.setState({page:i})}
                className={"bulletin-page-item " + (i === this.state.page ? "active-page-item" : "")}>
                {i + 1}
            </div>)
        }
        return pageItems;
    }

    render() {
        return(
            <div className="mutual-funds-sidebar-container flex flex-row">
                <div 
                    className="close-sidebar-space"
                    onClick={() => this.close()}></div>
                <div 
                    className={"create-fund-form-outer " + 
                        (this.state.closing ? "closing" : "")}>

                    <div
                        onClick={() => this.close()} 
                        className="close-form-button">
                        <IoIosCloseCircle />
                    </div>
                    <div className="form-header">Bulletins</div>

                    <div className="bulletins-page-select">
                        {this.renderPageSelect()}
                    </div>
                    <div className="bulletins">
                        {this.renderBulletins()}
                    </div>
                    <div className="bulletins-page-select">
                        {this.renderPageSelect()}
                    </div>
                </div>
            </div>
        )
    }

}

const MutualFundAllBulletins = connect(
    mapStateToProps,
    mapDispatchToProps
)(MutualFundAllBulletinsBind);

export default MutualFundAllBulletins;