import { handleActions } from 'redux-actions';

const mutualfunds = handleActions(
    {
        SET_FUNDS: (state:any, action:any) => ({
            ...state,
            funds: action.payload.funds
        }),
        SET_FUND_STATS: (state:any, action:any) => ({
            ...state,
            fundstats: action.payload.fundstats
        }),
        SET_FUND_HISTORY: (state:any, action:any) => ({
            ...state,
            fundhistory: action.payload.fundhistory
        }),
        SET_FUNDS_LOADED: (state:any, action:any) => ({
            ...state,
            loaded: action.payload.loaded
        }),
        SET_FUND_BULLETIN_BOARDS: (state:any, action:any) => ({
            ...state,
            bulletinboards: action.payload.bulletinBoards
        }),
        SET_FUND_CHAT: (state:any, action:any) => ({
            ...state,
            chat: action.payload.chat
        }),
        SET_JOIN_REQUESTS: (state:any, action:any) => ({
            ...state,
            joinRequests: action.payload.joinRequests
        }),
        SET_FUND_SOCKET_IDS: (state:any, action:any) => ({
            ...state,
            socketIds: action.payload.socketIds
        }),
        SET_FUND_USER_TOTALS: (state:any, action:any) => ({
            ...state,
            fundUserTotals: action.payload.fundUserTotals
        }),
        SET_FUND_ORDER_TOTALS: (state:any, action:any) => ({
            ...state,
            orders: action.payload.orders
        }),
        SET_FUND_PAYOUTS: (state:any, action:any) => ({
            ...state,
            fundPayouts: action.payload.fundPayouts
        }),
        SET_FUNDS_TO_DISSOLVE: (state:any, action:any) => ({
            ...state,
            fundsToDissolve: action.payload.fundsToDissolve
        }),
        SET_FUND_AUTOTRADER: (state:any, action:any) => ({
            ...state,
            autotraders: {
                ...state.autotraders,
                [action.payload.fund]: action.payload.autotrader
            }
        }),
        REMOVE_FUND: (state:any, action:any) => {
            let fund = action.payload.fund;
            if(state.funds[fund] !== undefined) {
                delete state.funds[fund];
                delete state.fundstats[fund];
                delete state.fundhistory[fund];
                delete state.bulletinboards[fund];
                delete state.autotraders[fund];
                delete state.fundUserTotals[fund];
            }
            if(state.joinRequests[fund] !== undefined) {
                delete state.joinRequests[fund];
            }
            if(state.chat[fund] !== undefined) {
                delete state.chat[fund];
            }
            if(state.socketIds[fund] !== undefined) {
                delete state.socketIds[fund];
            }
            if(state.orders[fund] !== undefined) {
                delete state.orders[fund];
            }
            if(state.fundPayouts[fund] !== undefined) {
                delete state.fundPayouts[fund];
            }
            if(state.fundsToDissolve[fund] !== undefined) {
                delete state.fundsToDissolve[fund];
            }
            return {
                funds: state.funds,
                fundstats: state.fundstats,
                fundhistory: state.fundhistory,
                bulletinboards: state.bulletinboards,
                joinRequests: state.joinRequests,
                chat: state.chat,
                socketIds: state.socketIds,
                orders: state.orders,
                fundPayouts: state.fundPayouts,
                fundsToDissolve: state.fundsToDissolve,
                autotraders: state.autotraders
            }
        }
    },
    {
        loaded:false,
        funds: {},
        fundstats: {},
        fundhistory: {},
        bulletinboards: {},
        joinRequests: {},
        chat: {},
        socketIds: {},
        orders: {},
        fundUserTotals: {},
        fundPayouts: {},
        fundsToDissolve: {},
        autotraders: {}
    }
)

export { mutualfunds as default }